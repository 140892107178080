// import AdminLoginSvg from "../../assets/illustration_maintenance.svg";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleLoader from "../../components/Loader";
import ResistoreIcon from "../../assets/signup.jpg";

import {
  Container,
  Typography,
  Box,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";

import Header from "../../components/Header";
import shapeResend from "../../assets/resend.svg";

import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser, storeToken } from "../../redux/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import "../../App.css";
import { LoadingButton } from "@mui/lab";
import { SuccessMsg } from "../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
import { Typography18 } from "../../components/typography/Typography";

export default function TwoStepVerification() {
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const [verifyBtnDisabled, setverifyBtnDisabled] = useState(true);
  const [ResendVerifyLoading, setResendVerifyLoading] = useState(false);
  const [open, setopen] = useState(false);
  const { state } = useLocation();
  const { username } = state;
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const [ResendOTPBtnDisabled, setResendOTPBtnDisabled] = useState(false);

  const handleOnChangeOTP = (e: any) => {
    setOTP(e.target.value);
    if (e.target.value !== "") {
      setverifyBtnDisabled(false);
    } else {
      setverifyBtnDisabled(true);
    }
  };

  // handleFormSubmit
  const handleFormSubmitLogin = async (e: any) => {
    e.preventDefault();
    setopen(true);
    setverifyBtnDisabled(true);
    let token;
    let data = new FormData(e.currentTarget);
    const FetchData = await axios
      .post("https://api.dev.assuredpay.in/auth/verifylogin", {
        username: username,
        otp: data.get("OTP"),
      })
      .then((res) => {
        if (res.status === 200 && res.data.Active === true) {
          let getUserLogin = res.data;
          setopen(false);
          dispatch(loginUser(true));
          token = getUserLogin.result.token;
          console.log(getUserLogin.result, "getUserLogin----");

          console.log(
            getUserLogin.result.userKyc,
            "getUserLogin--ndkjbdjhdbjhdbjd--"
          );
          dispatch(storeToken(getUserLogin.result.token));
          localStorage.setItem("User", getUserLogin.result);
          localStorage.setItem("photo", getUserLogin.result.userKyc.photo);
          localStorage.setItem(
            "Token",
            JSON.stringify(getUserLogin.result.token)
          );
          setResendVerifyLoading(false);
          // sessionStorage.setItem("User", getUserLogin.result);
          sessionStorage.setItem(
            "Token",
            JSON.stringify(getUserLogin.result.token)
          );
          SuccessMsg("Login Successfully !");
        }
      })
      .catch((err) => {
        ErrorMsg(`${err.response.data.message}, Please try again`);
        setResendVerifyLoading(false);
        setopen(false);
        // setErrorMsg(true);
      });

    let configHeaders = {
      headers: {
        token: token,
      },
    };
    const FetchData2 = await axios
      .get("https://api.dev.assuredpay.in/auth/profileStatus", configHeaders)
      .then((res) => {
        let ProfileStatus = res.data.result.profileStatus;
        //  EMAIL & MOBILE NOT VERIFIED / User onboarding ---
        if (ProfileStatus <= 0) {
          navigate("/verify");
        }
        //  PROCEED TO CHECKOUT --- COUPON
        else if (ProfileStatus === 1) {
          navigate("/auth/onboardingpays");
        }
        // GST PROCESS
        else if (ProfileStatus === 2) {
          navigate("/auth/userkyc");
        }
        // UPLOAD GST DOUCMENTS
        else if (ProfileStatus === 3) {
          navigate("/auth/upload-files");
        }
        // VERIFY ADHAAR
        else if (ProfileStatus === 4) {
          navigate("/auth/verifyaadhar");
        }
        // VERIFY PAN
        else if (ProfileStatus === 5) {
          navigate("/auth/verifypan");
        }
        // VERIFY PAN UPLOAD DOCUMENTS
        else if (ProfileStatus === 6) {
          navigate("/auth/panuploadfiles");
        }
        // VERIFY DOCUMENT APPROVAL
        else if (ProfileStatus === 7) {
          navigate("/auth/approval");
        }
        // AFTER SUCCESSFULL FINAL STATUS
        else if (ProfileStatus === 8) {
          navigate("/dashboard/payment");
        }
      })
      .catch((err) => {
        // setErrorMsg(true);
        ErrorMsg("Something went Wrong, Please try again");
        console.log("err", err);
      });
  };

  const resendOTPTimeSet = async () => {
    setResendVerifyLoading(true);
    setResendOTPBtnDisabled(true);
    setSeconds(30);
    const FetchData = await axios
      .post("https://api.dev.assuredpay.in/auth/resendverifycode", {
        username: username,
      })
      .then((res) => {
        console.log("Resend Otp Response --", res);
        if (res.status === 200 && res.data.Active === true) {
          // I will get token from response and set into Redux Store
          setResendVerifyLoading(false);
          SuccessMsg("OTP successfully send to your register mobile number");
        }
      })
      .catch((err) => {
        // setSeconds(0);
        console.log("Resend Otp Error -- ", err);
        ErrorMsg("Something went wrong, Please try again");
        setResendVerifyLoading(false);
        setResendOTPBtnDisabled(false);
      });
  };
  const resendOTPOnClick = () => {
    setTimeout(() => {
      if (seconds === 0) {
        setResendOTPBtnDisabled(false);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };
  useEffect(() => {
    resendOTPOnClick();
  }, [seconds]);

  return (
    <>
      <Header />
      <Box margin={2} sx={{ flexGrow: 1 }}>
        <Container>
          <Box
            margin={4}
            justifyContent={"center"}
            sx={{ display: "flex", flexDirection: "column" }}
            alignItems={"center"}
          >
            <Grid container spacing={{ xs: 5, md: 9, lg: 13 }}>
              <Grid>
                <Box
                  sx={{
                    boxShadow: 1,
                    borderRadius: 2,
                    px: 6,
                    pt: 4,
                    pb: 1,
                    marginTop: 15,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "340px",
                  }}
                >
                  <Stack spacing={0.5} sx={{ mb: 3, position: "relative" }}>
                    <Typography18
                      fontWeight={700}
                      lineHeight={"30px"}
                      color={"#334A67"}
                      text={"Two-step Verification"}
                    ></Typography18>
                    <Typography
                      component="div"
                      fontSize={12}
                      fontWeight={500}
                      fontFamily={"Public Sans"}
                      color={"#556486"}
                      letterSpacing={0.2}
                    >
                      {/* Enter code from your authenticator app for */}
                      Please enter your code for Two-step verification to
                      <br /> your username{" "}
                    </Typography>
                    <Box
                      component="form"
                      onSubmit={handleFormSubmitLogin}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <TextField
                        margin="normal"
                        sx={{
                          "& .MuiFormLabel-root": {
                            color: "#919EAB",
                            fontFamily: "Public Sans",
                            fontWeight: "500",
                            fontSize: 14,
                          },
                          "& .MuiFormHelperText-root": {
                            fontFamily: "Public Sans",
                            fontWeight: 500,
                          },
                        }}
                        fullWidth
                        name="OTP"
                        label="Enter Your OTP"
                        id="OTP"
                        onChange={handleOnChangeOTP}
                        type="number"
                        className={"hide-spin-buttons"}
                        size="small"
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            height: "40px",
                            borderColor: "#919EAB",
                          },
                        }}
                      />
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"center"}
                        spacing={3}
                        sx={{ mt: 2, mb: 2.5 }}
                      >
                        <LoadingButton
                          loading={ResendVerifyLoading}
                          onClick={resendOTPTimeSet}
                          disabled={ResendOTPBtnDisabled}
                          style={{
                            color: ResendOTPBtnDisabled ? "grey" : "green",
                            textTransform: "none",
                            background: ResendOTPBtnDisabled
                              ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                              : "var(--transparent-primary-16, rgba(0, 171, 85, 0.16))",
                            borderRadius: "8px",
                            justifyContent: "center",
                            alignItems: "center",
                            minWidth: "155px",
                            fontWeight: "600",
                            fontFamily: "Public Sans",
                          }}
                          size="small"
                        >
                          {!ResendOTPBtnDisabled ? (
                            <img
                              src={shapeResend}
                              style={{
                                marginRight: 2,
                                textDecorationColor:
                                  "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))",
                                color:
                                  "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))",
                              }}
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                          Resend Code
                        </LoadingButton>
                        <Button
                          //   disabled={btnDisabled}
                          style={{
                            fontWeight: 600,
                            color: verifyBtnDisabled ? "grey" : "white",
                            textTransform: "none",
                            background: verifyBtnDisabled
                              ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                              : "#303981",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            minWidth: "155px",
                            fontFamily: "Public Sans",
                          }}
                          size="small"
                          type="submit"
                        >
                          Verify
                        </Button>
                      </Stack>
                      {seconds > 0 ? (
                        <Typography
                          component="div"
                          fontSize={13}
                          marginTop={2}
                          fontWeight={500}
                          fontFamily={"Public Sans"}
                          color={"#556486"}
                        >
                          Please wait <b style={{ fontSize: 14 }}>{seconds}</b>{" "}
                          seconds for Resend OTP
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Stack>
                  <SimpleLoader props={open} />
                </Box>
              </Grid>
              <Grid>
                <img
                  src={ResistoreIcon}
                  style={{
                    marginTop: "60px",
                    maxHeight: "350px",
                    maxWidth: "400px",
                    alignItems: "center",
                  }}
                  alt=""
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
