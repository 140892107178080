import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  MenuItem,
  Typography,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSelector } from "react-redux";
import React from "react";
import { enqueueSnackbar } from "notistack";
import AvailableRecievablesList from "./AvailableRecievablesList";
import { Typography14 } from "../../../components/typography/Typography";

const AvailableRecievablesMain = () => {
  const [AvailableRecievables, setAvailableRecievables] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [Token, setToken] = useState<any>("");
  const newToken = useSelector((state: any) => state.user.Token);

  const getUsersList = async () => {
    // userToken check
    if (newToken !== "") {
      const axiosConfig = {
        headers: {
          token: newToken ? newToken : "",
        },
        // params: params,
      };
      const FetchData = await axios
        .get(
          "https://api.dev.assuredpay.in/checkerRoutes/getrecievables",
          axiosConfig
        )
        .then((res) => {
          console.log(res,"res>>>>>>>>>>>>>>>>,,,,,................")
          if (res.status === 200) {
            let data: any = res.data.result;
            if (data.length > 0) {
              setAvailableRecievables(data);
            } else {
              setAvailableRecievables([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("token token token", Token);
    }
  };
  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });
  useEffect(() => {
    getUsersList();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 140,
        borderRadius: "8px",
      },
    },
  };

  return (
    <>
      <Stack mx={4}>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"24px"}
          sx={{ mt: 4 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          Available Receivables
        </Typography>
      </Stack>
      <Box
        justifyContent={"center"}
        component={"div"}
        sx={{
          backgroundColor: "#F7F9FB",
        }}
      >
        <Box
          boxShadow={1}
          mx={4}
          my={2}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
        >
          <Stack
            sx={{ mx: 4, py: 2 }}
            display={"flex"}
            direction={"row"}
            spacing={1}
          >
            {" "}
            <TextField
              disabled
              fullWidth
              placeholder="Search By Milstone, Payment Order Number ....."
              InputProps={{
                style: { borderRadius: "10px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box>
            <AvailableRecievablesList props={AvailableRecievables} />
          </Box>
          <Box>
            <Divider></Divider>
            <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
              <TablePagination
                component="div"
                count={10}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AvailableRecievablesMain;
