import { enqueueSnackbar } from "notistack";

export const ErrorMsg = (value: any) => {
  return enqueueSnackbar(
    `${value}`,
    // "Verification Codes has been sent to your registered Email & Mobile Number",
    {
      variant: "error",
      style: {
        color: "white",
        backgroundColor: "vred",
        marginRight: "100px",
        fontFamily: "Public Sans",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "22px",
        maxWidth: "400px",
        marginTop: "50px",
      },
    }
  );
};
