import { BrowserRouter } from "react-router-dom";
import Router from "./routes/router";
import "./App.css";
import { useEffect } from "react";
import { SnackbarProvider } from "notistack";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { LocationInfo } from "./redux/slices/user";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const Theme = createTheme({
    typography: {
      fontFamily: "Public Sans",
      fontWeightLight: "300",
      fontWeightRegular: "400",
      fontWeightMedium: "500",
    },
  });
  const geolocationAPI = navigator.geolocation;
  const getUserCoordinates = () => {
    if (!geolocationAPI) {
    } else {
      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords, timestamp } = position;
          let LocationInfoData = {
            timestamp: timestamp,
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
          };
          dispatch(LocationInfo(LocationInfoData));
        },
        (error) => {
          console.log(error, "Error from ");
        }
      );
    }
  };
  useEffect(() => {
    getUserCoordinates();
  }, []);

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={2500}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // style={{
        //   color: " var(--success-darker, #0A5554)",
        //   // color: "rgba(54, 179, 126, 1)",
        //   backgroundColor: "var(--success-lighter, #D8FBDE)",
        //   marginRight: "100px",
        //   fontFamily: "Public Sans",
        //   fontWeight: 500,
        //   fontSize: "14px",
        //   lineHeight: "22px",
        //   maxWidth: "400px",
        //   marginTop: "50px",
        // }}
      >
        <MuiThemeProvider theme={Theme}>
          <BrowserRouter>
            <main className="MainPages">
              <Router />
            </main>
          </BrowserRouter>
        </MuiThemeProvider>
      </SnackbarProvider>
    </>
  );
}

export default App;
