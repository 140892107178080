import Logo from "../assets/LogoSvg.svg";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
import { fIndianCurrency } from "../utils/Validations";
import Label from "./Label/Label";

const Header = (props: any) => {
  const collapsed = props.collapsed;
  const login = useSelector((state: any) => state.user.userLogin);
  const location = useLocation();
  const path = location.pathname;
  const walletStyle = {
    textTransform: "capitalize",
    borderColor: "primary",
    borderRadius: 8,
    borderWidth: "2px",
    borderStyle: "solid",
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ backgroundColor: "white" }}
        elevation={1}
      >
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            {collapsed === undefined ? <img src={Logo} alt="" /> : ""}
          </div>
          <div>
            {path === "/login" || path === "/forget" || path === "/verify" ? (
              <Link to={"/"}>
                <Button
                  size="small"
                  color="inherit"
                  sx={{ px: 4 }}
                  style={{
                    maxWidth: "320px",
                    textTransform: "none",
                    fontSize: 15,
                    borderRadius: "8px",
                    backgroundColor: "rgba(145, 158, 171, 0.24)",
                    fontFamily: "Public Sans",
                    fontWeight: "700",
                    color: "black",
                  }}
                  fullWidth
                >
                  Sign Up
                </Button>
              </Link>
            ) : (
              <>
                <Stack direction={"row"} spacing={2}>
                  {props.showWallet ? (
                    <>
                      <Label variant="soft" color={"primary"} sx={walletStyle}>
                        AP Balance :{" "}
                        {fIndianCurrency(
                          props.BankBalanceData &&
                            props.BankBalanceData.availableBalance
                        ) || "₹" + " " + 0}
                      </Label>
                      <Label
                        variant="soft"
                        color={"secondary"}
                        sx={walletStyle}
                      >
                        Locked :{" "}
                        {fIndianCurrency(
                          props.BankBalanceData &&
                            props.BankBalanceData.availableReceivables
                        ) || "₹" + " " + 0}
                      </Label>
                      {/* <Label variant="soft"></Label> */}
                      <Label variant="soft" color={"warning"} sx={walletStyle}>
                        Receivables :{" "}
                        {fIndianCurrency(
                          props.BankBalanceData &&
                            props.BankBalanceData.availableReceivables
                        ) || "₹" + " " + 0}
                      </Label>
                    </>
                  ) : (
                    ""
                  )}
                  <Link to={"/login"}>
                    <Button
                      size="small"
                      color="inherit"
                      sx={{ px: 4 }}
                      style={{
                        maxWidth: "320px",
                        textTransform: "none",
                        fontSize: 15,
                        borderRadius: "8px",
                        backgroundColor: "rgba(145, 158, 171, 0.24)",
                        fontFamily: "Public Sans",
                        fontWeight: "700",
                        color: "black",
                      }}
                      fullWidth
                    >
                      {!login ? <p> Login</p> : <p>Logout</p>}
                    </Button>
                  </Link>
                </Stack>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
