import axios from "axios";

export const globalStatusExecute = async (token: any, globalStatus: any) => {
  const axiosConfig = {
    headers: {
      token: token !== "" ? JSON.parse(token) : "",
    },
  };

  const res1 = await axios
    .post(
      "https://api.dev.assuredpay.in/userkyc/setglobalstatus",
      { globalStatus: globalStatus },
      axiosConfig
    )
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
    })
    .catch((err) => {
      console.log(err, "catch Method");
      return false;
    });
};
