import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function ResetPassword() {
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [business_email, setbusinessEmail] = useState("");
  const [old_password, setold_password] = useState("");
  const [new_Password, setnew_Password] = useState("");
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      oldPassword: data.get("oldPassword"),
      newPassword: data.get("newPassword"),
    });
    // Base Url From ENV
    const baseUrl = process.env.BASE_URL
      ? process.env.BASE_URL
      : "https://api.dev.assuredpay.in";

    const FetchData = await axios.post(
      "https://api.dev.assuredpay.in/auth/change-password",
      {
        business_email: data.get("email"),
        oldPassword: data.get("oldPassword"),
        newPassword: data.get("newPassword"),
      }
    );
    const getUserLogin = FetchData.data;
    console.log("123, Login", getUserLogin);
  };

  useEffect(() => {
    console.log(business_email);

    if (business_email !== "" && old_password !== "" && new_Password !== "") {
      console.log(business_email);
      setbtnDisabled(false);
    } else {
      setbtnDisabled(true);
    }
  });

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 1,
          borderRadius: 1,
          px: 10,
          pt: 2,
          pb: 2,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Stack
          spacing={2}
          width={"400px"}
          sx={{
            mb: 2,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            maxWidth={"sx"}
            variant="h5"
            component="div"
            fontSize={22}
            fontWeight={700}
            fontFamily={"Public Sans"}
            lineHeight={"30px"}
            textAlign={"center"}
            color={"#334A67"}
          >
            Reset Your Password ?
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              mb: 4,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              color={"#919EAB"}
              fontSize={13}
              fontWeight={500}
              textAlign={"center"}
              fontFamily={"Public Sans"}
            >
              Please enter the email address associated with your account, old
              password and new passoword for update your password.
            </Typography>
          </Stack>
        </Stack>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, mb: 1 }}
        >
          <TextField
            sx={{
              "& .MuiFormLabel-root": {
                color: "#919EAB",
                fontFamily: "Public Sans",
                fontWeight: "500",
                fontSize: "16",
                // Height: "20px",
              },
            }}
            margin="normal"
            required
            onChange={(e) => {
              setbusinessEmail(e.target.value);
            }}
            fullWidth
            id="email"
            type="email"
            label="Business Email Address"
            name="email"
            autoComplete="email"
            InputProps={{
              style: {
                height: "50px",
                borderRadius: "8px",
                borderColor: "#919EAB",
              },
            }}
          />
          <TextField
            sx={{
              "& .MuiFormLabel-root": {
                color: "#919EAB",
                fontFamily: "Public Sans",
                fontWeight: "500",
                fontSize: "16",
                Height: "20px",
              },
            }}
            margin="normal"
            required
            onChange={(e) => {
              setold_password(e.target.value);
            }}
            fullWidth
            id="oldPassword"
            type="oldPassword"
            label="Old Password"
            name="oldPassword"
            autoComplete="oldPassword"
            InputProps={{
              style: {
                height: "50px",
                borderRadius: "8px",
                borderColor: "#919EAB",
              },
            }}
          />
          <TextField
            sx={{
              "& .MuiFormLabel-root": {
                color: "#919EAB",
                fontFamily: "Public Sans",
                fontWeight: "500",
                fontSize: "16",
                Height: "20px",
              },
            }}
            margin="normal"
            required
            onChange={(e) => {
              setnew_Password(e.target.value);
            }}
            fullWidth
            id="newPassword"
            type="newPassword"
            label="New Password"
            name="newPassword"
            autoComplete="newPassword"
            InputProps={{
              style: {
                height: "50px",
                borderRadius: "8px",
                borderColor: "#919EAB",
              },
            }}
          />
          <Button
            disabled={btnDisabled}
            // color="rgba(145, 158, 171, 0.24)"
            size="small"
            color="inherit"
            sx={{
              my: 3,
              mb: "0px",
              borderRadius: 2,
              textTransform: "none",
            }}
            style={{
              fontSize: 16,
              backgroundColor: btnDisabled
                ? "rgba(145, 158, 171, 0.24)"
                : "#303981",
              fontFamily: "Public Sans",
              fontWeight: "700",
              color: btnDisabled
                ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
                : "white",
            }}
            fullWidth
            variant="contained"
            type="submit"
          >
            Update Passoword{" "}
          </Button>
        </Box>
        <Stack direction="row" spacing={1} sx={{ mb: 4, mt: 1 }}>
          <ArrowBackIosIcon style={{ fontSize: "16px", marginTop: "3px" }} />
          <Link to={"/login"} style={{ textDecoration: "none" }}>
            {" "}
            <Typography
              fontWeight={600}
              fontSize={14}
              fontFamily={"Public Sans"}
              color={"#303981"}

              // lineHeight={"30px"}
            >
              {" "}
              Return to Sign In
            </Typography>
          </Link>
        </Stack>
      </Box>
    </Container>
  );
}
