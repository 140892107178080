import React from "react";
import { Link, useNavigate } from "react-router-dom";
import BusinessNetwork1 from "../../assets/BussinessNetwork1.svg";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  MenuItem,
  Typography,
  CircularProgress,
  TablePagination,
  Autocomplete,
  fabClasses,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

import Tabs from "@mui/material/Tabs";
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Chips } from "../../components/businessNetwok/chips/Chips";
import { TypographyText } from "../../components/businessNetwok/typography/TypographyText";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Typography12,
  Typography14,
} from "../../components/typography/Typography";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { findObjectLength, userNameValidation } from "../../utils/Validations";
const AddMaker = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const searchType = [
    { value: "MakePayment", label: "Make Payment" },
    { value: "RequestToPay", label: "Request To Pay" },
  ];

  const Token = useSelector((state: any) => state.user.Token);
  const [userVerifyMsg, setUserVerifyMsg] = useState("");
  const [userAlreadyExist, setuserAlreadyExist] = useState(false);

  // const [paymentTypeSelection, setpaymentTypeSelection] = useState("");
  const [BtnDisabled, setBtnDisabled] = useState(true);
  const [successStatus, setsuccessStatus] = useState(false);
  const [ErrorMsgAddMakerStatus, setErrorMsgAddMakerStatus] = useState(false);
  const [ErrorMsgAddMakerText, setErrorMsgAddMakerText] = useState("");

  const [addMakerData, setaddMakerData] = useState({
    Business_email: "",
    Username: "",
    Business_mobile: "",
  });
  const UserVerification = async () => {
    let name = addMakerData.Username;
    const N_V = userNameValidation(name);
    if (name !== "" && name !== undefined) {
      if (N_V === false) {
        setUserVerifyMsg("Username must have at least four characters.");
        setuserAlreadyExist(true);
      } else {
        setUserVerifyMsg("");
        setuserAlreadyExist(false);
      }
    } else {
      setUserVerifyMsg("");
      setuserAlreadyExist(false);
    }
    if (name !== "" && name !== undefined && N_V === true) {
      const FetchData = await axios
        .post("https://api.dev.assuredpay.in/Auth/searchExisting", {
          username: name,
        })
        .then((res) => {
          var getUserverification = res.data;
          if (
            getUserverification.result.found === true &&
            getUserverification.result.active === false
          ) {
            setUserVerifyMsg("User Already Exist");
            setuserAlreadyExist(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setUserVerifyMsg("Something went wrong, Please try again");
          setuserAlreadyExist(false);
        });
    }
  };
  // user Kyc Status for approver or new or rejected state
  const addMakerDataaddOn = (event: any) => {
    const { name, value } = event.target;
    setErrorMsgAddMakerStatus(false);
    setsuccessStatus(false);
    setaddMakerData((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };
  const handleOnClickCreateMaker = async (e: any) => {
    e.preventDefault();
    console.log("dhdjfbjfbfbfmbf");
    const data = new FormData(e.currentTarget);
    let params;
    console.log("dhdjfbjfbfbfmskmkjfbfhbbf");

    const axiosConfig = {
      headers: {
        token: Token !== "" ? Token : "",
      },
      params: params,
    };
    let body = {
      business_email: data.get("Business_email"),
      username: data.get("Username"),
      business_mobile: data.get("Business_mobile"),
      role: "Maker",
    };
    const FetchData = await axios
      .post(
        "https://api.dev.assuredpay.in/makerRoutes/createsubuser",
        body,
        axiosConfig
      )
      .then((res) => {
        console.log(res, "check maker data");
        setsuccessStatus(true);
        setaddMakerData({
          Business_email: "",
          Username: "",
          Business_mobile: "",
        });
        setBtnDisabled(true);
      })
      .catch((err) => {
        console.log(err, "erroinProductselect");
        setErrorMsgAddMakerStatus(true);
      });
  };
  useEffect(() => {
    if (
      addMakerData.Business_email !== "" &&
      addMakerData.Business_mobile !== "" &&
      addMakerData.Username !== "" &&
      !successStatus
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  });
  return (
    <div>
      {" "}
      <>
        <Stack mx={4}>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"20px"}
            sx={{ mt: 4 }}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#000000"}
          >
            Add Maker
          </Typography>
        </Stack>

        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
          }}
        >
          <Box
            boxShadow={0.5}
            mx={4}
            my={2}
            sx={{
              borderRadius: "8px",
              backgroundColor: "white",
              minHeight: "450px",
              border: "1px solid #EEF2FA",
            }}
            component={"form"}
            onSubmit={handleOnClickCreateMaker}
          >
            <Stack mx={4}>
              <Typography
                fontFamily={"Public Sans"}
                fontSize={"20px"}
                sx={{ mt: 3 }}
                fontWeight={700}
                fontStyle={"normal"}
                color={"#334A67"}
              >
                Add User
              </Typography>
            </Stack>
            <Stack px={4} py={2} display={"flex"} direction={"row"} spacing={4}>
              <TextField
                label="Username"
                size="small"
                type="text"
                name="Username"
                error={userAlreadyExist}
                helperText={userAlreadyExist ? userVerifyMsg : ""}
                value={addMakerData.Username}
                onChange={addMakerDataaddOn}
                onBlur={UserVerification}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "#919EAB",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 40,
                    minWidth: "23vw",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    Height: "20px",
                  },
                }}
              />
              <TextField
                label="Mobile"
                size="small"
                type="text"
                name="Business_mobile"
                // value={GstNumber}
                value={addMakerData.Business_mobile}
                onChange={addMakerDataaddOn}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "#919EAB",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 40,
                    minWidth: "23vw",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    Height: "20px",
                  },
                }}
              />
              <TextField
                label="Personal Email"
                size="small"
                type="email"
                name="Business_email"
                value={addMakerData.Business_email}
                // value={GstNumber}
                onChange={addMakerDataaddOn}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    borderColor: "#919EAB",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 40,
                    minWidth: "23vw",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    Height: "20px",
                  },
                }}
              />
            </Stack>
            <Box mx={4} my={2}>
              <Stack direction="row" spacing={3}>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  size="small"
                  // onClick={handleOnClickRedirectPayment}
                  disabled={BtnDisabled}
                  sx={{
                    px: 20,
                    textTransform: "none",
                    backgroundColor: BtnDisabled
                      ? "rgba(145, 158, 171, 0.24)"
                      : "#303981",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    borderRadius: "8px",
                    color: BtnDisabled ? "" : "white",
                    "&:hover": {
                      backgroundColor: BtnDisabled
                        ? "rgba(145, 158, 171, 0.24)"
                        : "#303981",
                    },
                  }}
                >
                  Add
                </LoadingButton>
                {successStatus ? (
                  <>
                    <Stack display={"flex"} direction={"row"} spacing={2}>
                      <DoneIcon
                        style={{ fontSize: "15px", color: "#36B37E" }}
                      />{" "}
                      <Typography
                        fontWeight={600}
                        fontSize={12}
                        fontFamily={"Public Sans"}
                        color={"#36B37E"}
                      >
                        {" "}
                        User Add Successfully.
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  ""
                )}
                {ErrorMsgAddMakerStatus ? (
                  <>
                    <DoneIcon style={{ fontSize: "15px", color: "#36B37E" }} />{" "}
                    <Typography
                      fontWeight={600}
                      fontSize={12}
                      fontFamily={"Public Sans"}
                      color={"red"}
                    >
                      {" "}
                      Something went Wrong, Please try again.
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Stack>
            </Box>
          </Box>
        </Box>
      </>
    </div>
  );
};

export default AddMaker;
