export const fontSizes = {
    xs:"10px",
    xsm:"12px",
    sm:"14px",
    md:"16px",
    lg:"18px",
    xlg:"20px",
    xxlg:"25px"
}
export const fontFamilys = {
    publicSans: "Public Sans"
}
