import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  InputAdornment,
  Stack,
  IconButton,
} from "@mui/material";
import SimpleLoader from "../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { logOutUser, saveUser } from "../../redux/slices/user";
import Header from "../../components/Header";
import { SuccessMsg } from "../../components/Messages/SuccessMsg";
import ApiService from "../../api/api";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
export default function SignIn() {
  document.title = "Secure Login - AssuredPay";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userNameStatus, setuserNameStatus] = useState(false);
  const [passwordStatus, setpasswordStatus] = useState(false);

  const [userError, setUserError] = useState("");
  const [passowordError, setPasswordError] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setPasswordError("");
    setUserError("");

    const data = new FormData(event.currentTarget);
    let body = {
      username: data.get("username"),
      password: data.get("password"),
      request: "Business_User01",
    };
    // let userName =
    const responseData: any = await ApiService.post("/auth/login", body);
    console.log(responseData, "check user status");
    if (responseData.status === 200) {
      const getUserLogin = responseData.data;
      dispatch(saveUser(getUserLogin.result));
      let resUserName = responseData.data.result.username;
      if (resUserName !== "") {
        SuccessMsg("OTP send successfully to your register mobile number");
        setopen(false);
        navigate("/authentication", { state: { username: resUserName } });
      }
    } else if (responseData.response.status === 400) {
      if (responseData.response.data.errorType === "username") {
        setuserNameStatus(true);
        setpasswordStatus(false);
        setUserError(responseData.response.data.error);
      } else {
        setuserNameStatus(false);
        setpasswordStatus(true);
        setPasswordError(responseData.response.data.error);
      }
      setopen(false);
    } else {
      setopen(false);
      ErrorMsg("Something went wrong, Please try again");
    }
  };
  useEffect(() => {
    dispatch(logOutUser(false));
    localStorage.removeItem("Token");
    localStorage.removeItem("User");
    if ((username && password) !== "") {
      setbtnDisabled(false);
    }
  });

  return (
    <>
      <Header />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            boxShadow: 1,
            borderRadius: 1,
            px: 4,
            pt: 4,
            pb: 2,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack spacing={0.5} sx={{ mb: 4, position: "relative" }}>
            <Typography
              variant="h5"
              component="div"
              fontSize={22}
              fontWeight={700}
              fontFamily={"Public Sans"}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Sign in to AssuredPay
            </Typography>

            <Stack direction="row" spacing={1}>
              <Typography
                color={"#334A67"}
                fontSize={14}
                fontWeight={500}
                fontFamily={"Public Sans"}
              >
                New user?
              </Typography>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                {" "}
                <Typography
                  fontWeight={600}
                  fontSize={14}
                  fontFamily={"Public Sans"}
                  color={"green"}

                  // lineHeight={"30px"}
                >
                  {" "}
                  Create an account
                </Typography>
              </Link>
            </Stack>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: "16",
                    Height: "20px",
                  },
                }}
                margin="normal"
                required
                fullWidth
                error={userNameStatus}
                helperText={userError ? userError : ""}
                id="username"
                onChange={(e) => {
                  setusername(e.target.value);
                }}
                label="Username"
                name="username"
                autoComplete="username"
                InputProps={{
                  style: {
                    height: "50px",
                    borderRadius: "8px",
                    borderColor: "#919EAB",
                    marginTop: 4,
                    marginBottom: 4,
                  },
                }}
              />

              <TextField
                margin="normal"
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: "16",
                    marginTop: 1,
                  },
                }}
                required
                error={passwordStatus}
                helperText={passowordError ? passowordError : ""}
                fullWidth
                name="password"
                label="Password"
                // type="password"
                id="password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                // id="outlined-start-adornment"
                size="small"
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
                // onChange={getUserValues}
                InputProps={{
                  style: {
                    height: "50px",
                    borderRadius: "8px",
                    borderColor: "#919EAB",
                    marginTop: 4,
                    marginBottom: 4,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {" "}
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Link to="/forget">
                  <Typography
                    fontWeight={500}
                    marginTop={1}
                    style={{ textDecoration: "underline" }}
                    fontSize={14}
                    fontFamily={"Public Sans"}
                    color={"#334A67"}
                  >
                    {" "}
                    Forgot Password?
                  </Typography>
                </Link>
              </div>

              <Button
                disabled={btnDisabled}
                size="small"
                color="inherit"
                sx={{
                  my: 4,
                  mb: "8px",
                  borderRadius: 2,
                  textTransform: "none",
                }}
                onClick={() => setopen(!open)}
                style={{
                  fontSize: 16,
                  backgroundColor: btnDisabled
                    ? "rgba(145, 158, 171, 0.24)"
                    : "#303981",
                  fontFamily: "Public Sans",
                  fontWeight: "700",
                  color: btnDisabled
                    ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
                    : "white",
                }}
                fullWidth
                variant="contained"
                type="submit"
              >
                Login{" "}
              </Button>
              <SimpleLoader props={open} />
            </Box>
          </Stack>
        </Box>
      </Container>
    </>
  );
}
