import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  MenuItem,
  Typography,
  CircularProgress,
  Autocomplete,
  Checkbox,
  Avatar,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { findObjectLength, splitDateConversion } from "../../utils/Validations";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {
  Typography12,
  Typography14,
  Typography16,
  Typography18,
} from "../../components/typography/Typography";
import { fontFamilys } from "../../utils/FontSize";
const CreateNewPayment = () => {
  const newToken = useSelector((state: any) => state.user.Token);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [checkBox, setcheckBox] = useState(false);
  const handleCheckbox = (e: any) => {
    if (e.target.checked === true) {
      setcheckBox(true);
    } else {
      setcheckBox(false);
    }
  };

  const searchType = [
    {
      value: "buyer",
      label: "Make Payment",
      icon: (
        <ShoppingCartIcon
          fontSize="small"
          sx={{ color: "rgba(99, 115, 129, 1)" }}
        />
      ),
    },
    {
      value: "seller",
      label: "Request To Pay",
      icon: (
        <AddShoppingCartIcon
          fontSize="small"
          sx={{
            color: "rgba(99, 115, 129, 1)",
          }}
        />
      ),
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const [paymentTypeSelection, setpaymentTypeSelection] = useState("");
  const [businessNameList, setbusinessNameList] = useState<any>([]);
  const [BtnDisabled, setBtnDisabled] = useState(true);
  const [selectBusinessSearchObj, setselectBusinessSearchObj] = useState<any>(
    {}
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 200,
        borderRadius: "8px",
      },
    },
  };
  const handleOnChangeForBusinessSearch = async (e: any) => {
    // let paramsData = {};
    setLoading(true);

    const axiosConfig = {
      headers: {
        token: newToken !== "" ? newToken : "",
      },
      params: {
        status: "active",
      },
    };

    const FetchData = await axios
      .get(
        "https://api.dev.assuredpay.in/userkyc/getBusinessNetwork",
        axiosConfig
      )
      .then((res) => {
        const businessData = res;
        console.log("static value", businessData);
        if (res.status === 200) {
          setLoading(false);
          setbusinessNameList(businessData.data.result.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (findObjectLength(selectBusinessSearchObj) && checkBox) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  });
  const handleOnClickRedirectPayment = () => {
    if (paymentTypeSelection === "buyer") {
      navigate("/dashboard/createpayment", {
        state: {
          businessNetwork: selectBusinessSearchObj,
          paymentSelection: paymentTypeSelection,
          fromPr: false,
        },
      });
    } else if (paymentTypeSelection === "seller") {
      navigate("/dashboard/createpaymentrequest", {
        state: {
          businessNetwork: selectBusinessSearchObj,
          paymentSelection: paymentTypeSelection,
        },
      });
    } else {
      enqueueSnackbar("Please select payment type for create payment", {
        variant: "error",
      });
    }
  };
  let acknowledgeDocuments = [
    {
      label: "User Responsibility:",
      value:
        "Users are solely responsible for the content, accuracy, and legality of the information provided on the new payment order. You must ensure compliance with applicable laws, regulations, and ethical standards when creating new payment orders.",
    },
    {
      label: "Financial Transactions:",
      value:
        "Users understand that the platform facilitates financial transactions and have control over the actual transfer of funds. Users are advised to verify payment details before finalizing any transactions. Once accepted, the transfer of funds can not be stopped under any circumstances.",
    },
    {
      label: "Changes to Terms:",
      value:
        "The platform reserves the right to modify these terms at any time. Users will be notified of any significant changes, and continued use of the service implies acceptance of the updated terms.",
    },
    {
      label: "Indemnification",
      value:
        "Users agree to indemnify and hold the platform harmless from any claims, damages, or liabilities arising from their use of the service, including but not limited to violations of these terms.",
    },
    {
      label: "Termination of Service:",
      value:
        "The platform reserves the right to suspend or terminate user accounts for violations of these terms or any fraudulent activities.",
    },
  ];

  return (
    <div>
      {" "}
      <>
        <Stack mx={4}>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"20px"}
            sx={{ mt: 4 }}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#000000"}
          >
            Create New Payment
          </Typography>
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                fontFamily: "Public Sans",
              }}
            >
              Payments
            </Typography>
            <Typography>.</Typography>

            <Typography
              fontFamily={"Public Sans"}
              fontSize={"13px"}
              fontWeight={400}
              fontStyle={"normal"}
              color={"rgba(145, 158, 171, 1)"}
            >
              Create New Payment Order
            </Typography>
          </Stack>
        </Stack>
        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
          }}
        >
          <Box
            boxShadow={1}
            mx={4}
            my={2}
            sx={{
              borderRadius: "8px",
              backgroundColor: "white",
              minHeight: "450px",
            }}
          >
            <Stack
              sx={{ mx: 4, py: 2 }}
              display={"flex"}
              direction={"row"}
              spacing={2}
            >
              {" "}
              <FormControl sx={{ minWidth: "20%" }}>
                <InputLabel
                  sx={{
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: "16",
                  }}
                >
                  Select Type
                </InputLabel>
                <Select
                  disabled={checkBox}
                  value={paymentTypeSelection ? paymentTypeSelection : ""}
                  onChange={(e) => setpaymentTypeSelection(e.target.value)}
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                  }}
                  input={
                    <OutlinedInput
                      sx={{
                        borderRadius: "8px",
                        "& .MuiFormLabel-root": {
                          fontSize: "10px",
                        },
                      }}
                      label="Select Type"
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {searchType.map((name, index) => (
                    <MenuItem key={index} value={name.value}>
                      <Stack display={"flex"} direction={"row"} spacing={1}>
                        {name.icon}
                        <Typography
                          fontFamily={"Public Sans"}
                          fontSize={"14px"}
                          fontWeight={400}
                        >
                          {name.label}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {paymentTypeSelection !== "" ? (
                <>
                  <Autocomplete
                    disableClearable
                    disabled={checkBox}
                    options={businessNameList}
                    getOptionLabel={(option: any) =>
                      option.businessDetails.Legal_Name_of_Business
                    }
                    size="medium"
                    fullWidth
                    loading={loading}
                    onChange={(event, value) =>
                      setselectBusinessSearchObj(
                        value !== (undefined || null) ? value : {}
                      )
                    }
                    renderInput={(params: any) => (
                      <TextField
                        sx={{
                          width: "57%",
                          "& .MuiFormHelperText-root": {
                            color: "Red",
                            fontFamily: "Public Sans",
                            fontWeight: "600",
                            fontSize: "12px",
                            textAlign: "end",
                          },
                        }}
                        {...params}
                        onChange={handleOnChangeForBusinessSearch}
                        onFocus={handleOnChangeForBusinessSearch}
                        InputLabelProps={{
                          sx: {
                            fontSize: "14px",
                            fontFamily: "Public Sans",
                            color: "#919EAB",
                            fontWeight: 400,
                          },
                        }}
                        label={"Search Business"}
                        placeholder="Please Select Business"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          style: {
                            fontSize: "14px",
                            borderRadius: "8px",
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              {" "}
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                <SearchIcon />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    noOptionsText={
                      <Typography14
                        color={"red"}
                        fontFamily={fontFamilys.publicSans}
                        text={`No records were found. Please add businesses to your business network. Once added, you will be authorized to ${
                          paymentTypeSelection === "buyer"
                            ? "make payment"
                            : paymentTypeSelection === "seller"
                            ? "request to pay"
                            : "N/A"
                        }`}
                      ></Typography14>
                    }
                  />
                </>
              ) : (
                ""
              )}
            </Stack>
            {findObjectLength(selectBusinessSearchObj) > 0 ? (
              <>
                <Box px={4}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={4}
                  >
                    <Box
                      gridColumn="span 8"
                      borderRadius={"10px"}
                      border={"1px solid var(--Grey-300, #DFE3E8)"}
                      sx={{ backgroundColor: "var(--Grey-100, #F9FAFB)" }}
                    >
                      {/* <Item> */}
                      <Box px={2} py={1.5}>
                        <Stack>
                          <Typography16
                            color={"#212B36"}
                            text={"Disclaimer"}
                          ></Typography16>
                          <Box mt={0.6}>
                            <Typography14
                              fontSize={13}
                              color={"#212B36"}
                              text={
                                "Assured Pay enables you to create new payment pages for various transactions. By utilizing this service, users acknowledge and agree to the following disclaimer:"
                              }
                            ></Typography14>
                          </Box>
                        </Stack>
                        <Stack spacing={1} mt={1.5}>
                          {acknowledgeDocuments.map((item) => {
                            return (
                              <Stack>
                                <Box>
                                  <Typography14
                                    fontSize={13}
                                    color={"#212B36"}
                                    fontWeight={500}
                                    text={item.label}
                                  ></Typography14>
                                </Box>

                                <Box>
                                  <Typography12
                                    color={"#637381"}
                                    fontWeight={400}
                                    text={item.value}
                                  ></Typography12>
                                </Box>
                              </Stack>
                            );
                          })}
                          <Box>
                            <Stack direction={"row"}>
                              <Box mt={-1}>
                                <Checkbox
                                  value={checkBox}
                                  onChange={handleCheckbox}
                                />
                              </Box>
                              <Typography12
                                color={!checkBox ? "#212B36" : "#303981"}
                                fontWeight={500}
                                text={
                                  "By creating a new payment on Assured Pay, you acknowledge that they have read, understood, and agree to abide by the terms and conditions outlined in this disclaimer."
                                }
                              ></Typography12>{" "}
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                      {/* </Item> */}
                    </Box>
                    <Box
                      gridColumn="span 4"
                      borderRadius={"10px"}
                      border={"1px solid var(--Grey-300, #DFE3E8)"}
                      sx={{ backgroundColor: "var(--Grey-100, #F9FAFB)" }}
                    >
                      <Box px={2} py={1.5}>
                        <Stack>
                          <Box>
                            <Typography
                              fontFamily={"Public Sans"}
                              textAlign={"center"}
                              fontSize={16}
                              color={"#212B36"}
                              fontWeight={700}
                            >
                              {paymentTypeSelection === "buyer"
                                ? "Seller"
                                : "Buyer"}{" "}
                              Information{" "}
                            </Typography>
                          </Box>
                          <Box mt={1.5}>
                            <Typography
                              fontFamily={"Public Sans"}
                              color={"#303981"}
                              textAlign={"center"}
                              fontWeight={600}
                            >
                              {selectBusinessSearchObj.businessDetails &&
                              selectBusinessSearchObj.businessDetails
                                .Legal_Name_of_Business
                                ? selectBusinessSearchObj.businessDetails &&
                                  selectBusinessSearchObj.businessDetails
                                    .Legal_Name_of_Business
                                : ""}
                            </Typography>
                          </Box>
                          <Box mt={4} px={2}>
                            <Stack spacing={3}>
                              <Stack spacing={5} direction={"row"}>
                                <Box>
                                  <Stack>
                                    <Typography14
                                      fontSize={13}
                                      color={"#919EAB"}
                                      fontWeight={400}
                                      text={
                                        "Authorised Business Representative"
                                      }
                                    ></Typography14>
                                    <Typography14
                                      fontSize={13}
                                      color={"#212B36"}
                                      text={
                                        selectBusinessSearchObj.businessDetails
                                          .nameInAadhaar || "N/A"
                                      }
                                    ></Typography14>
                                  </Stack>
                                </Box>
                                <Box>
                                  <Avatar
                                    src={
                                      selectBusinessSearchObj.businessDetails
                                        .aadharPhotoLink || ""
                                    }
                                    alt=""
                                    sx={{ width: 60, height: 60 }}
                                  />
                                </Box>
                              </Stack>
                              <Box>
                                <Typography14
                                  fontSize={13}
                                  color={"#919EAB"}
                                  fontWeight={400}
                                  text={"GST Number"}
                                ></Typography14>
                                <Typography14
                                  fontSize={13}
                                  color={"#212B36"}
                                  text={
                                    selectBusinessSearchObj.businessDetails
                                      .GSTIN_of_the_entity || "N/A"
                                  }
                                ></Typography14>
                              </Box>
                              <Box>
                                <Typography14
                                  fontSize={13}
                                  color={"#919EAB"}
                                  fontWeight={400}
                                  text={"Place of Business"}
                                ></Typography14>
                                <Typography14
                                  fontSize={13}
                                  color={"#212B36"}
                                  text={
                                    selectBusinessSearchObj.businessDetails
                                      .Place_of_Business || "N/A"
                                  }
                                ></Typography14>
                              </Box>
                              <Box>
                                <Typography14
                                  fontSize={13}
                                  color={"#919EAB"}
                                  fontWeight={400}
                                  text={"Joining Date"}
                                ></Typography14>
                                <Typography14
                                  fontSize={13}
                                  color={"#212B36"}
                                  text={
                                    splitDateConversion(
                                      selectBusinessSearchObj.businessDetails
                                        .createdAt
                                    ) || "N/A"
                                  }
                                ></Typography14>
                              </Box>
                              <Box>
                                <Typography14
                                  color={"#212B36"}
                                  fontWeight={500}
                                  text={`Once the ${
                                    paymentTypeSelection === "buyer"
                                      ? "seller"
                                      : "buyer"
                                  } ${
                                    paymentTypeSelection === "buyer"
                                      ? "accepts the payment"
                                      : "configure the payment request"
                                  }, it is crucial to understand that the transaction is irreversible, and no one can stop the payment from being completed.`}
                                ></Typography14>
                              </Box>
                              <Box display={"flex"} justifyContent={"center"}>
                                <Typography18
                                  color={"#303981"}
                                  fontWeight={600}
                                  text={"Thankyou!"}
                                ></Typography18>
                              </Box>
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box px={4} justifyContent={"start"} py={2}>
                  <LoadingButton
                    size="small"
                    onClick={handleOnClickRedirectPayment}
                    disabled={BtnDisabled}
                    sx={{
                      px: 2,
                      textTransform: "none",
                      backgroundColor: BtnDisabled
                        ? "rgba(145, 158, 171, 0.24)"
                        : "#303981",
                      fontFamily: "Public Sans",
                      fontWeight: "500",
                      borderRadius: "8px",
                      color: BtnDisabled ? "" : "white",
                      "&:hover": {
                        backgroundColor: BtnDisabled
                          ? "rgba(145, 158, 171, 0.24)"
                          : "#303981",
                      },
                    }}
                  >
                    Continue
                  </LoadingButton>
                </Box>
              </>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </>
    </div>
  );
};

export default CreateNewPayment;
