import { Link } from "react-router-dom";
import BusinessNetwork1 from "../../assets/BussinessNetwork1.svg";
import MakerListTable from "./MakerListTable";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import React from "react";
import { Typography14 } from "../../components/typography/Typography";
import { enqueueSnackbar } from "notistack";

const ViewMaker = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [UserSearchType, setUserSearchType] = useState("");
  const newToken = useSelector((state: any) => state.user.Token);
  const [NotFound, setNotFound] = useState(false);
  // user Kyc Status for approver or new or rejected state
  const [userKycStatusValue, setuserKycStatusValue] = useState<any>("");

  // userlist for save data from all users
  const [UserList, setUserList] = useState([]);

  const getUsersList = async () => {
    // let params = {
    //   page: page > 0 ? page : 1,
    //   rowsLimitInPage: rowsPerPage ? rowsPerPage : 10,
    //   status: userKycStatusValue,
    //   businessName: businessNameSearchValue,
    //   gst: gstSearchValue,
    // };
    const axiosConfig = {
      headers: {
        token: newToken ? newToken : "",
      },
      // params: params,
    };
    const FetchData = await axios
      .get(
        "https://api.dev.assuredpay.in/checkerRoutes/getallmymaker",
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          const userlist = res.data.result.subusers;
          setUserList(userlist);
        }
      })
      .catch((err) => {
        console.log(err);
        setNotFound(true);
      });
  };

  useEffect(() => {
    getUsersList();
  }, [rowsPerPage, page]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setuserKycStatusValue(newValue);
    console.log(newValue);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 140,
        borderRadius: "8px",
      },
    },
  };
  //   const handleClickInActiveStatus = async (value: any) => {
  //     console.log(value, "handleClickInActiveStatushandleClickInActiveStatus");
  //     let params = {
  //       businessId: value,
  //     };
  //     const axiosConfig = {
  //       headers: {
  //         token: Token !== "" ? JSON.parse(Token) : "",
  //       },
  //       params: params,
  //     };

  //     const FetchData = await axios
  //       .put(
  //         "https://api.dev.assuredpay.in/userkyc/updateBusinessNetwork",
  //         { status: "inActive" },
  //         axiosConfig
  //       )
  //       .then((res) => {
  //         console.log("res", res);
  //         enqueueSnackbar("Status Disable Successfully", {
  //           variant: "error",
  //           style: {
  //             color: "white",
  //             // color: "rgba(54, 179, 126, 1)",
  //             backgroundColor: "#FF7276",
  //             marginRight: "100px",
  //             fontFamily: "Public Sans",
  //             fontWeight: 500,
  //             fontSize: "14px",
  //             lineHeight: "22px",
  //             maxWidth: "400px",
  //             marginTop: "50px",
  //           },
  //         });
  //         getUsersList();
  //       })
  //       .catch((err) => {
  //         console.log(err, "err business network");
  //       });
  //   };
  //   const handleClickActiveStatus = async (value: any) => {
  //     let params = {
  //       businessId: value,
  //     };
  //     const axiosConfig = {
  //       headers: {
  //         token: Token !== "" ? JSON.parse(Token) : "",
  //       },
  //       params: params,
  //     };

  //     const FetchData = await axios
  //       .put(
  //         "https://api.dev.assuredpay.in/userkyc/updateBusinessNetwork",
  //         { status: "active" },
  //         axiosConfig
  //       )
  //       .then((res) => {
  //         console.log("res", res);
  //         enqueueSnackbar("Status Enable Successfully", {
  //           variant: "success",
  //           style: {
  //             color: " var(--success-darker, #0A5554)",
  //             // color: "rgba(54, 179, 126, 1)",
  //             backgroundColor: "var(--success-lighter, #D8FBDE)",
  //             marginRight: "100px",
  //             fontFamily: "Public Sans",
  //             fontWeight: 500,
  //             fontSize: "14px",
  //             lineHeight: "22px",
  //             maxWidth: "400px",
  //             marginTop: "50px",
  //           },
  //         });
  //         getUsersList();
  //       })
  //       .catch((err) => {
  //         console.log(err, "err business network");
  //       });
  //   };
  // const handleChangeOnSearch = (e: any) => {
  //   setsearchValue(e.target.value);
  //   getUsersList();
  // };
  const handleClickInActiveStatus = async (value: any) => {
    const axiosConfig = {
      headers: {
        token: newToken !== "" ? newToken : "",
      },
    };
    const FetchData = await axios
      .put(
        "https://api.dev.assuredpay.in/checkerRoutes/managemymaker",
        { status: "inActive", user: value },
        axiosConfig
      )
      .then((res) => {
        console.log("res", res);
        enqueueSnackbar("Status Disable Successfully", {
          variant: "error",
          style: {
            color: "white",
            backgroundColor: "#FF7276",
            marginRight: "100px",
            fontFamily: "Public Sans",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            maxWidth: "400px",
            marginTop: "50px",
          },
        });
        getUsersList();
      })
      .catch((err) => {
        console.log(err, "err business network");
      });
  };
  const handleClickActiveStatus = async (value: any) => {
    const axiosConfig = {
      headers: {
        token: newToken !== "" ? newToken : "",
      },
    };
    const FetchData = await axios
      .put(
        "https://api.dev.assuredpay.in/checkerRoutes/managemymaker",
        { status: "active", user: value },
        axiosConfig
      )
      .then((res) => {
        console.log("res", res);
        enqueueSnackbar("Status Enable Successfully", {
          variant: "success",
          style: {
            color: " var(--success-darker, #0A5554)",
            backgroundColor: "var(--success-lighter, #D8FBDE)",
            marginRight: "100px",
            fontFamily: "Public Sans",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            maxWidth: "400px",
            marginTop: "50px",
          },
        });
        getUsersList();
      })
      .catch((err) => {
        console.log(err, "err business network");
      });
  };
  return (
    <>
      <Stack mx={4}>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"24px"}
          sx={{ mt: 4 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          Users
        </Typography>
        <Box display={"flex"} justifyContent={"end"} mt={-5}>
          <Link to={"/dashboard/createmaker"}>
            <Button
              size="medium"
              sx={{
                // display: "flex",
                color: "white",
                borderRadius: "8px",
                backgroundColor: "#303981",
                textAlign: "right",
                fontFamily: "Public Sans",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#303981",
                },
              }}
            >
              <AddIcon sx={{ mr: 1 }} fontSize="small" />{" "}
              <Typography14 color={"white"} text={"Add Maker"}></Typography14>
            </Button>
          </Link>
        </Box>
      </Stack>
      {/* {UserList.length < 0 ? ( */}
      {UserList.length <= 0 ? (
        <Box
          boxShadow={1}
          mx={4}
          my={2}
          borderRadius={"16px"}
          gap={"40px"}
          display={"flex"}
          justifyContent={"center"}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
          height={"auto"}
        >
          <Stack
            mt={8}
            display={"flex"}
            justifyContent={"center"}
            textAlign={"center"}
          >
            <img
              src={BusinessNetwork1}
              alt=""
              height={"150px"}
              width={"auto"}
            />
            <Typography
              fontFamily={"Public Sans"}
              fontSize={24}
              sx={{ mt: 4 }}
              fontWeight={700}
              fontStyle={"normal"}
              color={"#161C24"}
            >
              No Records found
            </Typography>
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"14px"}
              fontStyle={"normal"}
              lineHeight={"22px"}
              color={"#454F5B"}
              fontWeight={400}
              sx={{ my: 4, mt: 1 }}
            >
              Please create a user (Maker) & try again.
            </Typography>
          </Stack>
        </Box>
      ) : (
        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
          }}
        >
          <Box
            boxShadow={1}
            mx={4}
            my={3}
            sx={{ borderRadius: "8px", backgroundColor: "white" }}
          >
            <Box>
              {UserList.length > 0 ? (
                <MakerListTable
                  props={UserList}
                  handleClickInActiveStatus={handleClickInActiveStatus}
                  handleClickActiveStatus={handleClickActiveStatus}
                />
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  textAlign={"center"}
                  height={"40vh"}
                >
                  <Stack mt={10} spacing={1}>
                    {/* <Box>
                      <CircularProgress />
                    </Box> */}
                    <Typography14 text="No Record Found !"></Typography14>
                  </Stack>
                </Box>
              )}
            </Box>
            <Box>
              <Divider></Divider>
              <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
                <TablePagination
                  component="div"
                  count={50}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* ) : ( */}

      {/* )} */}
      {/* </Box> */}
    </>
  );
};

export default ViewMaker;
