// import "./styles.css";
import React, { useState } from "react";
import { Stack, Box, ListItem, List, IconButton, Avatar } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import APLogo from "../assets/Assure_Logo.svg";
import newDashboardIcon from "../assets/ic_dashboard.svg";

import { Link } from "react-router-dom";
import { Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Logo from "../assets/LogoSvg.svg";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PaymentIcon from "@mui/icons-material/Payment";
import PreClaimsIcon from "../assets/ic_ecommerce.svg";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import {
  Typography12,
  Typography14,
} from "../components/typography/Typography";
import { useSelector } from "react-redux";
import { firstCharacterUppercase } from "../utils/Validations";
const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
function Sidebars() {
  const [paymentMenu, setpaymentMenu] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const userSaveData = useSelector((state: any) =>
    state.user.userSave.username ? state.user.userSave : ""
  );
  const UserProfilePhoto = localStorage.getItem("photo");
  // console.log("userkayyysysyssy", localStorage.getItem("User"));
  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        anchor="left"
        sx={{ transition: "smooth", transitionDelay: 0 }}
      >
        <Box sx={{ backgroundColor: "white" }}>
          <DrawerHeader onClick={handleDrawerClose} sx={{ px: 2 }}>
            <>
              {!open ? <img src={APLogo} alt="" /> : <img src={Logo} alt="" />}
            </>
          </DrawerHeader>
        </Box>

        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Box px={1}>
              <Box
                sx={{
                  borderRadius: "12px",
                  px: 0.5,
                  py: 3,
                  maxWidth: "240px",
                  backgroundColor: "rgba(145, 158, 171, 0.08)",
                }}
              >
                <Stack direction={"row"} display={"flex"} spacing={2}>
                  <Avatar src={UserProfilePhoto?.toString()} alt="" />
                  <ListItemText
                    primary={
                      <Stack>
                        {/* <Typography14
                          text={
                            userName ? firstCharacterUppercase(userName) : "N/A"
                          }
                          color={"rgba(33, 43, 54, 1)"}
                        ></Typography14> */}

                        <Typography14
                          sx={{ mt: 1 }}
                          text={firstCharacterUppercase(userSaveData.username)}
                        ></Typography14>
                        {/* <Typography12
                          sx={{ mt: -1 }}
                          text={userSaveData.role}
                        ></Typography12> */}
                      </Stack>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </Stack>
              </Box>
            </Box>
          </ListItem>
        </List>

        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link
              to={"/dashboard/main"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      height={"25px"}
                      width={"30px"}
                      src={newDashboardIcon}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"Dashboard"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={"/dashboard/addbusinessnetwork"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AddBusinessIcon sx={{ height: "25px", width: "30px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography14 text={"Business Network"}></Typography14>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={"/dashboard/payment"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PaymentIcon sx={{ height: "25px", width: "30px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"Payments"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={PreClaimsIcon}
                    width={"30px"}
                    height={"25px"}
                    alt=""
                  />
                </ListItemIcon>
                <ListItemText
                  onClick={() => setpaymentMenu(!paymentMenu)}
                  primary={<Typography14 text={"Pre Claims"}></Typography14>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {open ? (
                  paymentMenu ? (
                    <ExpandMoreIcon
                      onClick={() => setpaymentMenu(!paymentMenu)}
                    />
                  ) : (
                    <ChevronRightIcon
                      onClick={() => setpaymentMenu(!paymentMenu)}
                    />
                  )
                ) : (
                  ""
                )}
              </ListItemButton>
            </ListItem>
            <Link
              to={"/dashboard/users"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PaymentIcon sx={{ height: "25px", width: "30px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"My Users"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            {/* <Link
              to={"/dashboard/chat"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PaymentIcon sx={{ height: "25px", width: "30px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"Chat"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link> */}
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
export default Sidebars;
