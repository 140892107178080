import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import { Box, Chip, Stack, Typography, Popover, Button } from "@mui/material";
import NoRecordFound from "../../../components/NoRecordFound";
import { numberFormat, splitDateConversion } from "../../../utils/Validations";
import { useNavigate } from "react-router-dom";
import { fontFamilys, fontSizes } from "../../../utils/FontSize";
export default function AvailableUsageTable(props: any) {
  const navigate = useNavigate();
  const [Token, setToken] = useState<any>("");
  const orderDetails = props ? props.props : [];

  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });

  const redirectToBackPage = () => {
    navigate("/dashboard/availablerecievables");
  };
  return (
    <>
      <Box
        mx={3}
        boxShadow={
          "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
        }
        borderRadius={"16px"}
        sx={{
          backgroundColor: "rgba(48, 57, 129, 1)",
        }}
      >
        <Table>
          <TableHead
            sx={{
              borderRadius: "10px",
            }}
          >
            <TableRow>
              {/* <TableCell
                sx={{
                  borderBottom: "none",
                  px: 4,
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Payment
                </Typography>
              </TableCell> */}
              <TableCell
                sx={{
                  borderBottom: "none",
                  // p: 1,
                  textAlign: "center",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Order ID
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  textAlign: "center",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Payment Date
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Amount
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  FD Status
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  FD Ref No
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  FD Days
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  // px: 4,
                }}
              >
                <Typography
                  fontSize={fontSizes.sm}
                  fontWeight={600}
                  fontFamily={fontFamilys.publicSans}
                  color={"white"}
                >
                  Approx Interest
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white" }}>
            {orderDetails.length
              ? orderDetails.map((item: any, index: any) => {
                  return (
                    <>
                      <TableRow
                        sx={{
                          boxShadow:
                            "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
                          borderRadius: "16px",
                        }}
                      >
                        {/* <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            px: 4,

                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontFamily={fontFamilys.publicSans}
                            fontWeight={500}
                          >
                            {item.Payment}
                          </Typography>
                        </TableCell> */}
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            borderBottom: "none",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontFamily={fontFamilys.publicSans}
                            fontWeight={600}
                          >
                            {item.uniqueOrderId}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontWeight={500}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {splitDateConversion(item.paymentSettlementDate)}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"green"}
                            fontFamily={fontFamilys.publicSans}
                            fontWeight={500}
                          >
                            {numberFormat(item.amount || 0)}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={item.isFDAllowed ? "green" : "red"}
                            fontWeight={500}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {item.isFDAllowed ? "Yes" : "No"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontWeight={500}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {item.FD_Ref_No || "N/A"}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            color={"#212B36"}
                            fontWeight={500}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {item.FDDays}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography
                            fontSize={fontSizes.sm}
                            fontWeight={500}
                            color={"rgba(0, 123, 85, 1)"}
                            fontFamily={fontFamilys.publicSans}
                          >
                            {item.approxInterest > 0
                              ? item.approxInterest.toFixed(2)
                              : 0}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              : ""}
          </TableBody>
        </Table>
      </Box>

      {orderDetails.length <= 0 ? (
        <NoRecordFound
          text1={"Available Receivables Usage"}
          text2={"available receivables"}
        />
      ) : (
        ""
      )}
      <Box my={2} display={"flex"} justifyContent={"center"}>
        <Button
          size="small"
          sx={{ backgroundColor: "#303981" }}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            borderRadius: "8px",
            backgroundColor: "#303981",
            fontFamily: "Public Sans",
            fontWeight: "600",
            color: "white",
          }}
          onClick={redirectToBackPage}
        >
          Back{" "}
        </Button>
      </Box>
    </>
    // </TableContainer>
  );
}
