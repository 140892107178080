import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApprovedPaymentIcon from "../../../assets/ApprovedPayment.svg";
import OrderStack from "../Reusable/OrderStack";
import PaymentActionButton from "../Reusable/PaymentActionButton";
import { Stack } from "@mui/system";
import SimpleLoader from "../../../components/Loader";
import { Icon } from "@iconify/react";
import { Typography16 } from "../../../components/typography/Typography";
import { numberFormat, removeSubstring } from "../../../utils/Validations";
import ApiService from "../../../api/api";
import { fontFamilys, fontSizes } from "../../../utils/FontSize";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { SuccessMsg } from "../../../components/Messages/SuccessMsg";
import BookedInterestData from "./BookedInterestData";
import PartialBookedInterestTable from "./PartialBookedInterestTable";
import InterestTableList from "../ViewOrders/InProcess/InterestTableList";
const BookPayablesOrder = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const orderDetails = props && props.orderDetails;
  // const newToken = useSelector((state: any) => state.user.Token);
  // const bookedorderDetails = location.state ? location.state.orderDetails : "";
  // const getorderDetails = async () => {
  //   const Id = bookedorderDetails._id;
  //   const token = await ApiService.setToken(newToken);
  //   const responseData: any = await ApiService.get(
  //     `https://api.dev.assuredpay.in/checkerRoutes/order/${Id}`
  //   );
  //   if (responseData.status === 200) {
  //     const referenceData = responseData.data.data;
  //     setorderDetails(referenceData);
  //   } else {
  //     console.log("hii");
  //   }
  //   setOpen(false);
  // };
  // useEffect(() => {
  //   if (newToken !== "" && newToken !== undefined) {
  //     getorderDetails();
  //   }
  // }, []);
  const openAcceptPayment = () => {
    navigate("/dashboard/chat", { state: { orderDetails: orderDetails } });
  };

  // Order Details Box
  const orderDetailsBox = (label: string, value: any) => {
    return (
      <Box>
        <Typography
          fontSize={fontSizes.xsm}
          fontFamily={fontFamilys.publicSans}
          color="#919EAB"
        >
          {label}
        </Typography>
        <Typography
          color={"#212B36"}
          fontWeight={500}
          sx={{ maxWidth: "400px" }}
          // fontSize={15}
          fontSize={fontSizes.sm}
          fontFamily={fontFamilys.publicSans}
        >
          {value ? value : "N/A"}
        </Typography>
      </Box>
    );
  };
  function formatString(inputString: string) {
    // Splitting the string based on underscore
    const splitString = inputString.split("_");

    // Capitalizing the first character of each word and joining them with space
    const result = splitString
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return result;
  }
  const { copy } = useCopyToClipboard();
  const onCopy = (text: string) => {
    if (text) {
      SuccessMsg("Copied Successfully!");
      copy(text);
    }
  };
  return (
    <>
      <>
        <Box pb={2}>
          {/*  step 1 */}
          <OrderStack
            props={orderDetails}
            OrderText={"Business Order"}
            titleIcon={ApprovedPaymentIcon}
            confirmationTitle={"Payment Successful"}
            titleIconColor={"#36B37E"}
            confirmationSubtitle={`${
              orderDetails &&
              orderDetails.target &&
              orderDetails.target.Legal_Name_of_Business
            } is assured with this payment`}
          />
          {/* step2 */}
          <Box
            justifyContent={"center"}
            component={"div"}
            sx={{
              backgroundColor: "#F7F9FB",
            }}
            // pb={2}
          >
            <Box
              boxShadow={0.5}
              mx={4}
              sx={{
                borderRadius: "16px",
                backgroundColor: "white",
                border: "1px solid #EEF2FA",
                boxShadow:
                  "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              }}
              // component={"form"}
            >
              {/*  Payment Order Details & Payment Seller Details */}
              <Box px={5} mt={1.5}>
                <Stack direction={"row"} spacing={1}>
                  <Box pt={0.5}>
                    <Typography
                      fontWeight={700}
                      fontFamily={fontFamilys.publicSans}
                    >
                      Order Details :
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={"#F58336"}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={700}
                    >
                      {orderDetails &&
                        orderDetails.order &&
                        orderDetails.order.orderID}
                      <Tooltip title="Copy" placement="top">
                        <IconButton
                          onClick={() =>
                            onCopy(
                              orderDetails &&
                                orderDetails.order &&
                                orderDetails.order.orderID
                            )
                          }
                        >
                          <Icon icon="eva:copy-fill" width={20} />
                        </IconButton>
                      </Tooltip>
                    </Typography>{" "}
                  </Box>
                </Stack>
              </Box>
              <Box
                px={8}
                mx={5}
                my={0.5}
                sx={{
                  backgroundColor: "var(--grey-100, #F9FAFB)",
                  border: "1px solid var(--grey-300, #DFE3E8)",
                  borderRadius: "7px",
                }}
              >
                <Stack my={1.5} direction={"row"} spacing={"auto"}>
                  {orderDetailsBox(
                    "Order Title",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.title
                  )}
                  {orderDetailsBox(
                    "Payment Type",
                    orderDetails &&
                      orderDetails.order &&
                      formatString(orderDetails.order.orderType)
                  )}
                  {orderDetailsBox(
                    "Payment Method",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.paymentType === "full"
                      ? "Full Payment"
                      : "Partial Payment"
                  )}
                  <Tooltip
                    title={
                      orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.remark
                    }
                  >
                    {orderDetailsBox(
                      "Description",
                      orderDetails &&
                        orderDetails.order &&
                        removeSubstring(orderDetails.order.remark)
                    )}
                  </Tooltip>
                  {orderDetailsBox(
                    "Amount",
                    orderDetails &&
                      orderDetails.order &&
                      numberFormat(orderDetails.order.orderAmount)
                  )}
                  {orderDetailsBox(
                    "Agreed Days",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.paymentDays
                  )}
                  {/* {orderDetailsBox(
                    "Proposal Validity",
                    orderDetails &&
                      orderDetails.order &&
                      orderDetails.order.proposalExpireDate
                  )} */}
                </Stack>
              </Box>
              <Box px={5} mt={1.5}>
                <Typography
                  fontFamily={fontFamilys.publicSans}
                  fontWeight={700}
                >
                  {orderDetails &&
                    orderDetails.target &&
                    orderDetails.target.targetType}{" "}
                  Details
                </Typography>
              </Box>
              <Box
                px={8}
                mx={5}
                // my={1}
                pt={1}
                sx={{
                  backgroundColor: " var(--grey-100, #F9FAFB)",
                  border: "1px solid var(--grey-300, #DFE3E8)",
                  borderRadius: "7px",
                  maxHeight: "160px",
                }}
              >
                <Box maxWidth={"400px"} mt={1.2}>
                  <Typography16
                    fontWeight={700}
                    // color="#303981"
                    color="#212B36"
                    text={
                      orderDetails.target
                        ? orderDetails.target.Legal_Name_of_Business
                        : "N/A"
                    }
                  ></Typography16>
                </Box>
                <Box sx={{ px: 2, py: 2 }}>
                  <Grid
                    container
                    sx={
                      {
                        // my: 2,
                      }
                    }
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <Avatar
                          src={
                            orderDetails &&
                            orderDetails.target &&
                            orderDetails.target.aadharPhotoLink
                          }
                        />
                        {orderDetailsBox(
                          " Authorised Business Representative",
                          orderDetails && orderDetails.target
                            ? orderDetails.target.nameInAadhaar
                            : "N/A"
                        )}
                      </Stack>

                      <Box mt={2}>
                        <Typography
                          component="legend"
                          fontSize={"11px"}
                          color={"#919EAB"}
                        >
                          Rating
                        </Typography>
                        <Rating
                          name="simple-controlled"
                          value={4}
                          size="small"
                          // onChange={(event, newValue) => {
                          //   setValue(newValue);
                          // }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Box>
                        <Stack direction={"row"}>
                          {orderDetailsBox(
                            "Contact",
                            orderDetails && orderDetails.target
                              ? orderDetails.target.business_mobile
                              : "N/A"
                          )}
                          <Box pt={1.5}>
                            <Tooltip title="Copy" placement="top">
                              <IconButton
                                onClick={() =>
                                  onCopy(
                                    orderDetails &&
                                      orderDetails.target &&
                                      orderDetails.target.business_mobile
                                  )
                                }
                              >
                                <Icon icon="eva:copy-fill" width={20} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Stack>
                      </Box>

                      <Box mt={1}>
                        <Typography
                          component="legend"
                          fontSize={"11px"}
                          color={"#919EAB"}
                        >
                          Since
                        </Typography>
                        <Typography
                          fontFamily={"Public Sans"}
                          fontSize={"14px"}
                          lineHeight={"22px"}
                          fontWeight={600}
                        >
                          2023
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1.2 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      <Tooltip
                        title={
                          orderDetails &&
                          orderDetails.target &&
                          orderDetails.target.Place_of_Business
                        }
                      >
                        {orderDetailsBox(
                          "Address",
                          orderDetails &&
                            orderDetails.target &&
                            removeSubstring(
                              orderDetails.target.Place_of_Business
                            )
                        )}
                      </Tooltip>
                      <Box mt={2}>
                        <Typography
                          component="legend"
                          fontSize={"12px"}
                          color={"#919EAB"}
                        >
                          Orders Full-filed
                        </Typography>
                        <Stack spacing={1} display={"flex"} direction={"row"}>
                          <Typography
                            fontFamily={"Public Sans"}
                            fontSize={"14px"}
                            lineHeight={"22px"}
                            fontWeight={600}
                          >
                            {orderDetails &&
                              orderDetails.target &&
                              orderDetails.target.ordersFullfilled}{" "}
                            /{" "}
                            {orderDetails &&
                              orderDetails.target &&
                              orderDetails.target.totalOrders}
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid
                      style={{
                        padding: "8px",
                      }}
                      sx={{ my: 1.2 }}
                      xs={2}
                      sm={3}
                      md={3}
                    >
                      {/* {orderDetailsBox(
                        "GST Number",
                        orderDetails && orderDetails.target
                          ? orderDetails.target.GSTIN_of_the_entity
                          : "N/A"
                      )} */}
                      <Stack direction={"row"}>
                        {orderDetailsBox(
                          "GST",
                          orderDetails && orderDetails.target
                            ? orderDetails.target.GSTIN_of_the_entity
                            : "N/A"
                        )}
                        <Box pt={1.5}>
                          <Tooltip title="Copy" placement="top">
                            <IconButton
                              onClick={() =>
                                onCopy(
                                  orderDetails &&
                                    orderDetails.target &&
                                    orderDetails.target.GSTIN_of_the_entity
                                )
                              }
                            >
                              <Icon icon="eva:copy-fill" width={20} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>

                      <Box mt={2}>
                        <Stack
                          textAlign={"start"}
                          // spacing={5}
                          sx={
                            {
                              // px: 3,
                              // py: 1.5,
                            }
                          }
                        >
                          <Box>
                            <Typography
                              component="legend"
                              fontSize={"11px"}
                              color={"#919EAB"}
                              // mt={-2}
                            >
                              Connections
                            </Typography>
                            <Typography
                              fontFamily={"Public Sans"}
                              fontSize={"14px"}
                              lineHeight={"22px"}
                              fontWeight={600}
                            >
                              {(orderDetails &&
                                orderDetails.target &&
                                orderDetails.target.connections) ||
                                0}
                            </Typography>
                          </Box>
                          {/* <Stack
                            display={"flex"}
                            direction={"row"}
                            spacing={1.5}
                            mt={1}
                          >
                            <Box>
                              <Stack
                                display={"flex"}
                                direction={"row"}
                                spacing={1}
                              >
                                <Typography
                                  component="legend"
                                  fontSize={"11px"}
                                  color={"#919EAB"}
                                >
                                  Buyers
                                </Typography>
                                <Typography
                                  fontFamily={"Public Sans"}
                                  fontSize={"14px"}
                                  lineHeight={"22px"}
                                  fontWeight={600}
                                >
                                  0
                                </Typography>
                              </Stack>
                            </Box>
                            <Stack
                              display={"flex"}
                              direction={"row"}
                              spacing={1}
                            >
                              <Typography
                                component="legend"
                                fontSize={"11px"}
                                color={"#919EAB"}
                              >
                                Sellers
                              </Typography>
                              <Typography
                                fontFamily={"Public Sans"}
                                fontSize={"14px"}
                                lineHeight={"22px"}
                                fontWeight={600}
                              >
                                0
                              </Typography>
                            </Stack>
                          </Stack> */}
                        </Stack>
                      </Box>
                    </Grid>

                    {/*  */}
                  </Grid>
                </Box>
              </Box>
              {/* Blocked or Used Bank Balance AND Receivables Balance */}
              <BookedInterestData
                props={orderDetails}
                Text1={"Bank Balance Used"}
                Text2={"Receivables Used"}
              />
              <InterestTableList
                TableText={"Interest Details"}
                props={orderDetails}
              />
              <PaymentActionButton
                openAcceptPayment={openAcceptPayment}
                ButtonTextOne={"Chat with Seller"}
                Button2TrueFalse={false}
                Button1TrueFalse={true}
                ButtonColor1={"#36B37E"}
              />{" "}
            </Box>
          </Box>
        </Box>
      </>
    </>
  );
};
export default BookPayablesOrder;
