// apiService.js
import axios from 'axios';

const baseURL = process.env.CONSUMER_BASE_URL_PROD || "https://api.dev.assuredpay.in"; // replace with your API base URL
const apiService = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    // Add any additional headers if needed
  },
});
// const queryParams = { key1: 'value1', key2: 'value2' };
const ApiService = {
    setToken: (token:any) => {
        // Set the Authorization header for all requests
        apiService.defaults.headers.common['token'] = `${token}`;
        // apiService.defaults.headers.common['Authorizations'] = `Bearer ${token}`;

 },
// Function to make a GET request with query parameters
get: async (endpoint:any, params = {}) => {
    try {
      const response = await apiService.get(endpoint, { params });
      return response;
    } catch (error) {
        let response = error
        return response;
    }
  },

  // Function to make a POST request with query parameters
  post: async (endpoint:any, data:any, params = {}) => {
    try {
      const response = await apiService.post(endpoint, data, { params });
      return response;
    } catch (error) {
        let response = error
        return response;
    }
  },

  // Function to make a PUT request with query parameters
  put: async (endpoint:any, data:any, params = {}) => {
    try {
      const response = await apiService.put(endpoint, data, { params });
      return response;
    } catch (error) {
        let response = error
        return response;
    }
  },

   // Function to make a PUT request with query parameters
   patch: async (endpoint:any, data:any, params = {}) => {
    try {
      const response = await apiService.patch(endpoint, data, { params });
      return response;
    } catch (error) {
        let response = error
        return response;
    }
  },

  // Function to make a DELETE request with query parameters
  delete: async (endpoint:any, params = {}) => {
    try {
      const response = await apiService.delete(endpoint, { params });
      return response;
    } catch (error) {
      let response = error
      return response;
    }
  },
};



// // Make a POST request with query parameters
// const postData = { key1: 'value1', key2: 'value2' };
// const postResponse = await ApiService.post('/your-endpoint', postData, { param1: 'value1' });

// // Make a PUT request with query parameters
// const putData = { key1: 'value1', key2: 'value2' };
// const putResponse = await ApiService.put('/your-endpoint', putData, { param1: 'value1' });

// // Make a DELETE request with query parameters
// const deleteResponse = await ApiService.delete('/your-endpoint', { param1: 'value1' });

export default ApiService;
