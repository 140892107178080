import Card from "@mui/material/Card";
import ResistoreIcon from "../../../assets/signup.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import OtpVerifyForm from "./OtpVerifyForm";
import { Stack } from "@mui/material";
import Header from "../../../components/Header";
const OtpVerification = () => {
  return (
    <>
      <Header />
      <Box margin={4} sx={{ flexGrow: 1 }} maxWidth={"1366px"}>
        <Box
          component={"div"}
          marginTop={4}
          marginBottom={4}
          justifyContent={"center"}
          sx={{ display: "flex" }}
          alignItems={"center"}
        >
          <Grid container spacing={{ sm: 8, md: 10, lg: 12 }}>
            <Grid>
              <Card
                elevation={1}
                sx={{
                  px: "60px",
                  py: "32px",
                  mt: 2,
                }}
                style={{
                  marginLeft: "15vh",
                  paddingBottom: "20px",
                }}
              >
                <Stack>
                  <Typography
                    component="div"
                    fontFamily={"Public Sans"}
                    fontSize={"18px"}
                    fontWeight={700}
                    lineHeight={"28px"}
                    color={"#334A67"}
                    // textAlign={"start"}
                  >
                    Verify Your Business Account
                  </Typography>
                  <Typography
                    component="div"
                    fontSize={"12px"}
                    fontFamily={"Public Sans"}
                    fontWeight={600}
                    color={"#556486"}
                    textAlign={"start"}
                  >
                    OTP sent to your business email and mobile number.
                  </Typography>
                  <Stack sx={{ mt: 2 }}>
                    <OtpVerifyForm />
                  </Stack>
                </Stack>
              </Card>
            </Grid>
            <Grid>
              <Stack
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                flexShrink={0}
              >
                <img
                  src={ResistoreIcon}
                  style={{
                    marginTop: "100px",
                    maxHeight: "380px",
                    maxWidth: "520px",
                  }}
                  alt=""
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default OtpVerification;
