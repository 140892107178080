import * as React from "react";
import { Box, Card, Stack, Tab, Tabs } from "@mui/material";
import PaymentSummary from "./PaymentSummary";
import ConfigurePayment from "./ConfigurePayment";
import ConfirmationPage from "./ConfirmationPage";
import PartialPaymentScreen from "./PartialPaymentScreen";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useSelector } from "react-redux";

export default function PaymentFlowStepper() {
  const newToken = useSelector((state: any) => state.user.Token);
  const state = useLocation();
  const [requestToPayData, setRequestToPayData] = React.useState<any>();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isSteplock, setIsStepLock] = React.useState(false);

  const location = useLocation();
  const BusinessDetailsForPayment = location.state
    ? location.state.businessNetwork
    : {};

  const steps = ["Payment Summary", "Configure Payment", "Confirmation"];

  const [paymentSummaryData, setPaymentSummaryData] = React.useState({
    title: "",
    paymentType: "",
    PO: "",
    paymentMethod: "",
    desc: "",
    amount: "",
    date: dayjs(new Date()).add(7, "day"),
    days: "",
    milestone: "",
    businessId: BusinessDetailsForPayment?.businessDetails?.user || "",
  });

  const [confirmDataForPayment, setConfirmDataForPayment] = React.useState({
    orderDetails: {
      business_id: "",
      paymentType: "",
      paymentCombination: "",
      remaks: "",
      title: "",
      POPI: "",
      orderAmount: "",
      paymentIdentifier: "",
      paymentDays: "",
    },
    interestTable: [],
    milestoneDetails: [],
  });

  const handlePaymentSummary = (val: any) => {
    setPaymentSummaryData(val);
  };

  const handleStepper = (val: number, type: string) => {
    setActiveStep(val);
  };

  const handlePaymentDetail = (val: any) => {
    setConfirmDataForPayment(val);
  };

  React.useEffect(() => {
    if (state.state.prRequestId) {
      acceptPayment(state.state.prRequestId);
    }
  }, [state.state.prRequestId]);

  const acceptPayment = async (val: string) => {
    await axios
      .get(
        "https://api.dev.assuredpay.in/checkerRoutes/request/payment/received/" +
          val,
        {
          headers: { token: newToken ? newToken : "" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setPaymentSummaryData({
            title: res?.data?.data?.order?.title,
            paymentType: res?.data?.data.order?.orderType,
            PO: res?.data?.data?.order?.POPI,
            paymentMethod:
              res?.data?.data?.order?.paymentType == "full"
                ? "Full Payment"
                : "Partial Payment",
            desc: res?.data?.data?.order?.remark,
            amount: res?.data?.data?.order?.orderAmount,
            date: res?.data?.data?.order?.paymentSettlementDate,
            days: res?.data?.data?.order?.paymentDays,
            milestone:
              res?.data?.data?.milestones.length == 1
                ? ""
                : `${res?.data?.data?.milestones.length} Milestone`,
            businessId: res?.data?.data?.order?.createdBy,
          });
          setRequestToPayData({
            order: res?.data?.data?.order,
            milestones: res?.data?.data?.milestones,
          });
          res?.data?.data?.order?.title && setIsStepLock(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card
      sx={{
        m: 4,
        borderRadius: 4,
        borderStyle: "dashed",
        borderColor: "#d4dadf",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeStep}
          sx={{
            "& .css-1ocpf3a-MuiButtonBase-root-MuiTab-root.Mui-selected": {
              color: "#303981",
            },
            "& .css-1aquho2-MuiTabs-indicator": {
              backgroundColor: "#303981",
            },
            "& .css-1ocpf3a-MuiButtonBase-root-MuiTab-root": {
              minHeight: 48,
            },
          }}
          aria-label="secondary tabs example"
        >
          {steps.map((item, index) => {
            return (
              <Tab
                label={item}
                value={index}
                icon={
                  activeStep > index ? (
                    <Icon
                      icon="mdi:approve"
                      style={{ color: "#303981", height: 20, width: 20 }}
                    />
                  ) : (
                    <Icon
                      icon="mdi:approve"
                      style={{ color: "#dadada", height: 20, width: 20 }}
                    />
                  )
                }
                iconPosition="end"
                disabled={index !== activeStep}
              />
            );
          })}
        </Tabs>
      </Box>

      <Stack sx={{ backgroundColor: "#f5f6f8" }}>
        {activeStep == 0 ? (
          <PaymentSummary
            handleStepper={handleStepper}
            handlePaymentSummary={handlePaymentSummary}
            paymentSummaryData={paymentSummaryData}
            isSteplock={isSteplock}
          />
        ) : activeStep == 1 ? (
          paymentSummaryData.paymentMethod == "Partial Payment" ? (
            <PartialPaymentScreen
              paymentSummaryData={paymentSummaryData}
              handleStepper={handleStepper}
              requestToPayData={requestToPayData}
              isSteplock={isSteplock}
              confirmDetailData={handlePaymentDetail}
            />
          ) : (
            <ConfigurePayment
              handleStepper={handleStepper}
              paymentSummaryData={paymentSummaryData}
              confirmDetailData={setConfirmDataForPayment}
              isSteplock={isSteplock}
            />
          )
        ) : (
          <ConfirmationPage
            handleStepper={handleStepper}
            requestToPayData={requestToPayData}
            paymentSummaryData={paymentSummaryData}
            confirmDataForPayment={confirmDataForPayment}
            isSteplock={isSteplock}
          />
        )}
      </Stack>
    </Card>
  );
}
