import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./slices/user";
// ----------------------------------------------------------------------
const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};
const userPersistConfig = {
  key: "user",
  storage,
  keyPrefix: "AP-",
  whitelist: ["userLogin", "Token", "LocationInfo", "userSave","receivableState"],
};
const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
});
export { rootPersistConfig, rootReducer };
