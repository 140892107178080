import { Chip } from "@mui/material";

export const Chips = (props: any) => {
  return (
    <>
      {
        <Chip
          label={`${props.count}`}
          style={{
            backgroundColor: props.chipColor,
            color: props.chipFontColor,
            fontWeight: 700,
            fontSize: 10,
            lineHeight: "18px",
            fontFamily: "Public Sans",
          }}
          sx={{
            minheight: "20px",
            minwidth: "30px",
            "& .MuiChip-label": {
              fontWeight: 700,
            },
            borderRadius: "8px",
          }}
        />
      }
    </>
  );
};
