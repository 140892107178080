import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import RejectedIcon from "../../../../assets/icons8-reject.svg";
import {
  Typography14,
  Typography16,
  Typography22,
} from "../../../../components/typography/Typography";
import OrderStack from "../../Reusable/OrderStack";
import GridBoxDetails from "../../Reusable/GridBoxDetails";
import { useSelector } from "react-redux";
import axios from "axios";
import { Icon } from "@iconify/react";
import CancelIcon from "@mui/icons-material/Cancel";

import { useEffect, useState } from "react";
import InterestDetailsData from "../../Reusable/InterestDetailsData";
import SimpleLoader from "../../../../components/Loader";
import ApiService from "../../../../api/api";
import PaymentConfirmDialog from "../../Reusable/PaymentConfirmDialog";
import PaymentActionButton from "../../Reusable/PaymentActionButton";
import PartialInterestTable from "../InProcess/PartialInterestTable";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { SuccessMsg } from "../../../../components/Messages/SuccessMsg";
import { fontFamilys, fontSizes } from "../../../../utils/FontSize";
import { numberFormat, removeSubstring } from "../../../../utils/Validations";
import { ErrorMsg } from "../../../../components/Messages/ErrorMsg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InterestTableList from "../InProcess/InterestTableList";
import View from "../../Orders/view";

const CancelOrder = (props: any) => {
  const navigate = useNavigate();
  const orderDetails = (props && props.orderDetails) || {};
  //  Redirect To Chat Page
  const RedirectToSellerChat = () => {
    navigate("/dashboard/chat", { state: { orderDetails: orderDetails } });
  };

  return (
    <div>
      <>
        <Box pb={2}>
          <Box
            justifyContent={"center"}
            component={"div"}
            sx={{
              backgroundColor: "#F7F9FB",
            }}
          >
            <Box
              boxShadow={0.5}
              mx={4}
              sx={{
                borderRadius: "16px",
                backgroundColor: "white",
                border: "1px solid #EEF2FA",
                boxShadow:
                  "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)",
              }}
            >
              <View props={orderDetails} />

              <PaymentActionButton
                openAcceptPayment={RedirectToSellerChat}
                ButtonTextOne={"Chat with Seller"}
                Button2TrueFalse={false}
                Button1TrueFalse={true}
                ButtonColor1={"#303981"}
                ButtonColor2={"#FF5630"}
              />
            </Box>
          </Box>
        </Box>
      </>
    </div>
  );
};

export default CancelOrder;
