import { Box, Stack } from "@mui/system";
import {
  Typography12,
  Typography22,
} from "../../../components/typography/Typography";
const BookedInterestData = (props: any) => {
  const OrderDetails = props ? props.props : {};
  const Text1 = props ? props.Text1 : "";
  const Text2 = props ? props.Text2 : "";

  return (
    <Box display={"flex"} justifyContent={"center"} my={1.5}>
      <Stack display={"flex"} direction={"row"} spacing={3}>
        <Stack textAlign={"center"} justifyContent={"center"}>
          <Typography12 text={Text1}></Typography12>
          <Typography22
            color={"#303981"}
            text={
              OrderDetails &&
              OrderDetails.amountData &&
              OrderDetails.amountData.bankBalance.amount
            }
          ></Typography22>
          <Typography12
            color={"#007B55"}
            fontSize={12}
            fontWeight={400}
            text={`Approx interest Rs. ${
              OrderDetails &&
              OrderDetails.amountData &&
              OrderDetails.amountData.bankBalance.approxInterest.toFixed(2)
            }`}
          ></Typography12>
        </Stack>
        <Stack textAlign={"center"} justifyContent={"center"}>
          <Typography12 text={Text2 ? Text2 : ""}></Typography12>
          <Typography22
            color={"#303981"}
            text={
              OrderDetails &&
              OrderDetails.amountData &&
              OrderDetails.amountData.recievables.amount
            }
          ></Typography22>
          <Typography12
            color={"#007B55"}
            fontSize={12}
            fontWeight={400}
            text={`Approx interest Rs. ${
              OrderDetails &&
              OrderDetails.amountData &&
              OrderDetails.amountData.recievables.approxInterest.toFixed(2)
            }`}
          ></Typography12>
        </Stack>
      </Stack>
    </Box>
  );
};
export default BookedInterestData;
