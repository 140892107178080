import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Rating,
  Modal,
  Checkbox,
} from "@mui/material";
import { useState } from "react";
import React from "react";
import {
  Typography12,
  Typography14,
  Typography16,
  Typography18,
  Typography20,
} from "../../components/typography/Typography";
import { fontFamilys } from "../../utils/FontSize";
import { LoadingButton } from "@mui/lab";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import StarsIcon from "@mui/icons-material/Stars";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const RateYourSeller = () => {
  const navigate = useNavigate();
  const [cancleOpen, setCancleOpen] = useState(false);
  const [submitOpen, setsubmitOpen] = useState(false);

  const CancleDialogHandle = () => {
    setCancleOpen(true);
  };
  const SubmitDialogHandle = () => {
    setsubmitOpen(true);
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: "50%",
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
  };
  const styleCancleRating = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: 400,
    boxShadow: 24,
    borderRadius: 3,
    p: 2,
  };

  const [checkBox, setcheckBox] = useState(false);
  const SubmitReview = () => {
    console.log("review submit");
  };
  return (
    <>
      <Box>
        <Stack mx={4}>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"24px"}
            sx={{ mt: 4 }}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#000000"}
          >
            Order Id : 12345678910
          </Typography>
          <Stack>
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"14px"}
              fontWeight={400}
              fontStyle={"normal"}
              color={"rgba(145, 158, 171, 1)"}
            >
              Rate your order
            </Typography>
          </Stack>
        </Stack>
        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
          }}
        >
          <Box
            boxShadow={1}
            mx={4}
            my={3}
            display="flex"
            flexDirection="column"
            sx={{
              borderRadius: "12px",
              backgroundColor: "white",
              height: "70vh",
            }}
          >
            <Box p={2}>
              <Stack gap={1} px={2.5}>
                <Typography18
                  color="#000000"
                  fontWeight={700}
                  text={"Write Review for your Buyer"}
                ></Typography18>
              </Stack>
              <Stack gap={0.5} px={2.5} mt={2}>
                <Typography14
                  color="#000000"
                  fontWeight={400}
                  text={"How was your first experience"}
                ></Typography14>
                <Rating value={2} />
              </Stack>
              <Stack gap={0.5} px={2.5} py={1}>
                <Typography14
                  color="#000000"
                  fontWeight={400}
                  text={"How was your second experience"}
                ></Typography14>
                <Rating value={3} />
              </Stack>
              <Stack gap={0.5} px={2.5} py={1}>
                <Typography14
                  color="#000000"
                  fontWeight={400}
                  text={"How was your third experience"}
                ></Typography14>
                <Rating value={4} />
              </Stack>
              <Stack gap={1} px={2.5} py={1}>
                <Typography14
                  color="#000000"
                  fontWeight={600}
                  text={"Write Your Review"}
                ></Typography14>
                <Box
                  borderRadius={"16px"}
                  maxWidth={"50%"}
                  sx={{ backgroundColor: "#F5F6FA" }}
                  px={1}
                >
                  <TextField
                    type="text"
                    name="description"
                    placeholder="Type your review here"
                    // value={CreatePaymentOrder.description}
                    fullWidth
                    multiline
                    inputProps={{
                      disableUnderline: true,
                    }}
                    // onChange={createOrderForPayment}
                    rows={4}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        borderColor: "#919EAB",
                      },
                    }}
                    sx={{
                      "& fieldset": { border: "none" },
                      "& .MuiInputBase-root": {
                        height: "fit-content",
                        alignItems: "start",
                        color: "#7A8499",
                        fontSize: "13px",
                        fontFamily: fontFamilys.publicSans,
                        fontWeight: "400",
                        lineHeight: "20px",
                      },
                      // "& .MuiFormLabel-root": {
                      //   color: "#919EAB",
                      //   fontFamily: "Public Sans",
                      //   fontWeight: "500",
                      //   fontSize: "14px",
                      // },
                    }}
                  />
                </Box>
                <Box>
                  <Stack display={"flex"} direction={"row"} spacing={4} my={2}>
                    <Button
                      size="medium"
                      onClick={SubmitDialogHandle}
                      sx={{
                        color: "white",
                        borderRadius: "8px",
                        px: 2,
                        backgroundColor: "#303981",
                        textAlign: "right",
                        fontFamily: "Public Sans",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#303981",
                        },
                      }}
                    >
                      <Typography14
                        color={"white"}
                        text={"Submit"}
                      ></Typography14>
                    </Button>

                    <Button
                      size="medium"
                      onClick={CancleDialogHandle}
                      sx={{
                        color: "white",
                        borderRadius: "8px",
                        px: 2,
                        backgroundColor: `#FF5630`,
                        textAlign: "right",
                        fontFamily: "Public Sans",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: `#FF5630`,
                        },
                      }}
                    >
                      <Typography14
                        color={"white"}
                        text={"Cancle"}
                      ></Typography14>
                    </Button>
                  </Stack>
                </Box>
              </Stack>
              <Modal
                open={submitOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Stack gap={2}>
                    <Box display={"flex"} justifyContent={"center"}>
                      <StarsIcon fontSize="large" sx={{ color: "green" }} />
                    </Box>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Typography20
                        color={"#212B36"}
                        text={"Thanks For Rating !"}
                      ></Typography20>
                    </Box>
                  </Stack>

                  <Box m={4}>
                    <Typography14 text="Review"></Typography14>
                    <Box
                      py={2}
                      mt={1}
                      px={2}
                      borderRadius={"16px"}
                      sx={{ backgroundColor: "#F5F6FA" }}
                    >
                      <Typography12
                        color="#7A8499"
                        text={
                          "Apex Infra LTD is an outstanding seller with exceptional communication, top-quality products, competitive pricing, efficient shipping, and a strong commitment to customer satisfaction. Their transparent and supportive approachmakes them a trustworthy choice for all buyers. Highlyrecommended!"
                        }
                      ></Typography12>
                    </Box>
                  </Box>

                  <Box display={"flex"} justifyContent={"center"}>
                    <Stack gap={1} px={2}>
                      <Typography16
                        fontWeight={500}
                        color={!checkBox ? "#212B36" : "#303981"}
                        text={
                          "How was your overall experience with Apex India Private Limited"
                        }
                      ></Typography16>{" "}
                      <Box display={"flex"} justifyContent={"center"}>
                        <Rating value={3} />
                      </Box>
                    </Stack>
                  </Box>

                  <Stack
                    flexDirection={"row"}
                    justifyContent={"end"}
                    mt={2}
                    gap={1}
                  >
                    <Button
                      sx={{
                        borderRadius: 2,
                      }}
                      onClick={() => setsubmitOpen(!submitOpen)}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      sx={{
                        background: "#303981",
                        borderRadius: 2,
                        "&:hover": {
                          backgroundColor: "#343d84db",
                        },
                      }}
                      onClick={SubmitReview}
                      // loading={isPaymentLoading}
                      disabled={!checkBox}
                    >
                      Confirm
                    </LoadingButton>
                  </Stack>
                </Box>
              </Modal>
              {/* Cancle Dialog  */}
              <Modal
                open={cancleOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styleCancleRating}>
                  <Stack flexDirection={"row"} justifyContent={"center"} mb={2}>
                    <Typography16
                      color={"#212B36"}
                      text={"Are you sure ?"}
                    ></Typography16>
                  </Stack>
                  <Stack direction={"row"}>
                    <Box>
                      <Checkbox
                        value={checkBox}
                        onChange={() => setcheckBox(!checkBox)}
                        sx={{
                          color: "#303981",
                          "&.Mui-checked": {
                            color: "#303981",
                          },
                        }}
                      />
                    </Box>
                    <Typography12
                      fontWeight={500}
                      color={!checkBox ? "#212B36" : "#303981"}
                      text={
                        "Once you cancle the review option, then the inputs you have given in it will become empty."
                      }
                    ></Typography12>{" "}
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"end"}
                    mt={2}
                    gap={1}
                  >
                    <Button
                      sx={{
                        borderRadius: 2,
                      }}
                      onClick={() => setCancleOpen(!cancleOpen)}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      variant="contained"
                      sx={{
                        background: "#303981",
                        borderRadius: 2,
                        "&:hover": {
                          backgroundColor: "#343d84db",
                        },
                      }}
                      onClick={() => navigate(-1)}
                      // loading={isPaymentLoading}
                      disabled={!checkBox}
                    >
                      Confirm
                    </LoadingButton>
                  </Stack>
                </Box>
              </Modal>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RateYourSeller;
