import { Card, CardContent, Container, Box } from "@mui/material";
import ResistoreIcon from "../../assets/signup.jpg";
import Grid from "@mui/material/Unstable_Grid2";
import UserResistor from "./UserResistor";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../redux/slices/user";
import Header from "../../components/Header";
import {
  Typography12,
  Typography20,
  Typography22,
} from "../../components/typography/Typography";
import { Stack } from "@mui/system";

const Registor = () => {
  // For Title Page
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logOutUser(false));
    localStorage.removeItem("User");
    localStorage.removeItem("Token");
  });
  return (
    <>
      <Header />
      <Box margin={3} sx={{ flexGrow: 1 }}>
        <Container>
          <Box
            margin={1.5}
            justifyContent={"center"}
            sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
            alignItems={"center"}
          >
            <Grid
              container
              spacing={15}
              sx={{ xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }}
            >
              <Grid>
                <Box boxShadow={1} sx={{ maxWidth: 400, width: "100%" }} px={4}>
                  <Stack display={"flex"} direction={"column"} p={2} spacing={-0.5}>
                    <Typography20
                      text={"Register your business"}
                    ></Typography20>
                    <Typography12
                      fontSize={12}
                      color={"#556486"}
                      text={
                        "Join AssuredPay's business network. Streamline your"
                      }
                    ></Typography12>
                    <Typography12
                      fontSize={12}
                      color={"#556486"}
                      text={
                        "payments, optimize receivables, and access financial solutions."
                      }
                    ></Typography12>
                  </Stack>
                  <Box
                    display="block"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      " & .MuiTextField-root": {
                        m: 0.5,
                        maxWidth: "38ch",
                        width: "100%",
                      },
                    }}
                  >
                    {/* User Resistration page */}
                    <UserResistor />
                    {/*  */}
                  </Box>
                </Box>
              </Grid>
              <Grid>
                <img
                  src={ResistoreIcon}
                  style={{
                    marginTop: "80px",
                    maxHeight: "250px",
                    maxWidth: "550px",
                    alignItems: "center",
                  }}
                  alt=""
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Registor;
