import {
  Box,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  TablePagination,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSelector } from "react-redux";
import React from "react";
import { enqueueSnackbar } from "notistack";
import AcceptRequestList from "./Accept/AcceptRequestList";
import SimpleLoader from "../../../components/Loader";
import { useLocation } from "react-router-dom";
import ApproveRequestList from "./ApproveRequestList";
import { Chips } from "../../../components/businessNetwok/chips/Chips";
import { TypographyText } from "../../../components/businessNetwok/typography/TypographyText";

const ApprovePaymentRequestList = (props: any) => {
  const [open, setopen] = useState(false);
  const location = useLocation();

  const [acceptReequestList, setacceptReequestList] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [Token, setToken] = useState<any>("");
  const newToken = useSelector((state: any) => state.user.Token);
  const [selectStatus, setSelectStatus] = useState("approved");
  const [CountData, setCountData] = useState<any>({});

  const getUsersList = async () => {
    setopen(true);
    // userToken check
    let params = {
      filter: selectStatus,
    };
    if (newToken !== "") {
      const axiosConfig = {
        headers: {
          token: newToken ? newToken : "",
        },
        params: params,
      };
      const FetchData = await axios
        .get(
          `https://api.dev.assuredpay.in/checkerRoutes/request/payment/received`,
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            let data: any = res.data.data;
            let Count = res.data.countData;
            setCountData(Count);
            console.log(data, "dtaa-----------------");
            if (data.length > 0) {
              setacceptReequestList(data);
            } else {
              setacceptReequestList([]);
            }
            setopen(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setopen(false);
        });
    } else {
      console.log("token token token", Token);
    }
  };
  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });
  useEffect(() => {
    getUsersList();
  }, [selectStatus]);
  const handleChangeSelectStatus = (event: any, newValue: string) => {
    setSelectStatus(newValue);
  };
  return (
    <>
      <Stack mx={4}>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"24px"}
          sx={{ mt: 4 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          Accept Payment Requests
        </Typography>
      </Stack>
      <Box
        justifyContent={"center"}
        component={"div"}
        sx={{
          backgroundColor: "#F7F9FB",
        }}
      >
        <Box
          boxShadow={1}
          mx={4}
          my={2}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
        >
          {" "}
          <Stack direction={"row"}>
            <Tabs
              value={selectStatus}
              onChange={handleChangeSelectStatus}
              textColor="primary"
              indicatorColor="secondary"
              sx={{
                px: 2,
                "& .MuiTabs-indicator": {},
              }}
            >
              <Tab
                value="approved"
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={(CountData && CountData.approved) || 0}
                      chipColor={"rgba(145, 158, 171, 0.16)"}
                      chipFontColor={"rgba(33, 43, 54, 1)"}
                    />
                    <TypographyText
                      color={"rgba(99, 115, 129, 1)"}
                      fontSize={14}
                      text={"Pending"}
                    />
                  </Stack>
                }
              ></Tab>
              <Tab
                value="rejected"
                sx={{ textTransform: "none", px: 4 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={(CountData && CountData.rejected) || 0}
                      chipColor={"rgba(255, 86, 48, 0.16)"}
                      chipFontColor={"rgba(183, 29, 24, 1)"}
                    />
                    <TypographyText
                      color={"rgba(99, 115, 129, 1)"}
                      text={"Rejected"}
                      fontSize={14}
                    />
                  </Stack>
                }
              />
              <Tab
                value="expired"
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={(CountData && CountData.expired) || 0}
                      chipColor={"rgba(255, 86, 48, 0.16)"}
                      chipFontColor={"rgba(183, 29, 24, 1)"}
                    />
                    <TypographyText text={"Expired"} fontSize={14} />
                  </Stack>
                }
              ></Tab>
            </Tabs>{" "}
          </Stack>
          <Stack
            sx={{ mx: 4, py: 2 }}
            display={"flex"}
            direction={"row"}
            spacing={1}
          >
            {" "}
            <TextField
              disabled
              fullWidth
              placeholder="Search By Payment Request (PR) Number"
              InputProps={{
                style: { borderRadius: "10px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box>
            <ApproveRequestList props={acceptReequestList} />
          </Box>
          <Box>
            <Divider></Divider>
            <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
              <TablePagination
                component="div"
                count={10}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
      <SimpleLoader props={open} />
    </>
  );
};

export default ApprovePaymentRequestList;
