import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  FormHelperText,
  Grid,
  Modal,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  tableCellClasses,
} from "@mui/material";
// form
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../../../components/hook-form/FormProvider";
import { RHFTextField } from "../../../../components/hook-form";

// date component
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { ErrorMsg } from "../../../../components/Messages/ErrorMsg";
import axios from "axios";
import { SuccessMsg } from "../../../../components/Messages/SuccessMsg";
import { useSelector } from "react-redux";
import {
  daysConversionFromDate,
  numberFormat,
  numberToEnglish,
} from "../../../../utils/Validations";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#303981",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

type FormValuesProps = {
  balanceUsedFormilestone: number;
  usedBankBal: number;
  date: string;
  days: string;
};

export default function PartialPaymentScreen({
  paymentSummaryData,
  handleStepper,
  confirmDetailData,
  requestToPayData,
  isSteplock,
}: any) {
  const location = useLocation();
  const BusinessDetailsForPayment = location.state
    ? location.state.businessNetwork
    : {};
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeDateCount, setActiveDateCount] = React.useState<number>();
  const newToken = useSelector((state: any) => state.user.Token);
  const [receivableAmount, setReceivableAmount] = React.useState(0);
  const [availableBalance, setAvailableBalance] = React.useState(0);
  const [allReceiablesData, setAllReceiablesData] = React.useState([]);
  const [usedReceivables, setUsedReceivables] = React.useState<any>([]);
  const [milestonesData, setMilestoneData] = React.useState<any>([]);

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //state for saving draft
  const [isDraftLoading, setIsDraftLoading] = React.useState(false);
  const [isPaymentLock, setIsPaymentLock] = React.useState(false);

  // interest Table
  const [interestTable, setInterestTable] = React.useState<any>([]);

  // Form Controller
  const FilterSchema = Yup.object().shape({
    balanceUsedFormilestone: isSteplock
      ? Yup.number()
      : Yup.number()
          .typeError("Please Enter Valid Amount")
          .min(0, "Please Enter Amount")
          .max(
            +paymentSummaryData?.amount -
              milestonesData?.reduce(
                (total: number, item: any) =>
                  total + item?.balanceUsedFormilestone,
                0
              ) -
              (milestonesData?.length - milestonesData[activeStep]?._id - 1),
            `maximum limit for Milestone ${
              +paymentSummaryData.amount -
              milestonesData?.reduce(
                (total: number, item: any) =>
                  total + item?.balanceUsedFormilestone,
                0
              ) -
              (milestonesData?.length - milestonesData[activeStep]?._id - 1)
            }`
          )
          .required("Please Enter valid Amount"),
    usedBankBal: Yup.number()
      .typeError("Please Enter Valid Amount")
      .min(0, "Please Enter Amount")
      .required("Please Enter valid Amount"),
    date: Yup.string().required("Please Select date first"),
  });
  const defaultValues = {
    usedBankBal: 0,
    balanceUsedFormilestone: 0,
    date: dayjs(new Date()).add(7, "day"),
    days: "7",
  };
  const methods = useForm<FormValuesProps | any>({
    resolver: yupResolver(FilterSchema),
    defaultValues,
    mode: "onChange",
  });
  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const chipDays = [
    {
      label: "7 days",
      value: 7,
    },
    {
      label: "15 days",
      value: 15,
    },
    {
      label: "30 days",
      value: 30,
    },
    {
      label: "60 days",
      value: 60,
    },
    {
      label: "90 days",
      value: 90,
    },
    {
      label: "150 days",
      value: 150,
    },
    {
      label: "180 days",
      value: 180,
    },
  ];

  React.useEffect(() => {
    setUsedReceivables([]);
  }, [watch("balanceUsedFormilestone") || watch("usedBankBal")]);

  //FETCH ALL RECEIVALBLE FOR MODAL
  const viewAllReceivables = async () => {
    await axios
      .get("https://api.dev.assuredpay.in/checkerRoutes/getrecievables", {
        headers: { token: newToken ? newToken : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          setAllReceiablesData(res.data.result);
          handleOpen();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //FETCH BANK BALANCE & TOTAL RECEIVEABLE
  const fetchAmount = async () => {
    await axios
      .get(
        "https://api.dev.assuredpay.in/checkerRoutes/getrecievablesdashboard",
        { headers: { token: newToken ? newToken : "" } }
      )
      .then((res) => {
        if (res.status === 200) {
          let orderData = res.data.result;
          setReceivableAmount(orderData.totalReceivable);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get("https://api.dev.assuredpay.in/checkerRoutes/getwalletbalance", {
        headers: { token: newToken ? newToken : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          let orderData = res.data.result;
          setAvailableBalance(orderData.availableBalance);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = async (data: FormValuesProps) => {
    let body = {
      orderAmount: milestonesData[activeStep]?.invoiceAmount,
      milestoneAmount: data.balanceUsedFormilestone,
      dueDate: getValues("date"),
      days: daysConversionFromDate(getValues("date")),
      bankBalance: data.usedBankBal,
    };
    await axios
      .post(
        "https://api.dev.assuredpay.in/checkerRoutes/createOrder/getRecievables",
        body,
        { headers: { token: newToken ? newToken : "" } }
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.lowBankBalance) {
            ErrorMsg(
              // `You don't have any receivables in this tenure. Please add  from your Bank Balance.`
              `You do not have sufficient receivables. Please use the remaining ${res.data.requiredAmount.toFixed(
                2
              )} from your Bank Balance`
            );
          } else {
            setUsedReceivables(res.data.result);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //save Amount for draft and lock the UI and get Interest table
  const saveDraft = async () => {
    setIsDraftLoading(true);
    let usedReceivable =
      +getValues("usedBankBal") !== +getValues("balanceUsedFormilestone");
    let draftBodyArray: any = [];
    let i = 0;
    do {
      draftBodyArray.push({
        milestoneId: usedReceivable ? usedReceivables[i]?.id : "",
        orderAmount: milestonesData[activeStep]?.invoiceAmount,
        days: daysConversionFromDate(getValues("date")),
        bankBalanceUsed: +getValues("usedBankBal"),
        index: activeStep,
        milestoneAmount: +getValues("balanceUsedFormilestone"),
        dueDate: getValues("date"),
        assuredRecievables: usedReceivable
          ? usedReceivables[i]?.assuredRecievables
          : 0,
        usableRecievables: usedReceivable
          ? usedReceivables[i]?.usableRecievables
          : 0,
        receivablesToBeUsed: usedReceivable
          ? usedReceivables[i]?.receivablesToBeUsed
          : 0,
        convenienceFee: usedReceivable ? usedReceivables[i]?.convenienceFee : 0,
        isFDAllowed: usedReceivable ? usedReceivables[i]?.isFDAllowed : false,
        fdDays: usedReceivable ? usedReceivables[i]?.fdDays : 0,
        approxInterest: usedReceivable ? usedReceivables[i]?.approxInterest : 0,
      });
      i++;
    } while (i < usedReceivables.length);

    await axios
      .post(
        "https://api.dev.assuredpay.in/checkerRoutes/selectRecievable",
        draftBodyArray,
        { headers: { token: newToken ? newToken : "" } }
      )
      .then((res) => {
        if (res.status === 200) {
          setInterestTable(res.data.data);
          setIsPaymentLock(true);
          milestonesData.length - 1 == milestonesData[activeStep]?._id &&
            handleStepperMilestone(milestonesData[activeStep]?._id, {
              balanceUsedFormilestone: getValues("balanceUsedFormilestone") | 0,
              usedBankBal: getValues("usedBankBal") | 0,
              date: getValues("date"),
              days: getValues("days"),
              interestTable: interestTable,
              receivabletable: usedReceivables,
              invoiceAmount: milestonesData[activeStep]?.invoiceAmount,
              milestoneCount: parseInt(
                milestonesData[activeStep]?.milestoneCount
              ),
              milestoneLock: isPaymentLock,
              _id: milestonesData[activeStep]?._id,
            });
          SuccessMsg(res.data.message);
          setIsDraftLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsDraftLoading(false);
      });
  };

  React.useEffect(() => {
    setValue(
      "balanceUsedFormilestone",
      +requestToPayData?.milestones[0]?.amount
    );
    setValue("date", dayjs(requestToPayData?.milestones[0]?.date));
    setValue("days", dayjs(requestToPayData?.milestones[0]?.days));
    let array: any = [];
    if (isSteplock) {
      requestToPayData?.milestones.map((item: any, index: number) => {
        array.push({
          balanceUsedFormilestone: item.amount,
          usedBankBal: 0,
          date: dayjs(item.date),
          days: item.days,
          interestTable: [],
          receivabletable: [],
          invoiceAmount: +requestToPayData?.order?.orderAmount,
          milestoneCount: requestToPayData?.milestones?.length,
          milestoneLock: false,
          _id: index,
        });
      });
    } else {
      [...Array(parseInt(paymentSummaryData.milestone))].map((item, index) => {
        array.push({
          balanceUsedFormilestone: 0,
          usedBankBal: 0,
          date: "",
          days: 7 + index,
          interestTable: [],
          receivabletable: [],
          invoiceAmount: +paymentSummaryData.amount,
          milestoneCount: parseInt(paymentSummaryData.milestone),
          milestoneLock: false,
          _id: index,
        });
      });
    }
    console.log("array", array);
    setMilestoneData(array);
  }, []);

  React.useEffect(() => {
    fetchAmount();
    // console.log("milestone", milestonesData);
    // if (requestToPayData?.milestone?.length && milestonesData?.length) {
    //   setMilestoneData(
    //     milestonesData.map((item: any, index: any) => {
    //       return {
    //         balanceUsedFormilestone: requestToPayData[index].amount,
    //         usedBankBal: 0,
    //         date: dayjs(requestToPayData[index].date),
    //         days: requestToPayData[index].days,
    //         interestTable: [],
    //         receivabletable: [],
    //         invoiceAmount: +paymentSummaryData.amount,
    //         milestoneCount: requestToPayData?.milestones?.length,
    //         milestoneLock: false,
    //         _id: index,
    //       };
    //     })
    //   );
    // }
    // console.log("requestToPayData", dayjs(requestToPayData.milestones[0].date));
  }, [milestonesData]);

  const handleStepperMilestone = (val: any, data: any) => {
    setMilestoneData((prevState: any) => {
      let updatedBoard = [...prevState.map((item: any) => item)];
      updatedBoard[data._id] = data;
      updatedBoard.map((item: any, index: number) => {
        if (item._id == val + 1) {
          setUsedReceivables(item.receivabletable);
          setIsPaymentLock(item.milestoneLock);
          setValue(
            "balanceUsedFormilestone",
            isSteplock
              ? item.balanceUsedFormilestone
              : item?._id == milestonesData.length - 1
              ? +paymentSummaryData.amount -
                +updatedBoard.reduce(
                  (total: number, item: any) =>
                    total + item.balanceUsedFormilestone,
                  0
                )
              : item.balanceUsedFormilestone
          );
          setValue("usedBankBal", item.usedBankBal);
          setValue("date", item.date || "");
          setValue("days", item.days);
        }
      });
      return updatedBoard;
    });
    milestonesData.length - 1 !== milestonesData[activeStep]?._id &&
      setActiveStep(activeStep + 1);
  };

  const prevMilestone = (val: number) => {
    milestonesData.map((item: any) => {
      console.log(item);
      if (item._id == val - 1) {
        setUsedReceivables(item.receivabletable);
        setIsPaymentLock(item.milestoneLock);
        setValue("balanceUsedFormilestone", item.balanceUsedFormilestone);
        setValue("usedBankBal", item.usedBankBal);
        setValue("date", dayjs(item.date));
        setValue("days", daysConversionFromDate(item.date));
        setActiveStep(activeStep - 1);
        setActiveDateCount(daysConversionFromDate(item.date));
      }
    });
  };

  const resetDraft = async (val: number) => {
    await axios
      .delete(
        "https://api.dev.assuredpay.in/checkerRoutes/draft/milestone/" + val,
        {
          headers: { token: newToken ? newToken : "" },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setInterestTable(
            interestTable.filter((item: any) => item.index !== val)
          );
          setMilestoneData(
            milestonesData.map((item: any) => {
              if (item._id == val) {
                setUsedReceivables([]);
                setIsPaymentLock(false);
                return (item = {
                  balanceUsedFormilestone: 0,
                  usedBankBal: 0,
                  date: "",
                  days: 7 + val,
                  interestTable: [],
                  receivabletable: [],
                  invoiceAmount: +paymentSummaryData.amount,
                  milestoneCount: parseInt(paymentSummaryData.milestone),
                  milestoneLock: false,
                  _id: val,
                });
              } else {
                return item;
              }
            })
          );
          SuccessMsg(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCopyClick = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      SuccessMsg("Copied!");
    } catch (err) {
      ErrorMsg("Unable to copy to clipboard.");
    }
  };

  return (
    <>
      {/* detail payment summary */}
      <Grid
        m={4}
        rowGap={1}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
        }}
      >
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Order Title
          </Typography>
          <Typography variant="h6">{paymentSummaryData.title}</Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Payment Type
          </Typography>
          <Typography variant="h6">
            {paymentSummaryData.paymentType == "payment_against_invoice"
              ? "Payment Against Invoice"
              : paymentSummaryData.paymentType == "advanced_payment"
              ? "Advance Payment"
              : "Payment on Account"}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Order Type
          </Typography>
          <Typography variant="h6">
            {paymentSummaryData.paymentMethod}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            PO/PI (Mandatory)
          </Typography>
          <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
            <Typography variant="h6">{paymentSummaryData.PO}</Typography>
            <Icon
              icon="mingcute:copy-line"
              style={{ color: "#707070", fontSize: 20, cursor: "pointer" }}
              onClick={() => handleCopyClick(paymentSummaryData.PO)}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Invoice Amount
          </Typography>
          <Typography variant="h6">{paymentSummaryData.amount}</Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle2" sx={{ color: "#b0bac4" }}>
            Milestones
          </Typography>
          <Typography variant="h6">{paymentSummaryData.milestone}</Typography>
        </Stack>
      </Grid>

      <Stack
        mx={4}
        mt={2}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Choose Payment Option</Typography>
        <Button
          variant="contained"
          sx={{
            background: "#303981",
            borderRadius: 2,
            height: "fit-content",
            "&:hover": {
              backgroundColor: "#343d84db",
            },
          }}
          onClick={viewAllReceivables}
          disabled={isPaymentLock}
        >
          View all Receivables
        </Button>
      </Stack>

      <Card
        sx={{
          m: 3,
          borderRadius: 4,
          borderStyle: "dashed",
          borderColor: "#d4dadf",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeStep}
            aria-label="secondary tabs example"
            sx={{
              "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                color: "#303981",
              },
              "& .css-1aquho2-MuiTabs-indicator": {
                backgroundColor: "#303981",
              },
              "& .css-1ocpf3a-MuiButtonBase-root-MuiTab-root": {
                minHeight: 48,
              },
            }}
          >
            {[...Array(parseInt(paymentSummaryData.milestone))].map(
              (item, index) => {
                return (
                  <Tab
                    label={
                      <Stack>
                        <Typography color={"black"}>{`Milestone ${
                          index + 1
                        }`}</Typography>
                        {+milestonesData[index]?.balanceUsedFormilestone >
                          0 && (
                          <Typography
                            fontWeight={700}
                            variant="subtitle1"
                            color={"#303981"}
                          >
                            {" "}
                            Rs. {milestonesData[index]?.balanceUsedFormilestone}
                          </Typography>
                        )}
                      </Stack>
                    }
                    icon={
                      activeStep > index ? (
                        <Icon
                          icon="mdi:approve"
                          style={{ color: "#303981", height: 20, width: 20 }}
                        />
                      ) : (
                        <Icon
                          icon="mdi:approve"
                          style={{ color: "#dadada", height: 20, width: 20 }}
                        />
                      )
                    }
                    iconPosition="end"
                    value={index}
                    disabled={index !== activeStep}
                  />
                );
              }
            )}
          </Tabs>
        </Box>

        <Card sx={{ backgroundColor: "#f9fafb" }}>
          <Grid
            rowGap={2}
            columnGap={3}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(3, 1fr)",
            }}
            m={4}
          >
            <Stack>
              <Typography variant="body2" sx={{ color: "#b0bac4" }}>
                Available Balance
              </Typography>
              <Typography variant="h5" color={"#303981"}>
                {numberFormat(availableBalance)}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="body2" sx={{ color: "#b0bac4" }}>
                Total Receivable
              </Typography>
              <Typography variant="h5" color={"#303981"}>
                {numberFormat(receivableAmount)}
              </Typography>
            </Stack>
          </Grid>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(
              watch("balanceUsedFormilestone") == watch("usedBankBal")
                ? saveDraft
                : onSubmit
            )}
          >
            <Grid
              rowGap={2}
              columnGap={4}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
              m={4}
            >
              {/* Date picker */}
              <Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Payment Days"
                      format="DD/MM/YYYY"
                      disabled={isPaymentLock || isSteplock}
                      minDate={
                        milestonesData[activeStep - 1]?.date
                          ? dayjs(milestonesData[activeStep - 1]?.date).add(
                              1,
                              "day"
                            )
                          : dayjs(new Date()).add(7, "day")
                      }
                      // value={dayjs(watch("date"))}
                      onChange={(newValue) => {
                        setValue("date", newValue);
                        setActiveDateCount(0);
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            ".css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                              {
                                color: "white",
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "#303981",
                                border: "1px solid",
                                backgroundColor: "#303981",
                              },
                            ".css-1beqopj-MuiPickersYear-yearButton.Mui-selected":
                              {
                                color: "white",
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: "#303981",
                                border: "1px solid",
                                backgroundColor: "#303981",
                              },
                            ".css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel":
                              {
                                color: "#303981",
                                borderRadius: 2,
                                borderWidth: 1,
                                borderColor: "#303981",
                                border: "1px solid",
                                // backgroundColor: "#303981",
                              },
                          },
                        },
                      }}
                      sx={{
                        "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root":
                          {
                            backgroundColor: "white",
                            borderRadius: 2,
                          },
                        "& .css-i4bv87-MuiSvgIcon-root": {
                          color: "#303981",
                        },
                        "& label.Mui-focused": {
                          color: "#303981",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#E0E3E7",
                          },
                          "&:hover fieldset": {
                            borderColor: "#B2BAC2",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#303981",
                          },
                        },

                        width: "100%",
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {/* <Stack
                  flexDirection={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                  mt={0.5}
                >
                  {chipDays.map((item: any) => {
                    return (
                      <Chip
                        label={item.label}
                        disabled={isSteplock}
                        sx={
                          activeDateCount == item.value
                            ? {
                                fontSize: 12,
                                backgroundColor: "#D8FBDE",
                                color: "#5BE584",
                                borderColor: "#5BE584",
                                "&:hover": {
                                  backgroundColor: "#D8FBDE",
                                  color: "#5BE584",
                                  borderColor: "#5BE584",
                                },
                              }
                            : { fontSize: 12 }
                        }
                        onClick={() => {
                          setValue(
                            "date",
                            dayjs(new Date()).add(item.value, "day")
                          );
                          setActiveDateCount(item.value);
                        }}
                      />
                    );
                  })}
                </Stack> */}
              </Stack>

              <Stack gap={1} pt={1}>
                <RHFTextField
                  name="balanceUsedFormilestone"
                  label="Amount"
                  type="number"
                  disabled={
                    isPaymentLock ||
                    milestonesData[activeStep]?._id ==
                      milestonesData.length - 1 ||
                    isSteplock
                  }
                />
                <Typography variant="caption" sx={{ color: "#303981", ml: 1 }}>
                  {numberToEnglish(parseInt(watch("balanceUsedFormilestone")))}
                </Typography>
              </Stack>
              <Stack>
                <RHFTextField
                  name="usedBankBal"
                  type="number"
                  label="User Bank Balance"
                  // disabled={isPaymentLock || usedReceivables?.length}
                  disabled={isPaymentLock}
                />
                <Typography variant="caption" sx={{ color: "#303981", ml: 1 }}>
                  {numberToEnglish(parseInt(watch("usedBankBal")))}
                </Typography>
                {+watch("balanceUsedFormilestone") < +watch("usedBankBal") && (
                  <FormHelperText sx={{ color: "red" }}>
                    You can't use bank balance more than Amount
                  </FormHelperText>
                )}
              </Stack>
              <Stack flexDirection={"row"} gap={2}>
                {watch("balanceUsedFormilestone") > 0 &&
                  (watch("balanceUsedFormilestone") == watch("usedBankBal") ? (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      disabled={
                        isPaymentLock ||
                        !(
                          usedReceivables.reduce(
                            (total: number, item: any) =>
                              total + item.receivablesToBeUsed,
                            0
                          ) +
                            +watch("usedBankBal") ==
                          +watch("balanceUsedFormilestone")
                        )
                      }
                      loading={isSubmitting}
                      sx={{
                        background: "#303981",
                        borderRadius: 2,
                        width: "fit-content",
                        alignSelf: "center",
                        "&:hover": {
                          backgroundColor: "#343d84db",
                        },
                      }}
                    >
                      Continue
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      disabled={isPaymentLock}
                      loading={isSubmitting}
                      sx={{
                        background: "#303981",
                        borderRadius: 2,
                        width: "fit-content",
                        alignSelf: "center",
                        "&:hover": {
                          backgroundColor: "#343d84db",
                        },
                      }}
                    >
                      Fetch Receivables
                    </LoadingButton>
                  ))}
                <LoadingButton
                  variant="contained"
                  onClick={() => resetDraft(activeStep)}
                  disabled={
                    !isPaymentLock &&
                    !milestonesData[activeStep + 1]?.milestoneLock
                  }
                  sx={{
                    background: "#303981",
                    borderRadius: 2,
                    width: "fit-content",
                    alignSelf: "center",
                    "&:hover": {
                      backgroundColor: "#343d84db",
                    },
                  }}
                >
                  Reset
                </LoadingButton>
              </Stack>
            </Grid>
          </FormProvider>
          {usedReceivables.length > 0 && (
            <Card sx={{ m: 2, borderRadius: 4 }}>
              <TableContainer>
                <Table sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>Assured Receivables</StyledTableCell>
                      <StyledTableCell>Usable Receivables</StyledTableCell>
                      <StyledTableCell>Receivable to be used</StyledTableCell>
                      <StyledTableCell>AP Receivables Fees</StyledTableCell>
                      <StyledTableCell>Is FD Allowed</StyledTableCell>
                      <StyledTableCell>FD Days</StyledTableCell>
                      <StyledTableCell>Approx Interests</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usedReceivables.map((row: any) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell component="th" scope="row">
                          {new Date(row.dueDate).toLocaleDateString()}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          {row.assuredRecievables}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          {row.usableRecievables}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          {row.receivablesToBeUsed}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ textAlign: "center", color: "red" }}
                        >
                          {row.convenienceFee}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                            color: row.isFDAllowed ? "green" : "red",
                          }}
                        >
                          {row.isFDAllowed ? "Yes" : "No"}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textAlign: "center" }}>
                          {row.fdDays}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ textAlign: "center", color: "green" }}
                        >
                          {row.approxInterest.toFixed(2)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {usedReceivables.length > 0 && (
                <Stack
                  flexDirection={"row"}
                  justifyContent={"end"}
                  alignItems={"center"}
                  gap={5}
                  m={2}
                >
                  <Typography variant="subtitle2" color={"green"}>
                    Click Continue to lock the Milestone.
                  </Typography>

                  <LoadingButton
                    variant="contained"
                    onClick={saveDraft}
                    loading={isDraftLoading}
                    disabled={isPaymentLock}
                    sx={{
                      background: "#303981",
                      borderRadius: 2,
                      "&:hover": {
                        backgroundColor: "#343d84db",
                      },
                    }}
                  >
                    Continue
                  </LoadingButton>
                </Stack>
              )}
            </Card>
          )}

          <Stack flexDirection={"row"} justifyContent={"space-between"} m={2}>
            <Button
              onClick={() => prevMilestone(milestonesData[activeStep]?._id)}
              disabled={isPaymentLock || milestonesData[activeStep]?._id == 0}
            >
              Back
            </Button>
            {milestonesData.length - 1 !== milestonesData[activeStep]?._id && (
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                sx={{
                  background: "#303981",
                  borderRadius: 2,
                  width: "fit-content",
                  alignSelf: "end",
                  "&:hover": {
                    backgroundColor: "#343d84db",
                  },
                }}
                onClick={() => {
                  handleStepperMilestone(milestonesData[activeStep]?._id, {
                    balanceUsedFormilestone:
                      getValues("balanceUsedFormilestone") | 0,
                    usedBankBal: getValues("usedBankBal") | 0,
                    date: getValues("date"),
                    days: getValues("days"),
                    interestTable: interestTable,
                    receivabletable: usedReceivables,
                    invoiceAmount: milestonesData[activeStep]?.invoiceAmount,
                    milestoneCount: parseInt(
                      milestonesData[activeStep]?.milestoneCount
                    ),
                    milestoneLock: isPaymentLock,
                    _id: milestonesData[activeStep]?._id,
                  });
                  setActiveDateCount(0);
                }}
                disabled={!isPaymentLock}
              >
                Continue to Next Milestone
              </LoadingButton>
            )}
          </Stack>
        </Card>
      </Card>

      {/* Interest Table */}
      <Card
        sx={{
          m: 3,
          borderRadius: 4,
          borderStyle: "dashed",
          borderColor: "#d4dadf",
          backgroundColor: "#dfe3e8",
        }}
      >
        {interestTable.length > 0 && (
          <Stack sx={{ m: 2, borderRadius: 4 }}>
            <Typography
              fontFamily={"Public Sans"}
              fontWeight={700}
              variant="h5"
              my={1}
            >
              Interest Details
            </Typography>
            <Card>
              <TableContainer>
                <Table sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Amount Type</StyledTableCell>
                      <StyledTableCell>Amount</StyledTableCell>
                      <StyledTableCell>Days</StyledTableCell>
                      <StyledTableCell>Fees</StyledTableCell>
                      <StyledTableCell>Interest</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {interestTable.map((row: any) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell component="th" scope="row">
                          {row.amountType ? "Bank Balance" : "Receivables"}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={
                            !row.amountType
                              ? { color: "#F58336" }
                              : { color: "black" }
                          }
                        >
                          {row.amount}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.paymentDays} Days
                        </StyledTableCell>
                        <StyledTableCell>{row.convenienceFees}</StyledTableCell>
                        <StyledTableCell sx={{ color: "green" }}>
                          {row.approxInterest.toFixed(2)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
            <Typography variant="body1" fontFamily={"Public Sans"} my={2}>
              Disclaimer
            </Typography>
            <Typography
              variant="caption"
              fontWeight={500}
              fontFamily={"Public Sans"}
            >
              The displayed Fixed Deposit interest rates are current as of this
              moment and are subject to change. Rates are updated every 15
              seconds to provide you with the most accurate information. Please
              note that the actual interest rates applicable to your Fixed
              Deposit may differ at the time of confirmation. We recommend
              verifying the rates before finalizing any transactions or
              investments.
            </Typography>
            <Stack>
              <Typography
                variant="h6"
                color={"#F58336"}
                noWrap
                textAlign={"end"}
                mr={2}
              >
                Total Fees :{" "}
                {parseFloat(
                  interestTable.reduce(
                    (total: number, item: any) => total + item.convenienceFees,
                    0
                  )
                ).toFixed(2)}
              </Typography>
              <Typography
                variant="h6"
                color={"green"}
                noWrap
                textAlign={"end"}
                mr={2}
              >
                Total Interest :{" "}
                {parseFloat(
                  interestTable.reduce(
                    (total: number, item: any) => total + item.approxInterest,
                    0
                  )
                ).toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Card>

      <Stack flexDirection={"row"} justifyContent={"space-between"} m={3}>
        <Button
          onClick={() => handleStepper(0)}
          sx={{
            color: "#303981",
          }}
          disabled={!!interestTable.length}
        >
          Previous
        </Button>
        <Button
          onClick={() => {
            handleStepper(2);
            confirmDetailData({
              orderDetails: {
                business_id: BusinessDetailsForPayment?.businessDetails?.user,
                paymentType: paymentSummaryData.paymentMethod,
                orderType: paymentSummaryData.paymentType,
                paymentCombination: "BB",
                remaks: paymentSummaryData.desc,
                title: paymentSummaryData.title,
                POPI: paymentSummaryData.PO,
                orderAmount: paymentSummaryData.amount,
                paymentIdentifier: "buyer",
                paymentDays: "",
                fromPR: false,
                prId: "",
                paymentSettlementDate: dayjs(
                  milestonesData[milestonesData.length - 1].date
                ),
              },
              interestTable: interestTable,
              milestoneDetails: milestonesData.map((item: any) => {
                return {
                  dueDate: dayjs(item.date),
                  days: daysConversionFromDate(item.date),
                  ApproxInterest: item.interestTable.reduce(
                    (total: number, item: any) => total + item.approxInterest,
                    0
                  ),
                  amount: +item.balanceUsedFormilestone,
                  usingBB: item.usedBankBal,
                  usingRC:
                    item.receivabletable.length &&
                    item.receivabletable.reduce(
                      (total: number, row: any) =>
                        total + row.receivablesToBeUsed,
                      0
                    ),
                  receivablesUsedForComplition: item.receivabletable.map(
                    (elem: any) => {
                      return {
                        orderId: elem.orderId,
                        milestoneId: elem.id,
                        amount: elem.receivablesToBeUsed,
                        convenienceFee: elem.convenienceFee,
                        isFDAllowed: elem.isFDAllowed,
                        FDDays: elem.fdDays,
                        approxInterest: elem.approxInterest,
                      };
                    }
                  ),
                };
              }),
            });
          }}
          variant="contained"
          sx={{
            background: "#303981",
            borderRadius: 2,
            "&:hover": {
              backgroundColor: "#343d84db",
            },
          }}
          disabled={
            !(
              milestonesData.reduce(
                (total: number, item: any) =>
                  total + item.balanceUsedFormilestone,
                0
              ) == milestonesData[activeStep]?.invoiceAmount
            )
          }
        >
          Next
        </Button>
      </Stack>

      {/* all receivable modal */}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TableContainer>
            <Table sx={{ minWidth: 720 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Assured Receivables</StyledTableCell>
                  <StyledTableCell>Usable Receivables</StyledTableCell>
                  <StyledTableCell>AP Receivables fee (0.2%)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allReceiablesData.map((row: any) => (
                  <StyledTableRow key={row._id}>
                    <StyledTableCell component="th" scope="row">
                      {new Date(row.dueDate).toLocaleDateString()}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {(row.amount - row.platformFee).toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>
                      {parseFloat(row.eligibleAmount).toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center", color: "red" }}>
                      {parseFloat(row.platformFee).toFixed(2)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack flexDirection={"row"} justifyContent={"end"} mt={2}>
            <Button
              variant="contained"
              sx={{
                background: "#303981",
                borderRadius: 2,
                "&:hover": {
                  backgroundColor: "#343d84db",
                },
              }}
              onClick={handleClose}
            >
              close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
