import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props: any) {
  console.log(props, "check the props for the response ");

  return (
    <React.Fragment>
      <Dialog
        open={props.props}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.alertDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{props.SelectedTextForCond}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.condition}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.HandleClickCloseAlertDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
