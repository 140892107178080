import {
  Box,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  TablePagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import React from "react";
import BookPayablesList from "./BookPayablesList";
import ApiService from "../../../api/api";
import SimpleLoader from "../../../components/Loader";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";

// --------------------------BOOKED PAYABLES (ORDERS CREATED PAGE WHEN STATUS ACTIVE)--------------------------------------
const BookPayablesMain = () => {
  const [BookPayables, setBookPayables] = useState<any>([]);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const [Token, setToken] = useState<any>("");
  const newToken = useSelector((state: any) => state.user.Token);
  const getUsersList = async () => {
    const token = await ApiService.setToken(newToken);
    const responseData: any = await ApiService.get(
      "/checkerRoutes/created/orders"
    );
    if (responseData.status === 200) {
      const referenceData = responseData.data.result;
      if (referenceData.length > 0) {
        setBookPayables(referenceData);
      } else {
        setBookPayables([]);
      }
    } else {
      ErrorMsg("Something went wrong, Please try again !");
    }
    setOpen(false);
  };

  useEffect(() => {
    if (newToken !== "" && newToken !== undefined) {
      getUsersList();
    }
  }, []);

  return (
    <>
      {open ? (
        <>
          <SimpleLoader props={open} />
        </>
      ) : (
        <>
          <Stack mx={4}>
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"24px"}
              sx={{ mt: 4 }}
              fontWeight={700}
              fontStyle={"normal"}
              color={"#000000"}
            >
              Booked Payables
            </Typography>
          </Stack>
          <Box
            justifyContent={"center"}
            component={"div"}
            sx={{
              backgroundColor: "#F7F9FB",
            }}
          >
            <Box
              boxShadow={1}
              mx={4}
              my={2}
              sx={{ borderRadius: "8px", backgroundColor: "white" }}
            >
              <Stack
                sx={{ mx: 4, py: 2 }}
                display={"flex"}
                direction={"row"}
                spacing={1}
              >
                {" "}
                <TextField
                  disabled
                  fullWidth
                  placeholder="Search gst number, mobile number ....."
                  InputProps={{
                    style: { borderRadius: "10px" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Box px={2}>
                <BookPayablesList props={BookPayables} />
              </Box>
              <Box>
                <Divider></Divider>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  justifyContent={"end"}
                >
                  <TablePagination
                    component="div"
                    count={10}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Stack>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default BookPayablesMain;
