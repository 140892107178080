import {
  Box,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  TablePagination,
  Tabs,
  Tab,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import React from "react";
import AcceptRequestList from "./AcceptRequestList";
import SimpleLoader from "../../../../components/Loader";
import ApiService from "../../../../api/api";
import { ErrorMsg } from "../../../../components/Messages/ErrorMsg";
import { Chips } from "../../../../components/businessNetwok/chips/Chips";
import { TypographyText } from "../../../../components/businessNetwok/typography/TypographyText";

const AcceptRequestMain = () => {
  const [open, setopen] = useState(false);
  const [acceptReequestList, setacceptReequestList] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectStatus, setSelectStatus] = useState("pending");
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSelectStatus = (event: any, newValue: string) => {
    setSelectStatus(newValue);
  };
  const [Token, setToken] = useState<any>("");
  const newToken = useSelector((state: any) => state.user.Token);

  const getUsersList = async () => {
    setopen(true);
    if (newToken !== "" && newToken !== undefined) {
      const token = await ApiService.setToken(newToken);
      const responseData: any = await ApiService.get(
        "checkerRoutes/request/payment/created"
      );
      if (responseData.status === 200) {
        const referenceData = responseData.data.data;
        if (referenceData.length > 0) {
          setacceptReequestList(referenceData);
          setopen(false);
        } else {
          setacceptReequestList([]);
          setopen(false);
        }
      }
    } else {
      // setopen(false);
      ErrorMsg("Something went wrong,Please try again !");
    }
  };
  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });
  useEffect(() => {
    getUsersList();
  }, []);

  return (
    <>
      <Stack mx={4}>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"24px"}
          sx={{ mt: 4 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          My Payment Requests
        </Typography>
      </Stack>
      <Box
        justifyContent={"center"}
        component={"div"}
        sx={{
          backgroundColor: "#F7F9FB",
        }}
      >
        <Box
          boxShadow={1}
          mx={4}
          my={2}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
        >
          <Stack
            sx={{ mx: 4, py: 2 }}
            display={"flex"}
            direction={"row"}
            spacing={1}
          >
            <TextField
              disabled
              fullWidth
              placeholder="Search By Payment Request (PR) Number"
              InputProps={{
                style: { borderRadius: "10px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box>
            <AcceptRequestList props={acceptReequestList} />
          </Box>
          <Box>
            <Divider></Divider>
            <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
              <TablePagination
                component="div"
                count={10}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
      <SimpleLoader props={open} />
    </>
  );
};

export default AcceptRequestMain;
