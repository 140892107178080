import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import SimpleLoader from "../../components/Loader";

import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import {
  emailValidation,
  mobileValidation,
  userNameValidation,
  passwordValidation,
} from "../../utils/Validations";
import AlertDialogSlide from "./DialogConditions";
import { Stack } from "@mui/system";

import { loginUser, saveUser } from "../../redux/slices/user";
import { useDispatch } from "react-redux";

const UserResistor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [signup, setSignUp] = useState<any>([]);
  // Email Validation State and Error Msgs State
  const [emailAlreadyExist, setemailAlreadyExist] = useState(false);
  const [EmailVerificationMsg, setEmailVerificationMsg] = useState("");
  const [TermsAndConditionData, setTermsAndConditionData] = useState("");
  const [PrivacyPolicyData, setPrivacyPolicyData] = useState("");
  const [DisclaimerData, setDisclaimerData] = useState("");
  const [alertDialog, setalertDialog] = useState(false);
  const [SelectedTextForCond, setSelectedTextForCond] = useState("");
  const [ConditionValue, setConditionValue] = useState("");

  // Email verification
  const EmailVerification = async () => {
    let email = signup.business_email;
    const E_V = emailValidation(email);
    if (email !== "" && email !== undefined) {
      if (E_V === false) {
        setEmailVerificationMsg("Please provide a valid email address.");
        setemailAlreadyExist(true);
      } else {
        setemailAlreadyExist(false);
        setEmailVerificationMsg("");
      }
    } else {
      setEmailVerificationMsg("");
      setemailAlreadyExist(false);
    }
  };

  // Mobile Validation State and Error Msgs State
  const [mobileAlreadyExist, setmobileAlreadyExist] = useState(false);
  const [MobileVerificationMsg, setMobileVerificationMsg] = useState("");

  // mobile verification function
  const MobileVerification = async () => {
    let mobile = signup.business_mobile;
    const M_V = mobileValidation(mobile);
    if (mobile !== "" && mobile !== undefined) {
      if (M_V === false) {
        setMobileVerificationMsg("Please provide a valid mobile number.");
        setmobileAlreadyExist(true);
      } else {
        setMobileVerificationMsg("");
        setmobileAlreadyExist(false);
      }
    } else {
      setMobileVerificationMsg("");
      setmobileAlreadyExist(false);
    }
  };

  // UserName Validation State and Error Msgs State
  const [userAlreadyExist, setUserAlreadyExist] = useState(false);
  const [UserVerificationMsg, setUserVerificationMsg] = useState("");
  // user varification function
  const UserVerification = async () => {
    let name = signup.username;
    const N_V = userNameValidation(name);
    if (name !== "" && name !== undefined) {
      if (N_V === false) {
        setUserVerificationMsg("Username must have at least four characters.");
        setUserAlreadyExist(true);
      } else {
        setUserVerificationMsg("");
        setUserAlreadyExist(false);
      }
    } else {
      setUserVerificationMsg("");
      setUserAlreadyExist(false);
    }
    if (name !== "" && name !== undefined && N_V === true) {
      const FetchData = await axios
        .post("https://api.dev.assuredpay.in/Auth/searchExisting", {
          username: name,
        })
        .then((res) => {
          var getUserverification = res.data;
          if (
            getUserverification.result.found === true &&
            getUserverification.result.active === true
          ) {
            setUserVerificationMsg("User Already Exist");
            setUserAlreadyExist(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setUserVerificationMsg("Something went wrong, Please try again");
          setUserAlreadyExist(false);
        });
    }
  };

  // Password Visibility State & Validation-----------------------------
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // -------------------------------------------------------------------

  // Password Validations, State and Error Msg State -----------------------
  const [PasswordVerificationMsg, setPasswordVerificationMsg] = useState("");
  const [PasswordValidation, setPasswordValidation] = useState(false);
  const passwordRegexValidation = () => {
    let pass = signup.password;
    const P_V = passwordValidation(pass);
    if (pass !== "" && pass !== undefined) {
      if (P_V === false) {
        setPasswordVerificationMsg(
          "Password must be 8 characters Ex: Assured@1"
        );
        setPasswordValidation(true);
      } else {
        setPasswordVerificationMsg("");
        setPasswordValidation(false);
      }
    }
  };
  // -------------------------------------------------------------------

  // Confirm Password Visibility State & Validation---------------------
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickshowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const confirmPasswordVerification = () => {
    let password = signup.password;
    let confirmpassword = signup.confirmpassword;
    if (password !== "" && password !== undefined) {
      if (password !== confirmpassword) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }
  };
  // -------------------------------------------------------------------

  // ReferalCode State & Validation---------------------
  const [referalCodeErrorMsg, setreferalCodeErrorMsg] = useState("");
  const [invalidReferalCode, setinvalidReferalCode] = useState(false);
  const ReferalCodeVerification = async () => {
    let refferal_code = signup.refferal_code;
    if (refferal_code !== "" && refferal_code !== undefined) {
      const FetchData = await axios
        .post("https://api.dev.assuredpay.in/auth/searchexistingrefercode", {
          refercode: refferal_code,
        })
        .then((res) => {
          let getreferalCodeverification = res.data;
          if (getreferalCodeverification.result.found === false) {
            setreferalCodeErrorMsg("Invalid Referal Code");
            setinvalidReferalCode(true);
          } else {
            setinvalidReferalCode(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setreferalCodeErrorMsg("Something went wrong, Please try again.");
          setinvalidReferalCode(true);
        });
    } else {
      setinvalidReferalCode(false);
      setreferalCodeErrorMsg("");
    }
  };

  // ------------------------------------------------------------

  // User Register Page
  const resistorUser = async (e: any) => {
    e.preventDefault();
    setOpen(true);
    let data1 = new FormData(e.currentTarget);
    let params = {
      business_email: data1.get("business_email"),
      username: data1.get("username"),
      business_mobile: data1.get("business_mobile"),
      password: data1.get("password"),
    };
    if (
      data1.get("refferal_code") !== "" &&
      data1.get("refferal_code") !== undefined
    ) {
      Object.assign(params, { refferal_code: data1.get("refferal_code") });
    }
    Object.assign(params, { role: "Business_User" });
    if (
      (params.business_email &&
        params.business_mobile &&
        params.password &&
        params.username) !== ""
    ) {
      const baseUrl = process.env.BASE_URL
        ? process.env.BASE_URL
        : "https://api.dev.assuredpay.in";
      const FetchData = await axios
        .post(baseUrl + "/auth/registerbusiness", params)
        .then((res) => {
          var data = res;
          let userData = res.data.result;
          if (data.data.Active === true && data.status === 200) {
            console.log(
              userData,
              "---------//----------//-----------//-----------//-----------"
            );
            dispatch(saveUser(userData));
            enqueueSnackbar(
              "Verification Codes has been sent to your registered Email & Mobile Number",
              {
                variant: "success",
                style: {
                  color: " var(--success-darker, #0A5554)",
                  // color: "rgba(54, 179, 126, 1)",
                  backgroundColor: "var(--success-lighter, #D8FBDE)",
                  marginRight: "100px",
                  fontFamily: "Public Sans",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "22px",
                  maxWidth: "400px",
                  marginTop: "50px",
                },
              }
            );
            setOpen(false);
            navigate("/verify");
          }
        })
        .catch((err) => {
          setOpen(false);
          enqueueSnackbar("Please Try Again", { variant: "error" });
          console.log(err, "errr");
        });
      // Storing data in form of JSON
      //   var data = await FetchData;

      //   // condition based on response
      //   if (data.data.Active === true && data.status === 200) {
      //   } else {
      //   }
    }
  };

  // Checkbox State
  const [checkBox, setcheckBox] = useState(false);
  const handleCheckbox = (e: any) => {
    if (e.target.checked === true) {
      setcheckBox(true);
    } else {
      setcheckBox(false);
    }
  };
  const getUserValues = (event: any) => {
    const { name, value } = event.target;
    setSignUp((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    const {
      business_email,
      username,
      password,
      confirmpassword,
      business_mobile,
    } = signup;
    if (
      business_email !== undefined &&
      business_email !== "" &&
      username !== undefined &&
      username !== "" &&
      password !== undefined &&
      password !== "" &&
      confirmpassword !== undefined &&
      confirmpassword !== "" &&
      business_mobile !== undefined &&
      business_mobile !== "" &&
      checkBox === true &&
      userAlreadyExist === false &&
      mobileAlreadyExist === false &&
      emailAlreadyExist === false &&
      passwordMatch === false &&
      PasswordValidation === false
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  });
  const getTermsConditionsandPrivatePolicy = async () => {
    const FetchData = await axios
      .get("https://api.dev.assuredpay.in/auth/getlegaldocuments")
      .then((res) => {
        let termsOfService = res.data.result[0].termsOfService;
        let privacyPolicy = res.data.result[0].privacyPolicy;
        let discliamer = res.data.result[0].termsOfService;
        setTermsAndConditionData(termsOfService);
        setPrivacyPolicyData(privacyPolicy);
        setDisclaimerData(discliamer);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTermsConditionsandPrivatePolicy();
  }, []);
  const HandleClickCloseAlertDialog = () => {
    setalertDialog(false);
  };
  const handleClickConditions = (value: any) => {
    // console.log("changes");
    if (value === "Privacy Policy") {
      setSelectedTextForCond("Privacy Policy");
      setConditionValue(PrivacyPolicyData);
    } else {
      setSelectedTextForCond("Terms of Service");
      setConditionValue(TermsAndConditionData);
    }
    setalertDialog(true);
  };
  return (
    <form onSubmit={resistorUser} style={{ marginLeft: 10 }}>
      <TextField
        size="small"
        sx={{
          "& .MuiFormLabel-root": {
            color: "#919EAB",
            fontSize: "14px",
            fontFamily: "Public Sans",
            fontWeight: "500",
            mt: 0.2,
          },
        }}
        error={emailAlreadyExist}
        type="email"
        name="business_email"
        label="Business Email Address"
        helperText={emailAlreadyExist ? EmailVerificationMsg : ""}
        id="email"
        onChange={getUserValues}
        onBlur={EmailVerification}
        InputProps={{
          style: {
            height: "38px",
            borderRadius: "8px",
            borderColor: "#919EAB",
            marginTop: 2,
            marginBottom: 2,
          },
        }}
      />
      <TextField
        size="small"
        type="number"
        className={"hide-spin-buttons"}
        sx={{
          "& .MuiFormLabel-root": {
            color: "#919EAB",
            fontSize: "14px",
            fontFamily: "Public Sans",
            fontWeight: "500",
            mt: 0.2,
          },
        }}
        error={mobileAlreadyExist}
        helperText={mobileAlreadyExist ? MobileVerificationMsg : ""}
        label="Business Mobile Number"
        name="business_mobile"
        id="mobile"
        onBlur={MobileVerification}
        onChange={getUserValues}
        InputProps={{
          style: {
            height: "38px",
            borderRadius: "8px",
            marginTop: 2,
            marginBottom: 2,
            borderColor: "#919EAB",
          },
        }}
      />
      <TextField
        sx={{
          "& .MuiFormLabel-root": {
            color: "#919EAB",
            fontSize: "15px",
            fontFamily: "Public Sans",
            fontWeight: "500",
          },
        }}
        size="small"
        type="text"
        label="Username"
        name="username"
        id="username"
        onBlur={UserVerification}
        error={userAlreadyExist}
        helperText={userAlreadyExist ? UserVerificationMsg : ""}
        inputProps={{ minLength: 4 }}
        onChange={getUserValues}
        InputProps={{
          style: {
            height: "38px",
            borderRadius: "8px",
            marginTop: 2,
            marginBottom: 2,
            borderColor: "#919EAB",
          },
        }}
      />
      <TextField
        sx={{
          "& .MuiFormLabel-root": {
            color: "#919EAB",
            fontSize: "15px",
            fontFamily: "Public Sans",
            fontWeight: "500",
            mt: 0.2,
          },
        }}
        label="Password"
        type={showPassword ? "text" : "password"}
        error={PasswordValidation}
        helperText={PasswordValidation ? PasswordVerificationMsg : ""}
        size="small"
        name="password"
        onChange={getUserValues}
        onBlur={passwordRegexValidation}
        InputProps={{
          style: {
            height: "38px",
            borderRadius: "8px",
            marginTop: 2,
            marginBottom: 2,
            borderColor: "#919EAB",
          },
          endAdornment: (
            <InputAdornment position="end">
              {" "}
              <IconButton
                sx={{ color: PasswordValidation ? "red" : "" }}
                aria-label="toggle password visibility"
                edge="end"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        onBlur={confirmPasswordVerification}
        sx={{
          "& .MuiFormLabel-root": {
            color: "#919EAB",
            fontSize: "15px",
            fontFamily: "Public Sans",
            fontWeight: "500",
            mt: 0.2,
          },
        }}
        label="Confirm Password"
        type={showConfirmPassword ? "text" : "password"}
        id="outlined-start-adornment"
        name="confirmpassword"
        size="small"
        error={passwordMatch}
        helperText={passwordMatch ? "Password doesn't match" : ""}
        onChange={getUserValues}
        InputProps={{
          style: {
            height: "40px",
            borderRadius: "8px",
            marginTop: 2,
            marginBottom: 2,
            borderColor: "#919EAB",
          },
          endAdornment: (
            <InputAdornment position="end">
              {" "}
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                sx={{ color: passwordMatch ? "red" : "" }}
                onClick={handleClickshowConfirmPassword}
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        sx={{
          "& .MuiFormLabel-root": {
            color: "#919EAB",
            fontSize: "15px",
            fontFamily: "Public Sans",
            fontWeight: "500",
            mt: 0.2,
          },
        }}
        size="small"
        error={invalidReferalCode}
        helperText={invalidReferalCode ? referalCodeErrorMsg : ""}
        onBlur={ReferalCodeVerification}
        onChange={getUserValues}
        label="Referral Code"
        type="text"
        name="refferal_code"
        InputProps={{
          style: {
            height: "38px",
            borderRadius: "8px",
            marginTop: 2,
            marginBottom: 2,
            borderColor: "#919EAB",
          },
        }}
        id="referalCode"
      />
      <div style={{ display: "flex" }}>
        <div>
          <Checkbox onChange={handleCheckbox} />
        </div>
        <div>
          <Typography
            component="div"
            fontFamily={"Public Sans"}
            fontSize={"12px"}
            fontWeight={500}
            lineHeight={"16px"}
            color={"#556486"}
            letterSpacing={"0.36px"}
            maxWidth={"250px"}
            marginTop={"10px"}
          >
            By signing up, I agree to AssuredPay{" "}
            <Stack display={"flex"} direction={"row"} spacing={0.5}>
              <Typography
                fontSize={"12px"}
                fontWeight={600}
                fontStyle={"normal"}
                onClick={() => handleClickConditions("Terms of Service")}
                letterSpacing={"0px"}
                lineHeight={"14px"}
                color="#2549CA"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                Terms of Service{" "}
              </Typography>
              <b style={{ color: "#2549CA", fontWeight: "500" }}>&</b>
              <Typography
                fontSize={"12px"}
                fontWeight={600}
                fontStyle={"normal"}
                // onClick={handleClickConditions}
                onClick={() => handleClickConditions("Privacy Policy")}
                letterSpacing={"0px"}
                lineHeight={"16px"}
                color="#2549CA"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                {" "}
                Privacy Policy
              </Typography>
            </Stack>
          </Typography>
        </div>
      </div>

      <Button
        disabled={btnDisabled}
        // color="rgba(145, 158, 171, 0.24)"
        size="small"
        color="inherit"
        style={{
          maxWidth: "320px",
          marginTop: "18px",
          marginBottom: "35px",
          borderRadius: "8px",
          backgroundColor: btnDisabled
            ? "rgba(145, 158, 171, 0.24)"
            : "#303981",
          fontFamily: "Public Sans",
          fontWeight: "700",
          color: btnDisabled
            ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
            : "white",
        }}
        fullWidth
        variant="contained"
        type="submit"
      >
        Get Otp{" "}
      </Button>
      <SimpleLoader props={open} />
      <AlertDialogSlide
        props={alertDialog}
        HandleClickCloseAlertDialog={HandleClickCloseAlertDialog}
        SelectedTextForCond={SelectedTextForCond}
        condition={ConditionValue}
        // conditions
      />
    </form>
  );
};

export default UserResistor;
