import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NotFoundIcon from "../../assets/BussinessNetwork1.svg";
import { styled } from "@mui/system";
import { Box, Stack, Typography, TableContainer } from "@mui/material";
import { StringDateConversion } from "../../utils/Validations";
export default function UsedRecieveables(props: any) {
  let FetchReceivableData = props ? props.props : {};
  const ResponsiveTableContainer = styled(TableContainer)({
    overflowX: "auto",
    maxWidth: "100%",
  });

  return (
    <>
      <ResponsiveTableContainer>
        <Table >
          <TableHead sx={{ backgroundColor: "#F7F9FB",borderRadius:"20px" }}>
            <TableRow sx={{ backgroundColor: "#F7F9FB",borderRadius:"20px" }}>
              <TableCell
                sx={{
                  borderBottom: "none",
                  px: 4,
                }}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"grey"}
                >
                  Date
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                {" "}
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"grey"}
                >
                  Assured Receivables
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                {" "}
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"grey"}
                >
                  Usable Receivables
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                {" "}
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"grey"}
                >
                  Receivable to be used
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                {" "}
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"grey"}
                >
                  AP Receivables Fees
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                {" "}
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"grey"}
                >
                  Is FD Allowed
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                {" "}
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"grey"}
                >
                  FD Days
                </Typography>
              </TableCell>{" "}
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                {" "}
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"grey"}
                >
                  Approx Interests
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {FetchReceivableData && FetchReceivableData.length > 0
              ? FetchReceivableData.map((item: any, index: any) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        key={index}
                        component="th"
                        scope="row"
                        sx={{
                          borderBottom: "none",
                          px: 3,
                        }}
                      >
                        <Stack direction={"row"} display={"flex"} spacing={1}>
                          <Box>
                            <Stack>
                              <Typography
                                fontSize={13}
                                color={"#212B36"}
                                fontFamily={"Public Sans"}
                                fontWeight={600}
                              >
                                {item.dueDate !== ""
                                  ? StringDateConversion(item.dueDate)
                                  : "N/A"}{" "}
                              </Typography>
                            </Stack>
                          </Box>
                        </Stack>
                      </TableCell>
                      {/*  Assured Receivable  = Milestone Amount - milestone Ap Fees */}
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={13}
                          color={"#212B36"}
                          fontFamily={"Public Sans"}
                        >
                          {item.assuredRecievables}
                        </Typography>
                      </TableCell>

                      {/* Usable Amount to be eligible amount  */}
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={13}
                          color={"#212B36"}
                          fontFamily={"Public Sans"}
                        >
                          {item && item.usableRecievables
                            ? item.usableRecievables
                            : 0}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={13}
                          fontWeight={600}
                          color={"#212B36"}
                          fontFamily={"Public Sans"}
                        >
                          {item.receivablesToBeUsed
                            ? item.receivablesToBeUsed
                            : 0}
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={13}
                          color={"#B71D18"}
                          fontFamily={"Public Sans"}
                        >
                          {item && item.convenienceFee !== ""
                            ? item.convenienceFee
                            : 0}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={13}
                          color={item.isFDAllowed ? "green" : "#B71D18"}
                          fontFamily={"Public Sans"}
                        >
                          {item.isFDAllowed ? "Yes" : "No"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography fontSize={13} fontFamily={"Public Sans"}>
                          {item.fdDays}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          fontSize={13}
                          fontWeight={600}
                          color={"green"}
                          fontFamily={"Public Sans"}
                        >
                          {item.approxInterest}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              : ""}
          </TableBody>
        </Table>
        {FetchReceivableData.length <= 0 ? (
          <Box
            boxShadow={1}
            mb={2}
            borderRadius={"16px"}
            gap={"40px"}
            display={"flex"}
            justifyContent={"center"}
            sx={{ borderRadius: "8px", backgroundColor: "white" }}
            height={"auto"}
          >
            <Stack
              mt={8}
              display={"flex"}
              justifyContent={"center"}
              textAlign={"center"}
            >
              <img src={NotFoundIcon} alt="" height={"150px"} width={"auto"} />
              <Typography
                fontFamily={"Public Sans"}
                fontSize={24}
                sx={{ mt: 4 }}
                fontWeight={700}
                fontStyle={"normal"}
                color={"#161C24"}
              >
                No Eligible Receivables Found
              </Typography>
              <Typography
                fontFamily={"Public Sans"}
                fontSize={"14px"}
                fontStyle={"normal"}
                lineHeight={"18px"}
                color={"#454F5B"}
                fontWeight={400}
                sx={{ my: 6, mt: 1 }}
              >
                Try Fetching Eligible Receivable
              </Typography>
            </Stack>
          </Box>
        ) : (
          ""
        )}
      </ResponsiveTableContainer>
    </>
  );
}
