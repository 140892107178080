import "../../../App.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import VerifiedIcon from "@mui/icons-material/Verified";

import {
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
  Tab,
  Tabs,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Grid,
  Rating,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Typography12,
  Typography14,
  Typography16,
  Typography20,
} from "../../../components/typography/Typography";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import ic_info from "../../../assets/ic_info.svg";
import {
  getPaymentDays,
  numberFormat,
  onlyNumberValidation,
  numberToEnglish,
  orderAmtInt,
  findGreatest,
  daysConversionFromDate,
  usedReceivableAmtFull,
  compareNumberValueValidation,
} from "../../../utils/Validations";
import ArticleIcon from "@mui/icons-material/Article";
import PaymentBusinessInfo from "../PaymentBusinessInfo";
import SimpleLoader from "../../../components/Loader";
import PaymentConfirmDialog from "../Reusable/PaymentConfirmDialog";
import UsedRecieveables from "../UsedRecieveables";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PaymentInterestDetails from "../CreatePaymentBuyer/PaymentInterestDetails";

const CreateNewPayment = () => {
  // Define for identification of Payment Create from Buyer
  const GeneratePayment = "buyer";
  // payment confirmation dialog
  const [openAccept, setOpenAccept] = useState(false);
  const [lowBankBalance, setlowBankBalance] = useState<boolean>(false);
  const [requiredReceivables, setrequiredReceivables] = useState<number>(0);

  // use location for get State Data from Other pages
  const location = useLocation();

  // Arrange Business Records as per selection of payment Request (Seller / Recipent)
  // const BusinessDetailsForPayment = location.state
  //   ? location.state.businessNetwork
  //   : {};

  const prRequestid = location.state ? location.state.prRequestId : "";
  // Select Make Payment or Previous Payment Tab Value State
  const [selectPaymentSession, setselectPaymentSession] =
    useState("MakePayment");

  // Form Data for api Amount
  const [CreatePaymentOrder, setCreatePaymentOrder] = useState({
    orderTitle: "",
    PO: "",
    amount: "",
    paymentType: "full",
    paymentDays: "",
    useBankBalance: "",
    description: "",
  });

  // Payment mode state
  const [PaymentMethod, setPaymentMethod] = useState("full");

  // Reset Payment Request -  RESET/Reset/reset
  const resetOnClickcreatePaymentValue = () => {
    setCreatePaymentOrder({
      orderTitle: "",
      PO: "",
      amount: "",
      paymentType: "full",
      paymentDays: "",
      useBankBalance: "",
      description: "",
    });
    setinterestDetailsArray([]);
    setlowBankBalance(false);
    setfetchRecievables([]);
  };
  // Reset Partial Payment Request -  RESET/Reset/reset
  const resetOnClickCreatePartialPaymentValue = () => {
    setCreatePaymentOrder({
      orderTitle: "",
      PO: "",
      amount: "",
      paymentType: "full",
      paymentDays: "",
      useBankBalance: "",
      description: "",
    });
  };
  const handleOnSelectPaymentSession = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setselectPaymentSession(newValue);
  };
  // Set Full Payment Order State Details function
  const createOrderForPayment = (event: any) => {
    const { name, value } = event.target;
    if (name === "paymentDays") {
      setselectedChip(parseInt(value));
    }

    setCreatePaymentOrder((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };

  // Final Order Confirmation
  const confirmPaymentOrder = () => {
    setOpenAccept(true);
  };
  const ClosePaymentAcceptDialog = () => {
    setOpenAccept(false);
  };

  const [loading, setLoading] = useState(false);
  const newToken = useSelector((state: any) => state.user.Token);
  const navigate = useNavigate();
  const [interestDetailsArray, setinterestDetailsArray] = useState<any>([]);
  const [openSimpleLoader, setopenSimpleLoader] = useState(false);

  // FD Days as per calculation based
  const [selectedChip, setselectedChip] = useState<number>(0);

  const handleOnChangeDaysValue = (value: any) => {
    let name = "paymentDays";
    setCreatePaymentOrder((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });

    setselectedChip(value);
  };
  // Reset All Field of Make payment page
  const calculateInterest = (value: any) => {
    let si =
      (parseInt(value ? value : "0") *
        6 *
        ((parseInt(CreatePaymentOrder.paymentDays) > 0
          ? parseInt(CreatePaymentOrder.paymentDays)
          : 0) /
          365)) /
      100;
    return si.toFixed(2);
  };

  console.log("isValidcheck ------------", lowBankBalance);
  // fetch Recievable details and complitions
  const [fetchRecievalbles, setfetchRecievables] = useState<any>([]);
  const [EligibleReceivable, setEligibleReceivable] = useState<any>([]);
  const [disabledRecievableBtn, setdisabledRecievableBtn] = useState(false);
  const [showFetchReceivables, setshowFetchReceivables] = useState(false);
  const [receivableBtn, setreceivableBtn] = useState(false);

  // get all receivables for make payment

  // const [noReceivableFoundStatus, setnoReceivableFoundStatus] = useState(false);
  // const [receivableMsg, setreceivableMsg] = useState(false);

  const [receivableSuccess, setreceivableSuccess] = useState(false);
  const handleOnClickFetchRecievable = async () => {
    setdraftBtnDisabled(false);
    setreceivableBtn(true);
    setLoading(true);
    let params;
    const axiosConfig = {
      headers: {
        token: newToken !== "" ? newToken : "",
      },
      params: params,
    };
    let body = {
      // index: number;
      orderAmount:
        parseInt(CreatePaymentOrder.amount) > 0
          ? parseInt(CreatePaymentOrder.amount)
          : 0,
      milestoneAmount:
        parseInt(CreatePaymentOrder.amount) > 0
          ? parseInt(CreatePaymentOrder.amount)
          : 0,
      dueDate: getPaymentDays(CreatePaymentOrder.paymentDays),
      days:
        parseInt(CreatePaymentOrder.paymentDays) > 0
          ? parseInt(CreatePaymentOrder.paymentDays)
          : 0,
      bankBalance:
        parseInt(CreatePaymentOrder.useBankBalance) > 0
          ? parseInt(CreatePaymentOrder.useBankBalance)
          : 0,
    };
    const FetchData = await axios
      .post(
        "https://api.dev.assuredpay.in/checkerRoutes/createOrder/getRecievables",
        body,
        axiosConfig
      )
      .then(async (res) => {
        setLoading(false);
        setreceivableBtn(true);
        setreceivableSuccess(true);
        let resData = res.data.result;
        let resLengthValidation =
          resData && resData.length > 0 ? resData.length : 0;
        let isBankBalanceValid = res.data.lowBankBalance;
        let rcBalnce = res.data.requiredAmount;

        if (isBankBalanceValid) {
          setlowBankBalance(isBankBalanceValid);
          setfetchRecievables(resData);
        } else {
          setlowBankBalance(isBankBalanceValid);
          setfetchRecievables(resData);
        }
        // console.log(isBankBalanceValid);
        // if (isBankBalanceValid) {
        //   setlowBankBalance(true);
        //  setshowFetchReceivables(true);
        // } else {
        //   setlowBankBalance(false);
        //  setshowFetchReceivables(false);
        // }
        // if (rcBalnce > 0) {
        //   setrequiredReceivables(rcBalnce);
        // } else {
        //   setrequiredReceivables(rcBalnce);
        // }
        // if (resLengthValidation > 0) {
        //   setfetchRecievables(resData);
        // } else {
        //   setfetchRecievables(resData);
        // }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setreceivableBtn(false);
        setreceivableSuccess(false);
      });
  };
  // const useBankBalanceMsg = (value:any)=>{
  //   if (value){
  //     setreceivableMsg(true)
  //   }else {
  //     setreceivableMsg(false)
  //   }

  // }
  const [prRequestOrder, setprRequestOrder] = useState<any>([]);
  const [BusinessDetailsForPayment, setBusinessDetailsForPayment] =
    useState<any>({
      businessDetails: {},
    });
  const fetchPrOrder = async () => {
    const id = prRequestid;
    const axiosConfig = {
      headers: {
        token: newToken ? newToken : "",
      },
    };
    const FetchData = await axios
      .get(
        `https://api.dev.assuredpay.in/checkerRoutes/request/payment/received/${id}`,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let orderData = res.data.data;
          setprRequestOrder(orderData);
          setBusinessDetailsForPayment(orderData.requesterDetails);
          if (orderData.order.paymentType === "full") {
            setCreatePaymentOrder({
              PO: orderData.order.POPI,
              orderTitle: orderData.order.title,
              amount: orderData.order.orderAmount,
              paymentType: orderData.order.paymentType,
              paymentDays: getPaymentDays(orderData.order.paymentDays),
              useBankBalance: "",
              description: "",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchPrOrder();
  }, [prRequestid]);

  useEffect(() => {
    BankBalance();
    RecievableAmount();
  }, [prRequestOrder]);
  console.log(prRequestOrder, "prre------------------------------");
  const [BankBalanceData, setBankBalanceData] = useState<any>({});
  const BankBalance = async () => {
    let params;
    const axiosConfig = {
      headers: {
        token: newToken ? newToken : "",
      },
      params: params,
    };
    const FetchData = await axios
      .get(
        "https://api.dev.assuredpay.in/checkerRoutes/getwalletbalance",
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let orderData = res.data.result;
          setBankBalanceData(orderData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [TotalReceivableAmt, setTotalReceivableAmt] = useState<any>({});
  const RecievableAmount = async () => {
    const axiosConfig = {
      headers: {
        token: newToken ? newToken : "",
      },
    };
    const FetchData = await axios
      .get(
        "https://api.dev.assuredpay.in/checkerRoutes/getrecievablesdashboard",
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let orderData = res.data.result;
          setTotalReceivableAmt(orderData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    BankBalance();
    RecievableAmount();
  }, [EligibleReceivable]);
  //  amount will be empty after amount selection

  //  Validation Functions please correct if needed for reusable code ---------
  const validateOrderAmount = () => {
    let disabledField = false;

    // PoPI or OrderTitle condition check
    if (CreatePaymentOrder.orderTitle === "" && CreatePaymentOrder.PO === "") {
      disabledField = true;
    } else {
      disabledField = false;
    }
    return disabledField;
  };

  const validateMakePaymentBtn = () => {
    let { amount, orderTitle, PO, useBankBalance, paymentDays } =
      CreatePaymentOrder;
    let Amount: number = amount !== "" ? parseInt(amount) : 0;
    let UseBankBalance: number =
      useBankBalance !== "" ? parseInt(useBankBalance) : 0;
    let PaymentDays: number = paymentDays !== "" ? parseInt(paymentDays) : 0;
    // let leftOrderAmount =
    if (orderTitle === "") {
      console.log("step1");
      return true;
    } else {
      console.log(PO, "PO.....");
      if (PO === "") {
        console.log("step2");
        return true;
      } else {
        console.log("step3", Amount);

        if (Amount <= 0) {
          return true;
        } else {
          if (PaymentDays <= 7) {
            console.log("step4");

            return true;
          } else {
            if (UseBankBalance <= 0) {
              console.log("step5");

              return true;
            } else {
              if (UseBankBalance === Amount) {
                console.log("step6", UseBankBalance, Amount);
                return false;
              } else {
                return true;
                // check receivable and length abd sym of receivable and amount
              }
            }
          }
        }
      }
    }
  };
  useEffect(() => {
    validateMakePaymentBtn();
    // validateMakePayment()
  }, [CreatePaymentOrder]);

  const fetchDisabledBtn = () => {
    const { paymentDays, amount } = CreatePaymentOrder;
    if (amount !== "" && paymentDays !== "") {
      return false;
    } else {
      return true;
    }
  };

  const [recievableType, setrecievableType] = useState<number>(1);
  const modifyRecievableType = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setrecievableType(newValue);
  };
  const [draftBtnDisabled, setdraftBtnDisabled] = useState(false);
  const [draftBtnDisabled1, setdraftBtnDisabled1] = useState(false);

  const draftRecievableupdate = async () => {
    const { useBankBalance, amount } = CreatePaymentOrder;
    const Amount = amount === "" ? 0 : parseInt(amount);
    const UseBankBalance = useBankBalance === "" ? 0 : parseInt(useBankBalance);

    const axiosConfig = {
      headers: {
        token: newToken ? newToken : "",
      },
    };
    let array = [];
    if (fetchRecievalbles.length > 0) {
      for (let i = 0; i < fetchRecievalbles.length; i++) {
        let body = {
          milestoneId: fetchRecievalbles[i].id,
          index: 0,
          bankBalanceUsed: UseBankBalance,
          orderAmount: Amount,
          milestoneAmount: Amount - UseBankBalance,
          dueDate: fetchRecievalbles[i].dueDate,
          days: parseInt(CreatePaymentOrder.paymentDays),
          assuredRecievables: fetchRecievalbles[i].assuredRecievables,
          usableRecievables: fetchRecievalbles[i].usableRecievables,
          receivablesToBeUsed: fetchRecievalbles[i].receivablesToBeUsed,
          convenienceFee: fetchRecievalbles[i].convenienceFee,
          isFDAllowed: fetchRecievalbles[i].isFDAllowed,
          fdDays: fetchRecievalbles[i].fdDays,
          approxInterest: fetchRecievalbles[i].approxInterest,
        };
        array.push(body);
      }
    }
    const FetchData = await axios
      .post(
        "https://api.dev.assuredpay.in/checkerRoutes/selectRecievable",
        array,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let interestData = res.data.data;
          if (interestData.length > 0) {
            setinterestDetailsArray(interestData);
          } else {
            setinterestDetailsArray([]);
          }
          setdraftBtnDisabled(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFetchBtnDisableValidation = (value: boolean) => {
    const { useBankBalance, amount, paymentDays } = CreatePaymentOrder;
    if (
      amount !== "" &&
      parseInt(amount) > 0 &&
      paymentDays !== "" &&
      parseInt(paymentDays) > 0
    ) {
      if (parseInt(paymentDays) >= 7) {
        if (parseInt(amount) !== parseInt(useBankBalance)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const [OpenPartialAccept, setOpenPartialAccept] = useState(false);
  const confirmPartialPaymentOrder = () => {
    setOpenPartialAccept(true);
  };
  const ClosePartialPaymentAcceptDialog = () => {
    setOpenPartialAccept(false);
  };
  // Payment Order Create -  SUBMIT/MAKE Payment
  const HandleonClickCreatePayment = async () => {
    setopenSimpleLoader(true);
    let body = {};
    //  Payment Main Body
    let receivable = [];
    let receivable1 = [];
    let receivable2 = [];
    let receivable3 = [];

    console.log(PaymentMethod);
    if (PaymentMethod === "full") {
      for (let i = 0; i < fetchRecievalbles.length; i++) {
        let receivableToBeUsedValue = {
          orderId: fetchRecievalbles[i].orderId,
          milestoneId: fetchRecievalbles[i].id,
          amount: fetchRecievalbles[i].receivablesToBeUsed,
          convenienceFee: fetchRecievalbles[i].convenienceFee,
          isFDAllowed: fetchRecievalbles[i].isFDAllowed,
          FDDays: fetchRecievalbles[i].fdDays,
          approxInterest: fetchRecievalbles[i].approxInterest,
        };
        receivable.push(receivableToBeUsedValue);
      }
      body = {
        orderDetails: {
          fromPr: true,
          business_id: BusinessDetailsForPayment.user,
          paymentType: PaymentMethod,
          paymentCombination: "BB",
          remaks: CreatePaymentOrder.description,
          title: CreatePaymentOrder.orderTitle,
          POPI: CreatePaymentOrder.PO,
          orderAmount: parseInt(CreatePaymentOrder.amount),
          paymentIdentifier: "buyer",
          paymentDays: parseInt(CreatePaymentOrder.paymentDays),
        },
        milestoneDetails: [
          {
            dueDate: getPaymentDays(CreatePaymentOrder.paymentDays),
            days: CreatePaymentOrder.paymentDays,
            ApproxInterest: parseInt(
              calculateInterest(CreatePaymentOrder.useBankBalance)
            ),
            amount: parseInt(CreatePaymentOrder.amount),
            usingBB: parseInt(CreatePaymentOrder.useBankBalance),
            receivablesUsedForComplition: receivable,
          },
        ],
      };
    } else if (PaymentMethod === "partial") {
      if (parseInt(partialPaymentOption) === 2) {
        for (let i = 0; i < fetchReceivable1.length; i++) {
          let receivableToBeUsedValue = {
            orderId: fetchReceivable1[i].orderId,
            milestoneId: fetchReceivable1[i].id,
            amount: fetchReceivable1[i].receivablesToBeUsed,
            convenienceFee: fetchReceivable1[i].convenienceFee,
            isFDAllowed: fetchReceivable1[i].isFDAllowed,
            FDDays: fetchReceivable1[i].fdDays,
            approxInterest: fetchReceivable1[i].approxInterest,
          };
          receivable1.push(receivableToBeUsedValue);
        }
        for (let i = 0; i < fetchReceivable2.length; i++) {
          let receivableToBeUsedValue = {
            orderId: fetchReceivable2[i].orderId,
            milestoneId: fetchReceivable2[i].id,
            amount: fetchReceivable2[i].receivablesToBeUsed,
            convenienceFee: fetchReceivable2[i].convenienceFee,
            isFDAllowed: fetchReceivable2[i].isFDAllowed,
            FDDays: fetchReceivable2[i].fdDays,
            approxInterest: fetchReceivable2[i].approxInterest,
          };
          receivable2.push(receivableToBeUsedValue);
        }
        body = {
          orderDetails: {
            business_id: BusinessDetailsForPayment.user,
            paymentType: PaymentMethod,
            paymentCombination: "BB",
            remaks: CreatePaymentOrder.description,
            title: CreatePaymentOrder.orderTitle,
            POPI: CreatePaymentOrder.PO,
            orderAmount: parseInt(CreatePaymentOrder.amount),
            paymentIdentifier: "buyer",
            paymentDays: findGreatest(
              parseInt(partialPaymentDays.milestonePaymentDays1),
              parseInt(partialPaymentDays.milestonePaymentDays2)
            ), // order days depends upon top high days
          },
          milestoneDetails: [
            {
              dueDate: getPaymentDays(partialPaymentDays.milestonePaymentDays1),
              days: partialPaymentDays.milestonePaymentDays1,
              ApproxInterest: parseInt(
                calculateInterest(partialPaymentDays.useBankBalance1)
              ),
              amount: parseInt(partialPaymentDays.milestoneAmount1),
              usingBB: parseInt(partialPaymentDays.useBankBalance1),
              receivablesUsedForComplition: receivable1,
            },
            {
              dueDate: getPaymentDays(partialPaymentDays.milestonePaymentDays2),
              days: partialPaymentDays.milestonePaymentDays2,
              ApproxInterest: parseInt(
                calculateInterest(partialPaymentDays.useBankBalance2)
              ),
              amount: parseInt(partialPaymentDays.milestoneAmount2),
              usingBB: parseInt(partialPaymentDays.useBankBalance2),
              receivablesUsedForComplition: receivable2,
            },
          ],
        };
      } else if (parseInt(partialPaymentOption) === 3) {
        for (let i = 0; i < fetchReceivable1.length; i++) {
          let receivableToBeUsedValue = {
            orderId: fetchReceivable1[i].orderId,
            milestoneId: fetchReceivable1[i].id,
            amount: fetchReceivable1[i].receivablesToBeUsed,
            convenienceFee: fetchReceivable1[i].convenienceFee,
            isFDAllowed: fetchReceivable1[i].isFDAllowed,
            FDDays: fetchReceivable1[i].fdDays,
            approxInterest: fetchReceivable1[i].approxInterest,
          };
          receivable1.push(receivableToBeUsedValue);
        }
        for (let i = 0; i < fetchReceivable2.length; i++) {
          let receivableToBeUsedValue = {
            orderId: fetchReceivable2[i].orderId,
            milestoneId: fetchReceivable2[i].id,
            amount: fetchReceivable2[i].receivablesToBeUsed,
            convenienceFee: fetchReceivable2[i].convenienceFee,
            isFDAllowed: fetchReceivable2[i].isFDAllowed,
            FDDays: fetchReceivable2[i].fdDays,
            approxInterest: fetchReceivable2[i].approxInterest,
          };
          receivable2.push(receivableToBeUsedValue);
        }
        for (let i = 0; i < fetchReceivable3.length; i++) {
          let receivableToBeUsedValue = {
            orderId: fetchReceivable3[i].orderId,
            milestoneId: fetchReceivable3[i].id,
            amount: fetchReceivable3[i].receivablesToBeUsed,
            convenienceFee: fetchReceivable3[i].convenienceFee,
            isFDAllowed: fetchReceivable3[i].isFDAllowed,
            FDDays: fetchReceivable3[i].fdDays,
            approxInterest: fetchReceivable3[i].approxInterest,
          };
          receivable3.push(receivableToBeUsedValue);
        }
        body = {
          orderDetails: {
            business_id: BusinessDetailsForPayment.user,
            paymentType: PaymentMethod,
            paymentCombination: "BB",
            remaks: CreatePaymentOrder.description,
            title: CreatePaymentOrder.orderTitle,
            POPI: CreatePaymentOrder.PO,
            orderAmount: parseInt(CreatePaymentOrder.amount),
            paymentIdentifier: "buyer",
            paymentDays: findGreatest(
              parseInt(partialPaymentDays.milestonePaymentDays1),
              parseInt(partialPaymentDays.milestonePaymentDays2),
              parseInt(partialPaymentDays.milestonePaymentDays3)
            ), // order days depends upon top high days
          },
          milestoneDetails: [
            {
              dueDate: getPaymentDays(partialPaymentDays.milestonePaymentDays1),
              days: partialPaymentDays.milestonePaymentDays1,
              ApproxInterest: parseInt(
                calculateInterest(partialPaymentDays.useBankBalance1)
              ),
              amount: parseInt(partialPaymentDays.milestoneAmount1),
              usingBB: parseInt(partialPaymentDays.useBankBalance1),
              receivablesUsedForComplition: receivable1,
            },
            {
              dueDate: getPaymentDays(partialPaymentDays.milestonePaymentDays2),
              days: partialPaymentDays.milestonePaymentDays2,
              ApproxInterest: parseInt(
                calculateInterest(partialPaymentDays.useBankBalance2)
              ),
              amount: parseInt(partialPaymentDays.milestoneAmount2),
              usingBB: parseInt(partialPaymentDays.useBankBalance2),
              receivablesUsedForComplition: receivable2,
            },
            {
              dueDate: getPaymentDays(partialPaymentDays.milestonePaymentDays3),
              days: partialPaymentDays.milestonePaymentDays3,
              ApproxInterest: parseInt(
                calculateInterest(partialPaymentDays.useBankBalance3)
              ),
              amount: parseInt(partialPaymentDays.milestoneAmount3),
              usingBB: parseInt(partialPaymentDays.useBankBalance3),
              receivablesUsedForComplition: receivable3,
            },
          ],
        };
      }
    }

    //  payment config
    const axiosConfig = {
      headers: {
        token: newToken !== "" ? newToken : "",
      },
    };
    const FetchData = await axios
      .post(
        "https://api.dev.assuredpay.in/checkerRoutes/createprchecker",
        body,
        axiosConfig
      )
      .then((res) => {
        const businessData = res;
        console.log(
          res.data.result.paymentRequest,
          "res.data.result.paymentRequest"
        );
        if (res.status === 200) {
          setopenSimpleLoader(false);
          enqueueSnackbar("Payment Request created Successfully", {
            variant: "success",
            style: {
              color: " var(--success-darker, #0A5554)",
              backgroundColor: "var(--success-lighter, #D8FBDE)",
              marginRight: "100px",
              fontFamily: "Public Sans",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "22px",
              maxWidth: "400px",
              marginTop: "50px",
            },
          });
          navigate("/dashboard/order", {
            state: {
              orderDetails: res.data.result.order,
              GeneratePayment: GeneratePayment,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setopenSimpleLoader(false);
        if (err.response.data.error) {
          enqueueSnackbar(err.response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar("Something went wrong, Please try again", {
            variant: "error",
          });
        }
      });
  };

  const resetFullBankBalancePayment = () => {
    setpaymentInitiateStatus(false);
    setinterestDetailsArray([]);
  };

  //  Partial Setup  ------------

  // step 1  - Milestone Selection Option
  const [partialPaymentOption, setpartialPaymentOption] = useState<string>("");
  const selectMilestones = (event: any) => {
    setpartialPaymentOption(event.target.value);
  };

  // step 2 - Chip State values for Payment Days and Selection

  const chipPercentage = [
    {
      label: "10%",
      value: 10,
    },
    {
      label: "20%",
      value: 20,
    },
    {
      label: "30%",
      value: 30,
    },
    {
      label: "40%",
      value: 40,
    },
    {
      label: "50%",
      value: 50,
    },
    {
      label: "60%",
      value: 60,
    },
    {
      label: "70%",
      value: 70,
    },
  ];
  const [partialDays1, setPartialDays1] = useState<number>(0);
  const [partialAmt1, setPartialAmt1] = useState<number>(0);
  const [partialDays2, setPartialDays2] = useState<number>(0);
  const [partialAmt2, setPartialAmt2] = useState<number>(0);
  const [partialDays3, setPartialDays3] = useState<number>(0);
  const [partialAmt3, setPartialAmt3] = useState<number>(0);

  // step 3 - Partial Payment Days & Amount Selection/Input Option
  const [partialPaymentDays, setpartialPaymentDays] = useState<any>({
    milestoneOption: 1,
    milestonePaymentDays1: 0,
    milestoneAmount1: 0,
    useBankBalance1: 0,
    useBankBalance2: 0,
    useBankBalance3: 0,
    milestonePaymentDays2: 0,
    milestoneAmount2: 0,
    milestonePaymentDays3: 0,
    milestoneAmount3: 0,
    selectedDate1: "",
    selectedDate2: "",
    selectedDate3: "",
  });

  const partialPaymentDaysSelection = (event: any) => {
    const { name, value } = event.target;
    setpartialPaymentDays((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
    if (name === "milestonePaymentDays1") {
      setPartialDays1(parseInt(value));
    } else if (name === "milestonePaymentDays2") {
      setPartialDays2(parseInt(value));
    } else if (name === "milestonePaymentDays3") {
      setPartialDays3(parseInt(value));
    } else if (name === "milestoneAmount1") {
      setPartialAmt1(parseInt(value));
    } else if (name === "milestoneAmount2") {
      setPartialAmt2(parseInt(value));
    } else if (name === "milestoneAmount3") {
      setPartialAmt3(parseInt(value));
    }
  };

  const handleOnChangeDays1Value = (value: any) => {
    setpartialPaymentDays((preve: any) => {
      return {
        ...preve,
        ["milestonePaymentDays1"]: value,
      };
    });
    setPartialDays1(value);
  };
  const handleOnChangeAmt1Value = (value: any) => {
    let newValue = orderAmtInt(parseInt(CreatePaymentOrder.amount), value);
    setpartialPaymentDays((preve: any) => {
      return {
        ...preve,
        ["milestoneAmount1"]: newValue,
      };
    });
    setPartialAmt1(value);
    // handleChangeAutoCal1(newValue)
  };
  const handleOnChangeDays2Value = (value: any) => {
    setpartialPaymentDays((preve: any) => {
      return {
        ...preve,
        ["milestonePaymentDays2"]: value,
      };
    });
    setPartialDays2(value);
  };
  const handleOnChangeAmt2Value = (value: any) => {
    let newValue = orderAmtInt(parseInt(CreatePaymentOrder.amount), value);
    setpartialPaymentDays((preve: any) => {
      return {
        ...preve,
        ["milestoneAmount2"]: newValue,
      };
    });
    setPartialAmt2(newValue);
    // handleChangeAutoCal2(newValue)
  };
  const handleOnChangeDays3Value = (value: any) => {
    setpartialPaymentDays((preve: any) => {
      return {
        ...preve,
        ["milestonePaymentDays3"]: value,
      };
    });
    // setPartialDays2(value);
    setPartialDays3(value);
  };
  const handleOnChangeAmt3Value = (value: any) => {
    let newValue = orderAmtInt(parseInt(CreatePaymentOrder.amount), value);
    setpartialPaymentDays((preve: any) => {
      return {
        ...preve,
        ["milestoneAmount3"]: newValue,
      };
    });
    setPartialAmt3(newValue);
  };

  const [milestoneSelectValue, setmilestoneSelectValue] = useState<number>(1);
  const [fetchReceivable1, setfetchReceivable1] = useState<any>([]);
  const [fetchReceivable2, setfetchReceivable2] = useState<any>([]);
  const [fetchReceivable3, setfetchReceivable3] = useState<any>([]);

  const fetchRecievableForPartialPayment = async (value: number) => {
    let body = {};
    if (value === 1) {
      body = {
        // index: number;
        orderAmount:
          parseInt(CreatePaymentOrder.amount) > 0
            ? parseInt(CreatePaymentOrder.amount)
            : 0,
        milestoneAmount:
          parseInt(partialPaymentDays.milestoneAmount1) > 0
            ? parseInt(partialPaymentDays.milestoneAmount1)
            : 0,
        dueDate: getPaymentDays(partialPaymentDays.milestonePaymentDays1),
        days:
          parseInt(partialPaymentDays.milestonePaymentDays1) > 0
            ? parseInt(partialPaymentDays.milestonePaymentDays1)
            : 0,
        bankBalance:
          parseInt(partialPaymentDays.useBankBalance1) > 0
            ? parseInt(partialPaymentDays.useBankBalance1)
            : 0,
      };
    } else if (value === 2) {
      body = {
        // index: number;
        orderAmount:
          parseInt(CreatePaymentOrder.amount) > 0
            ? parseInt(CreatePaymentOrder.amount)
            : 0,
        milestoneAmount:
          parseInt(partialPaymentDays.milestoneAmount2) > 0
            ? parseInt(partialPaymentDays.milestoneAmount2)
            : 0,
        dueDate: getPaymentDays(partialPaymentDays.milestonePaymentDays2),
        days:
          parseInt(partialPaymentDays.milestonePaymentDays2) > 0
            ? parseInt(partialPaymentDays.milestonePaymentDays2)
            : 0,
        bankBalance:
          parseInt(partialPaymentDays.useBankBalance2) > 0
            ? parseInt(partialPaymentDays.useBankBalance2)
            : 0,
      };
    } else if (value === 3) {
      body = {
        // index: number;
        orderAmount:
          parseInt(CreatePaymentOrder.amount) > 0
            ? parseInt(CreatePaymentOrder.amount)
            : 0,
        milestoneAmount:
          parseInt(partialPaymentDays.milestoneAmount3) > 0
            ? parseInt(partialPaymentDays.milestoneAmount3)
            : 0,
        dueDate: getPaymentDays(partialPaymentDays.milestonePaymentDays3),
        days:
          parseInt(partialPaymentDays.milestonePaymentDays3) > 0
            ? parseInt(partialPaymentDays.milestonePaymentDays3)
            : 0,
        bankBalance:
          parseInt(partialPaymentDays.useBankBalance3) > 0
            ? parseInt(partialPaymentDays.useBankBalance3)
            : 0,
      };
    } else {
      body = {};
    }

    const axiosConfig = {
      headers: {
        token: newToken !== "" ? newToken : "",
      },
    };

    const FetchData = await axios
      .post(
        "https://api.dev.assuredpay.in/checkerRoutes/createOrder/getrecievables",
        body,
        axiosConfig
      )
      .then(async (res) => {
        let resData = res.data.result;

        if (value == 3) {
          setfetchReceivable3(resData);
        } else if (value == 2) {
          setfetchReceivable2(resData);
        } else {
          console.log(value, "value-1");

          console.log(resData, "9");
          setfetchReceivable1(resData);
        }
      })
      .catch((err) => {
        ErrorMsg("Something went wrong, Please try again");
        console.log(err);
      });
  };
  const FetchReceivablesAndInterestDetails = (value: number) => {
    fetchRecievableForPartialPayment(value);
  };

  const [partialInterestDetailsArray, setpartialInterestDetailsArray] =
    useState<any>([]);
  const draftRecievableupdate1 = async (arr: any, num: number) => {
    let useBankBalance = 0;
    let milestoneAmt = 0;
    let milestoneDays = 0;
    if (num == 2) {
      const { useBankBalance3, milestoneAmount3, milestonePaymentDays3 } =
        partialPaymentDays;
      useBankBalance =
        useBankBalance3 !== "" && parseInt(useBankBalance3) > 0
          ? parseInt(useBankBalance3)
          : 0;
      milestoneAmt =
        milestoneAmount3 !== "" && parseInt(milestoneAmount3) > 0
          ? parseInt(milestoneAmount3)
          : 0;
      milestoneDays =
        milestonePaymentDays3 !== "" && parseInt(milestonePaymentDays3) > 0
          ? parseInt(milestonePaymentDays3)
          : 0;
    } else if (num == 1) {
      const { useBankBalance2, milestoneAmount2, milestonePaymentDays2 } =
        partialPaymentDays;
      useBankBalance =
        useBankBalance2 !== "" && parseInt(useBankBalance2) > 0
          ? parseInt(useBankBalance2)
          : 0;
      milestoneAmt =
        milestoneAmount2 !== "" && parseInt(milestoneAmount2) > 0
          ? parseInt(milestoneAmount2)
          : 0;
      milestoneDays =
        milestonePaymentDays2 !== "" && parseInt(milestonePaymentDays2) > 0
          ? parseInt(milestonePaymentDays2)
          : 0;
    } else {
      const { useBankBalance1, milestoneAmount1, milestonePaymentDays1 } =
        partialPaymentDays;
      useBankBalance =
        useBankBalance1 !== "" && parseInt(useBankBalance1) > 0
          ? parseInt(useBankBalance1)
          : 0;
      milestoneAmt =
        milestoneAmount1 !== "" && parseInt(milestoneAmount1) > 0
          ? parseInt(milestoneAmount1)
          : 0;
      milestoneDays =
        milestonePaymentDays1 !== "" && parseInt(milestonePaymentDays1) > 0
          ? parseInt(milestonePaymentDays1)
          : 0;
    }
    const Amount =
      CreatePaymentOrder.amount === ""
        ? 0
        : parseInt(CreatePaymentOrder.amount);
    const axiosConfig = {
      headers: {
        token: newToken ? newToken : "",
      },
    };
    let array = [];
    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        console.log(arr[i], "arr.....................................");

        let body = {
          milestoneId: arr[i].id,
          index: num,
          bankBalanceUsed: useBankBalance,
          orderAmount: Amount,
          milestoneAmount: milestoneAmt,
          dueDate: arr[i].dueDate,
          days: milestoneDays,
          assuredRecievables: arr[i].assuredRecievables,
          usableRecievables: arr[i].usableRecievables,
          receivablesToBeUsed: arr[i].receivablesToBeUsed,
          convenienceFee: arr[i].convenienceFee,
          isFDAllowed: arr[i].isFDAllowed,
          fdDays: arr[i].fdDays,
          approxInterest: arr[i].approxInterest,
        };
        console.log(body, "body.....................................");
        array.push(body);
      }
    }
    const FetchData = await axios
      .post(
        "https://api.dev.assuredpay.in/checkerRoutes/selectRecievable",
        array,
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let interestData = res.data.data;
          setpartialInterestDetailsArray(interestData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [paymentInitiateStatus, setpaymentInitiateStatus] = useState(false);
  const verifyPaymentOrder = async () => {
    const axiosConfig = {
      headers: {
        token: newToken !== "" ? newToken : "",
      },
    };
    let array: any = [];
    let body = {
      milestoneId: "",
      orderAmount: parseInt(CreatePaymentOrder.amount),
      days: parseInt(CreatePaymentOrder.paymentDays),
      bankBalanceUsed: parseInt(CreatePaymentOrder.useBankBalance),
      index: 0,
      milestoneAmount: parseInt(CreatePaymentOrder.amount),
      dueDate: "",
      assuredRecievables: 0,
      usableRecievables: 0,
      receivablesToBeUsed: 0,
      convenienceFee: 0,
      isFDAllowed: false,
      fdDays: 0,
      approxInterest: 0,
    };
    array.push(body);
    const FetchData = await axios
      .post(
        "https://api.dev.assuredpay.in/checkerRoutes/selectRecievable",
        array,
        axiosConfig
      )
      .then(async (res) => {
        let resData = res.data.data;
        setpaymentInitiateStatus(true);
        setinterestDetailsArray(resData);
        console.log(res, "res........>>>>>>>>>");
      })
      .catch((err) => {
        setpaymentInitiateStatus(false);
        console.log(err, "err....................");
        ErrorMsg(err.response.data.error);
        console.log(err);
      });
  };
  const verifyPartialPaymentOrder = async (num: number) => {
    const axiosConfig = {
      headers: {
        token: newToken !== "" ? newToken : "",
      },
    };
    let array: any = [];
    let body = {};
    if (num === 0) {
      body = {
        milestoneId: "",
        orderAmount: parseInt(CreatePaymentOrder.amount),
        days: parseInt(partialPaymentDays.milestonePaymentDays1),
        bankBalanceUsed: parseInt(partialPaymentDays.useBankBalance1),
        index: num,
        milestoneAmount: parseInt(partialPaymentDays.milestoneAmount1),
        dueDate: "",
        assuredRecievables: 0,
        usableRecievables: 0,
        receivablesToBeUsed: 0,
        convenienceFee: 0,
        isFDAllowed: false,
        fdDays: 0,
        approxInterest: 0,
      };
    } else if (num === 1) {
      body = {
        milestoneId: "",
        orderAmount: parseInt(CreatePaymentOrder.amount),
        days: parseInt(partialPaymentDays.milestonePaymentDays2),
        bankBalanceUsed: parseInt(partialPaymentDays.useBankBalance2),
        index: num,
        milestoneAmount: parseInt(partialPaymentDays.milestoneAmount2),
        dueDate: "",
        assuredRecievables: 0,
        usableRecievables: 0,
        receivablesToBeUsed: 0,
        convenienceFee: 0,
        isFDAllowed: false,
        fdDays: 0,
        approxInterest: 0,
      };
    } else if (num === 2) {
      body = {
        milestoneId: "",
        orderAmount: parseInt(partialPaymentDays.milestoneAmount3),
        days: parseInt(partialPaymentDays.milestonePaymentDays3),
        bankBalanceUsed: parseInt(partialPaymentDays.useBankBalance3),
        index: num,
        milestoneAmount: parseInt(partialPaymentDays.milestoneAmount3),
        dueDate: "",
        assuredRecievables: 0,
        usableRecievables: 0,
        receivablesToBeUsed: 0,
        convenienceFee: 0,
        isFDAllowed: false,
        fdDays: 0,
        approxInterest: 0,
      };
    }
    array.push(body);
    const FetchData = await axios
      .post(
        "https://api.dev.assuredpay.in/checkerRoutes/selectRecievable",
        array,
        axiosConfig
      )
      .then(async (res) => {
        let resData = res.data.data;
        setpartialInterestDetailsArray(resData);
        console.log(res, "res........>>>>>>>>>");
      })
      .catch((err) => {
        console.log(err, "err....................");
        ErrorMsg(err.response.data.error);
        console.log(err);
      });
  };
  console.log(CreatePaymentOrder, "-----------hh");

  const makeFullPaymentValidation = (arr: any) => {
    if (arr.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleonChangeForDate = (value: any) => {
    console.log(value, "setDateValue");
    const selectedDate = daysConversionFromDate(value);
    setCreatePaymentOrder((prevState: any) => ({
      ...prevState, // Copy the previous state
      paymentDays: selectedDate,
      // isGSTDetailSaveManually: true, // Add the new key-value pair
    }));
  };
  const handleonChangeForDate1 = (value: any) => {
    console.log(value, "setDateValue");
    const selectedDate = daysConversionFromDate(value);
    setpartialPaymentDays((prevState: any) => ({
      ...prevState, // Copy the previous state
      milestonePaymentDays1: selectedDate,
      selectedDate1: value,
    }));
  };
  console.log(partialPaymentDays, "setpartialPaymentDays---------------------");
  const handleonChangeForDate2 = (value: any) => {
    console.log(value, "setDateValue");
    const selectedDate = daysConversionFromDate(value);
    setpartialPaymentDays((prevState: any) => ({
      ...prevState, // Copy the previous state
      milestonePaymentDays2: selectedDate,
      selectedDate2: value,
    }));
  };
  const handleonChangeForDate3 = (value: any) => {
    console.log(value, "setDateValue");
    const selectedDate = daysConversionFromDate(value);
    setpartialPaymentDays((prevState: any) => ({
      ...prevState, // Copy the previous state
      milestonePaymentDays3: selectedDate,
      selectedDate3: value,
    }));
  };
  const minDaysValidation = dayjs().add(7, "day");
  const maxDaysValidation = dayjs().add(180, "day");

  // partial
  const minDaysValidation1 = dayjs().add(7, "day");
  const maxDaysValidation1 = dayjs().add(180, "day");
  const minDaysValidation2 = dayjs().add(
    partialPaymentDays.milestonePaymentDays1,
    "day"
  );
  const maxDaysValidation2 = dayjs().add(180, "day");
  const minDaysValidation3 = dayjs().add(
    partialPaymentDays.milestonePaymentDays2,
    "day"
  );
  const maxDaysValidation3 = dayjs().add(180, "day");

  const [disableMilestones, setdisableMilestones] = useState(false);
  const disabledMilestoneSelection = () => {
    setdisableMilestones(true);
  };

  const resetDisabledMilestone = () => {
    setdisableMilestones(false);
  };
  return (
    <div>
      {" "}
      <>
        {/* 1. Main Page Box / Title / Subtitle */}
        {/* No Touch Code---1 */}
        <Stack mx={4}>
          <Typography20
            sx={{ mt: 4 }}
            fontFamily={"Public Sans"}
            fontWeight={700}
            color={"#000000"}
            text={"Create New Payment"}
          ></Typography20>
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                fontFamily: "Public Sans",
              }}
            >
              Payments
            </Typography>
            <Typography>.</Typography>

            <Typography
              fontFamily={"Public Sans"}
              fontSize={"13px"}
              fontWeight={400}
              fontStyle={"normal"}
              color={"rgba(145, 158, 171, 1)"}
            >
              Create New Payment
            </Typography>
          </Stack>
        </Stack>
        {/* No Touch Code---1 */}
        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
          }}
          pb={8}
        >
          {/* 2. Subtitle Box */}
          <Box
            boxShadow={1}
            mx={4}
            my={2}
            py={2}
            pb={4}
            sx={{
              borderRadius: "8px",
              backgroundColor: "white",
            }}
          >
            {/* 3. (Recipent/Seller) Business Details as per Selection or Payment Request */}
            {/* No Touch Code----2 */}
            <>
              <Stack
                sx={{ mx: 4, py: 2 }}
                display={"flex"}
                direction={"row"}
                spacing={1}
              >
                {" "}
                <TextField
                  label="Seller"
                  size="medium"
                  type="text"
                  disabled
                  value={BusinessDetailsForPayment.Legal_Name_of_Business}
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      borderColor: "#919EAB",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 50,
                      minWidth: "35vw",
                    },
                    "& .MuiFormLabel-root": {
                      // color: "#919EAB",
                      fontFamily: "Public Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      Height: "20px",
                    },
                  }}
                />
              </Stack>
              <Box
                mx={4}
                my={2}
                sx={{
                  borderRadius: "16px",
                  backgroundColor: "rgba(249, 250, 251, 1)",
                  border: "1px solid rgba(223, 227, 232, 1)",
                }}
              >
                <Stack
                  sx={{ mx: 4, py: 2 }}
                  display={"flex"}
                  direction={"row"}
                  spacing={1}
                >
                  <Typography16
                    fontFamily={"Public Sans"}
                    sx={{ mt: 4, pb: 2 }}
                    fontWeight={600}
                    color={"#000"}
                    text={`About ${BusinessDetailsForPayment.Legal_Name_of_Business}`}
                  ></Typography16>
                </Stack>
                <Grid
                  container
                  sx={{
                    py: 1,
                    px: 6,
                    "& .MuiPaper-root": {
                      padding: "2px",
                    },
                  }}
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid
                    style={{
                      padding: "8px",
                    }}
                    sx={{ my: 0, p: 0 }}
                    xs={2}
                    sm={4}
                    md={4}
                  >
                    <Typography
                      sx={{ m: 0, p: 0 }}
                      fontFamily={"Public Sans"}
                      fontSize={11}
                      color={"#919EAB"}
                    >
                      {" "}
                      Authorised Business Representative
                    </Typography>
                    <Typography
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      color={"#212B36"}
                      fontWeight={600}
                      sx={{ m: 0, p: 0 }}
                    >
                      {BusinessDetailsForPayment.nameInAadhaar}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      padding: "8px",
                    }}
                    sx={{ my: 0, p: 0 }}
                    xs={2}
                    sm={4}
                    md={4}
                  >
                    <Typography
                      sx={{ m: 0, p: 0 }}
                      fontFamily={"Public Sans"}
                      fontSize={11}
                      color={"#919EAB"}
                    >
                      {" "}
                      Contact
                    </Typography>
                    <Typography
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      color={"#212B36"}
                      fontWeight={600}
                      sx={{ m: 0, p: 0 }}
                    >
                      {BusinessDetailsForPayment.contact}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      padding: "8px",
                    }}
                    sx={{ my: 0, p: 0 }}
                    xs={2}
                    sm={4}
                    md={4}
                  >
                    <Typography
                      sx={{ m: 0, p: 0 }}
                      fontFamily={"Public Sans"}
                      fontSize={11}
                      color={"#919EAB"}
                    >
                      Address{" "}
                    </Typography>
                    <Typography
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      color={"#212B36"}
                      fontWeight={600}
                      sx={{ m: 0, p: 0 }}
                    >
                      {BusinessDetailsForPayment.Place_of_Business}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      padding: "8px",
                    }}
                    sx={{ my: 0, p: 0 }}
                    xs={2}
                    sm={4}
                    md={4}
                  >
                    <Typography
                      sx={{ m: 0, p: 0 }}
                      fontFamily={"Public Sans"}
                      fontSize={11}
                      color={"#919EAB"}
                    >
                      {" "}
                      GST
                    </Typography>
                    <Typography
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      color={"#212B36"}
                      fontWeight={600}
                      sx={{ m: 0, p: 0 }}
                    >
                      {BusinessDetailsForPayment.GSTIN_of_the_entity}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      padding: "8px",
                    }}
                    sx={{ my: 0, p: 0 }}
                    xs={2}
                    sm={4}
                    md={4}
                  >
                    <Typography
                      sx={{ m: 0, p: 0 }}
                      fontFamily={"Public Sans"}
                      fontSize={11}
                      color={"#919EAB"}
                    >
                      {" "}
                      CIN_NUMBER
                    </Typography>
                    <Typography
                      fontSize={"14px"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      color={"#212B36"}
                      fontWeight={600}
                      sx={{ m: 0, p: 0 }}
                    >
                      {BusinessDetailsForPayment.CIN}
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  mx={4}
                  my={2}
                  py={1.5}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "white",
                    border: "1px solid rgba(223, 227, 232, 1)",
                    maxWidth: "670px",
                  }}
                >
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    textAlign={"start"}
                    spacing={8}
                  >
                    <Stack
                      display={"flex"}
                      direction={"row"}
                      textAlign={"start"}
                      spacing={5}
                      sx={{
                        px: 1.5,
                        py: 2,
                        borderRadius: "8px",
                        //   backgroundColor: "#F9FAFB",
                      }}
                    >
                      <Box sx={{ px: 0.5 }}>
                        <Typography
                          component="legend"
                          fontSize={"11px"}
                          color={"#919EAB"}
                        >
                          Rating
                        </Typography>
                        <Rating
                          name="simple-controlled"
                          value={4}
                          size="small"
                          // onChange={(event, newValue) => {
                          //   setValue(newValue);
                          // }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          component="legend"
                          fontSize={"11px"}
                          color={"#919EAB"}
                        >
                          Since
                        </Typography>
                        <Typography
                          fontFamily={"Public Sans"}
                          fontSize={"14px"}
                          lineHeight={"22px"}
                          fontWeight={600}
                        >
                          2023
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component="legend"
                          fontSize={"12px"}
                          color={"#919EAB"}
                        >
                          Orders Full-filed
                        </Typography>
                        <Stack spacing={1} display={"flex"} direction={"row"}>
                          <Typography
                            fontSize={"12px"}
                            fontFamily={"Public Sans"}
                          >
                            30/100
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                    <Box
                      sx={{ backgroundColor: "#F9FAFB", borderRadius: "8px" }}
                    >
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        textAlign={"start"}
                        spacing={5}
                        sx={{
                          px: 3,
                          py: 1.5,
                        }}
                      >
                        <Box sx={{ px: 0.5 }}>
                          <Typography
                            component="legend"
                            fontSize={"11px"}
                            color={"#919EAB"}
                            mt={2}
                          >
                            Connections
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component="legend"
                            fontSize={"11px"}
                            color={"#919EAB"}
                          >
                            Buyers
                          </Typography>
                          <Typography
                            fontFamily={"Public Sans"}
                            fontSize={"14px"}
                            lineHeight={"22px"}
                            fontWeight={600}
                          >
                            0
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component="legend"
                            fontSize={"11px"}
                            color={"#919EAB"}
                          >
                            Sellers
                          </Typography>
                          <Typography
                            fontFamily={"Public Sans"}
                            fontSize={"14px"}
                            lineHeight={"22px"}
                            fontWeight={600}
                          >
                            0
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </>

            {/* <PaymentBusinessInfo props={BusinessDetailsForPayment} /> */}
            {/* No Touch Code----2 */}

            {/* Make Payment Box */}
            <Box mx={3}>
              {/* 4. Payment Initiate Option Make payment or previous payment  */}
              <Tabs
                value={selectPaymentSession}
                onChange={handleOnSelectPaymentSession}
                sx={{
                  // px: 2,
                  "& .MuiTabs-indicator": {
                    width: "auto",
                    backgroundColor: "rgba(48, 57, 129, 1)",
                    ml: 1,
                  },
                  "& .MuiTab-textColorPrimary": {
                    color: "rgba(33, 43, 54, 1)",
                  },
                  "& .MuiTab-textColorSecondary": {
                    color: "rgba(33, 43, 54, 1)",
                  },
                }}
              >
                <Tab
                  icon={<PaymentIcon fontSize="small" />}
                  iconPosition="start"
                  label="Make Payment"
                  value={"MakePayment"}
                  sx={{
                    "&.MuiTab-labelIcon": {
                      fontSize: "13px",
                      fontWeight: "600",
                      fontFamily: "Public Sans",
                      textTransform: "none",
                    },
                  }}
                />
                {/* <Tab
                  icon={<ArticleIcon fontSize="small" />}
                  iconPosition="start"
                  label="Previous Payment"
                  sx={{
                    "&.MuiTab-labelIcon": {
                      fontSize: "13px",
                      fontWeight: "600",
                      fontFamily: "Public Sans",
                      textTransform: "none",
                    },
                  }}
                /> */}
              </Tabs>
              <Stack mx={2} my={3} spacing={2}>
                {/* 5. Common Boxes for Full and Partial Payment */}
                <>
                  <TextField
                    label="Order Title (Optional)"
                    size="medium"
                    type="text"
                    name="orderTitle"
                    fullWidth
                    disabled={
                      CreatePaymentOrder.PO !== "" &&
                      CreatePaymentOrder.amount !== ""
                    }
                    value={CreatePaymentOrder.orderTitle}
                    onChange={createOrderForPayment}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        borderColor: "#919EAB",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 50,
                        maxWidth: "50%",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#919EAB",
                        fontFamily: "Public Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        Height: "20px",
                      },
                    }}
                  />
                  <TextField
                    label="PO/PI (Optional)"
                    size="medium"
                    type="text"
                    name="PO"
                    fullWidth
                    disabled={
                      CreatePaymentOrder.PO !== "" &&
                      CreatePaymentOrder.amount !== ""
                    }
                    value={CreatePaymentOrder.PO}
                    onChange={createOrderForPayment}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        borderColor: "#919EAB",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 50,
                        maxWidth: "50%",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#919EAB",
                        fontFamily: "Public Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        Height: "20px",
                      },
                    }}
                  />
                  <TextField
                    size="medium"
                    type="text"
                    placeholder="Please enter order amount"
                    label="Amount"
                    disabled
                    name="amount"
                    value={
                      CreatePaymentOrder.amount
                      // parseInt(CreatePaymentOrder.amount) > 0
                      //   ? onlyNumberValidation(CreatePaymentOrder.amount)
                      //   : ""
                    }
                    // disabled={validateOrderAmount()}
                    // disabled={paymentInitiateStatus || receivableSuccess}
                    onChange={createOrderForPayment}
                    helperText={
                      parseInt(CreatePaymentOrder.amount) > 0
                        ? numberToEnglish(parseInt(CreatePaymentOrder.amount))
                            .charAt(0)
                            .toUpperCase() +
                          numberToEnglish(
                            parseInt(CreatePaymentOrder.amount)
                          ).slice(1)
                        : "Acceptance in next 48 hours."
                    }
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        borderColor: "#919EAB",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 50,
                        maxWidth: "50%",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#919EAB",
                        fontFamily: "Public Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        Height: "20px",
                      },
                      "& .MuiFormHelperText-root": {
                        fontFamily: "Public Sans",
                        fontWeight: 500,
                      },
                    }}
                  />
                  {/* partial and full payment mode */}
                  <FormControl sx={{ px: 2, maxWidth: "50%" }}>
                    <RadioGroup
                      row
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      defaultValue={"full"}
                      value={PaymentMethod}
                      sx={{
                        "& .MuiRadio-root.Mui-checked": {
                          color: "grey",
                        },
                      }}
                    >
                      <FormControlLabel
                        control={<Radio size="small" />}
                        // disabled={CreatePaymentOrder.PO === "" ? true : false}
                        disabled={true}
                        label={
                          <Typography12 text="Full Payment"></Typography12>
                        }
                        value={"full"}
                      />
                      <FormControlLabel
                        value={"partial"}
                        disabled={true}
                        control={<Radio size="small" />}
                        // disabled={paymentInitiateStatus || receivableSuccess}
                        // disabled={CreatePaymentOrder.PO === "" ? true : false}
                        label={
                          <Typography12 text="Partial Payment"></Typography12>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </>
                <>
                  {/* 6. Full Payment Option */}
                  {PaymentMethod === "full" ? (
                    <>
                      {/* <Grid sx={{ p: 0 }} xs={2} sm={4} md={4}> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Payment Date"
                            format="DD/MM/YYYY"
                            // defaultValue={dayjs}
                            disabled={true}
                            minDate={minDaysValidation}
                            maxDate={maxDaysValidation}
                            sx={{
                              style: {
                                borderRadius: "8px",
                              },
                            }}
                            value={dayjs(CreatePaymentOrder.paymentDays)}
                            onChange={(value: any) =>
                              handleonChangeForDate(
                                moment(value ? value.$d : "").format(
                                  "YYYY/MM/DD"
                                )
                              )
                            }
                            slotProps={{
                              textField: {
                                size: "small",
                                helperText:
                                  CreatePaymentOrder.paymentDays !== ""
                                    ? `You decided to create your payment for ${
                                        CreatePaymentOrder.paymentDays
                                      } days from ${new Date().toDateString()}`
                                    : "Only seven to one hundred and eighty days are allowed for the payment period.",
                                sx: {
                                  "& .MuiInputBase-root": {
                                    height: 50,
                                    borderRadius: "8px",
                                  },
                                  "& .MuiFormLabel-root": {
                                    color: "#919EAB",
                                    fontFamily: "Public Sans",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    mt: 0.2,
                                  },
                                  "& .MuiFormHelperText-root": {
                                    fontFamily: "Public Sans",
                                    fontWeight: 500,
                                  },
                                },
                                style: {
                                  borderRadius: "16px",
                                  borderColor: "#919EAB",
                                  width: "100%",
                                  maxWidth: "50%",
                                  // marginTop: -8,
                                  marginTop: 0,
                                  // height: 40,
                                },
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        spacing={"auto"}
                        maxWidth={"50%"}
                      >
                        <Box minWidth={"100px"}>
                          <Stack>
                            <Typography12
                              fontSize={12}
                              text="Available Balance"
                            ></Typography12>
                            <Typography16
                              fontSize={15}
                              color={"#303981"}
                              fontWeight={700}
                              text={
                                BankBalanceData &&
                                BankBalanceData.availableBalance
                                  ? numberFormat(
                                      BankBalanceData.availableBalance
                                    )
                                  : 0
                              }
                            ></Typography16>
                          </Stack>
                        </Box>
                        <Box minWidth={"100px"}>
                          <Stack>
                            <Typography12
                              fontSize={12}
                              text="Used Receivables"
                            ></Typography12>
                            <Typography16
                              fontSize={15}
                              color={"#303981"}
                              fontWeight={700}
                              text={numberFormat(
                                usedReceivableAmtFull(fetchRecievalbles)
                              )}
                            ></Typography16>
                          </Stack>
                        </Box>
                        <Box minWidth={"100px"}>
                          <Stack>
                            <Typography12
                              fontSize={12}
                              text="Total Receivables"
                            ></Typography12>
                            <Typography16
                              fontSize={15}
                              color={"#303981"}
                              fontWeight={700}
                              text={numberFormat(
                                (TotalReceivableAmt &&
                                  TotalReceivableAmt.totalReceivable) ||
                                  0
                              )}
                            ></Typography16>
                          </Stack>
                        </Box>
                      </Stack>
                      <TextField
                        type="text"
                        size="medium"
                        name="useBankBalance"
                        label="Available Balance"
                        // error={(CreatePaymentOrder.useBankBalance !=="" && parseInt(CreatePaymentOrder.useBankBalance)) > (CreatePaymentOrder.useBankBalance !=="" && parseInt(CreatePaymentOrder.amount))
                        //   ? true
                        //   :false}
                        disabled={paymentInitiateStatus || receivableSuccess}
                        // helperText={
                        //   (CreatePaymentOrder.useBankBalance !== "" &&
                        //     parseInt(CreatePaymentOrder.useBankBalance)) >=
                        //   (CreatePaymentOrder.amount !== "" &&
                        //     parseInt(CreatePaymentOrder.amount))
                        //     ? `You're using the available balance to configure the entire Order Amount.`
                        //     : ""
                        // }
                        value={
                          parseInt(CreatePaymentOrder.useBankBalance) > 0
                            ? compareNumberValueValidation(
                                CreatePaymentOrder.useBankBalance,
                                parseInt(CreatePaymentOrder.amount)
                              )
                            : ""
                        }
                        onChange={createOrderForPayment}
                        // onBlur={checkOrderAmount}
                        InputProps={{
                          inputProps: {
                            max: 200,
                          },
                          style: {
                            borderRadius: "8px",
                            borderColor: "#919EAB",
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 50,
                            maxWidth: "50%",
                          },

                          "& .MuiFormLabel-root": {
                            color: "#919EAB",
                            fontFamily: "Public Sans",
                            fontWeight: "500",
                            fontSize: "14px",
                            Height: "20px",
                          },
                          "& .MuiFormHelperText-root": {
                            fontFamily: "Public Sans",
                            fontWeight: 500,
                            // color: "red",
                            maxWidth: "50%",
                          },
                        }}
                      />
                      <>
                        <Stack
                          display={"flex"}
                          direction={"row"}
                          sx={{
                            maxWidth: "50%",
                          }}
                        >
                          {/* {((CreatePaymentOrder.useBankBalance===""?0:parseInt(CreatePaymentOrder.useBankBalance))!==(CreatePaymentOrder.amount===""?0:parseInt(CreatePaymentOrder.amount)) && CreatePaymentOrder.useBankBalance!=="" && parseInt(CreatePaymentOrder.useBankBalance))? */}

                          {parseInt(CreatePaymentOrder.amount) <=
                          parseInt(CreatePaymentOrder.useBankBalance) ? (
                            <>
                              <Box display={"flex"} justifyContent={"end"}>
                                <Stack
                                  display={"flex"}
                                  direction={"row"}
                                  spacing={2}
                                >
                                  <LoadingButton
                                    loading={loading}
                                    size="medium"
                                    onClick={verifyPaymentOrder}
                                    disabled={paymentInitiateStatus}
                                    sx={{
                                      textTransform: "none",
                                      fontSize: "12px",
                                      backgroundColor: paymentInitiateStatus
                                        ? "rgba(145, 158, 171, 0.24)"
                                        : "#303981",
                                      fontFamily: "Public Sans",
                                      fontWeight: "400",
                                      borderRadius: "8px",
                                      color: paymentInitiateStatus
                                        ? ""
                                        : "white",
                                      "&:hover": {
                                        backgroundColor: paymentInitiateStatus
                                          ? "rgba(145, 158, 171, 0.24)"
                                          : "#303981",
                                      },
                                      py: 1.5,
                                      px: 4,
                                      mt: 1,
                                    }}
                                  >
                                    Continue
                                  </LoadingButton>{" "}
                                  <LoadingButton
                                    loading={loading}
                                    size="medium"
                                    onClick={resetFullBankBalancePayment}
                                    disabled={!paymentInitiateStatus}
                                    sx={{
                                      textTransform: "none",
                                      fontSize: "12px",
                                      backgroundColor: !paymentInitiateStatus
                                        ? "rgba(145, 158, 171, 0.24)"
                                        : "#303981",
                                      fontFamily: "Public Sans",
                                      fontWeight: "400",
                                      borderRadius: "8px",
                                      color: !paymentInitiateStatus
                                        ? ""
                                        : "white",
                                      "&:hover": {
                                        backgroundColor: !paymentInitiateStatus
                                          ? "rgba(145, 158, 171, 0.24)"
                                          : "#303981",
                                      },
                                      py: 1.5,
                                      px: 4,
                                      mt: 1,
                                    }}
                                  >
                                    Reset
                                  </LoadingButton>
                                </Stack>
                              </Box>
                              {paymentInitiateStatus ? (
                                <Box>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ mt: 2, mx: 1 }}
                                  >
                                    <>
                                      <DoneIcon
                                        style={{
                                          fontSize: "22px",
                                          color: "#36B37E",
                                        }}
                                      />{" "}
                                      <Typography
                                        fontWeight={600}
                                        fontSize={13}
                                        fontFamily={"Public Sans"}
                                        color={"#36B37E"}
                                      >
                                        {" "}
                                        Your full payment using available
                                        balance of Rs.{" "}
                                        <b>
                                          {numberFormat(
                                            parseInt(
                                              CreatePaymentOrder.useBankBalance
                                            )
                                          )}
                                        </b>{" "}
                                        is successfully initiated.
                                      </Typography>
                                    </>
                                  </Stack>
                                </Box>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <>
                              <Box display={"flex"} justifyContent={"start"}>
                                <Stack
                                  display={"flex"}
                                  direction={"row"}
                                  spacing={3}
                                >
                                  <LoadingButton
                                    loading={loading}
                                    size="medium"
                                    onClick={handleOnClickFetchRecievable}
                                    disabled={receivableBtn}
                                    sx={{
                                      textTransform: "none",
                                      fontSize: "11px",
                                      backgroundColor: receivableBtn
                                        ? "rgba(145, 158, 171, 0.24)"
                                        : "#303981",
                                      fontFamily: "Public Sans",
                                      fontWeight: "400",
                                      borderRadius: "8px",
                                      color: receivableBtn ? "" : "white",
                                      "&:hover": {
                                        backgroundColor: receivableBtn
                                          ? "rgba(145, 158, 171, 0.24)"
                                          : "#303981",
                                      },
                                      py: 1.5,
                                      px: 4,
                                      mt: 1,
                                    }}
                                  >
                                    Fetch Recievable
                                  </LoadingButton>

                                  {receivableSuccess ? (
                                    <Box>
                                      <Stack direction="row" spacing={1}>
                                        <Alert
                                          sx={{
                                            backgroundColor: "white",
                                            "& .MuiAlert-icon": {
                                              color: "#36B37E",
                                            },
                                          }}
                                          severity="success"
                                        >
                                          {" "}
                                          <Typography
                                            fontWeight={600}
                                            fontSize={13}
                                            fontFamily={"Public Sans"}
                                            color={"#36B37E"}
                                          >
                                            {" "}
                                            Receivables fetched successfully !
                                          </Typography>
                                        </Alert>
                                        {/* <>
                                          <DoneIcon
                                            style={{
                                              fontSize: "22px",
                                              color: "#36B37E",
                                            }}
                                          />{" "}
                                          <Typography
                                            fontWeight={600}
                                            fontSize={13}
                                            fontFamily={"Public Sans"}
                                            color={"#36B37E"}
                                          >
                                            {" "}
                                            Receivables fetched successfully !
                                          </Typography>
                                        </> */}
                                      </Stack>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </Stack>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </>
                      {/* <Box>
                                  <Stack
                                    direction="row"
                                    spacing={0.5}
                                    sx={{ mt: 1, mx: 1 }}
                                  >
                                 {
                                  showFetchReceivables?

                                  <>
                             
                                  <Typography
                                    fontWeight={600}
                                    fontSize={12}
                                    fontFamily={"Public Sans"}
                                    color={"red"}
                                  >
                                    {" "}
                                    Cannot countine due to insufficient
                                    balance. Available receivables are ₹{" "}
                                    <b>
                                      {numberFormat(
                                        usedReceivableAmtFull(
                                          fetchRecievalbles
                                        )
                                      )}
                                    </b>
                                    , A minimum of ₹$
                                    <b>
                                      {" "}
                                      {numberFormat(
                                        requiredReceivables
                                      )}{" "}
                                    </b>
                                    is required.
                                  </Typography>
                                </>:""
                                 }
                                   
                                   
                                  </Stack>
                                </Box> */}

                      {fetchRecievalbles.length > 0 ? (
                        <>
                          <Tabs
                            value={recievableType}
                            onChange={modifyRecievableType}
                            sx={{
                              "& .MuiTabs-indicator": {
                                width: "auto",
                                maxWidth: "145px",
                                mx: 3,
                                backgroundColor: "rgba(48, 57, 129, 1)",
                              },
                              "& .MuiTab-textColorPrimary": {
                                color: "rgba(33, 43, 54, 1)",
                              },
                              "& .MuiTab-textColorSecondary": {
                                color: "rgba(33, 43, 54, 1)",
                              },
                            }}
                          >
                            <Tab
                              icon={<ArticleIcon fontSize="small" />}
                              iconPosition="start"
                              label="Used Receivables"
                              value={1}
                              sx={{
                                "&.MuiTab-labelIcon": {
                                  fontSize: "13px",
                                  fontWeight: "600",
                                  fontFamily: "Public Sans",
                                  textTransform: "none",
                                },
                              }}
                            />
                            {/* <Tab
                              value={2}
                              icon={<ArticleIcon fontSize="small" />}
                              iconPosition="start"
                              label="All Receivables"
                              sx={{
                                "&.MuiTab-labelIcon": {
                                  fontSize: "13px",
                                  fontWeight: "600",
                                  fontFamily: "Public Sans",
                                  textTransform: "none",
                                },
                              }}
                            /> */}
                          </Tabs>
                          <Box
                            mx={3}
                            boxShadow={
                              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
                            }
                            borderRadius={"16px"}
                            // border={
                            //   "1px solid var(--transparent-grey-12, rgba(145, 158, 171, 0.12))"
                            // }
                            sx={{
                              backgroundColor:
                                "var(--Background-Light-Paper, #FFF)",
                            }}
                          >
                            <>
                              {" "}
                              <Box
                                // mt={2}
                                sx={{
                                  borderRadius: "8px",
                                  backgroundColor: "white",
                                }}
                              >
                                {/* {recievableType === 2 ? ( */}
                                {/* <AllReceivables
                                    props={fetchRecievalbles}
                                    PaymentOrderDetails={CreatePaymentOrder}
                                  />
                                ) : ( */}
                                <UsedRecieveables
                                  props={fetchRecievalbles}
                                  // ArrangeReceivable={ArrangeReceivable}
                                  // arrangeArrayForInterestDetails={
                                  //   arrangeArrayForInterestDetails
                                  // }
                                  PaymentOrderDetails={CreatePaymentOrder}
                                />
                              </Box>
                            </>
                          </Box>
                          <Box display={"flex"} justifyContent={"start"}>
                            <Stack
                              direction={"row"}
                              display={"flex"}
                              spacing={2}
                            >
                              <Box>
                                <LoadingButton
                                  loading={loading}
                                  size="small"
                                  onClick={draftRecievableupdate}
                                  disabled={draftBtnDisabled}
                                  sx={{
                                    textTransform: "none",
                                    maxWidth: "150px",
                                    fontSize: "11px",
                                    backgroundColor: draftBtnDisabled
                                      ? "rgba(145, 158, 171, 0.24)"
                                      : "#303981",
                                    fontFamily: "Public Sans",
                                    fontWeight: "400",
                                    borderRadius: "8px",
                                    color: draftBtnDisabled ? "" : "white",
                                    "&:hover": {
                                      backgroundColor: draftBtnDisabled
                                        ? "rgba(145, 158, 171, 0.24)"
                                        : "#303981",
                                    },
                                    py: 1,
                                    px: 4,
                                    mt: 1,
                                  }}
                                >
                                  Continue
                                </LoadingButton>
                              </Box>
                              {draftBtnDisabled ? (
                                <Box>
                                  <Alert
                                    sx={{
                                      backgroundColor: "white",
                                      "& .MuiAlert-icon": {
                                        color: "#36B37E",
                                      },
                                    }}
                                    severity="success"
                                  >
                                    {" "}
                                    <Typography
                                      fontWeight={600}
                                      fontSize={13}
                                      fontFamily={"Public Sans"}
                                      color={"#36B37E"}
                                    >
                                      {" "}
                                      Your receivables are initiating
                                      successfully !
                                    </Typography>
                                  </Alert>
                                </Box>
                              ) : (
                                ""
                              )}
                            </Stack>
                          </Box>
                        </>
                      ) : (
                        ""
                      )}

                      {paymentInitiateStatus ? (
                        <>
                          <Stack
                            pt={2}
                            display={"flex"}
                            direction={"row"}
                            spacing={5}
                          >
                            <Box>
                              <Stack>
                                <Typography12 text="Total Receivable Used"></Typography12>
                                <Typography16
                                  color={"#303981"}
                                  fontWeight={700}
                                  text={0}
                                ></Typography16>
                              </Stack>
                            </Box>
                            <Box>
                              <Stack>
                                <Typography12 text="Available Balance Used"></Typography12>
                                <Typography16
                                  fontWeight={700}
                                  color={"#303981"}
                                  text={
                                    numberFormat(
                                      CreatePaymentOrder.useBankBalance
                                    ) || 0
                                  }
                                ></Typography16>
                              </Stack>
                            </Box>
                            <Box>
                              <Stack>
                                <Typography12 text="Total Balance"></Typography12>
                                <Typography16
                                  fontWeight={700}
                                  color={"green"}
                                  text={
                                    numberFormat(
                                      CreatePaymentOrder.useBankBalance
                                    ) || 0
                                  }
                                ></Typography16>
                              </Stack>
                            </Box>
                          </Stack>
                        </>
                      ) : (
                        ""
                      )}
                      {interestDetailsArray.length > 0 ? (
                        <Box pt={2}>
                          <PaymentInterestDetails
                            props={interestDetailsArray}
                          />
                        </Box>
                      ) : (
                        ""
                      )}
                      <Stack sx={{ py: 2.5 }}>
                        {" "}
                        <TextField
                          label="Description"
                          type="text"
                          name="description"
                          onChange={createOrderForPayment}
                          fullWidth
                          multiline
                          minRows={3}
                          rows={3}
                          InputProps={{
                            style: {
                              borderRadius: "8px",
                              borderColor: "#919EAB",
                            },
                          }}
                          sx={{
                            maxWidth: "50%",
                            "& .MuiInputBase-root": {
                              height: "fit-content",
                              alignItems: "start",
                            },
                            "& .MuiFormLabel-root": {
                              color: "#919EAB",
                              fontFamily: "Public Sans",
                              fontWeight: "500",
                              fontSize: "14px",
                            },
                          }}
                        />
                        <Stack direction={"row"} py={0.5} spacing={0.5}>
                          <img src={ic_info} height={20} width={20} alt="" />
                          <Typography12
                            fontSize={11}
                            text={"Caption text, description, notification"}
                            fontWeight="400"
                          ></Typography12>
                        </Stack>
                      </Stack>

                      <Box px={8} display={"flex"} justifyContent={"end"}>
                        <Stack
                          mt={4}
                          display={"flex"}
                          direction={"row"}
                          spacing={2}
                        >
                          <Button
                            size="small"
                            onClick={resetOnClickcreatePaymentValue}
                            sx={{
                              color: "grey",
                              px: 2,
                              borderRadius: "8px",
                              textAlign: "right",
                              fontFamily: "Public Sans",
                              textTransform: "none",
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            // onClick={HandleonClickCreatePayment}
                            onClick={confirmPaymentOrder}
                            size="small"
                            disabled={makeFullPaymentValidation(
                              interestDetailsArray
                            )}
                            sx={{
                              color: makeFullPaymentValidation(
                                interestDetailsArray
                              )
                                ? ""
                                : "white",
                              px: 2,
                              borderRadius: "8px",
                              backgroundColor: makeFullPaymentValidation(
                                interestDetailsArray
                              )
                                ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                                : "#36B37E",
                              textAlign: "right",
                              fontFamily: "Public Sans",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: makeFullPaymentValidation(
                                  interestDetailsArray
                                )
                                  ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                                  : "#36B37E",
                              },
                            }}
                          >
                            Make Payment
                          </Button>
                        </Stack>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                </>
                <>
                  {PaymentMethod === "partial" ? (
                    // Select Milestone Value 2 or 3
                    <>
                      <>
                        {" "}
                        <Box>
                          <Stack mb={2}>
                            <FormControl sx={{ maxWidth: "50%" }}>
                              <InputLabel>
                                {" "}
                                <Typography14
                                  text={"Select Milestone"}
                                  fontWeight={400}
                                  color="#919EAB"
                                ></Typography14>{" "}
                              </InputLabel>
                              <Select
                                sx={{ borderRadius: "8px" }}
                                value={partialPaymentOption}
                                // disabled={
                                //  (parseInt(partialPaymentDays.paymentDays1)) > 0
                                //     ? true
                                //     : false
                                // }
                                label="Select Milestone"
                                onChange={selectMilestones}
                              >
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                              </Select>
                            </FormControl>
                          </Stack>
                        </Box>
                        {/* Milestone Selection Functionality */}
                        <Box display={"flex"} justifyContent={"start"}>
                          <Stack display={"flex"} direction={"row"} spacing={2}>
                            {!disableMilestones ? (
                              <LoadingButton
                                loading={loading}
                                size="medium"
                                onClick={disabledMilestoneSelection}
                                disabled={disableMilestones}
                                sx={{
                                  textTransform: "none",
                                  fontSize: "12px",
                                  backgroundColor: disableMilestones
                                    ? "rgba(145, 158, 171, 0.24)"
                                    : "#303981",
                                  fontFamily: "Public Sans",
                                  fontWeight: "400",
                                  borderRadius: "8px",
                                  color: disableMilestones ? "" : "white",
                                  "&:hover": {
                                    backgroundColor: disableMilestones
                                      ? "rgba(145, 158, 171, 0.24)"
                                      : "#303981",
                                  },
                                  py: 1,
                                  px: 4,
                                  mt: 1,
                                }}
                              >
                                Continue
                              </LoadingButton>
                            ) : (
                              <LoadingButton
                                loading={loading}
                                size="medium"
                                onClick={resetDisabledMilestone}
                                disabled={!disableMilestones}
                                sx={{
                                  textTransform: "none",
                                  fontSize: "12px",
                                  backgroundColor: !disableMilestones
                                    ? "rgba(145, 158, 171, 0.24)"
                                    : "#303981",
                                  fontFamily: "Public Sans",
                                  fontWeight: "400",
                                  borderRadius: "8px",
                                  color: !disableMilestones ? "" : "white",
                                  "&:hover": {
                                    backgroundColor: !disableMilestones
                                      ? "rgba(145, 158, 171, 0.24)"
                                      : "#303981",
                                  },
                                  py: 1,
                                  px: 4,
                                  mt: 1,
                                }}
                              >
                                Reset
                              </LoadingButton>
                            )}

                            {disableMilestones ? (
                              <Box>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  sx={{ mt: 1, mx: 1 }}
                                >
                                  <>
                                    <DoneIcon
                                      style={{
                                        fontSize: "22px",
                                        color: "#36B37E",
                                      }}
                                    />{" "}
                                    <Typography
                                      fontWeight={600}
                                      fontSize={13}
                                      fontFamily={"Public Sans"}
                                      color={"#36B37E"}
                                    >
                                      {" "}
                                      This order will initiate successfully into{" "}
                                      {parseInt(partialPaymentOption)}{" "}
                                      milestones.
                                    </Typography>
                                  </>
                                </Stack>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Stack>
                        </Box>
                      </>

                      <></>
                      {/* End of partial payment Days Selection */}
                      {disableMilestones ? (
                        <>
                          {/* Stack Choose Payment Options */}
                          <Stack mt={2}>
                            <Typography14
                              fontWeight={500}
                              color="#000"
                              text="Choose Payment Options"
                            ></Typography14>
                          </Stack>

                          {/* Milestones Selection Tabs */}
                          <Box
                            mt={2}
                            sx={{ backgroundColor: "#F7F9FB" }}
                            borderRadius={"16px"}
                            border={
                              "1px dashed var(--Other-_docs-Wrap, rgba(145, 158, 171, 0.32))"
                            }
                          >
                            <Tabs
                              value={milestoneSelectValue}
                              onChange={(event: any, newValue: number) =>
                                setmilestoneSelectValue(newValue)
                              }
                              sx={{
                                backgroundColor: "white",
                                // px: 2,
                                "& .MuiTabs-indicator": {
                                  width: "auto",
                                  backgroundColor: "rgba(48, 57, 129, 1)",
                                  ml: 1,
                                },
                                "& .MuiTab-textColorPrimary": {
                                  color: "rgba(33, 43, 54, 1)",
                                },
                                "& .MuiTab-textColorSecondary": {
                                  color: "rgba(33, 43, 54, 1)",
                                },
                              }}
                            >
                              <Tab
                                icon={
                                  <VerifiedIcon
                                    sx={{
                                      color:
                                        partialPaymentDays.useBankBalance2 > 0
                                          ? "green"
                                          : "grey",
                                    }}
                                  />
                                }
                                iconPosition="end"
                                label={"Milestone 1"}
                                value={1}
                                sx={{
                                  "&.MuiTab-labelIcon": {
                                    fontSize: "13px",
                                    fontWeight: "600",
                                    fontFamily: "Public Sans",
                                    textTransform: "none",
                                  },
                                }}
                              />
                              <Tab
                                icon={
                                  <VerifiedIcon
                                    sx={{
                                      color:
                                        partialPaymentDays.useBankBalance2 > 0
                                          ? "green"
                                          : "grey",
                                    }}
                                  />
                                }
                                iconPosition="end"
                                value={2}
                                label="Milestone 2"
                                sx={{
                                  "&.MuiTab-labelIcon": {
                                    fontSize: "13px",
                                    fontWeight: "600",
                                    fontFamily: "Public Sans",
                                    textTransform: "none",
                                  },
                                }}
                              />
                              {parseInt(partialPaymentOption) === 3 ? (
                                <Tab
                                  icon={
                                    <VerifiedIcon
                                      sx={{
                                        color:
                                          partialPaymentDays.useBankBalance3 > 0
                                            ? "green"
                                            : "grey",
                                      }}
                                    />
                                  }
                                  iconPosition="end"
                                  value={3}
                                  label="Milestone 3"
                                  sx={{
                                    "&.MuiTab-labelIcon": {
                                      fontSize: "13px",
                                      fontWeight: "600",
                                      fontFamily: "Public Sans",
                                      textTransform: "none",
                                    },
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </Tabs>
                            <Stack
                              display={"flex"}
                              py={3}
                              mx={3}
                              direction={"row"}
                              spacing={3}
                            >
                              <Box>
                                <Typography14 text="Available Balance"></Typography14>
                                <Typography16
                                  color={"#303981"}
                                  text={
                                    BankBalanceData &&
                                    BankBalanceData.availableBalance
                                      ? numberFormat(
                                          BankBalanceData.availableBalance
                                        )
                                      : 0
                                  }
                                ></Typography16>
                              </Box>
                              <Box>
                                <Typography14 text="Used Receivables"></Typography14>
                                <Typography16
                                  color={
                                    usedReceivableAmtFull(fetchRecievalbles) > 0
                                      ? "green"
                                      : "#303981"
                                  }
                                  text={usedReceivableAmtFull(
                                    fetchRecievalbles
                                  )}
                                ></Typography16>
                              </Box>
                              <Box>
                                <Typography14 text="Total Receivables"></Typography14>
                                <Typography16
                                  color={"#303981"}
                                  text={usedReceivableAmtFull(
                                    fetchRecievalbles
                                  )}
                                ></Typography16>
                              </Box>
                            </Stack>

                            <Box mx={2} my={2}>
                              <Stack>
                                {milestoneSelectValue === 1 ? (
                                  <>
                                    <Box
                                      flexGrow={1}
                                      py={1}
                                      px={2}
                                      mb={2}
                                      sx={{
                                        backgroundColor: "#F9FAFB",
                                        border:
                                          "1px solid var(--Grey-300, #DFE3E8)",
                                        borderRadius: "7px",
                                      }}
                                    >
                                      <>
                                        {/* partial Box 1 */}
                                        <Stack
                                          display={"flex"}
                                          direction={"row"}
                                          spacing={5}
                                          maxWidth={"100%"}
                                          my={2}
                                        >
                                          <Stack width={"50%"}>
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={["DatePicker"]}
                                              >
                                                <DatePicker
                                                  label="Payment Date"
                                                  format="DD/MM/YYYY"
                                                  disablePast
                                                  minDate={minDaysValidation1}
                                                  maxDate={maxDaysValidation1}
                                                  sx={{
                                                    style: {
                                                      borderRadius: "8px",
                                                    },
                                                  }}
                                                  value={
                                                    partialPaymentDays.selectedDate1 !==
                                                    ""
                                                      ? dayjs(
                                                          partialPaymentDays.selectedDate1
                                                        )
                                                      : dayjs(new Date()).add(
                                                          7,
                                                          "day"
                                                        )
                                                  }
                                                  onChange={(value: any) =>
                                                    handleonChangeForDate1(
                                                      moment(
                                                        value ? value.$d : ""
                                                      ).format("YYYY/MM/DD")
                                                    )
                                                  }
                                                  slotProps={{
                                                    textField: {
                                                      size: "small",
                                                      helperText:
                                                        partialPaymentDays.milestonePaymentDays1 !==
                                                        ""
                                                          ? `You have selected ${
                                                              partialPaymentDays.milestonePaymentDays1
                                                            } days for payment configuration from ${new Date().toDateString()}`
                                                          : "Payment Days should between 7 to 180 days.",
                                                      sx: {
                                                        "& .MuiInputBase-root":
                                                          {
                                                            height: 50,
                                                            borderRadius: "8px",
                                                          },
                                                        "& .MuiFormLabel-root":
                                                          {
                                                            color: "#919EAB",
                                                            fontFamily:
                                                              "Public Sans",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            mt: 0.2,
                                                          },
                                                      },
                                                      style: {
                                                        borderRadius: "16px",
                                                        borderColor: "#919EAB",
                                                        width: "100%",
                                                        marginTop: 0,
                                                      },
                                                    },
                                                  }}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          </Stack>
                                          <Stack width={"50%"}>
                                            <Stack
                                              direction={"row"}
                                              spacing={3}
                                              mt={1}
                                            >
                                              <TextField
                                                label="Amount"
                                                size="medium"
                                                type="number"
                                                name="milestoneAmount1"
                                                fullWidth
                                                helperText={
                                                  parseInt(
                                                    partialPaymentDays.milestoneAmount1
                                                  ) > 0
                                                    ? numberToEnglish(
                                                        parseInt(
                                                          partialPaymentDays.milestoneAmount1
                                                        )
                                                      )
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      numberToEnglish(
                                                        parseInt(
                                                          partialPaymentDays.milestoneAmount1
                                                        )
                                                      ).slice(1)
                                                    : ""
                                                }
                                                className={"hide-spin-buttons"}
                                                value={
                                                  partialPaymentDays.milestoneAmount1 >
                                                  0
                                                    ? partialPaymentDays.milestoneAmount1
                                                    : ""
                                                }
                                                onChange={
                                                  partialPaymentDaysSelection
                                                }
                                                // onBlur={() =>
                                                //   handleChangeAutoCal1(
                                                //     parseInt(partialPaymentDays.milestoneAmount1)
                                                //   )
                                                // }
                                                InputProps={{
                                                  style: {
                                                    borderRadius: "8px",
                                                    borderColor: "#919EAB",
                                                  },
                                                }}
                                                sx={{
                                                  "& .MuiInputBase-root": {
                                                    height: 50,
                                                  },
                                                  "& .MuiFormLabel-root": {
                                                    color: "#919EAB",
                                                    fontFamily: "Public Sans",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    Height: "20px",
                                                  },
                                                }}
                                              />
                                            </Stack>
                                            <Box>
                                              <Stack
                                                display={"flex"}
                                                direction={"row"}
                                                spacing={"auto"}
                                                mt={0.8}
                                              >
                                                {chipPercentage.map(
                                                  (value: any) => (
                                                    <Chip
                                                      size="small"
                                                      key={value.value}
                                                      // disabled={
                                                      //   parseInt(
                                                      //     partialPaymentDays.milestonePaymentDays1
                                                      //   ) > 0
                                                      //     ? false
                                                      //     : true ||
                                                      //       parseInt(
                                                      //         partialPaymentDays.milestoneAmount2
                                                      //       ) > 0
                                                      //     ? true
                                                      //     : false
                                                      // }
                                                      sx={{
                                                        borderRadius: "6px",
                                                        px: {
                                                          sm: 0,
                                                          md: 1,
                                                          lg: 1.5,
                                                        },

                                                        py: 0,
                                                        backgroundColor:
                                                          parseInt(
                                                            partialPaymentDays.milestoneAmount1
                                                          ) > 0 &&
                                                          partialAmt1 ===
                                                            value.value
                                                            ? "rgba(216, 251, 222, 1)"
                                                            : "var(--Grey-300, #DFE3E8)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            parseInt(
                                                              partialPaymentDays.amount1
                                                            ) > 0 &&
                                                            partialAmt1 ===
                                                              value.value
                                                              ? "rgba(216, 251, 222, 1)"
                                                              : "var(--Grey-300, #DFE3E8)",
                                                        },
                                                      }}
                                                      onClick={() => {
                                                        handleOnChangeAmt1Value(
                                                          value.value
                                                        );
                                                      }}
                                                      label={
                                                        <Typography12
                                                          fontSize={10}
                                                          fontWeight={600}
                                                          text={value.label}
                                                          color={
                                                            parseInt(
                                                              partialPaymentDays.milestoneAmount1
                                                            ) > 0 &&
                                                            partialAmt1 ===
                                                              value.value
                                                              ? "#36B37E"
                                                              : ""
                                                          }
                                                        ></Typography12>
                                                      }
                                                    />
                                                  )
                                                )}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Stack>
                                      </>
                                    </Box>
                                    <Stack mt={2} direction={"row"} spacing={2}>
                                      <TextField
                                        label="Use Bank Balance1"
                                        type="number"
                                        size="medium"
                                        name="useBankBalance1"
                                        disabled={
                                          partialPaymentDays.amount1 === 0
                                        }
                                        error={
                                          partialPaymentDays.useBankBalance1 >
                                          partialPaymentDays.amount1
                                        }
                                        helperText={
                                          parseInt(
                                            partialPaymentDays.useBankBalance1
                                          ) > partialPaymentDays.amount1
                                            ? `Bank balance should be smaller than or equal to selected Milestone amount ${partialPaymentDays.amount1}`
                                            : ""
                                        }
                                        // onBlur={
                                        //   interestDetailsArrayPartialPaymentData
                                        // }
                                        className={"hide-spin-buttons"}
                                        value={
                                          partialPaymentDays.useBankBalance1 <=
                                          0
                                            ? ""
                                            : partialPaymentDays.useBankBalance1
                                        }
                                        onChange={partialPaymentDaysSelection}
                                        InputProps={{
                                          style: {
                                            borderRadius: "8px",
                                            borderColor: "#919EAB",
                                          },
                                        }}
                                        sx={{
                                          backgroundColor: "white",
                                          minWidth: "50%",
                                          maxWidth: "50%",
                                          "& .MuiInputBase-root": {
                                            height: 50,
                                          },
                                          "& .MuiFormLabel-root": {
                                            color: "#919EAB",
                                            fontFamily: "Public Sans",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            Height: "20px",
                                          },
                                        }}
                                      />
                                      <Stack
                                        display={"flex"}
                                        direction={"row"}
                                        sx={{
                                          maxWidth: "50%",
                                        }}
                                      >
                                        {/* {((CreatePaymentOrder.useBankBalance===""?0:parseInt(CreatePaymentOrder.useBankBalance))!==(CreatePaymentOrder.amount===""?0:parseInt(CreatePaymentOrder.amount)) && CreatePaymentOrder.useBankBalance!=="" && parseInt(CreatePaymentOrder.useBankBalance))? */}

                                        {(partialPaymentDays.milestoneAmount1 !==
                                          "" &&
                                          parseInt(
                                            partialPaymentDays.milestoneAmount1
                                          )) ===
                                        (partialPaymentDays.useBankBalance1 !==
                                          "" &&
                                          parseInt(
                                            partialPaymentDays.useBankBalance1
                                          )) ? (
                                          <>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"end"}
                                            >
                                              <Stack>
                                                <LoadingButton
                                                  loading={loading}
                                                  size="medium"
                                                  onClick={() =>
                                                    verifyPartialPaymentOrder(0)
                                                  }
                                                  disabled={receivableBtn}
                                                  sx={{
                                                    textTransform: "none",
                                                    fontSize: "13px",
                                                    backgroundColor:
                                                      receivableBtn
                                                        ? "rgba(145, 158, 171, 0.24)"
                                                        : "#303981",
                                                    fontFamily: "Public Sans",
                                                    fontWeight: "400",
                                                    borderRadius: "8px",
                                                    color: receivableBtn
                                                      ? ""
                                                      : "white",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        receivableBtn
                                                          ? "rgba(145, 158, 171, 0.24)"
                                                          : "#303981",
                                                    },

                                                    py: 1.6,
                                                    px: 4,
                                                  }}
                                                >
                                                  Continue
                                                </LoadingButton>
                                              </Stack>
                                            </Box>
                                          </>
                                        ) : (
                                          <>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"start"}
                                            >
                                              <LoadingButton
                                                loading={loading}
                                                size="medium"
                                                onClick={() =>
                                                  FetchReceivablesAndInterestDetails(
                                                    1
                                                  )
                                                }
                                                disabled={receivableBtn}
                                                sx={{
                                                  textTransform: "none",
                                                  fontSize: "11px",
                                                  backgroundColor: receivableBtn
                                                    ? "rgba(145, 158, 171, 0.24)"
                                                    : "#303981",
                                                  fontFamily: "Public Sans",
                                                  fontWeight: "400",
                                                  borderRadius: "8px",
                                                  color: receivableBtn
                                                    ? ""
                                                    : "white",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      receivableBtn
                                                        ? "rgba(145, 158, 171, 0.24)"
                                                        : "#303981",
                                                  },
                                                  py: 1.7,
                                                  px: 4,
                                                  // mt: 1,
                                                }}
                                              >
                                                Fetch Recievable
                                              </LoadingButton>
                                            </Box>
                                            {showFetchReceivables ? (
                                              <Box>
                                                <Stack
                                                  direction="row"
                                                  spacing={0.5}
                                                  sx={{ mt: 1, mx: 1 }}
                                                >
                                                  <>
                                                    <DoneIcon
                                                      style={{
                                                        fontSize: "18px",
                                                        color: "#36B37E",
                                                      }}
                                                    />{" "}
                                                    <Typography
                                                      fontWeight={600}
                                                      fontSize={12}
                                                      fontFamily={"Public Sans"}
                                                      color={"#36B37E"}
                                                    >
                                                      {" "}
                                                      Available Receivable
                                                      Fetched SuccessFully
                                                    </Typography>
                                                  </>
                                                </Stack>
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}

                                        <Box
                                          display={"flex"}
                                          justifyContent={"start"}
                                        ></Box>
                                        {/* } */}
                                      </Stack>
                                      {/* <Box display={"flex"} justifyContent={"end"}>
                                  <LoadingButton
                                    sx={{ px: 4 }}
                                    size="small"
                                    onClick={() =>
                                      FetchReceivablesAndInterestDetails(1)
                                    }
                                    style={{
                                      fontFamily: "Public Sans",
                                      color: "white",
                                      // width: "auto",
                                      fontSize: "12px",
                                      backgroundColor: "#303981",
                                      // : "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))",
                                      textTransform: "none",
                                      borderRadius: "8px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Fetch Receivable
                                  </LoadingButton>
                                </Box> */}
                                    </Stack>
                                  </>
                                ) : (
                                  ""
                                )}

                                {milestoneSelectValue === 2 ? (
                                  <>
                                    <Box
                                      flexGrow={1}
                                      py={2}
                                      px={2}
                                      sx={{
                                        backgroundColor: "#F9FAFB",
                                        border:
                                          "1px solid var(--Grey-300, #DFE3E8)",
                                        borderRadius: "7px",
                                      }}
                                    >
                                      <>
                                        {/* partial box 2 */}
                                        <Stack
                                          display={"flex"}
                                          direction={"row"}
                                          spacing={5}
                                          maxWidth={"100%"}
                                          my={2}
                                        >
                                          {/* <Stack width={"50%"}>
                                        <TextField
                                          label="Payment Days"
                                          size="medium"
                                          type="number"
                                          name="milestonePaymentDays2"
                                          disabled={
                                            parseInt(
                                              partialPaymentDays.milestoneAmount1
                                            ) > 0
                                              ? false
                                              : true
                                          }
                                          helperText={
                                            (parseInt(
                                              partialPaymentDays.milestonePaymentDays2
                                            ) < 7 &&
                                              parseInt(
                                                partialPaymentDays.milestonePaymentDays2
                                              ) > 1) ||
                                            parseInt(
                                              partialPaymentDays.milestonePaymentDays2
                                            ) > 180
                                              ? "Payment days should be greater then 7 and less then 180"
                                              : ""
                                          }
                                          fullWidth
                                          className={"hide-spin-buttons"}
                                          value={
                                            partialPaymentDays.milestonePaymentDays2 >
                                            0
                                              ? minMaxLimit(
                                                  partialPaymentDays.milestonePaymentDays2
                                                )
                                              : ""
                                          }
                                          onChange={partialPaymentDaysSelection}
                                          InputProps={{
                                            style: {
                                              borderRadius: "8px",
                                              borderColor: "#919EAB",
                                            },
                                          }}
                                          sx={{
                                            "& .MuiInputBase-root": {
                                              height: 50,
                                            },
                                            "& .MuiFormLabel-root": {
                                              color: "#919EAB",
                                              fontFamily: "Public Sans",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              Height: "20px",
                                            },
                                          }}
                                        />
                                        <Box pb={1}>
                                          <Stack
                                            display={"flex"}
                                            direction={"row"}
                                            spacing={"auto"}
                                            // maxWidth={"35vw"}
                                            mt={1}
                                          >
                                            {chipData.map((value) => (
                                              <Chip
                                                size="small"
                                                key={value.value}
                                                sx={{
                                                  borderRadius: "6px",
                                                  px: { sm: 0, md: 1, lg: 1.5 },
                                                  py: 0,
                                                  backgroundColor:
                                                    parseInt(
                                                      partialPaymentDays.milestonePaymentDays2
                                                    ) > 0 &&
                                                    partialDays2 === value.value
                                                      ? "rgba(216, 251, 222, 1)"
                                                      : "var(--Grey-300, #DFE3E8)",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      parseInt(
                                                        partialPaymentDays.milestonePaymentDays2
                                                      ) > 0 &&
                                                      partialDays2 ===
                                                        value.value
                                                        ? "rgba(216, 251, 222, 1)"
                                                        : "var(--Grey-300, #DFE3E8)",
                                                  },
                                                }}
                                                onClick={() =>
                                                  handleOnChangeDays2Value(
                                                    value.value
                                                  )
                                                }
                                                label={
                                                  <Typography12
                                                    fontSize={10}
                                                    fontWeight={600}
                                                    text={value.label}
                                                    color={
                                                      parseInt(
                                                        partialPaymentDays.milestonePaymentDays2
                                                      ) > 0 &&
                                                      partialDays2 ===
                                                        value.value
                                                        ? "#36B37E"
                                                        : ""
                                                    }
                                                  ></Typography12>
                                                }
                                              />
                                            ))}
                                          </Stack>
                                        </Box>
                                      </Stack> */}
                                          <Stack width={"50%"}>
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={["DatePicker"]}
                                              >
                                                <DatePicker
                                                  label="Payment Date"
                                                  format="DD/MM/YYYY"
                                                  disablePast
                                                  minDate={minDaysValidation2}
                                                  maxDate={maxDaysValidation2}
                                                  sx={{
                                                    style: {
                                                      borderRadius: "8px",
                                                    },
                                                  }}
                                                  value={
                                                    partialPaymentDays.selectedDate2 !==
                                                    ""
                                                      ? dayjs(
                                                          partialPaymentDays.selectedDate2
                                                        )
                                                      : dayjs(new Date()).add(
                                                          partialPaymentDays.milestonePaymentDays1,
                                                          "day"
                                                        )
                                                  }
                                                  // value={
                                                  //   CreatePaymentOrder
                                                  //     ? dayjs(
                                                  //         CreatePaymentOrder.paymentDays
                                                  //       )
                                                  //     : dayjs("")
                                                  // }
                                                  onChange={(value: any) =>
                                                    handleonChangeForDate2(
                                                      moment(
                                                        value ? value.$d : ""
                                                      ).format("YYYY/MM/DD")
                                                    )
                                                  }
                                                  slotProps={{
                                                    textField: {
                                                      size: "small",
                                                      helperText:
                                                        partialPaymentDays.milestonePaymentDays2 !==
                                                        ""
                                                          ? `You have selected ${
                                                              partialPaymentDays.milestonePaymentDays2
                                                            } days for payment configuration from ${new Date().toDateString()}`
                                                          : "Payment Days should between 7 to 180 days.",
                                                      sx: {
                                                        "& .MuiInputBase-root":
                                                          {
                                                            height: 50,
                                                            borderRadius: "8px",
                                                          },
                                                        "& .MuiFormLabel-root":
                                                          {
                                                            color: "#919EAB",
                                                            fontFamily:
                                                              "Public Sans",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            mt: 0.2,
                                                          },
                                                      },
                                                      style: {
                                                        borderRadius: "16px",
                                                        borderColor: "#919EAB",
                                                        width: "100%",
                                                        // maxWidth: "50%",
                                                        // marginTop: -8,
                                                        marginTop: 0,
                                                        // height: 40,
                                                      },
                                                    },
                                                  }}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                            {/* <TextField
                                          label="Payment Days"
                                          size="medium"
                                          type="number"
                                          name="milestonePaymentDays1"
                                          fullWidth
                                          className={"hide-spin-buttons"}
                                          error={
                                            parseInt(
                                              partialPaymentDays.milestonePaymentDays1
                                            ) < 7 &&
                                            parseInt(
                                              partialPaymentDays.milestonePaymentDays1
                                            ) > 1
                                          }
                                          helperText={
                                            (parseInt(
                                              partialPaymentDays.milestonePaymentDays1
                                            ) < 7 &&
                                              parseInt(
                                                partialPaymentDays.milestonePaymentDays1
                                              ) > 1) ||
                                            parseInt(
                                              partialPaymentDays.milestonePaymentDays1
                                            ) > 180
                                              ? "Payment days should be greater then 7 and less then 180"
                                              : ""
                                          }
                                          value={
                                            partialPaymentDays.milestonePaymentDays1 >
                                            0
                                              ? minMaxLimit(
                                                  partialPaymentDays.milestonePaymentDays1
                                                )
                                              : ""
                                          }
                                          onChange={partialPaymentDaysSelection}
                                          InputProps={{
                                            style: {
                                              borderRadius: "8px",
                                              borderColor: "#919EAB",
                                            },
                                          }}
                                          sx={{
                                            "& .MuiInputBase-root": {
                                              height: 50,
                                            },
                                            "& .MuiFormLabel-root": {
                                              color: "#919EAB",
                                              fontFamily: "Public Sans",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              Height: "20px",
                                            },
                                          }}

                                        /> */}

                                            {/* <Box pb={1}>
                                          <Stack
                                            display={"flex"}
                                            direction={"row"}
                                            spacing={"auto"}
                                            mt={1}
                                          >
                                            {chipData.map((value) => (
                                              <Chip
                                                size="small"
                                                key={value.value}
                                                sx={{
                                                  borderRadius: "6px",
                                                  px: { sm: 0, md: 1, lg: 1.5 },
                                                  py: 0,
                                                  backgroundColor:
                                                    parseInt(
                                                      partialPaymentDays.milestonePaymentDays1
                                                    ) > 0 &&
                                                    partialDays1 === value.value
                                                      ? "rgba(216, 251, 222, 1)"
                                                      : "var(--Grey-300, #DFE3E8)",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      parseInt(
                                                        partialPaymentDays.milestonePaymentDays1
                                                      ) > 0 &&
                                                      partialDays1 ===
                                                        value.value
                                                        ? "rgba(216, 251, 222, 1)"
                                                        : "var(--Grey-300, #DFE3E8)",
                                                  },
                                                }}
                                                onClick={() =>
                                                  handleOnChangeDays1Value(
                                                    value.value
                                                  )
                                                }
                                                label={
                                                  <Typography12
                                                    fontSize={10}
                                                    fontWeight={600}
                                                    text={value.label}
                                                    color={
                                                      parseInt(
                                                        partialPaymentDays.milestonePaymentDays1
                                                      ) > 0 &&
                                                      partialDays1 ===
                                                        value.value
                                                        ? "#36B37E"
                                                        : ""
                                                    }
                                                  ></Typography12>
                                                }
                                              />
                                            ))}
                                          </Stack>
                                        </Box> */}
                                          </Stack>
                                          <Stack width={"50%"}>
                                            <Stack
                                              direction={"row"}
                                              spacing={3}
                                              mt={1}
                                            >
                                              <TextField
                                                label="Amount"
                                                size="medium"
                                                type="number"
                                                helperText={
                                                  parseInt(
                                                    partialPaymentDays.milestoneAmount2
                                                  ) > 0
                                                    ? numberToEnglish(
                                                        parseInt(
                                                          partialPaymentDays.milestoneAmount2
                                                        )
                                                      )
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      numberToEnglish(
                                                        parseInt(
                                                          partialPaymentDays.milestoneAmount2
                                                        )
                                                      ).slice(1)
                                                    : ""
                                                }
                                                name="milestoneAmount2"
                                                fullWidth
                                                disabled={
                                                  parseInt(
                                                    partialPaymentOption
                                                  ) === 2
                                                    ? true
                                                    : false
                                                }
                                                className={"hide-spin-buttons"}
                                                value={
                                                  partialPaymentDays.milestoneAmount2 >
                                                  0
                                                    ? partialPaymentDays.milestoneAmount2
                                                    : ""
                                                }
                                                // onBlur={() =>
                                                //   handleChangeAutoCal2(
                                                //     partialPaymentDays.amount2
                                                //   )
                                                // }
                                                onChange={
                                                  partialPaymentDaysSelection
                                                }
                                                InputProps={{
                                                  // inputProps: { min: 7, max: 180 },
                                                  style: {
                                                    borderRadius: "8px",
                                                    borderColor: "#919EAB",
                                                  },
                                                }}
                                                sx={{
                                                  "& .MuiInputBase-root": {
                                                    height: 50,
                                                    // minWidth: "23vw",
                                                    // maxWidth: "35vw",
                                                  },
                                                  "& .MuiFormLabel-root": {
                                                    color: "#919EAB",
                                                    fontFamily: "Public Sans",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    Height: "20px",
                                                  },
                                                }}
                                              />
                                            </Stack>
                                            <Box>
                                              <Stack
                                                display={"flex"}
                                                direction={"row"}
                                                spacing={"auto"}
                                                // maxWidth={"35vw"}
                                                mt={0.8}
                                              >
                                                {chipPercentage.map(
                                                  (value: any) => (
                                                    <Chip
                                                      size="small"
                                                      key={value.value}
                                                      disabled={
                                                        parseInt(
                                                          partialPaymentDays.milestonePaymentDays2
                                                        ) > 0
                                                          ? false
                                                          : true
                                                      }
                                                      sx={{
                                                        px: {
                                                          sm: 0,
                                                          md: 1,
                                                          lg: 1.5,
                                                        },
                                                        py: 0,

                                                        borderRadius: "6px",
                                                        backgroundColor:
                                                          parseInt(
                                                            partialPaymentDays.milestoneAmount2
                                                          ) > 0 &&
                                                          partialAmt2 ===
                                                            value.value
                                                            ? "rgba(216, 251, 222, 1)"
                                                            : "",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            parseInt(
                                                              partialPaymentDays.milestoneAmount2
                                                            ) > 0 &&
                                                            partialAmt2 ===
                                                              value.value
                                                              ? "rgba(216, 251, 222, 1)"
                                                              : "",
                                                        },
                                                      }}
                                                      onClick={() =>
                                                        handleOnChangeAmt2Value(
                                                          value.value
                                                        )
                                                      }
                                                      label={
                                                        <Typography12
                                                          fontSize={11}
                                                          fontWeight={600}
                                                          text={value.label}
                                                          color={
                                                            parseInt(
                                                              partialPaymentDays.milestoneAmount2
                                                            ) > 0 &&
                                                            partialAmt2 ===
                                                              value.value
                                                              ? "#36B37E"
                                                              : ""
                                                          }
                                                        ></Typography12>
                                                      }
                                                    />
                                                  )
                                                )}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Stack>
                                      </>
                                    </Box>
                                    <Stack direction={"row"} spacing={2} mt={1}>
                                      <TextField
                                        label="Bank Balance"
                                        type="number"
                                        size="medium"
                                        name="useBankBalance2"
                                        className={"hide-spin-buttons"}
                                        error={
                                          partialPaymentDays.useBankBalance2 >
                                          partialPaymentDays.amount2
                                        }
                                        helperText={
                                          partialPaymentDays.useBankBalance2 >
                                          partialPaymentDays.amount2
                                            ? "Bank balance should be smaller than or equal to input milestone amount"
                                            : ""
                                        }
                                        value={
                                          partialPaymentDays.useBankBalance2 <=
                                          0
                                            ? ""
                                            : partialPaymentDays.useBankBalance2
                                        }
                                        // onBlur={
                                        //   interestDetailsArrayPartialPaymentData1
                                        // }
                                        onChange={partialPaymentDaysSelection}
                                        InputProps={{
                                          style: {
                                            borderRadius: "8px",
                                            borderColor: "#919EAB",
                                          },
                                        }}
                                        sx={{
                                          minWidth: "50%",
                                          maxWidth: "50%",
                                          backgroundColor: "white",
                                          "& .MuiInputBase-root": {
                                            height: 50,
                                          },
                                          "& .MuiFormLabel-root": {
                                            color: "#919EAB",
                                            fontFamily: "Public Sans",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            Height: "20px",
                                          },
                                        }}
                                      />
                                      <Stack
                                        display={"flex"}
                                        direction={"row"}
                                        sx={{
                                          maxWidth: "50%",
                                        }}
                                      >
                                        {/* {((CreatePaymentOrder.useBankBalance===""?0:parseInt(CreatePaymentOrder.useBankBalance))!==(CreatePaymentOrder.amount===""?0:parseInt(CreatePaymentOrder.amount)) && CreatePaymentOrder.useBankBalance!=="" && parseInt(CreatePaymentOrder.useBankBalance))? */}

                                        {(partialPaymentDays.milestoneAmount2 !==
                                          "" &&
                                          parseInt(
                                            partialPaymentDays.milestoneAmount2
                                          )) ===
                                        (partialPaymentDays.useBankBalance2 !==
                                          "" &&
                                          parseInt(
                                            partialPaymentDays.useBankBalance2
                                          )) ? (
                                          <>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"end"}
                                            >
                                              <Stack>
                                                <LoadingButton
                                                  loading={loading}
                                                  size="medium"
                                                  onClick={() =>
                                                    verifyPartialPaymentOrder(1)
                                                  }
                                                  disabled={receivableBtn}
                                                  sx={{
                                                    textTransform: "none",
                                                    fontSize: "11px",
                                                    backgroundColor:
                                                      receivableBtn
                                                        ? "rgba(145, 158, 171, 0.24)"
                                                        : "#303981",
                                                    fontFamily: "Public Sans",
                                                    fontWeight: "400",
                                                    borderRadius: "8px",
                                                    color: receivableBtn
                                                      ? ""
                                                      : "white",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        receivableBtn
                                                          ? "rgba(145, 158, 171, 0.24)"
                                                          : "#303981",
                                                    },
                                                    py: 1.5,
                                                    px: 4,
                                                    mt: 1,
                                                  }}
                                                >
                                                  Continue
                                                </LoadingButton>
                                              </Stack>
                                            </Box>
                                          </>
                                        ) : (
                                          <>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"start"}
                                            >
                                              <LoadingButton
                                                loading={loading}
                                                size="medium"
                                                onClick={() =>
                                                  FetchReceivablesAndInterestDetails(
                                                    2
                                                  )
                                                }
                                                disabled={receivableBtn}
                                                sx={{
                                                  textTransform: "none",
                                                  fontSize: "11px",
                                                  backgroundColor: receivableBtn
                                                    ? "rgba(145, 158, 171, 0.24)"
                                                    : "#303981",
                                                  fontFamily: "Public Sans",
                                                  fontWeight: "400",
                                                  borderRadius: "8px",
                                                  color: receivableBtn
                                                    ? ""
                                                    : "white",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      receivableBtn
                                                        ? "rgba(145, 158, 171, 0.24)"
                                                        : "#303981",
                                                  },
                                                  py: 1.5,
                                                  px: 4,
                                                  mt: 1,
                                                }}
                                              >
                                                Fetch Recievable
                                              </LoadingButton>
                                            </Box>
                                            {showFetchReceivables ? (
                                              <Box>
                                                <Stack
                                                  direction="row"
                                                  spacing={0.5}
                                                  sx={{ mt: 1, mx: 1 }}
                                                >
                                                  <>
                                                    <DoneIcon
                                                      style={{
                                                        fontSize: "18px",
                                                        color: "#36B37E",
                                                      }}
                                                    />{" "}
                                                    <Typography
                                                      fontWeight={600}
                                                      fontSize={12}
                                                      fontFamily={"Public Sans"}
                                                      color={"#36B37E"}
                                                    >
                                                      {" "}
                                                      Available Receivable
                                                      Fetched SuccessFully
                                                    </Typography>
                                                  </>
                                                </Stack>
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}

                                        <Box
                                          display={"flex"}
                                          justifyContent={"start"}
                                        ></Box>
                                        {/* } */}
                                      </Stack>
                                    </Stack>
                                  </>
                                ) : (
                                  ""
                                )}
                                {milestoneSelectValue === 3 ? (
                                  <>
                                    <Box
                                      flexGrow={1}
                                      py={2}
                                      px={2}
                                      sx={{
                                        backgroundColor: "#F9FAFB",
                                        border:
                                          "1px solid var(--Grey-300, #DFE3E8)",
                                        borderRadius: "7px",
                                      }}
                                    >
                                      {/* partial Box 3 */}
                                      <>
                                        <Stack
                                          display={"flex"}
                                          direction={"row"}
                                          spacing={5}
                                          maxWidth={"100%"}
                                          my={2}
                                        >
                                          {/* <Stack width={"50%"}>
                                        <TextField
                                          label="Payment Days"
                                          size="medium"
                                          type="number"
                                          name="milestonePaymentDays3"
                                          fullWidth
                                          className={"hide-spin-buttons"}
                                          disabled={
                                            parseInt(
                                              partialPaymentDays.milestoneAmount3
                                            ) > 0
                                              ? false
                                              : true
                                          }
                                          helperText={
                                            (parseInt(
                                              partialPaymentDays.milestonePaymentDays3
                                            ) < 7 &&
                                              parseInt(
                                                partialPaymentDays.milestonePaymentDays3
                                              ) > 1) ||
                                            parseInt(
                                              partialPaymentDays.milestonePaymentDays3
                                            ) > 180
                                              ? "Payment days should be greater then 7 and less then 180"
                                              : ""
                                          }
                                          value={
                                            partialPaymentDays.milestonePaymentDays3 >
                                            0
                                              ? minMaxLimit(
                                                  partialPaymentDays.milestonePaymentDays3
                                                )
                                              : ""
                                          }
                                          onChange={partialPaymentDaysSelection}
                                          InputProps={{
                                            style: {
                                              borderRadius: "8px",
                                              borderColor: "#919EAB",
                                            },
                                          }}
                                          sx={{
                                            "& .MuiInputBase-root": {
                                              height: 50,
                                            },
                                            "& .MuiFormLabel-root": {
                                              color: "#919EAB",
                                              fontFamily: "Public Sans",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              Height: "20px",
                                            },
                                          }}
                                        />
                                        <Box pb={1}>
                                          <Stack
                                            display={"flex"}
                                            direction={"row"}
                                            spacing={"auto"}
                                            mt={1}
                                          >
                                            {chipData.map((value) => (
                                              <Chip
                                                size="small"
                                                disabled={
                                                  parseInt(
                                                    partialPaymentDays.milestoneAmount2
                                                  ) > 0
                                                    ? false
                                                    : true
                                                }
                                                key={value.value}
                                                sx={{
                                                  borderRadius: "6px",
                                                  px: {
                                                    sm: 0,
                                                    md: 1,
                                                    lg: 1.5,
                                                  },
                                                  py: 0,
                                                  backgroundColor:
                                                    parseInt(
                                                      partialPaymentDays.milestonePaymentDays3
                                                    ) > 0 &&
                                                    partialDays3 === value.value
                                                      ? "rgba(216, 251, 222, 1)"
                                                      : "var(--Grey-300, #DFE3E8)",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      parseInt(
                                                        partialPaymentDays.milestonePaymentDays3
                                                      ) > 0 &&
                                                      partialDays3 ===
                                                        value.value
                                                        ? "rgba(216, 251, 222, 1)"
                                                        : "var(--Grey-300, #DFE3E8)",
                                                  },
                                                }}
                                                onClick={() =>
                                                  handleOnChangeDays3Value(
                                                    value.value
                                                  )
                                                }
                                                label={
                                                  <Typography12
                                                    fontSize={10}
                                                    fontWeight={600}
                                                    text={value.label}
                                                    color={
                                                      parseInt(
                                                        partialPaymentDays.milestonePaymentDays3
                                                      ) > 0 &&
                                                      partialDays3 ===
                                                        value.value
                                                        ? "#36B37E"
                                                        : ""
                                                    }
                                                  ></Typography12>
                                                }
                                              />
                                            ))}
                                          </Stack>
                                        </Box>
                                      </Stack> */}
                                          <Stack width={"50%"}>
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <DemoContainer
                                                components={["DatePicker"]}
                                              >
                                                <DatePicker
                                                  label="Payment Date"
                                                  format="DD/MM/YYYY"
                                                  disablePast
                                                  minDate={minDaysValidation3}
                                                  maxDate={maxDaysValidation3}
                                                  sx={{
                                                    style: {
                                                      borderRadius: "8px",
                                                    },
                                                  }}
                                                  // value={
                                                  //   CreatePaymentOrder
                                                  //     ? dayjs(
                                                  //         CreatePaymentOrder.paymentDays
                                                  //       )
                                                  //     : dayjs("")
                                                  // }
                                                  value={
                                                    partialPaymentDays.selectedDate3 !==
                                                    ""
                                                      ? dayjs(
                                                          partialPaymentDays.selectedDate3
                                                        )
                                                      : dayjs(new Date()).add(
                                                          partialPaymentDays.milestonePaymentDays2,
                                                          "day"
                                                        )
                                                  }
                                                  onChange={(value: any) =>
                                                    handleonChangeForDate3(
                                                      moment(
                                                        value ? value.$d : ""
                                                      ).format("YYYY/MM/DD")
                                                    )
                                                  }
                                                  slotProps={{
                                                    textField: {
                                                      size: "small",
                                                      helperText:
                                                        partialPaymentDays.milestonePaymentDays3 !==
                                                        ""
                                                          ? `You have selected ${
                                                              partialPaymentDays.milestonePaymentDays3
                                                            } days for payment configuration from ${new Date().toDateString()}.`
                                                          : "Payment Days should between 7 to 180 days.",
                                                      sx: {
                                                        "& .MuiInputBase-root":
                                                          {
                                                            height: 50,
                                                            borderRadius: "8px",
                                                          },
                                                        "& .MuiFormLabel-root":
                                                          {
                                                            color: "#919EAB",
                                                            fontFamily:
                                                              "Public Sans",
                                                            fontWeight: "500",
                                                            fontSize: "14px",
                                                            mt: 0.2,
                                                          },
                                                      },
                                                      style: {
                                                        borderRadius: "16px",
                                                        borderColor: "#919EAB",
                                                        width: "100%",
                                                        // maxWidth: "50%",
                                                        // marginTop: -8,
                                                        marginTop: 0,
                                                        // height: 40,
                                                      },
                                                    },
                                                  }}
                                                />
                                              </DemoContainer>
                                            </LocalizationProvider>
                                          </Stack>
                                          <Stack width={"50%"}>
                                            <Stack
                                              direction={"row"}
                                              spacing={3}
                                              mt={1}
                                            >
                                              <TextField
                                                label="Amount"
                                                size="medium"
                                                type="number"
                                                name="milestoneAmount3"
                                                fullWidth
                                                helperText={
                                                  parseInt(
                                                    partialPaymentDays.milestoneAmount3
                                                  ) > 0
                                                    ? numberToEnglish(
                                                        parseInt(
                                                          partialPaymentDays.milestoneAmount3
                                                        )
                                                      )
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      numberToEnglish(
                                                        parseInt(
                                                          partialPaymentDays.milestoneAmount3
                                                        )
                                                      ).slice(1)
                                                    : ""
                                                }
                                                className={"hide-spin-buttons"}
                                                disabled={
                                                  parseInt(
                                                    partialPaymentDays.milestonePaymentDays3
                                                  ) > 0
                                                    ? false
                                                    : true
                                                }
                                                value={
                                                  partialPaymentDays.milestoneAmount3 >
                                                  0
                                                    ? partialPaymentDays.milestoneAmount3
                                                    : ""
                                                }
                                                onChange={
                                                  partialPaymentDaysSelection
                                                }
                                                InputProps={{
                                                  // inputProps: { min: 7, max: 180 },
                                                  style: {
                                                    borderRadius: "8px",
                                                    borderColor: "#919EAB",
                                                  },
                                                }}
                                                sx={{
                                                  "& .MuiInputBase-root": {
                                                    height: 50,
                                                    // minWidth: "23vw",
                                                    // maxWidth: "35vw",
                                                  },
                                                  "& .MuiFormLabel-root": {
                                                    color: "#919EAB",
                                                    fontFamily: "Public Sans",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    Height: "20px",
                                                  },
                                                }}
                                              />
                                            </Stack>
                                            <Box>
                                              <Stack
                                                display={"flex"}
                                                direction={"row"}
                                                spacing={"auto"}
                                                // maxWidth={"35vw"}
                                                mt={0.8}
                                              >
                                                {chipPercentage.map(
                                                  (value: any) => (
                                                    <Chip
                                                      size="small"
                                                      key={value.value}
                                                      disabled={
                                                        parseInt(
                                                          partialPaymentDays.milestonePaymentDays3
                                                        ) > 0
                                                          ? false
                                                          : true
                                                      }
                                                      sx={{
                                                        borderRadius: "6px",
                                                        px: {
                                                          sm: 0,
                                                          md: 1,
                                                          lg: 1.5,
                                                        },
                                                        py: 0,
                                                        backgroundColor:
                                                          parseInt(
                                                            partialPaymentDays.milestoneAmount3
                                                          ) > 0 &&
                                                          partialAmt3 ===
                                                            value.value
                                                            ? "rgba(216, 251, 222, 1)"
                                                            : "var(--Grey-300, #DFE3E8)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            parseInt(
                                                              partialPaymentDays.milestoneAmount3
                                                            ) > 0 &&
                                                            partialAmt3 ===
                                                              value.value
                                                              ? "rgba(216, 251, 222, 1)"
                                                              : "var(--Grey-300, #DFE3E8)",
                                                        },
                                                      }}
                                                      onClick={() =>
                                                        handleOnChangeAmt3Value(
                                                          value.value
                                                        )
                                                      }
                                                      label={
                                                        <Typography12
                                                          fontSize={11}
                                                          fontWeight={700}
                                                          text={value.label}
                                                          color={
                                                            parseInt(
                                                              partialPaymentDays.milestoneAmount3
                                                            ) > 0 &&
                                                            partialAmt3 ===
                                                              value.value
                                                              ? "#36B37E"
                                                              : ""
                                                          }
                                                        ></Typography12>
                                                      }
                                                    />
                                                  )
                                                )}
                                              </Stack>
                                            </Box>
                                          </Stack>
                                        </Stack>
                                      </>
                                    </Box>
                                    <Stack direction={"row"} spacing={2} my={3}>
                                      <TextField
                                        label="Bank Balance"
                                        type="text"
                                        size="medium"
                                        name="useBankBalance3"
                                        error={
                                          partialPaymentDays.useBankBalance3 >
                                          partialPaymentDays.amount3
                                        }
                                        // helperText={
                                        //   partialPaymentDays.useBankBalance3 >
                                        //   partialPaymentDays.amount3
                                        //     ? "Bank balance should be smaller than or equal to input milestone amount"
                                        //     : ""
                                        // }
                                        className={"hide-spin-buttons"}
                                        value={
                                          partialPaymentDays.useBankBalance3 <=
                                          0
                                            ? ""
                                            : partialPaymentDays.useBankBalance3
                                        }
                                        onChange={partialPaymentDaysSelection}
                                        // onBlur={
                                        //   interestDetailsArrayPartialPaymentData2
                                        // }
                                        InputProps={{
                                          style: {
                                            borderRadius: "8px",
                                            borderColor: "#919EAB",
                                          },
                                        }}
                                        sx={{
                                          backgroundColor: "white",
                                          minWidth: "50%",
                                          maxWidth: "50%",
                                          "& .MuiInputBase-root": {
                                            height: 50,
                                          },
                                          "& .MuiFormLabel-root": {
                                            color: "#919EAB",
                                            fontFamily: "Public Sans",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            Height: "20px",
                                          },
                                        }}
                                      />

                                      <Stack
                                        display={"flex"}
                                        direction={"row"}
                                        sx={{
                                          maxWidth: "50%",
                                        }}
                                      >
                                        {/* {((CreatePaymentOrder.useBankBalance===""?0:parseInt(CreatePaymentOrder.useBankBalance))!==(CreatePaymentOrder.amount===""?0:parseInt(CreatePaymentOrder.amount)) && CreatePaymentOrder.useBankBalance!=="" && parseInt(CreatePaymentOrder.useBankBalance))? */}

                                        {(partialPaymentDays.milestoneAmount3 !==
                                          "" &&
                                          parseInt(
                                            partialPaymentDays.milestoneAmount3
                                          )) ===
                                        (partialPaymentDays.useBankBalance3 !==
                                          "" &&
                                          parseInt(
                                            partialPaymentDays.useBankBalance3
                                          )) ? (
                                          <>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"end"}
                                            >
                                              <Stack>
                                                <LoadingButton
                                                  loading={loading}
                                                  size="medium"
                                                  onClick={() =>
                                                    verifyPartialPaymentOrder(2)
                                                  }
                                                  disabled={receivableBtn}
                                                  sx={{
                                                    textTransform: "none",
                                                    fontSize: "13px",
                                                    backgroundColor:
                                                      receivableBtn
                                                        ? "rgba(145, 158, 171, 0.24)"
                                                        : "#303981",
                                                    fontFamily: "Public Sans",
                                                    fontWeight: "400",
                                                    borderRadius: "8px",
                                                    color: receivableBtn
                                                      ? ""
                                                      : "white",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        receivableBtn
                                                          ? "rgba(145, 158, 171, 0.24)"
                                                          : "#303981",
                                                    },
                                                    py: 1.7,
                                                    px: 4,
                                                    // mt: 1,
                                                  }}
                                                >
                                                  Continue
                                                </LoadingButton>
                                              </Stack>
                                            </Box>
                                          </>
                                        ) : (
                                          <>
                                            <Box
                                              display={"flex"}
                                              justifyContent={"start"}
                                            >
                                              <LoadingButton
                                                loading={loading}
                                                size="medium"
                                                onClick={() =>
                                                  FetchReceivablesAndInterestDetails(
                                                    3
                                                  )
                                                }
                                                disabled={receivableBtn}
                                                sx={{
                                                  textTransform: "none",
                                                  fontSize: "11px",
                                                  backgroundColor: receivableBtn
                                                    ? "rgba(145, 158, 171, 0.24)"
                                                    : "#303981",
                                                  fontFamily: "Public Sans",
                                                  fontWeight: "400",
                                                  borderRadius: "8px",
                                                  color: receivableBtn
                                                    ? ""
                                                    : "white",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      receivableBtn
                                                        ? "rgba(145, 158, 171, 0.24)"
                                                        : "#303981",
                                                  },
                                                  py: 1.7,
                                                  px: 4,
                                                }}
                                              >
                                                Fetch Recievable
                                              </LoadingButton>
                                            </Box>
                                            {showFetchReceivables ? (
                                              <Box>
                                                <Stack
                                                  direction="row"
                                                  spacing={0.5}
                                                  sx={{ mt: 1, mx: 1 }}
                                                >
                                                  <>
                                                    <DoneIcon
                                                      style={{
                                                        fontSize: "18px",
                                                        color: "#36B37E",
                                                      }}
                                                    />{" "}
                                                    <Typography
                                                      fontWeight={600}
                                                      fontSize={12}
                                                      fontFamily={"Public Sans"}
                                                      color={"#36B37E"}
                                                    >
                                                      {" "}
                                                      Available Receivable
                                                      Fetched SuccessFully
                                                    </Typography>
                                                  </>
                                                </Stack>
                                              </Box>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}

                                        <Box
                                          display={"flex"}
                                          justifyContent={"start"}
                                        ></Box>
                                        {/* } */}
                                      </Stack>
                                    </Stack>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Stack>
                            </Box>
                            {milestoneSelectValue === 1 &&
                            fetchReceivable1.length > 0 ? (
                              <Box>
                                {" "}
                                <>
                                  <Tabs
                                    value={recievableType}
                                    onChange={modifyRecievableType}
                                    sx={{
                                      "& .MuiTabs-indicator": {
                                        width: "auto",
                                        maxWidth: "145px",
                                        mx: 3,
                                        backgroundColor: "rgba(48, 57, 129, 1)",
                                      },
                                      "& .MuiTab-textColorPrimary": {
                                        color: "rgba(33, 43, 54, 1)",
                                      },
                                      "& .MuiTab-textColorSecondary": {
                                        color: "rgba(33, 43, 54, 1)",
                                      },
                                    }}
                                  >
                                    <Tab
                                      icon={<ArticleIcon fontSize="small" />}
                                      iconPosition="start"
                                      label="Used Receivables"
                                      value={1}
                                      sx={{
                                        "&.MuiTab-labelIcon": {
                                          fontSize: "13px",
                                          fontWeight: "600",
                                          fontFamily: "Public Sans",
                                          textTransform: "none",
                                        },
                                      }}
                                    />
                                  </Tabs>
                                  <Box
                                    mx={3}
                                    boxShadow={
                                      "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
                                    }
                                    borderRadius={"16px"}
                                    sx={{
                                      backgroundColor:
                                        "var(--Background-Light-Paper, #FFF)",
                                    }}
                                  >
                                    <>
                                      {" "}
                                      <Box
                                        sx={{
                                          mt: 3,
                                          borderRadius: "8px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        <UsedRecieveables
                                          props={fetchReceivable1}
                                          PaymentOrderDetails={
                                            CreatePaymentOrder
                                          }
                                        />
                                      </Box>
                                    </>
                                  </Box>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"start"}
                                    px={3}
                                    mt={1.5}
                                    mb={2}
                                  >
                                    <Stack>
                                      <LoadingButton
                                        loading={loading}
                                        size="small"
                                        onClick={() =>
                                          draftRecievableupdate1(
                                            fetchReceivable1,
                                            0
                                          )
                                        }
                                        disabled={draftBtnDisabled1}
                                        sx={{
                                          textTransform: "none",
                                          maxWidth: "150px",
                                          fontSize: "11px",
                                          backgroundColor: draftBtnDisabled1
                                            ? "rgba(145, 158, 171, 0.24)"
                                            : "#303981",
                                          fontFamily: "Public Sans",
                                          fontWeight: "400",
                                          borderRadius: "8px",
                                          color: draftBtnDisabled1
                                            ? ""
                                            : "white",
                                          "&:hover": {
                                            backgroundColor: draftBtnDisabled1
                                              ? "rgba(145, 158, 171, 0.24)"
                                              : "#303981",
                                          },
                                          py: 1,
                                          px: 4,
                                          mt: 1,
                                        }}
                                      >
                                        Continue
                                      </LoadingButton>
                                    </Stack>
                                  </Box>
                                </>
                              </Box>
                            ) : (
                              ""
                            )}
                            {milestoneSelectValue === 2 &&
                            fetchReceivable2.length > 0 ? (
                              <Box>
                                {" "}
                                <>
                                  <Tabs
                                    value={recievableType}
                                    onChange={modifyRecievableType}
                                    sx={{
                                      "& .MuiTabs-indicator": {
                                        width: "auto",
                                        maxWidth: "145px",
                                        mx: 3,
                                        backgroundColor: "rgba(48, 57, 129, 1)",
                                      },
                                      "& .MuiTab-textColorPrimary": {
                                        color: "rgba(33, 43, 54, 1)",
                                      },
                                      "& .MuiTab-textColorSecondary": {
                                        color: "rgba(33, 43, 54, 1)",
                                      },
                                    }}
                                  >
                                    <Tab
                                      icon={<ArticleIcon fontSize="small" />}
                                      iconPosition="start"
                                      label="Used Receivables"
                                      value={1}
                                      sx={{
                                        "&.MuiTab-labelIcon": {
                                          fontSize: "13px",
                                          fontWeight: "600",
                                          fontFamily: "Public Sans",
                                          textTransform: "none",
                                        },
                                      }}
                                    />
                                  </Tabs>
                                  <Box
                                    mx={3}
                                    boxShadow={
                                      "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
                                    }
                                    borderRadius={"16px"}
                                    sx={{
                                      backgroundColor:
                                        "var(--Background-Light-Paper, #FFF)",
                                    }}
                                  >
                                    <>
                                      {" "}
                                      <Box
                                        sx={{
                                          mt: 3,
                                          borderRadius: "8px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        <UsedRecieveables
                                          props={fetchReceivable2}
                                          PaymentOrderDetails={
                                            CreatePaymentOrder
                                          }
                                        />
                                      </Box>
                                    </>
                                  </Box>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"start"}
                                    mt={1.5}
                                    mb={2}
                                    px={3}
                                  >
                                    <Stack>
                                      <LoadingButton
                                        loading={loading}
                                        size="small"
                                        onClick={() =>
                                          draftRecievableupdate1(
                                            fetchReceivable2,
                                            1
                                          )
                                        }
                                        disabled={draftBtnDisabled}
                                        sx={{
                                          textTransform: "none",
                                          maxWidth: "150px",
                                          fontSize: "11px",
                                          backgroundColor: draftBtnDisabled
                                            ? "rgba(145, 158, 171, 0.24)"
                                            : "#303981",
                                          fontFamily: "Public Sans",
                                          fontWeight: "400",
                                          borderRadius: "8px",
                                          color: draftBtnDisabled
                                            ? ""
                                            : "white",
                                          "&:hover": {
                                            backgroundColor: draftBtnDisabled
                                              ? "rgba(145, 158, 171, 0.24)"
                                              : "#303981",
                                          },
                                          py: 1,
                                          px: 4,
                                          mt: 1,
                                        }}
                                      >
                                        Continue
                                      </LoadingButton>
                                    </Stack>
                                  </Box>
                                </>
                              </Box>
                            ) : (
                              ""
                            )}
                            {milestoneSelectValue === 3 &&
                            fetchReceivable3.length > 0 ? (
                              <Box>
                                {" "}
                                <>
                                  <Tabs
                                    value={recievableType}
                                    onChange={modifyRecievableType}
                                    sx={{
                                      "& .MuiTabs-indicator": {
                                        width: "auto",
                                        maxWidth: "145px",
                                        mx: 3,
                                        backgroundColor: "rgba(48, 57, 129, 1)",
                                      },
                                      "& .MuiTab-textColorPrimary": {
                                        color: "rgba(33, 43, 54, 1)",
                                      },
                                      "& .MuiTab-textColorSecondary": {
                                        color: "rgba(33, 43, 54, 1)",
                                      },
                                    }}
                                  >
                                    <Tab
                                      icon={<ArticleIcon fontSize="small" />}
                                      iconPosition="start"
                                      label="Used Receivables"
                                      value={1}
                                      sx={{
                                        "&.MuiTab-labelIcon": {
                                          fontSize: "13px",
                                          fontWeight: "600",
                                          fontFamily: "Public Sans",
                                          textTransform: "none",
                                        },
                                      }}
                                    />
                                  </Tabs>
                                  <Box
                                    mx={3}
                                    boxShadow={
                                      "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
                                    }
                                    borderRadius={"16px"}
                                    sx={{
                                      backgroundColor:
                                        "var(--Background-Light-Paper, #FFF)",
                                    }}
                                  >
                                    <>
                                      {" "}
                                      <Box
                                        sx={{
                                          mt: 3,

                                          borderRadius: "8px",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        <UsedRecieveables
                                          props={fetchReceivable3}
                                          PaymentOrderDetails={
                                            CreatePaymentOrder
                                          }
                                        />
                                      </Box>
                                    </>
                                  </Box>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"start"}
                                    px={3}
                                    mt={1.5}
                                    mb={2}
                                  >
                                    <Stack>
                                      <LoadingButton
                                        loading={loading}
                                        size="small"
                                        onClick={() =>
                                          draftRecievableupdate1(
                                            fetchReceivable3,
                                            2
                                          )
                                        }
                                        disabled={draftBtnDisabled}
                                        sx={{
                                          textTransform: "none",
                                          maxWidth: "150px",
                                          fontSize: "11px",
                                          backgroundColor: draftBtnDisabled
                                            ? "rgba(145, 158, 171, 0.24)"
                                            : "#303981",
                                          fontFamily: "Public Sans",
                                          fontWeight: "400",
                                          borderRadius: "8px",
                                          color: draftBtnDisabled
                                            ? ""
                                            : "white",
                                          "&:hover": {
                                            backgroundColor: draftBtnDisabled
                                              ? "rgba(145, 158, 171, 0.24)"
                                              : "#303981",
                                          },
                                          py: 1,
                                          px: 4,
                                          mt: 1,
                                        }}
                                      >
                                        Continue
                                      </LoadingButton>
                                    </Stack>
                                  </Box>
                                </>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                          {partialInterestDetailsArray.length > 0 ? (
                            <PaymentInterestDetails
                              props={partialInterestDetailsArray}
                            />
                          ) : (
                            ""
                          )}
                          <Stack sx={{ py: 2.5 }}>
                            {" "}
                            <TextField
                              label="Description"
                              type="text"
                              name="description"
                              onChange={createOrderForPayment}
                              fullWidth
                              multiline
                              minRows={3}
                              rows={3}
                              InputProps={{
                                style: {
                                  borderRadius: "8px",
                                  borderColor: "#919EAB",
                                },
                              }}
                              sx={{
                                maxWidth: "50%",
                                "& .MuiInputBase-root": {
                                  height: "fit-content",
                                  alignItems: "start",
                                },
                                "& .MuiFormLabel-root": {
                                  color: "#919EAB",
                                  fontFamily: "Public Sans",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                },
                              }}
                            />
                            <Stack direction={"row"} py={0.5} spacing={0.5}>
                              <img
                                src={ic_info}
                                height={20}
                                width={20}
                                alt=""
                              />
                              <Typography12
                                fontSize={11}
                                text={"Caption text, description, notification"}
                                fontWeight="400"
                              ></Typography12>
                            </Stack>
                          </Stack>

                          <Box px={8} display={"flex"} justifyContent={"end"}>
                            <Stack
                              mt={4}
                              display={"flex"}
                              direction={"row"}
                              spacing={2}
                            >
                              <Button
                                size="small"
                                onClick={resetOnClickCreatePartialPaymentValue}
                                sx={{
                                  color: "grey",
                                  px: 2,
                                  borderRadius: "8px",
                                  textAlign: "right",
                                  fontFamily: "Public Sans",
                                  textTransform: "none",
                                }}
                              >
                                Reset
                              </Button>
                              <Button
                                // onClick={HandleonClickCreatePayment}
                                onClick={confirmPaymentOrder}
                                size="small"
                                // disabled={validateMakePaymentBtn()}
                                sx={{
                                  color:
                                    // validateMakePaymentBtn() ? "" :
                                    "white",
                                  px: 2,
                                  borderRadius: "8px",
                                  backgroundColor:
                                    // validateMakePaymentBtn()
                                    //   ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                                    //   :
                                    "#36B37E",
                                  textAlign: "right",
                                  fontFamily: "Public Sans",
                                  textTransform: "none",
                                  "&:hover": {
                                    backgroundColor:
                                      //  validateMakePaymentBtn()
                                      //   ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                                      //   :
                                      "#36B37E",
                                  },
                                }}
                              >
                                Make Payment
                              </Button>
                            </Stack>
                          </Box>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              </Stack>
              <SimpleLoader props={openSimpleLoader} />
              <PaymentConfirmDialog
                OpenDailog={openAccept}
                ClosePaymentAcceptDialog={ClosePaymentAcceptDialog}
                // VerifyAcceptPaymentLoader={VerifyAcceptPaymentLoader}
                ApprovedPaymentConfigure={HandleonClickCreatePayment}
                TitleText={"Payment Order Confirmation"}
                ConfirmationText={
                  "Are you sure you want to proceed with this order Confirmation ?"
                }
              />
            </Box>
          </Box>
        </Box>
      </>
    </div>
  );
};

export default CreateNewPayment;
