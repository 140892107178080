// These are the protected Routes pages for handling or providing safe accessibility for applications
import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/Header";
import { useProSidebar } from "react-pro-sidebar";

import { AppBar, Box, Grid } from "@mui/material";
import Sidebars from "../components/Sidebars";
import { createTheme, ThemeProvider } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (phones)
      sm: 500, // Small devices (tablets)
      md: 1280, // Medium devices (desktops)
      lg: 1600, // Large devices (desktops)
      xl: 1920, // Extra large devices (large desktops)
    },
  },
});
const DashboardRoutes = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  let newToken: any = useSelector((state: any) =>
    state.user.Token ? state.user.Token : null
  );
  const { collapseSidebar } = useProSidebar();
  const collapsed = useProSidebar().collapsed;
  const Token = localStorage.getItem("Token");
  const [BankBalanceData, setBankBalanceData] = useState<any>({});

  const getBankBalance = async () => {
    let params;
    const axiosConfig = {
      headers: {
        token: newToken ? newToken : "",
      },
      params: params,
    };
    await axios
      .get(
        "https://api.dev.assuredpay.in/checkerRoutes/getwalletbalance",
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let orderData = res.data.result;
          setBankBalanceData(orderData);
          console.log("orderData");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (newToken) {
      getBankBalance();
    }
  }, []);
  // setTimeout(() => {
  //   getBankBalance();
  // }, 1000);
  if (Token !== "" && Token !== null && Token !== undefined) {
    return (
      <ThemeProvider theme={theme}>
        <Box>
          {isSmallScreen && (
            <Grid container item xs={12} display={"flex"}>
              <Grid>
                <Sidebars />
              </Grid>
              <Grid
                sx={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "#F7F9FB",
                }}
              >
                <AppBar>
                  <Header
                    collapsed={collapsed}
                    BankBalanceData={BankBalanceData}
                    showWallet={true}
                  />{" "}
                </AppBar>{" "}
                <div style={{ marginTop: "50px" }}>
                  <Outlet />
                </div>
              </Grid>

              {/* Render components for large screens */}
            </Grid>
          )}
          {isMediumScreen && (
            <Grid md={6} item display={"flex"}>
              <Grid>
                <Sidebars />
              </Grid>
              <Grid
                sx={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "#F7F9FB",
                }}
              >
                <AppBar>
                  <Header
                    collapsed={collapsed}
                    BankBalanceData={BankBalanceData}
                    showWallet={true}
                  />{" "}
                </AppBar>{" "}
                <div style={{ marginTop: "50px" }}>
                  <Outlet />
                </div>
              </Grid>

              {/* Render components for large screens */}
            </Grid>
          )}
          {isLargeScreen && (
            <Grid lg={3} display={"flex"}>
              <Grid item>
                <Sidebars />
              </Grid>
              <Grid
                item
                sx={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "#F7F9FB",
                }}
              >
                <AppBar>
                  <Header
                    collapsed={collapsed}
                    BankBalanceData={BankBalanceData}
                    showWallet={true}
                  />{" "}
                </AppBar>{" "}
                <Box style={{ marginTop: "50px" }}>
                  <Outlet />
                </Box>
              </Grid>

              {/* Render components for large screens */}
            </Grid>
          )}
        </Box>
      </ThemeProvider>
    );
  } else {
    return <Navigate to={"/login"} />;
  }
};

export default DashboardRoutes;
