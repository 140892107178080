import { IconButton, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { fontFamilys, fontSizes } from "../../../utils/FontSize";
import { SuccessMsg } from "../../../components/Messages/SuccessMsg";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { Icon } from "@iconify/react";
import {
  numberFormat,
  removeSubstring,
  splitDateConversion,
} from "../../../utils/Validations";
import Timer from "../../../components/StopWatch/Timer";

const OrderDetails = (props: any) => {
  const orderDetails = props ? props.props : {};

  function formatString(inputString: string) {
    // Splitting the string based on underscore
    const splitString = inputString.split("_");

    // Capitalizing the first character of each word and joining them with space
    const result = splitString
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return result;
  }
  const { copy } = useCopyToClipboard();
  const onCopy = (text: string) => {
    if (text) {
      SuccessMsg("Copied Successfully!");
      copy(text);
    }
  };
  const orderDetailsBox = (label: string, value: any) => {
    return (
      <Box>
        <Typography
          fontSize={fontSizes.xsm}
          fontFamily={fontFamilys.publicSans}
          color="#919EAB"
        >
          {label}
        </Typography>
        <Typography
          color={label === "Amount" ? "green" : "#212B36"}
          fontWeight={label === "Amount" ? 700 : 500}
          sx={{ maxWidth: "400px" }}
          fontSize={fontSizes.sm}
          fontFamily={fontFamilys.publicSans}
        >
          {value ? value : "N/A"}
        </Typography>
      </Box>
    );
  };
  return (
    <div>
      {" "}
      <Box px={5} mt={1.5}>
        <Stack direction={"row"} spacing={1}>
          <Box pt={0.5}>
            <Typography fontWeight={700} fontFamily={fontFamilys.publicSans}>
              Order Details :
            </Typography>
          </Box>
          <Box>
            <Typography
              color={"#F58336"}
              fontFamily={fontFamilys.publicSans}
              fontWeight={700}
            >
              {orderDetails && orderDetails.order && orderDetails.order.orderID}
              <Tooltip title="Copy" placement="top">
                <IconButton
                  onClick={() =>
                    onCopy(
                      orderDetails &&
                        orderDetails.order &&
                        orderDetails.order.orderID
                    )
                  }
                >
                  <Icon icon="eva:copy-fill" width={20} />
                </IconButton>
              </Tooltip>
            </Typography>{" "}
          </Box>
          {(orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "approved") ||
          (orderDetails &&
            orderDetails.order &&
            orderDetails.order.status === "pending") ? (
            <Box pt={0.5}>
              <Typography fontWeight={700} fontFamily={fontFamilys.publicSans}>
                <Timer
                  props={
                    orderDetails &&
                    orderDetails.order &&
                    orderDetails.order.recipientExpiryDate
                  }
                />
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Stack>
      </Box>
      <Box
        px={8}
        mx={5}
        my={0.5}
        sx={{
          backgroundColor: "var(--grey-100, #F9FAFB)",
          border: "1px solid var(--grey-300, #DFE3E8)",
          borderRadius: "7px",
        }}
      >
        <Stack my={1.5} direction={"row"} spacing={"auto"}>
          {orderDetailsBox(
            "Order Title",
            orderDetails && orderDetails.order && orderDetails.order.title
          )}
          {orderDetailsBox(
            "Payment Type",
            orderDetails &&
              orderDetails.order &&
              formatString(orderDetails.order.orderType)
          )}
          {orderDetailsBox(
            "Payment Method",
            orderDetails &&
              orderDetails.order &&
              orderDetails.order.paymentType === "full"
              ? "Full Payment"
              : "Partial Payment"
          )}
          <Tooltip
            title={
              orderDetails && orderDetails.order && orderDetails.order.remark
            }
          >
            {orderDetailsBox(
              "Description",
              orderDetails &&
                orderDetails.order &&
                removeSubstring(orderDetails.order.remark)
            )}
          </Tooltip>
          {orderDetailsBox(
            "Amount",
            orderDetails &&
              orderDetails.order &&
              numberFormat(orderDetails.order.orderAmount)
          )}
          {orderDetailsBox(
            "Agreed Days",
            orderDetails && orderDetails.order && orderDetails.order.paymentDays
          )}
          {orderDetailsBox(
            "Payment Settlement Days",
            orderDetails &&
              orderDetails.order &&
              splitDateConversion(orderDetails.order.paymentSettlementDate)
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default OrderDetails;
