import ApprovalSVG from "../../assets/illustration_upload.svg";
import { Box, Stack, Container, Typography, Paper } from "@mui/material";
import CustomizedSteppers from "../../components/Stepper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ApiService from "../../api/api";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";

const AdminApproved = () => {
  const location = useLocation();
  const newToken = useSelector((state: any) => state.user.Token);
  let uuid = location.state ? location.state : "";
  const [uuidData, setuuidData] = useState("");

  // const ApiService =
  const getUserReferenceNumber = async () => {
    if (newToken !== "" && newToken !== undefined) {
      const token = await ApiService.setToken(newToken);
      const responseData: any = await ApiService.get("/userkyc/getuuid");
      const referenceData = responseData.data.result;
      setuuidData(referenceData);
    } else {
      console.log("api not call due to missing token or user Information");
      ErrorMsg("Something went wrong,Please try again !");
    }
  };
  useEffect(() => {
    getUserReferenceNumber();
  }, [uuid]);
  return (
    <Box margin={2} sx={{ flexGrow: 1 }}>
      <Stack sx={{ px: "100px" }}>
        <CustomizedSteppers activeStep={3} />
      </Stack>
      <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
        <Box justifyContent={"center"} textAlign={"center"} sx={{ mt: 4 }}>
          <img src={ApprovalSVG} alt="" />
          <Typography
            sx={{
              fontSize: "20px",
              mt: 2,
              color: "Black",
              fontWeight: 700,
              fontFamily: "Public Sans",
            }}
          >
            {" "}
            Request Sent Successfully
          </Typography>
          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Stack direction={"row"} display={"flex"} spacing={2}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "Black",
                  fontWeight: 500,
                  fontFamily: "Public Sans",
                  //   fontStyle: "normal",
                }}
              >
                {" "}
                Reference No :{" "}
              </Typography>
              <Typography
                fontWeight={700}
                fontFamily={"Public Sans"}
                color="#303981"
              >
                {uuid !== "" ? uuid : uuidData}
              </Typography>
            </Stack>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            mt={4}
            py={4}
            px={2}
            boxShadow={
              "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
            }
            borderRadius={"8px"}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: "Black",
                fontWeight: 500,
                fontFamily: "Public Sans",
                //   fontStyle: "normal",
              }}
            >
              Please wait for a while,Your documents have been forwarded to the
              Assured Pay's admin/support team & they will promptly review and
              approve them & contact to you shortly.
            </Typography>
            {/* </Stack> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminApproved;
