import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { Chip, Button, Typography, TableContainer } from "@mui/material";

import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  StringDateConversion,
  firstCharacterUppercase,
  numberFormat,
} from "../../../utils/Validations";
import NoRecordFound from "../../../components/NoRecordFound";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";
export default function PaymentListTable(props: any) {
  const [Token, setToken] = useState<any>("");
  const paymentList = props ? props.props : [];
  const paymentSelectionType = props ? props.PaymentSelectType : "seller";
  const navigate = useNavigate();
  console.log(
    paymentList,
    "paymentListststst---------------------//---------------------//------------------//-----------------"
  );

  const chipBackgroundColor = (value: any) => {
    if (value === "complete") {
      return "var(--Success-Lighter, #D8FBDE)";
    } else if (value === "active") {
      return "var(--Success-Lighter, #D8FBDE)";
    } else if (
      value === "rejected" ||
      value === "expired" ||
      value === "cancelled"
    ) {
      return "var(--transparent-error-16, rgba(255, 86, 48, 0.16))";
    } else {
      return "var(--transparent-grey-16, rgba(145, 158, 171, 0.16))";
    }
  };
  const chipTextColor = (value: any) => {
    if (value === "complete") {
      return "#007B55";
    } else if (value === "active") {
      return "#007B55";
    } else if (
      value === "rejected" ||
      value === "expired" ||
      value === "cancelled"
    ) {
      return "#B71D18";
    } else {
      return "#212B36";
    }
  };

  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });
  const ResponsiveTableContainer = styled(TableContainer)({
    overflowX: "auto",
    maxWidth: "100%",
  });
  const handleViewRequestOnClick = (item: any) => {
    if (item) {
      navigate("/dashboard/order", { state: { orderDetails: item } });
    } else {
      ErrorMsg("No Record Found !");
    }
  };
  return (
    <>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F7F9FB" }}>
            <TableCell
              sx={{
                borderBottom: "none",
                px: 4,
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                {paymentSelectionType === "buyer" ? "Seller" : "Buyer"}
              </Typography>
            </TableCell>

            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Payment Type
              </Typography>
            </TableCell>
            {/* <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Payment Method
              </Typography>
            </TableCell> */}
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Create On Date
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Payment Date
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Amount
              </Typography>
            </TableCell>

            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Status
              </Typography>
            </TableCell>
            {paymentSelectionType === "buyer" ? (
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
              >
                {" "}
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  fontFamily={"Public Sans"}
                  color={"grey"}
                >
                  Action
                </Typography>
              </TableCell>
            ) : (
              ""
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentList.length > 0
            ? paymentList.map((item: any, index: any) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      key={index}
                      component="th"
                      scope="row"
                      sx={{
                        borderBottom: "none",
                        px: 3,
                      }}
                    >
                      <Typography
                        fontSize={13}
                        color={"#303981"}
                        fontFamily={"Public Sans"}
                        fontWeight={700}
                      >
                        {item.legalNameOfBusiness &&
                        item.legalNameOfBusiness !== ""
                          ? item.legalNameOfBusiness
                          : "N/A"}{" "}
                      </Typography>
                      <Typography
                        fontSize={12}
                        color={"#212B36"}
                        fontFamily={"Public Sans"}
                        fontWeight={500}
                      >
                        Order No:{" "}
                        {item && item.orderID !== "" ? item.orderID : "N/A"}{" "}
                      </Typography>
                      <Typography
                        fontSize={11}
                        color={"#212B36"}
                        fontFamily={"Public Sans"}
                      >
                        {item && item.POPI !== "" ? item.POPI : "N/A"}{" "}
                      </Typography>
                    </TableCell>
                    <TableCell
                      key={index}
                      component="th"
                      scope="row"
                      sx={{
                        borderBottom: "none",
                        // px: 3,
                      }}
                    >
                      <Typography
                        fontSize={13}
                        color={"#212B36"}
                        fontFamily={"Public Sans"}
                      >
                        {item.paymentType === "full"
                          ? "Full Payment"
                          : "Partial Payment"}
                      </Typography>
                    </TableCell>
                    {/* <TableCell
                      key={index}
                      component="th"
                      scope="row"
                      sx={{
                        borderBottom: "none",
                        px: 3,
                      }}
                    >
                      <Typography
                        fontSize={13}
                        color={"#212B36"}
                        fontFamily={"Public Sans"}
                      >
                        {item.paymentType === "full"
                          ? "Full Payment"
                          : "Partial Payment"}
                      </Typography>
                    </TableCell> */}
                    <TableCell
                      sx={{
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        fontSize={13}
                        color={"#212B36"}
                        fontFamily={"Public Sans"}
                      >
                        {item && item.proposalCreatedDate !== ""
                          ? StringDateConversion(item.proposalCreatedDate)
                          : "N/A"}{" "}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        fontSize={13}
                        color={"#212B36"}
                        fontFamily={"Public Sans"}
                      >
                        {item && item.lastMilestoneDate !== ""
                          ? StringDateConversion(item.lastMilestoneDate)
                          : "N/A"}{" "}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        fontSize={13}
                        color={"#212B36"}
                        fontFamily={"Public Sans"}
                      >
                        {item && item.orderAmount
                          ? numberFormat(item.orderAmount)
                          : "N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                      }}
                    >
                      {" "}
                      <Chip
                        label={
                          item &&
                          item.status &&
                          firstCharacterUppercase(item.status)
                        }
                        style={{
                          backgroundColor: chipBackgroundColor(
                            item && item.status
                          ),
                          color: chipTextColor(item.status),
                          fontWeight: 700,
                          fontSize: 12,
                          lineHeight: "20px",
                          fontFamily: "Public Sans",
                        }}
                        sx={{
                          minheight: "24px",
                          minwidth: "32px",
                          "& .MuiChip-label": {
                            fontWeight: 700,
                          },
                          borderRadius: "8px",
                        }}
                      />
                    </TableCell>
                    {paymentSelectionType === "buyer" ? (
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => handleViewRequestOnClick(item)}
                          sx={{
                            fontSize: "12px",
                            fontFamily: "Public Sans",
                            backgroundColor: "",
                            color: "var(--text-light-primary, #212B36)",
                            borderRadius: "8px",
                            fontWeight: 700,
                            px: "8px",
                            py: "4px",
                            gap: "8px",
                            lineHeight: "22px",
                            fontStyle: "normal",
                            border: "1px solid  rgba(145, 158, 171, 0.32)",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#36B37E",
                              color: "white",
                            },
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                );
              })
            : ""}
        </TableBody>
      </Table>
      {paymentList.length <= 0 ? (
        <NoRecordFound text1={"Payments"} text2={"payments"} />
      ) : (
        ""
      )}
    </>
  );
}
