import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

import React, { useEffect, useState } from "react";
import { fontFamilys, fontSizes } from "../../utils/FontSize";

const Timer = (props: any) => {
  //   const seconds = 300;
  const RED = props && props.props;
  let redValue = new Date(RED).getTime();
  const [seconds, setsecond] = useState(redValue);
  const [EnableTimer, setEnableTimer] = useState(false);
  const compareDatesAndGetDuration = () => {
    // Aaj ki date aur time
    let aajKiDate = new Date();

    let aajKiDateTime = aajKiDate.getTime();
    // Kal ki date aur time
    let kalKiDateTime = new Date(RED).getTime();
    // Difference between kal ki date aur aaj ki date in milliseconds
    let differenceInMs = kalKiDateTime - aajKiDateTime;
    // Convert difference to positive if it's negative
    let duration = Math.abs(differenceInMs);

    // Convert difference to seconds, minutes, hours
    let seconds = Math.floor(duration / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    // Remaining seconds after hours and minutes are deducted
    seconds = seconds % 60;
    // Remaining minutes after hours are deducted
    minutes = minutes % 60;
    // Remaining milliseconds after seconds, minutes, and hours are deducted
    let milliseconds = duration % 1000;

    // Return the duration in milliseconds, seconds, minutes, hours
    return {
      milliseconds: milliseconds,
      seconds: seconds < 10 ? `0${seconds}` : seconds,
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      hours: hours < 10 ? `0${hours}` : hours,
    };
  };

  // Kal ki date ko lekar function call karein aur result prapt karein

  // Output result
  //   console.log("Duration:", durationResult);

  const resendOTPOnClick = () => {
    setTimeout(() => {
      if (seconds === 0) {
        setEnableTimer(true);
      } else {
        setsecond(seconds - 1);
      }
    }, 1000);
  };
  useEffect(() => {
    compareDatesAndGetDuration();
    resendOTPOnClick();
  }, [seconds]);

  return (
    <div>
      <Box>
        <Typography
          fontFamily={fontFamilys.publicSans}
          fontSize={fontSizes.md}
          color={"red"}
          fontWeight={600}
        >
          {"Expires in "}{" "}
          {compareDatesAndGetDuration().hours +
            ":" +
            compareDatesAndGetDuration().minutes +
            ":" +
            compareDatesAndGetDuration().seconds}
        </Typography>
      </Box>
    </div>
  );
};

export default Timer;
