// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import {
  Radio,
  RadioGroup,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
} from "@mui/material";

// ----------------------------------------------------------------------

type Props = RadioGroupProps & {
  name: string;
  options: {
    label: string;
    value: any;
    disabled: boolean;
  }[];
};

export default function RHFRadioGroup({ name, options, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup
            {...field}
            row
            {...other}
            sx={{
              "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
                color: "#303981",
              },
              "& .css-ahj2mt-MuiTypography-root": {
                fontSize: "13px",
              },
              "& .css-1hbvpl3-MuiSvgIcon-root": {
                width: "16px",
                height: "16px",
              },
              "& .css-11zohuh-MuiSvgIcon-root": {
                width: "16px",
                height: "16px",
              },
              "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
                {
                  fontSize: "13px",
                },
              "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                fontSize: "10px",
              },
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                disabled={option.disabled}
              />
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
