// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField, TextFieldProps } from "@mui/material";

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  children: React.ReactNode;
};

export default function RHFSelect({ name, children, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{ native: true }}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            style: {
              borderRadius: "8px",
              borderColor: "#919EAB",
            },
          }}
          sx={{
            "& .MuiFormLabel-root": {
              fontFamily: "Public Sans",
              // fontWeight: "500",
              fontSize: "13px",
            },
            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
              {
                backgroundColor: "white",
                borderRadius: 3,
                padding: "13.5px 14px",
              },
            "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
              fontSize: "10px",
            },
            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
              {
                fontSize: "13px",
              },

            "& label.Mui-focused": {
              color: "#303981",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E3E7",
              },
              "&:hover fieldset": {
                borderColor: "#B2BAC2",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#303981",
              },
            },
          }}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
