// // ----------------------------------------------------------------------
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  error: null,
  userLogin: <any>[],
  login: false,
  Token: <any>{},
  LocationInfo: <any>{},
  userSave: <any>{},
  receivableState:<any>{},
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.isLoading = true;
      state.userLogin = action.payload;
    },
    saveUser: (state, action) => {
      state.isLoading = false;
      state.userSave = action.payload;
    },
    storeToken: (state, action) => {
      state.isLoading = true;
      state.Token = action.payload;
    },
    logOutUser: (state, action) => {
      state.isLoading = true;
      state.userLogin = action.payload;
    },
    LocationInfo: (state, action) => {
      state.isLoading = true;
      state.LocationInfo = action.payload;
    },
    arrangeReceivableState:(state,action)=>{
      state.isLoading=true;
      state.receivableState = action.payload
    }
  },
});
export const { loginUser, logOutUser, storeToken, LocationInfo, saveUser,arrangeReceivableState } =
  userSlice.actions;

export default userSlice.reducer;
