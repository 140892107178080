import PaymnetsIcon from "../../assets/paymentsImage.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Divider, TextField, Stack } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";

const Payments = () => {
  const navigate = useNavigate();
  const [CouponCode, setCouponCode] = useState("");
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [btnDisabledCheckout, setbtnDisabledCheckout] = useState(true);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("couponCode"),
    });
    // Base Url From ENV
    // const baseUrl = process.env.BASE_URL
    //   ? process.env.BASE_URL
    //   : "https://api.dev.assuredpay.in";

    // const FetchData = await axios.post(
    //   "https://api.dev.assuredpay.in/auth/login",
    //   {
    //     business_email: data.get("email"),
    //     password: data.get("password"),
    //   }
    // );
    // const getUserLogin = FetchData.data;
    // if (getUserLogin) {
    //   navigate("/kyc/start");
    // }
    // console.log("123, Login", getUserLogin);
  };
  const handleProceedCheckout = () => {
    navigate("/auth/userkyc");
  };

  useEffect(() => {
    if (CouponCode !== "") {
      setbtnDisabled(false);
    } else {
      setbtnDisabled(true);
    }
  });

  return (
    <Box
      justifyContent={"center"}
      sx={{ display: "flex", flexDirection: "column" }}
      alignItems={"center"}
    >
      <Grid container spacing={20}>
        <Grid>
          <Box
            sx={{
              boxShadow: 1,
              borderRadius: 1,
              px: 4,
              pt: 4,
              pb: 2,
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack sx={{ mb: 4, position: "relative" }}>
              <Typography
                variant="h5"
                component="div"
                fontSize={22}
                fontWeight={700}
                fontFamily={"Public Sans"}
                lineHeight={"22px"}
                color={"#334A67"}
              >
                Onboarding Enrolment Fees
              </Typography>
              <Typography
                variant="h5"
                component="div"
                fontSize={14}
                fontWeight={400}
                fontFamily={"Public Sans"}
                lineHeight={"30px"}
                color={"#334A67"}
              >
                This is a one time fees.
              </Typography>

              <Stack
                direction="row"
                spacing={30}
                sx={{ mt: "20px" }}
                style={{ marginTop: "20px" }}
              >
                <div>
                  <Typography
                    color="var(--text-light-disabled, #919EAB)"
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={"20px"}
                    fontFamily={"Public Sans"}
                  >
                    One time Joining Fee
                  </Typography>
                  <Typography
                    color={"var(--text-light-primary, #212B36)"}
                    fontSize={16}
                    fontWeight={500}
                    fontFamily={"Public Sans"}
                    gap={"8px"}
                    fontStyle={"normal"}
                    sx={{ overflow: "hidden" }}
                  >
                    AssuredPay Inc.
                  </Typography>
                </div>{" "}
                <Typography
                  fontWeight={500}
                  fontSize={14}
                  fontFamily={"Public Sans"}
                  fontStyle={"normal"}
                  color={"#212B36"}
                  lineHeight={"30px"}
                  maxWidth={"132px"}
                  minWidth={"132px"}
                >
                  {" "}
                  50,000 INR
                </Typography>
              </Stack>

              <Stack
                direction="row"
                spacing={31.5}
                sx={{ mt: "20px" }}
                style={{ marginTop: "20px" }}
              >
                <div>
                  <Typography
                    color="var(--text-light-disabled, #919EAB)"
                    fontSize={14}
                    fontWeight={400}
                    lineHeight={"20px"}
                    fontFamily={"Public Sans"}
                  >
                    Coupon Code
                  </Typography>
                  <Typography
                    color={"var(--text-light-primary, #212B36)"}
                    fontSize={16}
                    fontWeight={500}
                    fontFamily={"Public Sans"}
                    gap={"8px"}
                    fontStyle={"normal"}
                    sx={{ overflow: "hidden" }}
                    maxWidth={"132px"}
                    minWidth={"132px"}
                  >
                    VSBDHJ-HSBS
                  </Typography>
                </div>{" "}
                <Typography
                  fontWeight={500}
                  color={"green"}
                  fontSize={14}
                  fontFamily={"Public Sans"}
                  fontStyle={"normal"}
                  // color={"#212B36"}
                  lineHeight={"30px"}
                >
                  {" "}
                  -5000 INR
                </Typography>
              </Stack>
              <div>
                <Divider
                  sx={{ marginLeft: "0px", mt: "60px", maxWidth: "460px" }}
                  variant="middle"
                />
              </div>
              <Stack
                direction="row"
                spacing={30}
                sx={{ mt: "20px" }}
                style={{ marginTop: "20px" }}
              >
                <div>
                  <Typography
                    color={"var(--text-light-primary, #212B36)"}
                    fontSize={14}
                    fontWeight={500}
                    fontFamily={"Public Sans"}
                    gap={"8px"}
                    fontStyle={"normal"}
                    minWidth={"135px"}
                    sx={{ overflow: "hidden" }}
                  >
                    Total
                  </Typography>
                </div>

                <Typography
                  fontWeight={500}
                  fontSize={14}
                  fontFamily={"Public Sans"}
                  fontStyle={"normal"}
                  color={"#212B36"}
                  lineHeight={"30px"}
                  maxWidth={"132px"}
                  minWidth={"132px"}
                >
                  {" "}
                  45,000 INR
                </Typography>
              </Stack>
              <Box sx={{ my: 3 }} onSubmit={handleSubmit}>
                <Grid container spacing={2} alignItems={"flex-start"}>
                  <Grid>
                    <TextField
                      label="Enter Coupon Code"
                      size="small"
                      id="couponCode"
                      type="couponCode"
                      disabled
                      // defaultValue={"VSBDHJ-HSBS"}
                      name="couponCode"
                      onChange={(e) => setCouponCode(e.target.value)}
                      sx={{
                        minWidth: "40ch",
                        borderRadius: "8px",
                        "& .MuiFormLabel-root": {
                          color: "#919EAB",
                          fontSize: "15px",
                          fontFamily: "Public Sans",
                          fontWeight: "500",
                          mt: 0.2,
                        },
                      }}
                    />
                    {/* <Stack direction="row" spacing={0.5} sx={{ ml: "8px" }}>
                      <DoneIcon
                        style={{ fontSize: "20px", color: "#36B37E" }}
                      />{" "}
                      <Typography
                        fontWeight={600}
                        fontSize={14}
                        fontFamily={"Public Sans"}
                        color={"#36B37E"}

                        // lineHeight={"30px"}
                      >
                        {" "}
                        Coupon code Applied Successfully.
                      </Typography>
                    </Stack> */}
                    {/* <Typography
                      sx={{
                        mt: 0.1,
                        ml: "12px",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                      color={"#36B37E"}
                    >
                      <DoneIcon style={{ fontSize: "20px" }} /> Coupon code
                      Applied Successfully.
                    </Typography> */}
                  </Grid>
                  <Grid>
                    <Button
                      size="small"
                      style={{
                        textTransform: "none",
                        marginTop: 4,
                        fontFamily: "Public Sans",
                        fontWeight: "600",
                        backgroundColor: btnDisabled
                          ? "var(--grey-300, #DFE3E8)"
                          : "#303981",
                        borderRadius: "8px",
                        color: btnDisabled ? "black" : "white",
                      }}
                      type="submit"
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box alignItems={"flex-start"}>
                <Button
                  // disabled={btnDisabled}
                  // color="rgba(145, 158, 171, 0.24)"
                  size="large"
                  color="inherit"
                  // variant="contained"
                  style={{
                    // maxWidth: "320px",
                    // marginTop: "18px",
                    // marginBottom: "35px",
                    borderRadius: "8px",
                    textTransform: "none",
                    fontSize: "14px",
                    backgroundColor:
                      // btnDisabled
                      // ? "rgba(145, 158, 171, 0.24)"
                      //   :
                      "#303981",
                    fontFamily: "Public Sans",
                    fontWeight: "700",
                    color:
                      // btnDisabled
                      // ? "var(--action-light-disabled, rgba(145, 158, 171, 0.80))"
                      // :
                      "white",
                  }}
                  onClick={handleProceedCheckout}
                  variant="contained"
                >
                  Proceed To Checkout{" "}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid>
          <img
            src={PaymnetsIcon}
            style={{
              marginTop: "140px",
              maxHeight: "250px",
              maxWidth: "550px",
              alignItems: "center",
            }}
            alt=""
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Payments;
