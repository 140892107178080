import { Box, Stack } from "@mui/system";
import {
  Typography12,
  Typography22,
} from "../../../components/typography/Typography";
import { calculateBankBalanceInterest } from "../../../utils/Validations";
const InterestDetailsData = (props: any) => {
  const OrderDetails = props ? props.props : {};
  const Text1 = props ? props.Text1 : "";
  const Text2 = props ? props.Text2 : "";

  // const totalApBalance = () => {
  //   const milestoneLength =
  //     OrderDetails && OrderDetails.milestones && OrderDetails.milestones.length;
  //   let totalBankBalance = 0;
  //   let totalBankBalanceInterest: any = 0;
  //   if (milestoneLength > 0) {
  //     for (let i = 0; i < milestoneLength; i++) {
  //       totalBankBalance += OrderDetails.milestones[i].usingBB;
  //       totalBankBalanceInterest += calculateBankBalanceInterest(
  //         OrderDetails.milestones[i].usingBB,
  //         OrderDetails.milestones[i].FD_Days
  //       );
  //     }
  //     return {
  //       totalBankBalance,
  //       totalBankBalanceInterest,
  //     };
  //   } else {
  //     totalBankBalance = 0;
  //     totalBankBalanceInterest = 0;
  //     return {
  //       totalBankBalance,
  //       totalBankBalanceInterest,
  //     };
  //   }
  // };
  // const totalApReceivables = () => {
  //   const milestoneLength =
  //     OrderDetails && OrderDetails.milestones && OrderDetails.milestones.length;
  //   let totalBankBalance = 0;
  //   let totalBankBalanceInterest: any = 0;
  //   if (milestoneLength > 0) {
  //     for (let i = 0; i < milestoneLength; i++) {
  //       totalBankBalance += OrderDetails.milestones[i].usingRC;
  //       totalBankBalanceInterest += calculateBankBalanceInterest(
  //         OrderDetails.milestones[i].usingRC,
  //         OrderDetails.milestones[i].FD_Days
  //       );
  //     }
  //     return {
  //       totalBankBalance,
  //       totalBankBalanceInterest,
  //     };
  //   } else {
  //     totalBankBalance = 0;
  //     totalBankBalanceInterest = 0;
  //     return {
  //       totalBankBalance,
  //       totalBankBalanceInterest,
  //     };
  //   }
  // };
  return (
    <Box display={"flex"} justifyContent={"center"} my={1.5}>
      <Stack display={"flex"} direction={"row"} spacing={3}>
        <Stack textAlign={"center"} justifyContent={"center"}>
          <Typography12 text={Text1}></Typography12>
          <Typography22
            color={"#303981"}
            text={
              OrderDetails &&
              OrderDetails.amountData &&
              OrderDetails.amountData.bankBalance.amount
            }
          ></Typography22>
          <Typography12
            color={"#007B55"}
            fontSize={12}
            fontWeight={400}
            text={`Approx interest Rs. ${
              OrderDetails &&
              OrderDetails.amountData &&
              OrderDetails.amountData.bankBalance.approxInterest.toFixed(2)
            }`}
          ></Typography12>
        </Stack>
        <Stack textAlign={"center"} justifyContent={"center"}>
          <Typography12 text={Text2 ? Text2 : ""}></Typography12>
          <Typography22
            color={"#303981"}
            text={
              OrderDetails &&
              OrderDetails.amountData &&
              OrderDetails.amountData.recievables.amount
            }
          ></Typography22>
          <Typography12
            color={"#007B55"}
            fontSize={12}
            fontWeight={400}
            text={`Approx interest Rs. ${
              OrderDetails &&
              OrderDetails.amountData &&
              OrderDetails.amountData.recievables.approxInterest.toFixed(2)
            }`}
          ></Typography12>
        </Stack>
      </Stack>
    </Box>
  );
};
export default InterestDetailsData;
