import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import ReceiptIcon from "@mui/icons-material/Receipt";

const PaymentRejectDialog = (props: any) => {
  const OpenDailog = props ? props.OpenDailog : false;
  const TitleText = props ? props.TitleText : "";
  const RejectionText = props ? props.RejectionText : "";
  return (
    <>
      <Dialog
        open={OpenDailog}
        aria-labelledby="responsive-dialog-title"
        style={{ borderRadius: "10px" }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ px: 4, maxWidth: "500px" }}
        >
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <ReceiptIcon sx={{ color: "red" }} />
            <Typography
              component="div"
              fontFamily={"Public Sans"}
              fontSize={"20px"}
              fontWeight={700}
              lineHeight={"28px"}
              color={"#212B36"}
            >
              {TitleText}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ px: 4, maxWidth: "430px" }}>
          <DialogContentText
            color={"#454F5B"}
            textAlign={"start"}
            justifyContent={"center"}
            alignSelf={"stretch"}
            fontFamily={"Public Sans"}
            fontSize={"16px"}
            fontWeight={400}
          >
            {RejectionText}
          </DialogContentText>
          <Box
            mt={2}
            mb={1}
            display={"flex"}
            justifyContent={"start"}
            maxWidth={380}
          >
            <TextField
              size="small"
              fullWidth
              sx={{
                "& .MuiFormLabel-root": {
                  color: "#919EAB",
                  fontSize: "14px",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                },
              }}
              // error={emailAlreadyExist}
              type="email"
              name="business_email"
              label="Reason"
              // helperText={emailAlreadyExist ? EmailVerificationMsg : ""}
              id="email"
              onChange={props.rejectRemarkValue}
              // onBlur={EmailVerification}
              InputProps={{
                style: {
                  height: "45px",
                  borderRadius: "8px",
                  borderColor: "#919EAB",
                  marginTop: 2,
                  marginBottom: 2,
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            textAlign: "center",
            px: 8,
            mb: 2,
            // maxWidth: "430px",
          }}
        >
          <LoadingButton
            disabled={props.confirmationBtnDisable}
            onClick={props.RejectPaymentConfigure}
            loading={props.VerifyRejectPaymentLoader}
            sx={{ mr: 1, px: 3 }}
            style={{
              fontFamily: "Public Sans",
              color: !props.confirmationBtnDisable ? "white" : "",
              width: "auto",
              fontSize: "14px",
              backgroundColor: !props.confirmationBtnDisable
                ? "red"
                : "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))",
              textTransform: "none",
              borderRadius: "8px",
              fontWeight: "700",
            }}
          >
            Yes
          </LoadingButton>
          <Button
            type="submit"
            sx={{ ml: 1, px: 2, py: 0.6 }}
            style={{
              fontFamily: "Public Sans",
              color: "Black",
              width: "auto",
              border: "1px solid ",
              fontSize: "14px",
              textTransform: "none",
              borderRadius: "8px",
              fontWeight: "700",
            }}
            onClick={props.CloseRejectPayment}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentRejectDialog;
