import RemainingIcon from "../../assets/ic_info.svg";
import { experimentalStyled as styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Stack,
  Container,
  Typography,
  Button,
  TextField,
  Paper,
  Avatar,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import Grid from "@mui/material/Unstable_Grid2";
import CustomizedSteppers from "../../components/Stepper";
import ProgressBarCircular from "../../components/ProgressBarCircular";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { SuccessMsg } from "../../components/Messages/SuccessMsg";
import { ErrorMsg } from "../../components/Messages/ErrorMsg";
import { characterUpperCase } from "../../utils/Validations";

const AadharVerify = () => {
  const navigate = useNavigate();
  const [UserAadharNumber, setUserAadharNumber] = useState("");
  const [OtpbtnDisabled, setOtpBtnDisabled] = useState(true);
  const [VerifyBtnDisabled, setVerifyBtnDisabled] = useState(true);
  const [UserAadharOTP, setUserAadharOTP] = useState("");
  const [showVerifyOtpOption, setshowVerifyOtpOption] = useState(false);
  const [UserAadharNumberDisabled, setUserAadharNumberDisabled] =
    useState(false);
  const [showAadharVerifyMsg, setshowAadharVerifyMsg] = useState(false);
  const [verifiedUserAdharData, setverifiedUserAdharData] = useState([]);
  const [Token, setToken] = useState<any>("");
  const [uploadAdharbtnDisabled, setuploadAdharbtnDisabled] = useState(true);
  const [AdharOTPErrMsg, setAdharOTPErrMsg] = useState(false);
  const [verifyAdharPhoto, setverifyAdharPhoto] = useState("");
  const [VerifyLoading, setVerifyLoading] = useState(false);
  const [verifyAadharLoading, setverifyAadharLoading] = useState(false);
  const [RemainingCount, setRemainingCount] = useState(false);

  const [fetchAdharDetails, setfetchAdharDetails] = useState(false);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));
  // verify Adhar Page
  const handleAdharGetOtp = async (e: any) => {
    e.preventDefault();
    setVerifyLoading(true);
    setOtpBtnDisabled(true);

    if (UserAadharNumber !== "") {
      const data = new FormData(e.currentTarget);
      const baseUrl = process.env.CONSUMER_BASE_URL_PROD
        ? process.env.CONSUMER_BASE_URL_PROD
        : "https://api.dev.assuredpay.in";

      const axiosConfig = {
        headers: {
          token: Token !== "" ? JSON.parse(Token) : "",
        },
      };

      const FetchData = await axios
        .post(
          "https://api.dev.assuredpay.in/userkyc/verifyadhar",
          { AadharNumber: data.get("UserAadharNumber") },
          axiosConfig
        )
        .then((res) => {
          const getUserLogin = res;
          if (getUserLogin.status === 200) {
            setRemainingCount(res.data.leftAttempt);
            setVerifyLoading(false);
            setUserAadharNumberDisabled(true);
            setOtpBtnDisabled(true);
            setshowVerifyOtpOption(true);
            SuccessMsg("OTP send to your Aadhar registered mobile number");
          }
        })
        .catch((err) => {
          // Api Set Error Msg and left count
          setAdharOTPErrMsg(true);
          setVerifyLoading(false);
          ErrorMsg("Invalid Aadhar Number, Please try again");
          console.log(err, "catch Method");
        });
    }
  };
  const handleAdharVerify = async (e: any) => {
    e.preventDefault();
    setverifyAadharLoading(true);
    setVerifyBtnDisabled(true);
    if (UserAadharNumber !== "") {
      const data = new FormData(e.currentTarget);
      const baseUrl = process.env.BASE_URL
        ? process.env.BASE_URL
        : "https://api.dev.assuredpay.in";

      const axiosConfig = {
        headers: {
          token: Token !== "" ? JSON.parse(Token) : "",
        },
      };

      const FetchData = await axios
        .post(
          "https://api.dev.assuredpay.in/userkyc/verifyadharotp",
          {
            otp: data.get("userGstNumber"),
            aadharNum: UserAadharNumber,
          },
          axiosConfig
        )
        .then((res) => {
          const getUserLogin = res;
          if (getUserLogin.status === 200) {
            setverifiedUserAdharData(res.data.results.resultSend);
            setverifyAdharPhoto(
              res.data.results.resultSendImage.aadharPhotoLink
            );
            setverifyAadharLoading(false);
            setshowVerifyOtpOption(false);
            setshowAadharVerifyMsg(true);
            setfetchAdharDetails(true);
            setuploadAdharbtnDisabled(false);
          }
        })
        .catch((err) => {
          setverifyAadharLoading(false);
          setfetchAdharDetails(false);
          setVerifyBtnDisabled(false);
          console.log(err, "catch Method");
        });
    }
  };
  const handleOnClickfileUploadSuccess = () => {
    navigate("/auth/verifypan");
    // const axiosConfig = {
    //   headers: {
    //     token: Token !== "" ? JSON.parse(Token) : "",
    //   },
    // };

    // const FetchData = await axios
    //   .post(
    //     "https://api.dev.assuredpay.in/userkyc/saveaadhardetail",
    //     verifiedUserAdharData,
    //     axiosConfig
    //   )
    //   .then((res) => {
    //     const getUserLogin = res;
    //     navigate("/verifypan");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  const handleGetOtpBtn = (e: any) => {
    setAdharOTPErrMsg(false);
    const TrimGst = e.target.value ? e.target.value.trim() : "";
    setUserAadharNumber(TrimGst);
    if (e.target.value && TrimGst.length === 12) {
      setOtpBtnDisabled(false);
    } else {
      setOtpBtnDisabled(true);
    }
  };

  const handleOnChangeVerifyUserAadharOTP = (e: any) => {
    if (e.target.value !== "") {
      setUserAadharOTP(e.target.value);
      setVerifyBtnDisabled(false);
    } else {
      setVerifyBtnDisabled(true);
    }
  };
  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });
  const changeTextAdharProfile = (value: any) => {
    console.log(value);
    if (value === "aadharCO") {
      return "Care of";
    } else if (value === "aadharGender") {
      return "Gender";
    } else if (value === "aadharCountry") {
      return "Country";
    } else if (value === "aadharPO") {
      return "PO";
    } else if (value === "nameInAadhaar") {
      return "Name";
    } else if (value === "distInAadhar") {
      return "District";
    } else if (value === "aadharPincode") {
      return "Pincode";
    } else if (value === "aadharNumber") {
      return "Aadhar No";
    } else if (value === "aadharDOB") {
      return "Date of Birth";
    } else if (value === "aadharSubDistrict") {
      return "Sub District";
    } else if (value === "aadharState") {
      return "State";
    } else if (value === "aadharHouse") {
      return "House Address";
    } else if (value === "aadharStreet") {
      return "Street";
    } else if (value === "cityInAadhar") {
      return "City";
    } else if (value === "addressInAadhar") {
      return "Address";
    }
  };
  console.log(verifyAdharPhoto, "verifyAdharPhoto");
  return (
    <Box margin={2} sx={{ flexGrow: 1 }}>
      <Box textAlign={"center"}>
        <Stack sx={{ px: "100px" }}>
          <CustomizedSteppers activeStep={1} />
        </Stack>
      </Box>

      <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
        <Box
          boxShadow={"1"}
          justifyContent={"center"}
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 5,
            py: 3,
          }}
        >
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ fontSize: "12px" }}
          >
            <Grid sx={{ order: { xs: 12, sm: 2 } }}>
              <Typography
                component="div"
                fontFamily={"Public Sans"}
                fontSize={21}
                fontWeight={700}
                lineHeight={"30px"}
                color={"#212B36"}
              >
                Enter Authorised Business Representative details
              </Typography>
            </Grid>
            <Grid container columnSpacing={1} sx={{ order: { xs: 12, sm: 2 } }}>
              <Grid>
                <ProgressBarCircular percentage={30} />
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <>
              <Box sx={{ my: 3 }} component="form" onSubmit={handleAdharGetOtp}>
                {!fetchAdharDetails}
                <Stack direction={"row"}>
                  <TextField
                    disabled={UserAadharNumberDisabled}
                    label="Enter Your Aadhar Number"
                    size="small"
                    type="text"
                    error={AdharOTPErrMsg}
                    name="UserAadharNumber"
                    value={characterUpperCase(UserAadharNumber)}
                    onChange={handleGetOtpBtn}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        borderColor: "#919EAB",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 40,
                        minWidth: "23vw",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#919EAB",
                        fontFamily: "Public Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        Height: "20px",
                      },
                    }}
                  />{" "}
                  {showAadharVerifyMsg ? (
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{ ml: "20px", mt: 1 }}
                    >
                      <>
                        <DoneIcon
                          style={{ fontSize: "15px", color: "#36B37E" }}
                        />{" "}
                        <Typography
                          fontWeight={600}
                          fontSize={11}
                          fontFamily={"Public Sans"}
                          color={"#36B37E"}
                        >
                          {" "}
                          Aadhar Details Fetched SuccessFully
                        </Typography>
                      </>
                    </Stack>
                  ) : (
                    <LoadingButton
                      size="small"
                      disabled={OtpbtnDisabled}
                      sx={{ px: 1.5 }}
                      style={{
                        marginLeft: 12,
                        textTransform: "none",
                        backgroundColor: OtpbtnDisabled
                          ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                          : "#00AB55",
                        color: OtpbtnDisabled
                          ? "rgba(145, 158, 171, 0.80)"
                          : "white",
                        borderRadius: "8px",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                      loading={VerifyLoading}
                      type="submit"
                    >
                      Get OTP
                    </LoadingButton>
                  )}
                </Stack>
              </Box>
              {showVerifyOtpOption ? (
                <Box
                  sx={{ my: 2 }}
                  component="form"
                  onSubmit={handleAdharVerify}
                >
                  <Stack direction={"row"}>
                    <TextField
                      label="Enter Your Aadhar OTP"
                      size="small"
                      type="text"
                      name="userGstNumber"
                      onChange={handleOnChangeVerifyUserAadharOTP}
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          borderColor: "#919EAB",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 40,
                          minWidth: "23vw",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#919EAB",
                          fontFamily: "Public Sans",
                          fontWeight: "500",
                          fontSize: "14px",
                          Height: "20px",
                        },
                      }}
                    />{" "}
                    <>
                      <LoadingButton
                        size="small"
                        sx={{ py: 0.1, px: 3, mt: 0.2 }}
                        style={{
                          marginLeft: 12,
                          textTransform: "none",
                          backgroundColor: VerifyBtnDisabled
                            ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                            : "#00AB55",
                          color: VerifyBtnDisabled
                            ? "rgba(145, 158, 171, 0.80)"
                            : "white",
                          borderRadius: "8px",
                          fontSize: "13px",
                          fontWeight: "700",
                        }}
                        loading={verifyAadharLoading}
                        type="submit"
                      >
                        Verify
                      </LoadingButton>
                    </>
                    <> </>
                  </Stack>
                </Box>
              ) : (
                <></>
              )}
            </>
          </Box>

          <Box>
            {showVerifyOtpOption ? (
              <>
                <Stack display={"flex"} direction={"row"} spacing={0.5}>
                  <img
                    src={RemainingIcon}
                    style={{ height: "16px", width: "16px" }}
                    alt=""
                  />
                  <Typography
                    fontSize={12}
                    color={"var(--text-light-secondary, #637381)"}
                    fontStyle={"normal"}
                    fontFamily={"Public Sans"}
                    fontWeight={400}
                  >
                    {" "}
                    Remaining attempt : {RemainingCount ? RemainingCount : 0}
                  </Typography>
                </Stack>
              </>
            ) : (
              ""
            )}
            {fetchAdharDetails ? (
              <Box sx={{ display: "flex", flexShrink: 0 }}>
                <Grid
                  container
                  flexDirection={{ xs: "column", sm: "row" }}
                  sx={{ fontSize: "12px" }}
                >
                  <Grid xs={10} alignItems={"center"}>
                    <Typography
                      component="div"
                      fontFamily={"Public Sans"}
                      fontSize={19}
                      fontWeight={700}
                      lineHeight={"30px"}
                      color={"#212B36"}
                    >
                      <Grid
                        container
                        sx={{
                          m: 0,
                          mt: 0.5,
                          ml: -0.5,
                          p: 0,
                          "& .MuiPaper-root": {
                            padding: "0px",
                          },
                        }}
                        spacing={{ xs: 3, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        {/* {verifiedUserAdharData.map((name, index) => ( */}
                        {Object.keys(verifiedUserAdharData).map(
                          (name: any, index: any) => (
                            <Grid
                              style={{
                                padding: "8px",
                              }}
                              sx={{ my: 0, p: 0 }}
                              xs={2}
                              sm={4}
                              md={4}
                              key={index}
                            >
                              <Item>
                                <Typography
                                  sx={{ m: 0, p: 0 }}
                                  fontFamily={"Public Sans"}
                                  fontSize={11}
                                  color={"#919EAB"}
                                >
                                  {changeTextAdharProfile(name)}
                                </Typography>
                                <Typography
                                  fontSize={"14px"}
                                  fontStyle={"normal"}
                                  fontFamily={"Public Sans"}
                                  color={"#212B36"}
                                  fontWeight={600}
                                  sx={{ m: 0, p: 0 }}
                                >
                                  {verifiedUserAdharData[name]}
                                </Typography>
                              </Item>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent={"center"}
                    // alignItems={"center"}
                    columnSpacing={0}
                    sx={{ order: { xs: 2, sm: 2 }, mt: 1 }}
                  >
                    {/* <ProgressBarCircular /> */}
                    <Avatar
                      alt=""
                      src={
                        verifyAdharPhoto
                          ? verifyAdharPhoto
                          : "/static/images/avatar/1.jpg"
                      }
                      sx={{ width: 100, height: 100 }}
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <div style={{ minHeight: "30vh" }}></div>
            )}
          </Box>
          <Box>
            <Stack display={"flex"} direction={"row"} sx={{ mt: 2 }}>
              <Button
                size="small"
                disabled={uploadAdharbtnDisabled}
                onClick={handleOnClickfileUploadSuccess}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#303981",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  borderRadius: "8px",
                  px: 2,
                  background: uploadAdharbtnDisabled
                    ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                    : "#303981",
                  color: uploadAdharbtnDisabled ? "black" : "white",
                  "&:hover": {
                    background: uploadAdharbtnDisabled
                      ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                      : "#303981",
                  },
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AadharVerify;
