import {
  Avatar,
  Grid,
  IconButton,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Box, Stack } from "@mui/system";
import React from "react";
import { fontFamilys, fontSizes } from "../../../utils/FontSize";
import { Typography16 } from "../../../components/typography/Typography";
import { removeSubstring } from "../../../utils/Validations";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { SuccessMsg } from "../../../components/Messages/SuccessMsg";

const BuisnessDetails = (props: any) => {
  const orderDetails = props ? props.props : {};
  function formatString(inputString: string) {
    // Splitting the string based on underscore
    const splitString = inputString.split("_");

    // Capitalizing the first character of each word and joining them with space
    const result = splitString
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return result;
  }
  const { copy } = useCopyToClipboard();
  const onCopy = (text: string) => {
    if (text) {
      SuccessMsg("Copied Successfully!");
      copy(text);
    }
  };
  const orderDetailsBox = (label: string, value: any) => {
    return (
      <Box>
        <Typography
          fontSize={fontSizes.xsm}
          fontFamily={fontFamilys.publicSans}
          color="#919EAB"
        >
          {label}
        </Typography>
        <Typography
          color={label === "Amount" ? "green" : "#212B36"}
          fontWeight={label === "Amount" ? 700 : 500}
          sx={{ maxWidth: "400px" }}
          fontSize={fontSizes.sm}
          fontFamily={fontFamilys.publicSans}
        >
          {value ? value : "N/A"}
        </Typography>
      </Box>
    );
  };
  return (
    <div>
      {" "}
      <Box px={5} mt={1.5}>
        <Typography fontFamily={fontFamilys.publicSans} fontWeight={700}>
          {orderDetails &&
            orderDetails.target &&
            orderDetails.target.targetType}{" "}
          Details
        </Typography>
      </Box>
      <Box
        px={8}
        mx={5}
        // my={1}
        pt={1}
        sx={{
          backgroundColor: " var(--grey-100, #F9FAFB)",
          border: "1px solid var(--grey-300, #DFE3E8)",
          borderRadius: "7px",
          maxHeight: "160px",
        }}
      >
        <Box maxWidth={"400px"} mt={1.2}>
          <Typography16
            fontWeight={700}
            // color="#303981"
            color="#212B36"
            text={
              orderDetails.target
                ? orderDetails.target.Legal_Name_of_Business
                : "N/A"
            }
          ></Typography16>
        </Box>
        <Box sx={{ px: 2, py: 2 }}>
          <Grid
            container
            sx={
              {
                // my: 2,
              }
            }
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid
              style={{
                padding: "8px",
              }}
              sx={{ my: 1 }}
              xs={2}
              sm={3}
              md={3}
            >
              <Stack direction={"row"} spacing={1}>
                <Avatar
                  src={
                    orderDetails &&
                    orderDetails.target &&
                    orderDetails.target.aadharPhotoLink
                  }
                />
                {orderDetailsBox(
                  " Authorised Business Representative",
                  orderDetails && orderDetails.target
                    ? orderDetails.target.nameInAadhaar
                    : "N/A"
                )}
              </Stack>

              <Box mt={2}>
                <Typography
                  component="legend"
                  fontSize={"11px"}
                  color={"#919EAB"}
                >
                  Rating
                </Typography>
                <Rating
                  name="simple-controlled"
                  value={4}
                  size="small"
                  disabled
                />
              </Box>
            </Grid>
            <Grid
              style={{
                padding: "8px",
              }}
              sx={{ my: 1 }}
              xs={2}
              sm={3}
              md={3}
            >
              <Box>
                <Stack direction={"row"}>
                  {orderDetailsBox(
                    "Contact",
                    orderDetails && orderDetails.target
                      ? orderDetails.target.business_mobile
                      : "N/A"
                  )}
                  <Box pt={1.5}>
                    <Tooltip title="Copy" placement="top">
                      <IconButton
                        onClick={() =>
                          onCopy(
                            orderDetails &&
                              orderDetails.target &&
                              orderDetails.target.business_mobile
                          )
                        }
                      >
                        <Icon icon="eva:copy-fill" width={20} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
              </Box>

              <Box mt={1}>
                <Typography
                  component="legend"
                  fontSize={"11px"}
                  color={"#919EAB"}
                >
                  Since
                </Typography>
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"14px"}
                  lineHeight={"22px"}
                  fontWeight={600}
                >
                  2023
                </Typography>
              </Box>
            </Grid>
            <Grid
              style={{
                padding: "8px",
              }}
              sx={{ my: 1.2 }}
              xs={2}
              sm={3}
              md={3}
            >
              <Tooltip
                title={
                  orderDetails &&
                  orderDetails.target &&
                  orderDetails.target.Place_of_Business
                }
              >
                {orderDetailsBox(
                  "Address",
                  orderDetails &&
                    orderDetails.target &&
                    removeSubstring(orderDetails.target.Place_of_Business)
                )}
              </Tooltip>
              <Box mt={2}>
                <Typography
                  component="legend"
                  fontSize={"12px"}
                  color={"#919EAB"}
                >
                  Orders Full-filed
                </Typography>
                <Stack spacing={1} display={"flex"} direction={"row"}>
                  <Typography
                    fontFamily={"Public Sans"}
                    fontSize={"14px"}
                    lineHeight={"22px"}
                    fontWeight={600}
                  >
                    {orderDetails &&
                      orderDetails.target &&
                      orderDetails.target.ordersFullfilled}{" "}
                    /{" "}
                    {orderDetails &&
                      orderDetails.target &&
                      orderDetails.target.totalOrders}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid
              style={{
                padding: "8px",
              }}
              sx={{ my: 1.2 }}
              xs={2}
              sm={3}
              md={3}
            >
              {/* {orderDetailsBox(
        "GST Number",
        orderDetails && orderDetails.target
          ? orderDetails.target.GSTIN_of_the_entity
          : "N/A"
      )} */}
              <Stack direction={"row"}>
                {orderDetailsBox(
                  "GST",
                  orderDetails && orderDetails.target
                    ? orderDetails.target.GSTIN_of_the_entity
                    : "N/A"
                )}
                <Box pt={1.5}>
                  <Tooltip title="Copy" placement="top">
                    <IconButton
                      onClick={() =>
                        onCopy(
                          orderDetails &&
                            orderDetails.target &&
                            orderDetails.target.GSTIN_of_the_entity
                        )
                      }
                    >
                      <Icon icon="eva:copy-fill" width={20} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>

              <Box mt={2}>
                <Stack
                  textAlign={"start"}
                  // spacing={5}
                  sx={
                    {
                      // px: 3,
                      // py: 1.5,
                    }
                  }
                >
                  <Box>
                    <Typography
                      component="legend"
                      fontSize={"11px"}
                      color={"#919EAB"}
                      // mt={-2}
                    >
                      Connections
                    </Typography>
                    <Typography
                      fontFamily={"Public Sans"}
                      fontSize={"14px"}
                      lineHeight={"22px"}
                      fontWeight={600}
                    >
                      {(orderDetails &&
                        orderDetails.target &&
                        orderDetails.target.connections) ||
                        0}
                    </Typography>
                  </Box>
                  {/* <Stack
            display={"flex"}
            direction={"row"}
            spacing={1.5}
            mt={1}
          >
            <Box>
              <Stack
                display={"flex"}
                direction={"row"}
                spacing={1}
              >
                <Typography
                  component="legend"
                  fontSize={"11px"}
                  color={"#919EAB"}
                >
                  Buyers
                </Typography>
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"14px"}
                  lineHeight={"22px"}
                  fontWeight={600}
                >
                  0
                </Typography>
              </Stack>
            </Box>
            <Stack
              display={"flex"}
              direction={"row"}
              spacing={1}
            >
              <Typography
                component="legend"
                fontSize={"11px"}
                color={"#919EAB"}
              >
                Sellers
              </Typography>
              <Typography
                fontFamily={"Public Sans"}
                fontSize={"14px"}
                lineHeight={"22px"}
                fontWeight={600}
              >
                0
              </Typography>
            </Stack>
          </Stack> */}
                </Stack>
              </Box>
            </Grid>

            {/*  */}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default BuisnessDetails;
