// These are the protected Routes pages for handling or providing safe accessibility for applications
import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/Header";

const ProtectedRoutes = () => {
  const Token = localStorage.getItem("Token");
  if (Token !== "" && Token !== null && Token !== undefined) {
    console.log(Token, "check Token on EveryRoute");
    return (
      <div>
        <Header />
        <Outlet />
      </div>
    );
  } else {
    //   if user have to redirect to login page if tokens not provided
    return <Navigate to={"/login"} />;
  }
};

export default ProtectedRoutes;
