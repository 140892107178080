// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField, TextFieldProps } from "@mui/material";

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
};

export default function RHFTextField({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          size="medium"
          onWheel={(e: any) => e.target.blur()}
          value={
            typeof field.value == "number" && field.value == 0
              ? ""
              : field.value
          }
          error={!!error}
          helperText={error?.message}
          InputProps={{
            style: {
              borderRadius: "13px",
              borderColor: "#919EAB",
            },
          }}
          sx={{
            "& .MuiInputBase-root": {
              height: 50,
            },
            "& .MuiOutlinedInput-input:focused": {
              borderColor: "green",
            },
            "& label.Mui-focused": {
              color: "#303981",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#E0E3E7",
              },
              "&:hover fieldset": {
                borderColor: "#B2BAC2",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#303981",
              },
            },
            "& .MuiFormLabel-root": {
              fontFamily: "Public Sans",
              // fontWeight: "500",
              fontSize: "13px",
            },
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              fontSize: "13px",
              backgroundColor: "white",
              borderRadius: 3,
              padding: "13.5px 14px",
            },
            "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
              fontSize: "10px",
            },
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          }}
          {...other}
        />
      )}
    />
  );
}
