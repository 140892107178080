import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import NoRecordFound from "../../../components/NoRecordFound";
import {
  StringDateConversion,
  numberFormat,
  splitDateConversion,
} from "../../../utils/Validations";
import { useNavigate } from "react-router-dom";
import { fontFamilys, fontSizes } from "../../../utils/FontSize";

export default function BookPayablesList(props: any) {
  const navigate = useNavigate();
  const BookPayablesList = props ? props.props : [];
  const handleViewRequestOnClick = (item: any) => {
    navigate("/dashboard/order", {
      state: { orderDetails: item },
    });
  };
  return (
    <>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F7F9FB" }}>
            <TableCell
              sx={{
                borderBottom: "none",
                px: 2,
              }}
            >
              <Typography
                fontSize={fontSizes.sm}
                fontWeight={600}
                fontFamily={fontFamilys.publicSans}
                color={"grey"}
              >
                Date
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Order ID
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontFamily={fontFamilys.publicSans}
                fontWeight={600}
                // color={"#303981"}
                color={"grey"}
              >
                Business
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontFamily={fontFamilys.publicSans}
                fontWeight={600}
                color={"grey"}
              >
                Type
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontFamily={fontFamilys.publicSans}
                fontWeight={600}
                color={"grey"}
              >
                Deduction on
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontFamily={fontFamilys.publicSans}
                fontWeight={600}
                color={"grey"}
              >
                Amount
              </Typography>
            </TableCell>

            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={fontSizes.sm}
                fontFamily={fontFamilys.publicSans}
                fontWeight={600}
                color={"grey"}
              >
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {BookPayablesList.length > 0 &&
            BookPayablesList.map((item: any, index: any) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      px: 2,
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={600}
                      color={"#212B36"}
                    >
                      {item.proposalCreatedDate !== ""
                        ? StringDateConversion(item.proposalCreatedDate)
                        : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={fontFamilys.publicSans}
                    >
                      {item.orderID !== "" ? item.orderID : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      fontFamily={fontFamilys.publicSans}
                      fontWeight={600}
                      color={"#303981"}
                    >
                      {item.Legal_Name_of_Business !== ""
                        ? item.Legal_Name_of_Business
                        : "N/A"}{" "}
                    </Typography>
                    {/* <Typography
                      fontSize={10}
                      color={"primary"}
                      fontFamily={"Public Sans"}
                    >
                      ({item && item.orderType ? item.orderType : ""}){" "}
                    </Typography> */}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={fontFamilys.publicSans}
                    >
                      {item.paymentType === "full"
                        ? "Full Payment"
                        : "Partial Payment"}{" "}
                    </Typography>
                    <Typography
                      fontSize={10}
                      color={"primary"}
                      fontFamily={"Public Sans"}
                    >
                      (
                      {item.milestoneDetails && item.milestoneDetails.length > 0
                        ? item.milestoneDetails.length
                        : 0}
                      ) {"milestones"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item && item.receivingDate
                        ? splitDateConversion(item.receivingDate)
                        : ""}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={fontSizes.sm}
                      color={"green"}
                      fontFamily={"Public Sans"}
                    >
                      {item.orderAmount ? numberFormat(item.orderAmount) : 0}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Button
                      size="small"
                      onClick={() => handleViewRequestOnClick(item)}
                      sx={{
                        fontSize: "12px",
                        fontFamily: "Public Sans",
                        backgroundColor: "",
                        color: "var(--text-light-primary, #212B36)",
                        borderRadius: "8px",
                        fontWeight: 700,

                        fontStyle: "normal",
                        border: "1px solid  rgba(145, 158, 171, 0.32)",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#36B37E",
                          color: "white",
                        },
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {BookPayablesList.length <= 0 ? (
        <NoRecordFound text1={"Booked Payables"} />
      ) : (
        ""
      )}
    </>

    // </TableContainer>
  );
}
