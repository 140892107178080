import { Link } from "react-router-dom";
import BusinessNetwork1 from "../../assets/BussinessNetwork1.svg";
import Table1 from "./Busi3Table";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  MenuItem,
  Typography,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Chips } from "../../components/businessNetwok/chips/Chips";
import { TypographyText } from "../../components/businessNetwok/typography/TypographyText";
import axios from "axios";
import { useSelector } from "react-redux";
import React from "react";
import { Typography14 } from "../../components/typography/Typography";
import { enqueueSnackbar } from "notistack";

const Busi1 = () => {
  const searchType = [
    { value: "gst", label: "GST" },
    { value: "businessName", label: "Business Name" },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [Token, setToken] = useState<any>("");
  const [UserSearchType, setUserSearchType] = useState("");
  const newToken = useSelector((state: any) => state.user.Token);
  const [NotFound, setNotFound] = useState(false);
  const [userCount, setuserCount] = useState<any>({});
  // user Kyc Status for approver or new or rejected state
  const [userKycStatusValue, setuserKycStatusValue] = useState<any>("");

  // userlist for save data from all users
  const [UserList, setUserList] = useState([]);
  const [searchValue, setsearchValue] = useState("");

  const getUsersList = async () => {
    setNotFound(false);
    let gstSearchValue;
    let businessNameSearchValue;
    // condition for params empty or not
    if (UserSearchType === "gst") {
      businessNameSearchValue = "";
      gstSearchValue = searchValue;
    } else {
      gstSearchValue = "";
      businessNameSearchValue = searchValue;
    }
    let params = {
      page: page > 0 ? page : 1,
      rowsLimitInPage: rowsPerPage ? rowsPerPage : 10,
      status: userKycStatusValue,
      businessName: businessNameSearchValue,
      gst: gstSearchValue,
    };
    // userToken check
    if (newToken !== "") {
      const axiosConfig = {
        headers: {
          token: newToken ? newToken : "",
        },
        params: params,
      };

      const FetchData = await axios
        .get(
          "https://api.dev.assuredpay.in/userkyc/getBusinessNetwork",
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200 && res.data.Active === true) {
            let count: any = res.data.result;
            console.log(count.totalCount, "...................");
            let data: any = res.data.result.data;
            setuserCount(count);
            if (data.length > 0) {
              setUserList(data);
            } else {
              setUserList([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setNotFound(true);
        });
    } else {
      console.log("token token token", Token);
    }
  };
  console.log("console.log.....", userCount);
  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });
  useEffect(() => {
    getUsersList();
  }, [UserSearchType, searchValue, userKycStatusValue, rowsPerPage, page]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setuserKycStatusValue(newValue);
    console.log(newValue);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 140,
        borderRadius: "8px",
      },
    },
  };
  const handleClickInActiveStatus = async (value: any) => {
    console.log(value, "handleClickInActiveStatushandleClickInActiveStatus");
    let params = {
      businessId: value,
    };
    const axiosConfig = {
      headers: {
        token: Token !== "" ? JSON.parse(Token) : "",
      },
      params: params,
    };

    const FetchData = await axios
      .put(
        "https://api.dev.assuredpay.in/userkyc/updateBusinessNetwork",
        { status: "inActive" },
        axiosConfig
      )
      .then((res) => {
        console.log("res", res);
        enqueueSnackbar("Status Disabled Successfully", {
          variant: "error",
          style: {
            color: "white",
            // color: "rgba(54, 179, 126, 1)",
            backgroundColor: "#FF7276",
            marginRight: "100px",
            fontFamily: "Public Sans",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            maxWidth: "400px",
            marginTop: "50px",
          },
        });
        getUsersList();
      })
      .catch((err) => {
        console.log(err, "err business network");
      });
  };
  const handleClickActiveStatus = async (value: any) => {
    let params = {
      businessId: value,
    };
    const axiosConfig = {
      headers: {
        token: Token !== "" ? JSON.parse(Token) : "",
      },
      params: params,
    };

    const FetchData = await axios
      .put(
        "https://api.dev.assuredpay.in/userkyc/updateBusinessNetwork",
        { status: "active" },
        axiosConfig
      )
      .then((res) => {
        console.log("res", res);
        enqueueSnackbar("Status Enabled Successfully", {
          variant: "success",
          style: {
            color: " var(--success-darker, #0A5554)",
            // color: "rgba(54, 179, 126, 1)",
            backgroundColor: "var(--success-lighter, #D8FBDE)",
            marginRight: "100px",
            fontFamily: "Public Sans",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            maxWidth: "400px",
            marginTop: "50px",
          },
        });
        getUsersList();
      })
      .catch((err) => {
        console.log(err, "err business network");
      });
  };

  return (
    <>
      <Stack mx={4}>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"24px"}
          sx={{ mt: 4 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          Business Networks
        </Typography>
        <Stack display={"flex"} direction={"row"} spacing={2}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Public Sans",
            }}
          >
            Dashboard
          </Typography>
          <Typography>.</Typography>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"14px"}
            fontWeight={400}
            fontStyle={"normal"}
            color={"rgba(145, 158, 171, 1)"}
          >
            Business Network
          </Typography>
        </Stack>
        <Box display={"flex"} justifyContent={"end"} mt={-5}>
          <Link to={"/dashboard/businesslist"}>
            <Button
              size="medium"
              sx={{
                // display: "flex",
                color: "white",
                borderRadius: "8px",
                backgroundColor: "#303981",
                textAlign: "right",
                fontFamily: "Public Sans",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#303981",
                },
              }}
            >
              <AddIcon sx={{ mr: 1 }} fontSize="small" />{" "}
              <Typography14
                color={"white"}
                text={"Add Business Network"}
              ></Typography14>
            </Button>
          </Link>
        </Box>
      </Stack>
      {/* {UserList.length < 0 ? ( */}
      {NotFound ? (
        <Box
          boxShadow={1}
          mx={4}
          my={2}
          borderRadius={"16px"}
          gap={"40px"}
          display={"flex"}
          justifyContent={"center"}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
          height={"auto"}
        >
          <Stack
            mt={8}
            display={"flex"}
            justifyContent={"center"}
            textAlign={"center"}
          >
            <img
              src={BusinessNetwork1}
              alt=""
              height={"150px"}
              width={"auto"}
            />
            <Typography
              fontFamily={"Public Sans"}
              fontSize={24}
              sx={{ mt: 4 }}
              fontWeight={700}
              fontStyle={"normal"}
              color={"#161C24"}
            >
              No Business Network Found
            </Typography>
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"14px"}
              fontStyle={"normal"}
              lineHeight={"22px"}
              color={"#454F5B"}
              fontWeight={400}
              sx={{ my: 4, mt: 1 }}
            >
              Please Add new Business Network for see details
            </Typography>
          </Stack>
        </Box>
      ) : (
        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
          }}
        >
          <Box
            boxShadow={1}
            mx={4}
            my={3}
            sx={{ borderRadius: "8px", backgroundColor: "white" }}
          >
            <Tabs
              value={userKycStatusValue}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="secondary"
              sx={{
                px: 2,
                "& .MuiTabs-indicator": {
                  // ml: 4,
                  // maxWidth: "90px",
                },
              }}
            >
              <Tab
                value=""
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={userCount.totalCount ? userCount.totalCount : 0}
                      chipColor={"rgba(0, 184, 217, 0.16)"}
                      chipFontColor={"#006C9C"}
                    />
                    <TypographyText color={"black"} text={"All"} />
                  </Stack>
                }
              ></Tab>
              <Tab
                value="active"
                sx={{ textTransform: "none", px: 4 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={
                        userCount.activeStatusCount
                          ? userCount.activeStatusCount
                          : 0
                      }
                      chipColor={"rgba(54, 179, 126, 0.16)"}
                      chipFontColor={"rgba(27, 128, 106, 1)"}
                    />
                    <TypographyText
                      color={"rgba(99, 115, 129, 1)"}
                      text={"Active"}
                    />
                  </Stack>
                }
              />
              <Tab
                value="inActive"
                sx={{ textTransform: "none", px: 4 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={
                        userCount.inactiveStatusCount
                          ? userCount.inactiveStatusCount
                          : 0
                      }
                      chipColor={"rgba(255, 171, 0, 0.16)"}
                      chipFontColor={"rgba(183, 110, 0, 1)"}
                    />
                    <TypographyText
                      color={"rgba(99, 115, 129, 1)"}
                      text={"Disabled"}
                    />
                  </Stack>
                }
              />
            </Tabs>
            <Divider sx={{ color: "rgba(145, 158, 171, 0.24)" }}></Divider>
            <Stack
              sx={{ mx: 4, py: 2 }}
              display={"flex"}
              direction={"row"}
              spacing={1}
            >
              {" "}
              <FormControl sx={{ minWidth: "12vw" }}>
                <InputLabel
                  sx={{
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "500",
                    fontSize: "16",
                  }}
                >
                  Search Type
                </InputLabel>
                <Select
                  disabled
                  value={UserSearchType ? UserSearchType : ""}
                  onChange={(e) => setUserSearchType(e.target.value)}
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                  }}
                  input={
                    <OutlinedInput
                      sx={{
                        borderRadius: "8px",
                        "& .MuiFormLabel-root": {
                          fontSize: "10px",
                        },
                      }}
                      label="Seacrh Type"
                    />
                  }
                  MenuProps={MenuProps}
                >
                  {searchType.map((name, index) => (
                    <MenuItem key={index} value={name.value}>
                      <Typography
                        fontFamily={"Public Sans"}
                        fontSize={"14px"}
                        fontWeight={400}
                      >
                        {name.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                disabled
                fullWidth
                onChange={(e) => setsearchValue(e.target.value)}
                placeholder="Search gst number, mobile number ....."
                InputProps={{
                  style: { borderRadius: "10px" },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Box>
              {UserList.length > 0 ? (
                <Table1
                  props={UserList}
                  handleClickInActiveStatus={handleClickInActiveStatus}
                  handleClickActiveStatus={handleClickActiveStatus}
                />
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  textAlign={"center"}
                  height={"40vh"}
                >
                  <Stack mt={10} spacing={1}>
                    <Typography14 text="No Record Found !"></Typography14>
                  </Stack>
                </Box>
              )}
            </Box>
            <Box>
              <Divider></Divider>
              <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
                <TablePagination
                  component="div"
                  count={10}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* ) : ( */}

      {/* )} */}
      {/* </Box> */}
    </>
  );
};

export default Busi1;
