import { Box, Stack, Container, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CustomizedSteppers from "../../components/Stepper";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Docs1 from "../../assets/Docs1.svg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LinearDeterminate from "../../components/ProgressBar";
import { useEffect, useState } from "react";
import { UploadFile } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import DoneIcon from "@mui/icons-material/Done";

const CreateBusinessProfile = () => {
  document.title = "Let's Get Started with Your Information";

  const navigate = useNavigate();

  const [FileUploadStatus, setFileUploadStatus] = useState(false);
  const [uploadFileData, setuploadFileData] = useState(null);
  const [Token, setToken] = useState<any>("");
  const [AadharFlie1, setAadharFlie1] = useState(false);
  const [AadharFile1BtnDis, setAadharFile1BtnDis] = useState(false);
  const [btnDisabledForGstDocs, setbtnDisabledForGstDocs] = useState(true);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  console.log("file", uploadFileData);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  const handleFileUpload = async (e: any) => {
    let file;
    if (e.target.name === "GSTFILE") {
      file = e.target.files[0];
      setAadharFlie1(true);
      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: Token !== "" ? JSON.parse(Token) : "",
        },
        params: {
          filename: e.target.name,
        },
      };
      console.log("failesssssssssssssssssssssss", file);
      const FetchData = await axios
        .post(
          "https://api.dev.assuredpay.in/uploadRoutes/uploaddoc",
          { document: file },
          axiosConfig
        )
        .then((res) => {
          const getUserLogin = res;
          if (getUserLogin.status === 200) {
            setAadharFlie1(false);
            setAadharFile1BtnDis(true);
            setbtnDisabledForGstDocs(false);
          }
        })
        .catch((err) => {
          console.log(err, "catch Method");
          // setshowAadharVerifyMsgError(true);
        });
    }
    // reader.onload = (e) => {
    //   // setuploadFileData(e.target.result);
  };
  const handleOnClickfileUploadSuccess = () => {
    navigate("/auth/verifyaadhar");
  };
  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });
  return (
    <Box margin={1} sx={{ flexGrow: 1 }}>
      <Box textAlign={"center"}>

      <Stack sx={{ px: "100px" }}>
        <CustomizedSteppers />
      </Stack>
      </Box>

      <Container component="main" maxWidth="xl" sx={{ mt: 1 }}>
        <Box
          boxShadow={"1"}
          justifyContent={"center"}
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 5,
            py: 2,
            pb:4
          }}
        >
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ fontSize: "12px" }}
          >
            <Grid sx={{ order: { xs: 12, sm: 2 }, mt: 4, mb: 2 }}>
              <Typography
                component="div"
                fontFamily={"Public Sans"}
                fontSize={20}
                fontWeight={700}
                lineHeight={"30px"}
                color={"#212B36"}
              >
                Create Your Business Profile
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                mb: 5,
                color: "#556486",
                fontWeight: 600,
                fontFamily: "Public Sans",
                fontStyle: "normal",
              }}
            >
              {" "}
              List of KYC documents for Private Company
            </Typography>
          </Box>

          <Box padding={0}>
            <Grid container>
              <Grid xs={10}>
                <Item>
                  <Stack display={"flex"} direction={"row"} spacing={5}>
                    <img
                      src={Docs1}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginTop: "0px",
                      }}
                      alt=""
                    />
                    <Typography
                      fontSize={14}
                      color={"#454F5B"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      fontWeight={500}
                    >
                      {" "}
                      Certificate of Incorporation
                    </Typography>
                  </Stack>{" "}
                </Item>
              </Grid>
              <Grid xs>
                <Item>
                  {AadharFlie1 ? (
                    <LinearDeterminate />
                  ) : (
                    <>
                      {!AadharFile1BtnDis ? (
                        <Button
                          component="label"
                          // variant="contained"
                          size="small"
                          sx={{
                            border: "1px solid",
                            mb: 4,
                            color: "black",
                            px: 1,
                            fontFamily: "Public Sans",
                            fontSize: "14px",
                            textTransform: "none",
                          }}
                          startIcon={<FileUploadIcon sx={{ mt: 0 }} />}
                        >
                          Upload
                          <VisuallyHiddenInput
                            name="GSTFILE"
                            onChange={handleFileUpload}
                            sx={{ px: 2 }}
                            type="file"
                          />
                        </Button>
                      ) : (
                        <Stack direction="row">
                          <DoneIcon
                            style={{ fontSize: "12px", color: "#36B37E" }}
                          />{" "}
                          <Typography
                            fontWeight={700}
                            fontSize={10}
                            fontFamily={"Public Sans"}
                            color={"#36B37E"}
                          >
                            {" "}
                            File upload Successfully
                          </Typography>
                        </Stack>
                      )}
                    </>
                  )}
                </Item>
              </Grid>
            </Grid>

            <Stack display={"flex"} direction={"row"} sx={{ mt: 30 }}>
              <Button
                size="small"
                onClick={handleOnClickfileUploadSuccess}
                disabled={btnDisabledForGstDocs}
                sx={{
                  px: 2,
                  textTransform: "none",
                  backgroundColor: btnDisabledForGstDocs
                    ? "rgba(145, 158, 171, 0.24)"
                    : "#303981",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  borderRadius: "8px",
                  color: btnDisabledForGstDocs ? "" : "white",
                  "&:hover": {
                    backgroundColor: btnDisabledForGstDocs
                      ? "rgba(145, 158, 171, 0.24)"
                      : "#303981",
                  },
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>

          {/* Gst Verify Check for Button and Success Msg showing after successfull gst verify check */}
        </Box>
      </Container>
    </Box>
  );
};

export default CreateBusinessProfile;
