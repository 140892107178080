import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import { Box, Chip, Stack, Typography, Popover } from "@mui/material";
export default function MakerListTable(props: any) {
  const [Token, setToken] = useState<any>("");
  const makerList = props ? props.props : [];

  const chipBackgroundColor = (value: any) => {
    if (value === "active") {
      return "var(--transparent-primary-16, rgba(0, 171, 85, 0.16))";
    } else {
      return "var(--transparent-error-16, rgba(255, 86, 48, 0.16))";
    }
  };
  const chipTextColor = (value: any) => {
    if (value === "active") {
      return "var(--primary-dark, #007B55)";
    } else {
      return "var(--error-dark, #B71D18)";
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);

  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });

  return (
    <>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F7F9FB" }}>
            <TableCell
              sx={{
                borderBottom: "none",
                px: 4,
              }}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                User Name
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Email
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Phone
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Last Activity
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
              <Typography
                fontSize={14}
                fontWeight={600}
                fontFamily={"Public Sans"}
                color={"grey"}
              >
                Status
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: "none",
              }}
            >
              {" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {makerList.length > 0 &&
            makerList.map((item: any, index: any) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      px: 4,
                    }}
                  >
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                      fontWeight={600}
                    >
                      {item.username !== "" ? item.username : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                    >
                      {item.business_email !== "" ? item.business_email : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                      // fontWeight={00}
                    >
                      {item.business_mobile !== ""
                        ? item.business_mobile
                        : "N/A"}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontSize={13}
                      color={"#212B36"}
                      fontFamily={"Public Sans"}
                      // fontWeight={00}
                    >
                      {"N/A"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {" "}
                    <Chip
                      label={item.currentStatus}
                      style={{
                        backgroundColor: chipBackgroundColor(
                          item.currentStatus
                        ),
                        color: chipTextColor(item.currentStatus),
                        fontWeight: 700,
                        fontSize: 12,
                        lineHeight: "20px",
                        fontFamily: "Public Sans",
                      }}
                      sx={{
                        minheight: "24px",
                        minwidth: "32px",
                        "& .MuiChip-label": {
                          fontWeight: 700,
                        },
                        borderRadius: "8px",
                      }}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {/* <MoreVertIcon fontSize="small" /> */}
                    <div>
                      <Typography onClick={(e: any) => handleClick(e, item)}>
                        <Chip
                          label={<MoreVertIcon fontSize="small" />}
                          sx={{
                            borderRadius: "50%",
                            minWidth: "40px",
                            minHeight: "40px",
                          }}
                        />
                      </Typography>

                      <Popover
                        id={item.userId}
                        open={openPopOver}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        sx={{
                          ml: -10,
                          mt: 1,
                        }}
                      >
                        <Box>
                          {selectedItem.currentStatus === "inActive" ? (
                            <Stack m={1.5}>
                              <Typography
                                fontSize={14}
                                fontFamily={"Public Sans"}
                                sx={{ p: 1.5 }}
                                fontWeight={600}
                                onClick={() =>
                                  props.handleClickActiveStatus(
                                    selectedItem.userId
                                  )
                                }
                              >
                                Enable
                              </Typography>
                            </Stack>
                          ) : (
                            <Stack m={1.5}>
                              <Typography
                                fontSize={14}
                                fontFamily={"Public Sans"}
                                sx={{ p: 1.5 }}
                                fontWeight={600}
                                onClick={() =>
                                  props.handleClickInActiveStatus(
                                    selectedItem.userId
                                  )
                                }
                              >
                                Disable
                              </Typography>
                            </Stack>
                          )}
                        </Box>
                      </Popover>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
    // </TableContainer>
  );
}
