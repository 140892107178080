import { Link } from "react-router-dom";
import SCircleIcon from "../../../assets/S.svg";
import BankBalanceIcon from "../../../assets/bankBalance.svg";
import BookedPayablesIcon from "../../../assets/BookedPayable.svg";

import SCircleGreen from "../../../assets/SCircleGreen.svg";
import SCircleYellow from "../../../assets/SCircleYellow.svg";
import SCircleYellow1 from "../../../assets/Receivables.svg";

import SPreClaim from "../../../assets/PreClaim.svg";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  TablePagination,
  Tabs,
  Tab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Chips } from "../../../components/businessNetwok/chips/Chips";
import { TypographyText } from "../../../components/businessNetwok/typography/TypographyText";
import axios from "axios";
import { useSelector } from "react-redux";
import React from "react";
import {
  Typography12,
  Typography16,
  Typography18,
} from "../../../components/typography/Typography";
import PaymentListTable from "./PaymentListTable";
import SimpleLoader from "../../../components/Loader";
import {
  accurateDateFormat,
  numberFormat,
  numberToEnglish,
} from "../../../utils/Validations";
import { ErrorMsg } from "../../../components/Messages/ErrorMsg";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { ArrowCircleDown } from "@mui/icons-material";

const Dashboard = () => {
  let filterStatus = "";
  const [open, setopen] = useState(false);
  const searchType = [
    { value: "gst", label: "GST" },
    { value: "businessName", label: "Business Name" },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    let value = newPage + 1;
    setPage(value);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    getpaymentList();
    // setPage(2);
  };

  const [Token, setToken] = useState<any>("");
  const [tabValue, setTabValue] = useState("buyer");
  const [UserSearchType, setUserSearchType] = useState("");

  let newToken: any = useSelector((state: any) =>
    state.user.Token ? state.user.Token : null
  );
  const [NotFound, setNotFound] = useState(false);
  // user Kyc Status for approver or new or rejected state
  const [userKycStatusValue, setuserKycStatusValue] = useState<any>("all");
  const [BankBalanceData, setBankBalanceData] = useState<any>({});
  // userlist for save data from all users
  const [OrderList, setOrderLists] = useState([]);
  const [searchValue, setsearchValue] = useState("");
  const [OrderCount, setOrderCount] = useState<any>({});
  const [dashboardData, setdashboardData] = useState<any>({});
  const [startDate, setStartDate] = useState<any>("");
  const [EndDate, setEndDate] = useState<any>("");

  const handleonChangeForDate = (value: any) => {
    const selectedDate = accurateDateFormat(value);
    setStartDate(selectedDate);
  };

  const handleonChangeForDate1 = (value: any) => {
    const selectedDate = accurateDateFormat(value);
    setEndDate(selectedDate);
  };
  useEffect(() => {
    if ((startDate && EndDate) || searchValue) {
      getpaymentList();
    }
  }, [startDate, EndDate, searchValue]);
  // Wallet Balance
  const BankBalance = async () => {
    let params;
    const axiosConfig = {
      headers: {
        token: newToken ? newToken : "",
      },
      params: params,
    };
    await axios
      .get(
        "https://api.dev.assuredpay.in/checkerRoutes/getwalletbalance",
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let orderData = res.data.result;
          setBankBalanceData(orderData);
          console.log("listdata");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Payment List
  const getpaymentList = async () => {
    setNotFound(false);
    setopen(true);
    let params = {
      page: page > 0 ? page : 1,
      rowsLimitInPage: rowsPerPage ? rowsPerPage : 10,
      paymentIdentifier: tabValue,
      status: userKycStatusValue,
      startDate: startDate,
      endDate: EndDate,
      search: searchValue,
    };
    if (newToken !== "") {
      const axiosConfig = {
        headers: {
          token: newToken ? newToken : "",
        },
        params: params,
      };

      await axios
        .get(
          "https://api.dev.assuredpay.in/checkerRoutes/getallpaymentofchecker",
          axiosConfig
        )
        .then((res) => {
          if (res.status === 200) {
            let list = res.data.result;
            let count = res.data.orderCounts;
            setOrderCount(count);
            if (list.length > 0) {
              setOrderLists(list);
            } else {
              setOrderLists([]);
            }
            setopen(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setNotFound(true);
          setopen(false);
        });
    } else {
      console.log("token token token", Token);
    }
  };

  // Dashboard api Data
  const paymentDetailsDashboard = async () => {
    let params;
    const axiosConfig = {
      headers: {
        token: newToken ? newToken : "",
      },
      params: params,
    };
    await axios
      .get(
        "https://api.dev.assuredpay.in/checkerRoutes/dashboard/data",
        axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          let orderData = res.data;
          setdashboardData(orderData);
        }
      })
      .catch((err) => {
        ErrorMsg("Something went wrong, Please try again!");
        console.log(err);
      });
  };
  useEffect(() => {
    paymentDetailsDashboard();
    BankBalance();
  }, [newToken]);
  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });

  useEffect(() => {
    getpaymentList();
  }, [tabValue, userKycStatusValue]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setuserKycStatusValue(newValue);
    setPage(1);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 140,
        borderRadius: "8px",
      },
    },
  };
  const handleOnChangeTabValue = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setTabValue(newValue);
    getpaymentList();
  };
  const resetSeachValue = () => {
    setEndDate("");
    setStartDate("");
    setsearchValue("");
    console.log(EndDate, startDate, searchValue);
  };

  return (
    <div>
      {" "}
      <>
        <Stack mx={4}>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"22px"}
            sx={{ mt: 6 }}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#000000"}
          >
            Payment Dashboard
          </Typography>

          <Box display={"flex"} justifyContent={"end"} mt={-5}>
            <Link to={"/dashboard/selectpayment"}>
              <Button
                size="medium"
                sx={{
                  color: "white",
                  px: 2,
                  borderRadius: "8px",
                  backgroundColor: "#303981",
                  textAlign: "right",
                  fontFamily: "Public Sans",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#303981",
                  },
                }}
              >
                <AddIcon sx={{ mr: 1 }} fontSize="small" />{" "}
                <Typography12
                  color={"white"}
                  text={"New Payment"}
                ></Typography12>
              </Button>
            </Link>
          </Box>
        </Stack>
        {/* {paymentList.length < 0 ? ( */}
        <Box
          boxShadow="0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
          mx={4}
          mt={3}
          mb={1.5}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
        >
          <Grid xs={12} display={"flex"} container px={2}>
            <Grid xs={3}>
              <Stack display={"flex"} direction={"row"}>
                <Box
                  sx={{
                    m: 3,
                  }}
                >
                  <img
                    src={BankBalanceIcon}
                    width={"60px"}
                    height={"60px"}
                    alt=""
                  />
                </Box>
                <Box mt={1.5}>
                  <Stack spacing={0}>
                    <Typography18
                      text="Available Balance"
                      fontWeight={700}
                      color="#212B36"
                    ></Typography18>
                    <Typography
                      // maxWidth={"120px"}
                      fontSize={12}
                      color="#212B36"
                      fontWeight={700}
                    >
                      (can be used to make payments)
                    </Typography>
                    <Typography16
                      fontWeight={700}
                      color="#FF5630"
                      text={`Rs. ${
                        BankBalanceData && BankBalanceData.availableBalance
                          ? numberFormat(
                              parseInt(BankBalanceData.availableBalance)
                            ) + " "
                          : 0
                      }`}
                    ></Typography16>
                    <Typography
                      maxWidth={"200px"}
                      sx={{ p: 0.2 }}
                      color={"#FF5630"}
                      lineHeight={"12px"}
                      fontWeight={500}
                      fontFamily={"Public Sans"}
                      fontSize={11}
                    >
                      {BankBalanceData.availableBalance > 0
                        ? numberToEnglish(BankBalanceData.availableBalance)
                        : ""}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack display={"flex"} direction={"row"}>
                <Box
                  sx={{
                    m: 3,
                  }}
                >
                  <img
                    src={BookedPayablesIcon}
                    width={"60px"}
                    height={"60px"}
                    alt=""
                  />
                </Box>
                <Box mt={2}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/dashboard/bookpayables"}
                  >
                    <Stack>
                      <Typography18
                        text="Booked Payables"
                        fontWeight={700}
                        color="#212B36"
                      ></Typography18>
                      <Typography
                        maxWidth={"120px"}
                        fontSize={12}
                        color="#212B36"
                        fontWeight={700}
                      >
                        {dashboardData && dashboardData.bookedPayment
                          ? dashboardData.bookedPayment.documentCount
                          : 0}{" "}
                        Orders
                      </Typography>
                      <Typography16
                        fontWeight={700}
                        color="#36B37E"
                        text={`Rs. ${
                          dashboardData && dashboardData.bookedPayment
                            ? numberFormat(
                                dashboardData.bookedPayment.sumOrderAmount
                              )
                            : 0
                        }`}
                      >
                        {" "}
                      </Typography16>
                      <Typography
                        maxWidth={"200px"}
                        sx={{ p: 0.2 }}
                        color={"#36B37E"}
                        lineHeight={"12px"}
                        fontWeight={500}
                        fontFamily={"Public Sans"}
                        fontSize={11}
                      >
                        {dashboardData && dashboardData.bookedPayment
                          ? numberToEnglish(
                              parseInt(
                                dashboardData.bookedPayment.sumOrderAmount
                              )
                            )
                          : ""}{" "}
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack display={"flex"} direction={"row"}>
                <Box
                  sx={{
                    m: 3,
                  }}
                >
                  <img
                    src={SCircleYellow1}
                    width={"60px"}
                    height={"60px"}
                    alt=""
                  />
                </Box>
                <Box mt={2}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/dashboard/availablerecievables"}
                  >
                    <Stack>
                      <Typography18
                        text="Available Receivables"
                        fontWeight={700}
                        color="#212B36"
                      ></Typography18>
                      <Typography
                        maxWidth={"120px"}
                        fontSize={12}
                        color="#212B36"
                        fontWeight={700}
                      >
                        {dashboardData && dashboardData.receivables
                          ? dashboardData.receivables.totalMilestones
                          : 0}{" "}
                        Orders
                      </Typography>
                      <Typography16
                        fontWeight={700}
                        color="#FFAB00"
                        text={`Rs. ${
                          dashboardData && dashboardData.receivables
                            ? numberFormat(
                                parseInt(
                                  dashboardData.receivables.totalReceivable
                                )
                              )
                            : 0
                        }`}
                      ></Typography16>
                      <Typography
                        maxWidth={"200px"}
                        sx={{ p: 0.2 }}
                        color={"#FFAB00"}
                        lineHeight={"12px"}
                        fontWeight={500}
                        fontFamily={"Public Sans"}
                        fontSize={11}
                      >
                        {dashboardData && dashboardData.receivables
                          ? numberToEnglish(
                              parseInt(
                                dashboardData.receivables.totalReceivable
                              )
                            )
                          : ""}{" "}
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack display={"flex"} direction={"row"}>
                <Box
                  sx={{
                    m: 3,
                  }}
                >
                  <img src={SPreClaim} width={"60px"} height={"60px"} alt="" />
                </Box>
                <Box mt={4}>
                  <Stack>
                    <Typography18
                      text="Pre Claims"
                      fontWeight={700}
                      color="#212B36"
                    ></Typography18>
                    <Typography
                      maxWidth={"120px"}
                      fontSize={12}
                      color="#212B36"
                      fontWeight={700}
                    >
                      N/A
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Stack mx={4} mt={3}>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"20px"}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#000000"}
          >
            Actionable Items
          </Typography>
        </Stack>
        <Box
          boxShadow="0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
          mx={4}
          display={"flex"}
          justifyContent={"center"}
          my={1}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
        >
          <Grid xs={12} container px={2}>
            <Grid xs={3}>
              <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
                <Box
                  sx={{
                    m: 3,
                  }}
                >
                  <img
                    src={SCircleIcon}
                    width={"60px"}
                    height={"60px"}
                    alt=""
                  />
                </Box>
                <Box mt={2}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/dashboard/requestpayments"}
                  >
                    <Stack>
                      <Typography18
                        text="My Payment Requests"
                        fontWeight={700}
                        color="#212B36"
                      ></Typography18>
                      <Typography
                        maxWidth={"120px"}
                        fontSize={12}
                        color="#FFAB00"
                        fontWeight={700}
                      >
                        {dashboardData && dashboardData.createdPaymentRequests
                          ? numberFormat(
                              dashboardData.createdPaymentRequests.documentCount
                            )
                          : 0}{" "}
                        Orders
                      </Typography>
                      <Typography16
                        fontWeight={700}
                        color="#FFAB00"
                        text={`Rs. ${
                          dashboardData && dashboardData.createdPaymentRequests
                            ? numberFormat(
                                dashboardData.createdPaymentRequests.totalSum
                              )
                            : 0
                        }`}
                      ></Typography16>
                      <Typography
                        maxWidth={"200px"}
                        sx={{ p: 0.2 }}
                        color={"#FFAB00"}
                        lineHeight={"12px"}
                        fontWeight={500}
                        fontFamily={"Public Sans"}
                        fontSize={11}
                      >
                        {dashboardData && dashboardData.createdPaymentRequests
                          ? numberToEnglish(
                              dashboardData.createdPaymentRequests.totalSum
                            )
                          : ""}
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    m: 3,
                  }}
                >
                  <img
                    src={SCircleIcon}
                    width={"60px"}
                    height={"60px"}
                    alt=""
                    color="#FF5630"
                  />
                </Box>
                <Box mt={2}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/dashboard/acceptrequest"}
                  >
                    <Stack>
                      <Typography18
                        text="Make Payment"
                        fontWeight={700}
                        color="#212B36"
                      ></Typography18>
                      <Typography
                        maxWidth={"120px"}
                        fontSize={12}
                        color="#FF5630"
                        fontWeight={700}
                      >
                        {dashboardData && dashboardData.receivedPaymentRequests
                          ? numberFormat(
                              dashboardData.receivedPaymentRequests
                                .documentCount
                            )
                          : 0}{" "}
                        Buyers
                      </Typography>
                      <Typography16
                        fontWeight={700}
                        color="#FF5630"
                        text={`Rs. ${
                          dashboardData &&
                          dashboardData.receivedPaymentRequests &&
                          dashboardData.receivedPaymentRequests.totalSum
                            ? numberFormat(
                                dashboardData.receivedPaymentRequests.totalSum
                              )
                            : 0
                        }
                         `}
                      ></Typography16>
                      <Typography
                        maxWidth={"200px"}
                        sx={{ p: 0.2 }}
                        color={"#FF5630"}
                        lineHeight={"12px"}
                        fontWeight={500}
                        fontFamily={"Public Sans"}
                        fontSize={11}
                      >
                        {dashboardData && dashboardData.receivedPaymentRequests
                          ? numberToEnglish(
                              dashboardData.receivedPaymentRequests.totalSum
                            )
                          : ""}
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    m: 3,
                  }}
                >
                  <img
                    src={SCircleGreen}
                    width={"60px"}
                    height={"60px"}
                    alt=""
                  />
                </Box>
                <Box mt={2}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/dashboard/acceptpayments"}
                  >
                    <Stack>
                      <Typography18
                        text="Accept Payment"
                        fontWeight={700}
                        color="#212B36"
                      ></Typography18>
                      <Typography
                        maxWidth={"120px"}
                        fontSize={12}
                        color="#212B36"
                        fontWeight={700}
                      >
                        {dashboardData && dashboardData.receivedOrderRequests
                          ? numberFormat(
                              dashboardData.receivedOrderRequests.documentCount
                            )
                          : 0}{" "}
                        Buyers
                      </Typography>
                      <Typography16
                        fontWeight={700}
                        color="#36B37E"
                        text={`Rs. ${
                          dashboardData &&
                          dashboardData.receivedOrderRequests &&
                          dashboardData.receivedOrderRequests.sumOrderAmount
                            ? numberFormat(
                                dashboardData.receivedOrderRequests
                                  .sumOrderAmount
                              )
                            : 0
                        }
                         `}
                      ></Typography16>
                      <Typography
                        maxWidth={"200px"}
                        sx={{ p: 0.2 }}
                        color={"#36B37E"}
                        lineHeight={"12px"}
                        fontWeight={500}
                        fontFamily={"Public Sans"}
                        fontSize={11}
                      >
                        {dashboardData && dashboardData.receivedOrderRequests
                          ? numberToEnglish(
                              dashboardData.receivedOrderRequests.sumOrderAmount
                            )
                          : ""}
                      </Typography>
                    </Stack>
                  </Link>
                </Box>
              </Stack>
            </Grid>
            <Grid xs={3}>
              <Stack display={"flex"} direction={"row"}>
                <Box
                  sx={{
                    m: 3,
                  }}
                >
                  <img
                    src={SCircleYellow}
                    width={"60px"}
                    height={"60px"}
                    alt=""
                  />
                </Box>
                <Box mt={4}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/dashboard/makerrequest"}
                  >
                    <Stack>
                      <Typography18
                        text="Maker Requests"
                        fontWeight={700}
                        color="#212B36"
                      ></Typography18>
                      <Typography
                        maxWidth={"120px"}
                        fontSize={12}
                        color="#212B36"
                        fontWeight={700}
                      >
                        0 Requests
                      </Typography>
                      {/* <Typography12
                      fontWeight={700}
                      color="#FFAB00"
                      text={"Rs. 50,00,000"}
                    ></Typography12> */}
                    </Stack>
                  </Link>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Tabs
          value={tabValue}
          onChange={handleOnChangeTabValue}
          sx={{
            mt: 2,
            mx: 2,
            "& .MuiTabs-indicator": {
              maxWidth: "60px",
              mx: 2,
              backgroundColor: "rgba(48, 57, 129, 1)",
              textColor: "rgba(33, 43, 54, 1)",
            },

            "& .MuiTab-textColorPrimary": {
              color: "black",
            },
            "& .MuiTab-textColorSecondary": {
              color: "red",
            },
          }}
        >
          <Tab
            label="Buying"
            value="buyer"
            sx={{
              textDecoration: "none",
              textTransform: "none",
              "&.MuiTab-labelIcon": {
                fontSize: "12px",
                fontWeight: "600",
                fontFamily: "Public Sans",
                textTransform: "none",
              },
            }}
          />
          <Tab
            label="Selling"
            value={"seller"}
            sx={{
              ml: -2,
              textTransform: "none",
              "&.MuiTab-labelIcon": {
                fontSize: "12px",
                fontWeight: "600",
                fontFamily: "Public Sans",
                textTransform: "none",
              },
            }}
          />
        </Tabs>
        <Box
          justifyContent={"center"}
          component={"div"}
          sx={{
            backgroundColor: "#F7F9FB",
            pb: 4,
          }}
        >
          <Box
            boxShadow={1}
            mx={4}
            my={2}
            sx={{ borderRadius: "8px", backgroundColor: "white" }}
          >
            <Tabs
              value={userKycStatusValue}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="secondary"
              sx={{
                px: 2,
                "& .MuiTabs-indicator": {
                  ml: 2,
                  // maxWidth: "90px",
                },
              }}
            >
              <Tab
                value="all"
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={OrderCount.all || 0}
                      chipColor={"rgba(0, 184, 217, 0.16)"}
                      chipFontColor={"#006C9C"}
                    />
                    <TypographyText fontSize={14} text={"All"} />
                  </Stack>
                }
              ></Tab>
              <Tab
                value="approved"
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={OrderCount.approved || 0}
                      chipColor={"rgba(145, 158, 171, 0.16)"}
                      chipFontColor={"rgba(33, 43, 54, 1)"}
                    />
                    <TypographyText text={"Approved"} fontSize={14} />
                  </Stack>
                }
              ></Tab>
              <Tab
                value="active"
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={OrderCount.active || 0}
                      chipColor={"rgba(54, 179, 126, 0.16)"}
                      chipFontColor={"green"}
                    />
                    <TypographyText text={"Active"} fontSize={14} />
                  </Stack>
                }
              ></Tab>
              <Tab
                value="cancelled"
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={OrderCount.cancelled || 0}
                      chipColor={"rgba(255, 86, 48, 0.16)"}
                      chipFontColor={"rgba(183, 29, 24, 1)"}
                    />
                    <TypographyText text={"Cancelled"} fontSize={14} />
                  </Stack>
                }
              ></Tab>
              <Tab
                value="rejected"
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={OrderCount.rejected || 0}
                      chipColor={"rgba(255, 86, 48, 0.16)"}
                      chipFontColor={"rgba(183, 29, 24, 1)"}
                    />
                    <TypographyText text={"Rejected"} fontSize={14} />
                  </Stack>
                }
              ></Tab>
              <Tab
                value="expired"
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={OrderCount.expired || 0}
                      chipColor={"rgba(255, 86, 48, 0.16)"}
                      chipFontColor={"rgba(183, 29, 24, 1)"}
                    />
                    <TypographyText text={"Expired"} fontSize={14} />
                  </Stack>
                }
              ></Tab>
              <Tab
                value="complete"
                sx={{ textTransform: "none", px: 2 }}
                label={
                  <Stack display={"flex"} direction={"row"} spacing={1.5}>
                    <Chips
                      count={
                        OrderCount && OrderCount.completed
                          ? OrderCount.completed
                          : 0
                      }
                      chipColor={"rgba(54, 179, 126, 0.16)"}
                      chipFontColor={"green"}
                    />
                    <TypographyText text={"Complete"} fontSize={14} />
                  </Stack>
                }
              ></Tab>
            </Tabs>
            <Divider sx={{ color: "rgba(145, 158, 171, 0.24)" }}></Divider>
            <Stack
              sx={{ mx: 4, py: 2 }}
              display={"flex"}
              direction={"row"}
              spacing={0.5}
            >
              {" "}
              <Box>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Start Date"
                        format="DD/MM/YYYY"
                        sx={{
                          style: {
                            borderRadius: "8px",
                          },
                        }}
                        onChange={(value: any) =>
                          handleonChangeForDate(value ? value.$d : "")
                        }
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: {
                              "& .MuiInputBase-root": {
                                height: 50,
                                maxWidth: "100%",
                                borderRadius: "8px",
                              },
                              "& .MuiFormLabel-root": {
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "14px",
                                mt: 0.2,
                              },
                            },
                            style: {
                              borderRadius: "16px",
                              borderColor: "#919EAB",
                              // width: "100%",
                              // maxWidth: "50%",
                              marginTop: 0,
                            },
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="End Date"
                        format="DD/MM/YYYY"
                        minDate={dayjs(startDate)}
                        disabled={startDate === ""}
                        sx={{
                          style: {
                            borderRadius: "8px",
                          },
                        }}
                        onChange={(value: any) =>
                          handleonChangeForDate1(value ? value.$d : "")
                        }
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: {
                              "& .MuiInputBase-root": {
                                height: 50,
                                borderRadius: "8px",
                              },
                              "& .MuiFormLabel-root": {
                                color: "#919EAB",
                                fontFamily: "Public Sans",
                                fontWeight: "500",
                                fontSize: "14px",
                                mt: 0.2,
                              },
                            },
                            style: {
                              borderRadius: "16px",
                              borderColor: "#919EAB",
                              width: "100%",
                              maxWidth: "50%",
                              marginTop: 0,
                            },
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <TextField
                fullWidth
                onChange={(e) => setsearchValue(e.target.value)}
                placeholder="Search by Business Name"
                value={searchValue}
                InputProps={{
                  sx: {
                    my: 1,
                  },
                  style: { borderRadius: "10px", height: "50px" },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box>
                <Button
                  size="small"
                  onClick={resetSeachValue}
                  sx={{
                    color: "white",
                    mt: 1.7,
                    px: 2,
                    py: 1,
                    ml: 1,
                    borderRadius: "8px",
                    backgroundColor: "#303981",
                    textAlign: "right",
                    fontFamily: "Public Sans",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#303981",
                    },
                  }}
                >
                  <Typography12 color={"white"} text={"Reset"}></Typography12>
                </Button>
              </Box>
            </Stack>
            <Box>
              <PaymentListTable
                props={OrderList}
                PaymentSelectType={tabValue}
              />
            </Box>
            <Box>
              <Divider></Divider>
              <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
                <TablePagination
                  component="div"
                  count={OrderCount.all}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
        <SimpleLoader props={open} />
      </>
    </div>
  );
};

export default Dashboard;
