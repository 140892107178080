import { Box, Stack, Container, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Docs1 from "../../assets/Docs1.svg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LinearDeterminate from "../../components/ProgressBar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { useSelector } from "react-redux";
import { globalStatusExecute } from "../../utils/ApiGlobal";

const ReUpload = () => {
  document.title = "Let's Get Started with Your Information";
  const navigate = useNavigate();
  const locationData = useSelector((state: any) => state.user.LocationInfo);
  const newToken = useSelector((state:any)=>state.user.Token)
  // const [FileUploadStatus, setFileUploadStatus] = useState(false);
  const [uploadFileData, setuploadFileData] = useState(null);
  const [PanFlie1, setPanFlie1] = useState(false);
  const [PanFlie1BtnDisabled, setPanFlie1BtnDisabled] = useState(false);
  const [PanFlie2BtnDisabled, setPanFlie2BtnDisabled] = useState(false);
  const [PanFlie3BtnDisabled, setPanFlie3BtnDisabled] = useState(false);

  const [PanFlie2, setPanFlie2] = useState(false);
  const [PanFlie3, setPanFlie3] = useState(false);
  const [Token, setToken] = useState<any>("");
  const [activeStep, setActiveStep] = useState(2);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  console.log("file", uploadFileData);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  useEffect(() => {
    let tokenData = localStorage.getItem("Token");
    setToken(tokenData);
  });
  const getRejectedDocus = async()=>{
    const axiosConfig = {
      headers: {
        token: newToken !== "" ? newToken : "",
      },
    };
    const res1 = await axios
      .get(
        "https://api.dev.assuredpay.in/userkyc/getrejecteddocuments",
        axiosConfig
      )
      .then((res) => {
          console.log("async docus checks",res)
      })
      .catch((err)=>{
         console.log(err)
      })
  } 
  useEffect(()=>{
    getRejectedDocus()
  })
  return (
    <Box margin={1} sx={{ flexGrow: 1 }}>
      <Container component="main"  sx={{ mt: 4 }}>
        <Box
          boxShadow={"1"}
          justifyContent={"center"}
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 5,
            py: 2,
            
          }}
        >
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ fontSize: "12px" }}
          >
            <Grid sx={{ order: { xs: 12, sm: 2 }, mt: 4, mb: 1 }}>
              <Typography
                component="div"
                fontFamily={"Public Sans"}
                fontSize={20}
                fontWeight={700}
                lineHeight={"30px"}
                color={"#212B36"}
              >
                Reupload Your Documents
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                mb: 5,
                color: "#556486",
                fontWeight: 600,
                fontFamily: "Public Sans",
                fontStyle: "normal",
              }}
            >
              {" "}
              List of documents rejected from Assured Pay team. Please reupload
              for approval.
            </Typography>
          </Box>

          <Box padding={0}>
            <Grid container>
              <Grid xs={10}>
                <Item>
                  <Stack display={"flex"} direction={"row"} spacing={5}>
                    <img
                      src={Docs1}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginTop: "0px",
                      }}
                      alt=""
                    />
                    <Typography
                      fontSize={14}
                      color={"#454F5B"}
                      fontStyle={"normal"}
                      fontFamily={"Public Sans"}
                      fontWeight={500}
                    >
                      {" "}
                      Choose front side of Aadhar Card
                    </Typography>
                  </Stack>{" "}
                </Item>
              </Grid>
              {/* <Grid xs>
                <Item>
                  {PanFlie1 ? (
                    <LinearDeterminate />
                  ) : (
                    <>
                      {!PanFlie1BtnDisabled ? (
                        <Button
                          component="label"
                          // variant="contained"
                          disabled={PanFlie1BtnDisabled}
                          size="small"
                          sx={{
                            border: "1px solid grey",
                            height: "25px",
                            color: "black",
                            px: 2,
                            fontFamily: "Public Sans",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          startIcon={<FileUploadIcon sx={{ mt: 0 }} />}
                        >
                          Upload
                          <VisuallyHiddenInput
                            name="aadharFileUrl"
                            onChange={handleFileUpload}
                            sx={{ px: 2 }}
                            type="file"
                          />
                        </Button>
                      ) : (
                        <Stack direction="row">
                          <>
                            <DoneIcon
                              style={{ fontSize: "12px", color: "#36B37E" }}
                            />{" "}
                            <Typography
                              fontWeight={700}
                              fontSize={10}
                              fontFamily={"Public Sans"}
                              color={"#36B37E"}
                            >
                              {" "}
                              File upload Successfully
                            </Typography>
                          </>
                        </Stack>
                      )}
                    </>
                  )}  
                </Item>
                <Item>
                  {PanFlie2 ? (
                    <LinearDeterminate />
                  ) : (
                    <>
                      {!PanFlie2BtnDisabled ? (
                        <Button
                          component="label"
                          // variant="contained"
                          disabled={PanFlie2BtnDisabled}
                          size="small"
                          sx={{
                            border: "1px solid grey",
                            height: "25px",
                            color: "black",
                            px: 2,
                            fontFamily: "Public Sans",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          startIcon={<FileUploadIcon sx={{ mt: 0 }} />}
                        >
                          Upload
                          <VisuallyHiddenInput
                            name="aadharBackUrl"
                            onChange={handleFileUpload}
                            sx={{ px: 2 }}
                            type="file"
                          />
                        </Button>
                      ) : (
                        <Stack direction="row">
                          <>
                            <DoneIcon
                              style={{ fontSize: "12px", color: "#36B37E" }}
                            />{" "}
                            <Typography
                              fontWeight={700}
                              fontSize={10}
                              fontFamily={"Public Sans"}
                              color={"#36B37E"}
                            >
                              {" "}
                              File upload Successfully
                            </Typography>
                          </>
                        </Stack>
                      )}
                    </>
                  )}
                </Item>
                <Item>
                  {PanFlie3 ? (
                    <LinearDeterminate />
                  ) : (
                    <>
                      {!PanFlie3BtnDisabled ? (
                        <Button
                          component="label"
                          // variant="contained"
                          disabled={PanFlie3BtnDisabled}
                          size="small"
                          sx={{
                            border: "1px solid grey",
                            height: "25px",
                            color: "black",
                            px: 2,
                            fontFamily: "Public Sans",
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          startIcon={<FileUploadIcon sx={{ mt: 0 }} />}
                        >
                          Upload
                          <VisuallyHiddenInput
                            name="PANFile"
                            onChange={handleFileUpload}
                            sx={{ px: 2 }}
                            type="file"
                          />
                        </Button>
                      ) : (
                        <Stack direction="row">
                          <>
                            <DoneIcon
                              style={{ fontSize: "12px", color: "#36B37E" }}
                            />{" "}
                            <Typography
                              fontWeight={700}
                              fontSize={10}
                              fontFamily={"Public Sans"}
                              color={"#36B37E"}
                            >
                              {" "}
                              File upload Successfully
                            </Typography>
                          </>
                        </Stack>
                      )}
                    </>
                  )}
                </Item>
              </Grid> */}
            </Grid>
            <Stack display={"flex"} direction={"row"} sx={{ mt: 8, py: 3 }}>
              <Button
                size="small"
                disabled={
                  !PanFlie1BtnDisabled ||
                  !PanFlie2BtnDisabled ||
                  !PanFlie3BtnDisabled
                }
                // onClick={handleOnClickfileUploadSuccess}
                sx={{
                  textTransform: "none",
                  backgroundColor:
                    !PanFlie1BtnDisabled ||
                    !PanFlie2BtnDisabled ||
                    !PanFlie3BtnDisabled
                      ? "rgba(145, 158, 171, 0.24)"
                      : "#303981",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  color:
                    !PanFlie1BtnDisabled ||
                    !PanFlie2BtnDisabled ||
                    !PanFlie3BtnDisabled
                      ? "black"
                      : "white",
                  px: 2,
                  "&:hover": {
                    backgroundColor:
                      !PanFlie1BtnDisabled ||
                      !PanFlie2BtnDisabled ||
                      !PanFlie3BtnDisabled
                        ? "rgba(145, 158, 171, 0.24)"
                        : "#303981",
                  },
                }}
                color="inherit"
              >
                Continue
              </Button>
            </Stack>
          </Box>
          {/* Gst Verify Check for Button and Success Msg showing after successfull gst verify check */}
        </Box>
      </Container>
    </Box>
  );
};

export default ReUpload;
